import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Cross = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 152 152">
    <g
      transform="translate(23 23)"
      stroke={color}
      strokeWidth={4}
      fill="none"
      fillRule="evenodd"
    >
      <circle cx={53.5} cy={53.5} r={53.5} />
      <path
        d="M29 29l48.578 48.578M77.02 29L29 77.02"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

Cross.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Cross.defaultProps = {
  width: 152,
  height: 152,
  color: theme.palette.primary.default
};

export default Cross;
