import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Info = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 20 20">
    <g transform="translate(-462.000000, -441.000000)">
      <g transform="translate(460.000000, 439.000000)">
        <path
          d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,11 L13,11 L13,17 Z M13,9 L11,9 L11,7 L13,7 L13,9 Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

Info.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Info.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Info;
