import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Envelop = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 59 45">
    <path
      d="M7.814 42.712c-.806 0-1.57-.177-2.26-.484L20.152 31.88c.53-.358.663-1.07.299-1.591a1.177 1.177 0 0 0-1.618-.294L3.845 40.619c-.08.054-.148.118-.21.186a5.301 5.301 0 0 1-1.306-3.483V7.678c0-.952.254-1.847.698-2.623.006.003.01.008.015.012l26.226 17.72c.24.163.522.22.791.19.27.03.551-.027.793-.19L56.255 5.623c.267.633.416 1.327.416 2.055v29.644c0 1.086-.331 2.096-.896 2.942L41.287 29.996a1.177 1.177 0 0 0-1.619.294c-.364.52-.23 1.233.3 1.591l14.119 10.007a5.518 5.518 0 0 1-2.902.824H7.815zm43.37-40.424c1.446 0 2.759.556 3.74 1.457L30.059 20.546 4.584 3.331a5.51 5.51 0 0 1 3.23-1.043h43.37zm0-2.288H7.815C3.505 0 0 3.444 0 7.678v29.644C0 41.556 3.505 45 7.814 45h43.37C55.495 45 59 41.556 59 37.322V7.678C59 3.444 55.494 0 51.185 0z"
      fill={color}
    />
  </svg>
);

Envelop.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Envelop.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Envelop;
