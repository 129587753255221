import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { centsToBasicUnit } from "../../../../utils";
import { useLocaleCurrency } from "../../../../hooks";
import { Button, Typography } from "../../../../atoms";
import { Visa, MasterCard, NoStore, Info } from "../../../../atoms/Icons";
import { ErrorBoundary } from "../../../../templates";
import useMonitoringContext from "../../../../contexts/Monitoring";

import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  order_list_to_update_item: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0 15px",
    borderBottom: `1px solid ${theme.palette.shade.default}`
  },
  order_list_to_update_item__text: {
    marginLeft: "10px",
    minWidth: "44%",
    "@media(min-width: 768px)": {
      minWidth: "51%"
    },
    "& > span": {
      display: "block"
    },
    "& > svg": {
      verticalAlign: "middle"
    },
    fontSize: "13px"
  },
  order_list_to_update_item__actions: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "45%",
    "@media(min-width: 768px)": {
      width: "36%"
    },
    "& > span": {
      marginTop: "5px",
      cursor: "pointer"
    }
  },
  order_list_to_update_item__action: {
    width: "90px"
  },
  order_list_to_update_item__info: {
    lineHeight: "normal"
  }
}));

const OrderListToUpdate = ({
  orders,
  onClickUpdateCard,
  currentCardReferenceId,
  currentOrder
}) => {
  const [isTooltipOpened, setTooltipOpened] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { monitoringSetContext } = useMonitoringContext();
  return (
    <ul>
      {orders.map(order => (
        <ErrorBoundary
          onError={() => {
            monitoringSetContext(order);
          }}
          key={order.id}
        >
          <li className={classes.order_list_to_update_item}>
            {order.merchant.logo ? (
              <img src={order.merchant.logo} alt={order.merchant.name} />
            ) : (
              <NoStore width={39} height={34} />
            )}
            <div className={classes.order_list_to_update_item__text}>
              <Typography
                className={classes.modal__content__title}
                variant={K.typographicVariants.body2}
                color={theme.palette.primary.default}
              >
                {order.merchant.name}
              </Typography>
              {order.cardReference.issuer === "Mastercard" ? (
                <MasterCard width={28} height={24} />
              ) : (
                <Visa width={32} height={24} />
              )}{" "}
              {`.... .... .... ${order.cardReference.lastFour}`}
            </div>
            <div className={classes.order_list_to_update_item__actions}>
              <span
                data-tip
                data-for="fees_saved_info"
                data-event="click"
                className={classes.amortization_summary_table__content__info}
              >
                <Info
                  width={20}
                  height={20}
                  color={
                    isTooltipOpened
                      ? theme.palette.info.light
                      : theme.palette.info.default
                  }
                />
              </span>
              <Button
                size="small"
                color="primary"
                className={classes.order_list_to_update_item__action}
                onClick={() =>
                  onClickUpdateCard(
                    order.id,
                    currentCardReferenceId,
                    currentOrder
                  )
                }
              >
                {intl.formatMessage(locales.update_cards_flow_update_button)}
              </Button>
              <ReactTooltip
                id="fees_saved_info"
                backgroundColor={theme.palette.info.default}
                effect="solid"
                globalEventOff="click"
                className={classes.amortization_summary_table__content__tooltip}
                afterShow={() => {
                  setTooltipOpened(true);
                }}
                afterHide={() => {
                  setTooltipOpened(false);
                }}
              >
                <p className={classes.order_list_to_update_item__info}>
                  {intl.formatMessage(locales.update_cards_flow_info_date)}{" "}
                  {intl.formatDate(order.confirmedAt, {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    timeZone: "Europe/Madrid"
                  })}
                </p>
                <p className={classes.order_list_to_update_item__info}>
                  {intl.formatMessage(locales.update_cards_flow_info_total)}{" "}
                  {useLocaleCurrency(centsToBasicUnit(order.currentOrderValue))}
                </p>
                <p className={classes.order_list_to_update_item__info}>
                  {intl.formatMessage(locales.update_cards_flow_info_articles)}{" "}
                  {order.cart.length}
                </p>
              </ReactTooltip>
            </div>
          </li>
        </ErrorBoundary>
      ))}
    </ul>
  );
};

OrderListToUpdate.propTypes = {
  orders: PropTypes.oneOfType([() => null, PropTypes.object]),
  currentOrder: PropTypes.oneOfType([() => null, PropTypes.object]),
  currentCardReferenceId: PropTypes.string,
  onClickUpdateCard: PropTypes.func.isRequired
};

OrderListToUpdate.defaultProps = {
  orders: [],
  currentOrder: [],
  currentCardReferenceId: ""
};

export default OrderListToUpdate;
