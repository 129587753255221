import React from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button } from "@sequra/telar";
import { OrderContext } from "../../../../../contexts";
import { Paper, Typography } from "../../../../../atoms";
import { centsToBasicUnit, isMobile } from "../../../../../utils";
import locales from "../../../../../locales";
import K from "../../../../../constants";

const useStyles = createUseStyles({
  refund_process_confirmed_wrapper: {
    marginRight: "24px",
    maxWidth: "612px"
  },
  content: {
    margin: "16px 0",
    lineHeight: "24px"
  },
  refund_process_confirmed_thanks_message: {
    marginTop: "16px",
    display: "block"
  }
});

const Confirmed = () => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();
  const order = React.useContext(OrderContext);

  return (
    <div className={classes.refund_process_confirmed_wrapper}>
      <Paper>
        <Typography variant={K.typographicVariants.heading3}>
          {intl.formatMessage(locales.order_refund_confirmed_received)}
        </Typography>
        <div className={classes.content}>
          <Typography variant={K.typographicVariants.body1}>
            {intl.formatMessage(locales.order_refund_confirmed_notification, {
              amount: intl.formatNumber(
                centsToBasicUnit(order.refundProcess.amount),
                { style: "currency", currency: "EUR" }
              )
            })}
          </Typography>
          <Typography
            variant={K.typographicVariants.body1}
            className={classes.refund_process_confirmed_thanks_message}
          >
            {intl.formatMessage(locales.order_refund_confirmed_thanks)}
          </Typography>
        </div>

        <Button
          type="button"
          fullWidth={isMobile.phone}
          onClick={() => {
            history.push(`/order/${order.id}`);
          }}
        >
          {intl.formatMessage(locales.order_refund_confirmed_back_to_order)}
        </Button>
      </Paper>
    </div>
  );
};

export default Confirmed;
