import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useOrder, useLocaleCurrency, useConversionGateway } from "../../hooks";
import {
  ConversionSelectPlanModal,
  ConfirmationPlanModal,
  SuccessModal,
  ErrorModal
} from "./modals";
import K from "../../constants";

const ConversionFlow = () => {
  const [conversionPlan, setConversionPlan] = useState(null);
  const order = useOrder();
  const location = useLocation();
  const intl = useIntl();

  const query = queryString.parse(location.search);
  // eslint-disable-next-line camelcase
  const { instalment_count } = query;
  // eslint-disable-next-line camelcase
  const instalmentCountSelected = parseInt(instalment_count, 10);

  const {
    onClickConversion,
    conversionPlanSelectedDetails,
    onRestartConversion
  } = useConversionGateway(order?.id, instalmentCountSelected);

  const { status, amount, dueOn } = conversionPlanSelectedDetails;

  const dueOnFormatted = intl.formatDate(dueOn, {
    month: "short",
    day: "numeric",
    timeZone: "Europe/Madrid"
  });
  const amountFormatted = useLocaleCurrency(amount, {
    useCents: true
  });
  const conversionPlanSelected = order.conversionPlans.find(
    // eslint-disable-next-line camelcase
    plan => plan.instalmentCount === instalmentCountSelected
  );

  useEffect(() => {
    setConversionPlan(order.conversionPlans.find(plan => plan.default));
  }, []);

  if (!order.isConvertible) {
    return null;
  }

  switch (query.step) {
    case K.steps.flexiSelectPlan:
      return (
        <ConversionSelectPlanModal
          selectedPlan={conversionPlan}
          setConversionPlan={setConversionPlan}
        />
      );
    case K.steps.flexiConfirmationPlan:
      return (
        <ConfirmationPlanModal
          selectedPlan={conversionPlanSelected}
          onClickConversion={onClickConversion}
          conversionPlanStatus={status}
        />
      );
    case K.steps.flexiSuccess:
      return amount > 0 ? (
        <SuccessModal amount={amountFormatted} dueOn={dueOnFormatted} />
      ) : (
        window.location.replace(location.pathname)
      );
    case K.steps.flexiError:
      return <ErrorModal onRestartConversion={onRestartConversion} />;
    default:
      return null;
  }
};

export default ConversionFlow;
