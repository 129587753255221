import K from "../constants";

export default (active, disabled) => {
  if (disabled) {
    return K.colors.tertiary;
  }
  if (!active) {
    return K.colors.secondary;
  }
  return K.colors.primary;
};
