import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const CheckCircle = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7a.996.996 0 111.41-1.41L10 14.17l6.88-6.88a.996.996 0 111.41 1.41l-7.59 7.59a.996.996 0 01-1.41 0z"
        fill={color}
      />
    </g>
  </svg>
);

CheckCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

CheckCircle.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default CheckCircle;
