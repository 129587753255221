import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../utils";
import K from "../../../constants";
import locales from "../../../locales";
import { getRemainingDaysToDateFromNow } from "../../../utils/datetime/datetimeUtils";
/*
  detailedOrder param in methods is the value returned by
  getOrderDetail() util.
*/
export const getNextActionCardTextColor = detailedOrder => {
  if (detailedOrder.isOverdue) {
    return K.colors.error;
  }
  switch (detailedOrder.currentProductName) {
    case K.products.pp1:
    case K.products.pp2:
    case K.products.pp3:
    case K.products.sp1:
    case K.products.pp5:
    case K.products.pp6:
    case K.products.pp9:
      return K.colors.info;
    case K.products.i1:
      return detailedOrder.isShipped ? K.colors.success : K.colors.secondary;
    default:
      return K.colors.secondary;
  }
};
export const getNextActionCardPaymentAmount = detailedOrder => {
  if (!detailedOrder || detailedOrder.isPaid) {
    return 0;
  }
  let nextAmount = 0;
  if (detailedOrder.isPaid) {
    return nextAmount;
  }
  if (detailedOrder.currentProductName === "i1") {
    if (detailedOrder.overdueInvoices.length > 0) {
      detailedOrder.overdueInvoices.forEach(i => {
        nextAmount += i.unpaidAmount;
      });
    } else if (detailedOrder.unpaidInvoices.length > 0) {
      nextAmount = detailedOrder.unpaidInvoices[0].unpaidAmount;
    } else {
      nextAmount = detailedOrder.unshippedCart.totalWithTax;
    }
  } else if (detailedOrder.payments.overdueCount > 0) {
    detailedOrder.overdueInstalments.forEach(i => {
      nextAmount += i.unpaidAmount;
    });
  } else if (detailedOrder.payments.unpaidCount > 0) {
    nextAmount = detailedOrder.unpaidInstalments[0].unpaidAmount;
  }
  return nextAmount;
};
export const getNextActionCardPaymentDateStr = detailedOrder => {
  if (!detailedOrder || detailedOrder.isPaid) {
    return null;
  }
  let nextDate;
  if (detailedOrder.isPaid) {
    nextDate = null;
  } else if (detailedOrder.currentProductName === "i1") {
    if (detailedOrder.overdueInvoices.length > 0) {
      nextDate = detailedOrder.overdueInvoices[0].dueOn;
    } else if (detailedOrder.unpaidInvoices.length > 0) {
      nextDate = detailedOrder.unpaidInvoices[0].dueOn;
    } else {
      nextDate = null;
    }
  } else if (detailedOrder.payments.overdueCount > 0) {
    nextDate = detailedOrder.overdueInstalments[0].dueOn;
  } else if (detailedOrder.payments.unpaidCount > 0) {
    nextDate = detailedOrder.unpaidInstalments[0].dueOn;
  }
  return nextDate;
};
export const getNextActionCardProps = detailedOrder => {
  return detailedOrder
    ? {
        title: locales.next_payment.id,
        withoutNextAction: false,
        amountColor: getNextActionCardTextColor(detailedOrder),
        nextPaymentAmountStr: getNextActionCardPaymentAmount(detailedOrder),
        nextPaymentDateStr: getNextActionCardPaymentDateStr(detailedOrder),
        orderMerchantName: detailedOrder.merchant.name,
        orderDateStr: detailedOrder.confirmedAt,
        orderItemsTotal: getTotalCountFromItemsList(
          getArticlesListFromCart(detailedOrder?.cart)
        )
      }
    : {
        title: locales.next_payment.id,
        withoutNextAction: true
      };
};
export const getDashboardMessageProps = nextActionOrder => {
  let message;
  let textColor;
  let cardColor;
  let buttonText;
  if (!nextActionOrder) {
    message = locales.dashboard_message_empty.id;
    cardColor = K.colors.primary;
    textColor = K.colors.white;
    buttonText = locales.dashboard_message_empty_button_text.id;
  } else if (nextActionOrder.isOverdue) {
    message = locales.dashboard_message_payment_delay.id;
    cardColor = K.colors.error;
    textColor = K.colors.primary;
    buttonText = locales.pay_debt.id;
  } else if (nextActionOrder.hasExpiredCard) {
    message = locales.dashboard_message_expired_card.id;
    cardColor = K.colors.error;
    textColor = K.colors.primary;
    buttonText = locales.change_card.id;
  } else if (nextActionOrder.hasExpiringSoonCard) {
    message = locales.dashboard_message_expiring_card.id;
    cardColor = K.colors.error;
    textColor = K.colors.primary;
    buttonText = locales.change_card.id;
  } else if (nextActionOrder.paymentPlan) {
    message = locales.dashboard_message_you_are_okay.id;
    cardColor = K.colors.info;
    textColor = K.colors.primary;
    buttonText = null;
  } else if (nextActionOrder.unpaidInvoices.length === 0) {
    message = locales.dashboard_message_invoice_not_sent.id;
    cardColor = K.colors.tertiary;
    textColor = K.colors.primary;
    buttonText = "";
  } else {
    const remainingDaysToPay = getRemainingDaysToDateFromNow(
      getNextActionCardPaymentDateStr()
    );
    if (remainingDaysToPay > 0 && remainingDaysToPay <= 2) {
      message = locales.dashboard_message_invoice_shipped_payment_soon.id;
      cardColor = K.colors.warning;
      textColor = K.colors.white;
      buttonText = locales.pay_debt.id;
    } else {
      message = locales.dashboard_message_invoice_shipped.id;
      cardColor = K.colors.success;
      textColor = K.colors.white;
      buttonText = locales.pay_debt.id;
    }
  }
  return {
    message,
    cardColor,
    textColor,
    buttonText
  };
};
