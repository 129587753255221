const documentTypes = {
  NIF: "NIF",
  NIE: "NIE",
  CIF: "CIF"
};

const NIF_REGEX = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const NIE_REGEX = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;

const isNIF = document => NIF_REGEX.test(document.toUpperCase());
const isNIE = document => NIE_REGEX.test(document.toUpperCase());
const isCIF = document => CIF_REGEX.test(document.toUpperCase());

const getDocumentType = document => {
  if (isNIF(document.toUpperCase())) {
    return documentTypes.NIF;
  }
  if (isNIE(document.toUpperCase())) {
    return documentTypes.NIE;
  }
  if (isCIF(document.toUpperCase())) {
    return documentTypes.CIF;
  }

  return null;
};

export { documentTypes, getDocumentType };
