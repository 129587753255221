import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Input from "../../atoms/Input";

const useStyles = createUseStyles({
  otp: {
    display: "flex",
    justifyContent: "space-between",
    "& > input": {
      width: "53px",
      fontSize: "24px",
      textAlign: "center"
    }
  }
});

const Otp = ({ onChangeOTP, numberOfItems }) => {
  const classes = useStyles();
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array(numberOfItems).fill(""));

  const focusInput = useCallback(
    inputIndex => {
      const selectedIndex = Math.max(
        Math.min(numberOfItems - 1, inputIndex),
        0
      );
      setActiveInput(selectedIndex);
    },
    [numberOfItems]
  );

  const handleOtpChange = useCallback(
    otp => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );
  const changeCodeAtFocus = useCallback(
    value => {
      const updatedOTPValues = [...otpValues];
      const updatedOTPValuesCurrent = updatedOTPValues[activeInput];
      if (
        !updatedOTPValuesCurrent ||
        (updatedOTPValuesCurrent.length === 1 && !value)
      ) {
        updatedOTPValues[activeInput] = value || "";
        setOTPValues(updatedOTPValues);
        handleOtpChange(updatedOTPValues);
      }
    },
    [activeInput, handleOtpChange, otpValues]
  );

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnChange = useCallback(
    (e, value) => {
      if (!value) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(value);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const handleOnKeyDown = useCallback(
    e => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            const activeInputValueToUpdate =
              activeInput - 1 >= 0 ? activeInput - 1 : 0;
            setActiveInput(activeInputValueToUpdate);
            changeCodeAtFocus();
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    e => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, numberOfItems - activeInput)
        .split("");

      const isPastedDataAString = pastedData.map(Number).includes(NaN);

      if (pastedData && pastedData.length > 0 && !isPastedDataAString) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = pastedData.shift() || val;
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, numberOfItems - 1));
        handleOtpChange(updatedOTPValues);
      }
    },
    [activeInput, numberOfItems, otpValues]
  );

  return (
    <div className={classes.otp}>
      {Array(numberOfItems)
        .fill("")
        .map((_, index) => (
          <Input
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            type="number"
            value={otpValues && otpValues[index]}
            inputRef={React.createRef()}
            focus={index === activeInput}
            maxLength={1}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onPaste={handleOnPaste}
            testid={`testid${index}`}
          />
        ))}
    </div>
  );
};

Otp.propTypes = {
  onChangeOTP: PropTypes.func.isRequired,
  numberOfItems: PropTypes.number.isRequired
};

export default Otp;
