import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Star = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 20 19">
    <polygon
      points="20 7.24 12.81 6.62 10 0 7.19 6.63 0 7.24 5.46 11.97 3.82 19 10 15.27 16.18 19 14.55 11.97"
      fill={color}
    />
  </svg>
);

Star.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Star.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Star;
