import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(theme => ({
  card: {
    position: "relative",
    backgroundColor: theme.palette.white.default,
    border: `1px solid ${theme.palette.white.default}`,
    borderRadius: ({ borderRadius }) => (borderRadius ? "8px" : "0px"),
    boxShadow: ({ boxShadow }) =>
      boxShadow ? "0 4px 8px 0 rgba(73,73,73,.01);" : "none",
    minWidth: "calc(320px - 32px)",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    /*
      This provokes a warning running on node:
      - Warning: [JSS] Rule is not linked. Missing sheet option "link: true".

      Issue: https://github.com/cssinjs/jss/issues/1277
    */
    "@media(min-width: 768px)": {
      maxWidth: ({ fullWidth }) => (fullWidth ? "100%" : "343px"),
      borderRadius: "8px"
    }
  }
}));

const Card = ({ children, className, fullWidth, borderRadius, boxShadow }) => {
  const classes = useStyles({ fullWidth, borderRadius, boxShadow });

  return (
    <div
      className={classnames(classes.card, className)}
      data-testid="atom_card"
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  borderRadius: PropTypes.bool,
  boxShadow: PropTypes.bool
};

Card.defaultProps = {
  className: "",
  fullWidth: false,
  borderRadius: true,
  boxShadow: false
};

export default Card;
