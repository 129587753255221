import React from "react";
import PropTypes from "prop-types";
import { iconTags, getRoundedIconColors } from "../../utils";
import { Return, Deliver, TagPrice } from "../Icons";

const RoundedIcon = ({ iconTag, color }) => {
  const { circleColor, iconColor } = getRoundedIconColors(color);
  const iconProps = {
    circleColor,
    color: iconColor,
    width: 40,
    height: 40
  };
  switch (iconTag) {
    case iconTags.Deliver:
      return <Deliver {...iconProps} />;
    case iconTags.Return:
      return <Return {...iconProps} />;
    case iconTags.TagPrice:
      return <TagPrice {...iconProps} />;
    default:
      return null;
  }
};

RoundedIcon.propTypes = {
  color: PropTypes.oneOf(["success", "warning", "info"]),
  iconTag: PropTypes.string.isRequired
};

RoundedIcon.defaultProps = {
  color: "sucess"
};

export default RoundedIcon;
