import React from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  MufasaPaymentPlanTokenizationGateway,
  MessageBox
} from "../../../../molecules";
import { useOrder } from "../../../../hooks";
import { withModal } from "../../../../hocs";
import { getIntl } from "../../../../utils";
import useTrackingContext from "../../../../contexts/Tracking";
import useNotificationContext from "../../../../contexts/Notification";
import K from "../../../../constants";
import locales from "../../../../locales";

const MufasaModal = () => {
  const order = useOrder();
  const location = useLocation();
  const intl = useIntl();
  const history = useHistory();
  const { setSnackbarLayoutProps } = useNotificationContext();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  const processSuccessfulAssignCardOperation = ({ trackEventProps }) => {
    track(eventNames.assignCard.success, trackEventProps);
    const success = queryString.stringifyUrl({
      url: location.pathname,
      query: {
        process: K.process.updateCards
      }
    });
    history.replace(success);
  };

  const processFailedCardOperation = ({ trackEventProps }) => () => {
    track(eventNames.assignCard.fail, trackEventProps);
    setSnackbarLayoutProps({
      open: true,
      variant: "error",
      label: intl.formatMessage(locales.ups_something_went_wrong)
    });
  };

  return (
    <>
      <MufasaPaymentPlanTokenizationGateway
        orderId={order.id}
        onSuccess={() =>
          processSuccessfulAssignCardOperation({
            trackEventProps: buildOrderTrackingPayload(order, true, false),
            label: intl.formatMessage(locales.updated_your_order_card, {
              merchantName: order.merchant.name
            })
          })
        }
        onError={() =>
          processFailedCardOperation({
            trackEventProps: buildOrderTrackingPayload(order, true, false)
          })
        }
      />
      <MessageBox
        type="warning"
        text={intl.formatMessage(
          locales.verification_when_new_card_message_line,
          {
            br: <br />
          }
        )}
      />
    </>
  );
};

export default withModal(MufasaModal, {
  title: getIntl.formatMessage(locales.new_card)
});
