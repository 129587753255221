import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Pp5 = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 80 80">
    <path
      d="m70.694 68.635 9.635.296c.38.012.68.328.67.707l-.247 9.547a.691.691 0 0 1-.71.671l-1.363-.035a.691.691 0 0 1-.675-.708l.152-5.847a33.953 33.953 0 0 1-3.792 3.231 33.757 33.757 0 0 1-17.721 6.428c-2.124.14-4.259.083-6.356-.17a.689.689 0 0 1-.597-.783l.192-1.344a.694.694 0 0 1 .775-.585c1.915.229 3.864.28 5.803.15a31.001 31.001 0 0 0 19.548-8.657l-5.398-.165a.691.691 0 0 1-.671-.71l.042-1.357a.691.691 0 0 1 .713-.669zM42.34 78.58a.695.695 0 0 1 .895-.377c1.304.501 2.647.915 4.005 1.236a.69.69 0 0 1 .524.815l-.284 1.329a.69.69 0 0 1-.83.529 33.771 33.771 0 0 1-4.446-1.372.686.686 0 0 1-.384-.904zm-4.433-3.04c.653.41 1.325.795 2.007 1.155.305.16.445.512.34.828l-.039.093-.61 1.214a.693.693 0 0 1-.938.304 33.925 33.925 0 0 1-2.26-1.3.685.685 0 0 1-.254-.87l.05-.091.752-1.133a.697.697 0 0 1 .952-.2zm-3.839-2.834c.344.297.696.588 1.053.87a.69.69 0 0 1 .187.866l-.06.093-.825 1.083a.693.693 0 0 1-.977.129 34.377 34.377 0 0 1-1.211-1.001.684.684 0 0 1-.123-.89l.07-.09.918-1.007a.694.694 0 0 1 .968-.053zm21.22-43.631.145.057 19.585 9.533c.074.028.148.053.218.092.369.21.595.586.637.999l.007.139v21.145c0 .446-.214.869-.577 1.119l-.126.077-19.399 10.417a1.264 1.264 0 0 1-.257.2 1.563 1.563 0 0 1-.703.174 1.25 1.25 0 0 1-.337-.045c-.06-.016-.121-.05-.181-.077l-.063-.024-.064-.029-.033-.018-.025-.011-.01-.007-19.646-10.551a1.34 1.34 0 0 1-.695-1.048l-.008-.148V39.923c0-.467.234-.904.644-1.138l.06-.027.061-.024-.003-.007 19.714-9.596a1.44 1.44 0 0 1 1.056-.057zM36.45 42.11v18.17l16.99 9.13V50.393L36.45 42.11zm36.705 0L56.194 50.38v19.015l16.96-9.113V42.11zM54.817 31.844l-16.614 8.093 16.584 8.071 16.614-8.086-16.584-8.078z"
      fill={color}
      transform="translate(-11 -16)"
    />
  </svg>
);

Pp5.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Pp5.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Pp5;
