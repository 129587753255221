import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  collapse: {
    display: "flex",
    overflow: "hidden",
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  }
});

const Collapse = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const componentRef = ref || React.useRef(null);
  const { children, collapsed } = props;
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setHeight(componentRef.current.scrollHeight);
  }, []);

  React.useEffect(() => {
    const { current } = componentRef;
    if (collapsed) {
      current.style.height = `${height}px`;
      current.style.opacity = 1;
    } else {
      current.style.height = 0;
      current.style.opacity = 0;
    }
  }, [collapsed]);

  return (
    <div ref={componentRef} className={classes.collapse}>
      {children}
    </div>
  );
});

Collapse.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool
};

Collapse.defaultProps = {
  children: null,
  collapsed: false
};

export default Collapse;
