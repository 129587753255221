import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles({
  payment_card_error: {
    padding: "8px 16px",
    maxWidth: "375px",
    display: "block",
    boxSizing: "border-box",
    "@media(min-width: 768px)": {
      padding: "8px 0 16px"
    }
  }
});

const PaymentCardError = ({
  isExpired,
  isExpiringSoon,
  isDisabled,
  isBlocked
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const color =
    isDisabled && isExpiringSoon
      ? theme.palette.tertiary.default
      : theme.palette.error.default;

  let component = null;

  if (isExpiringSoon) {
    component = isDisabled
      ? intl.formatMessage(locales.expiring_soon_card_long_message)
      : intl.formatMessage(locales.expiring_soon_card_long_message_two);
  } else if (isExpired) {
    component = intl.formatMessage(locales.expired_card_long_message);
  } else if (isBlocked) {
    component = intl.formatMessage(locales.blocked_card_long_message);
  } else {
    return null;
  }

  return (
    <Typography
      color={color}
      variant={K.typographicVariants.caption1}
      className={classes.payment_card_error}
    >
      {component}
    </Typography>
  );
};

PaymentCardError.propTypes = {
  isExpired: PropTypes.bool,
  isExpiringSoon: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isBlocked: PropTypes.bool
};

PaymentCardError.defaultProps = {
  isExpired: false,
  isExpiringSoon: false,
  isDisabled: false,
  isBlocked: false
};

export default PaymentCardError;
