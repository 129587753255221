import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { Typography } from "../../atoms";
import { Card } from "../../atoms/Icons";

const useStyles = createUseStyles(theme => ({
  card_link: {
    padding: "0 16px",
    minHeight: "56px",
    height: "56px",
    borderTop: `1px solid ${theme.palette.shade.default}`,
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

const CardLink = React.forwardRef((props, ref) => {
  const componentRef = ref || React.useRef(null);
  const theme = useTheme();
  const classes = useStyles();
  const { label, onClick } = props;

  return (
    <div className={classes.card_link} ref={componentRef}>
      <i>
        <Card />
      </i>
      <Typography color={theme.palette.success.default}>
        <span
          className={classes.card_link__link}
          onClick={onClick}
          onKeyPress={onClick}
          role="button"
          tabIndex="0"
        >
          {label}
        </span>
      </Typography>
    </div>
  );
});

CardLink.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
};

CardLink.defaultProps = {
  label: "",
  onClick: () => {}
};

export default CardLink;
