import * as React from "react";
import PropTypes from "prop-types";
import injectSheet from "react-jss";
import classNames from "classnames";
import { cover } from "polished";
import ErrorBoundary from "../../templates/ErrorBoundary";

const styles = {
  root: {
    ...cover(),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "999999"
  }
};

const LoaderBox = ({ className, classes, children }) => (
  <ErrorBoundary component="LoaderBox">
    <div className={classNames(classes.root, className)}>{children}</div>
  </ErrorBoundary>
);

LoaderBox.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};

LoaderBox.defaultProps = {
  children: null,
  className: ""
};

export default injectSheet(styles)(LoaderBox);
