import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import { useLocaleCurrency } from "../../../../hooks";
import CopyBox from "../CopyBox";
import locales from "../../../../locales";
import K from "../../../../constants";
import bankAccountLogoFetcher from "./bankAccountLogos";

const useStyles = createUseStyles({
  bank_accounts_section_info: {
    display: "block",
    margin: "16px 0",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "16px",
      "&:last-child": {
        marginBottom: 0
      },
      "& > :first-child": {
        marginBottom: "8px"
      }
    }
  },
  bank_accounts_section_accounts: {
    display: "block",
    marginTop: "16px",
    "& > :first-child": {
      marginBottom: "8px"
    },
    "& > div": {
      margin: "16px 0",
      "& > div": {
        marginBottom: "24px",
        "&:last-child": {
          marginBottom: 0
        },
        "& > img": {
          marginBottom: "8px"
        }
      }
    }
  }
});

const BankAccountsSection = ({
  beneficiary,
  reference,
  amount,
  bankAccounts
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <div className={classes.bank_accounts_section_info}>
        <div>
          <Typography variant={K.typographicVariants.heading4}>
            {intl.formatMessage(locales.amortization_flow_recipient)}:
          </Typography>
          <CopyBox label={beneficiary} />
        </div>
        <div>
          <Typography variant={K.typographicVariants.heading4}>
            {intl.formatMessage(locales.amortization_flow_reference)}:
          </Typography>
          <CopyBox label={reference} />
        </div>
        <div>
          <Typography variant={K.typographicVariants.heading4}>
            {intl.formatMessage(locales.amortization_flow_amount)}:
          </Typography>
          <CopyBox
            label={useLocaleCurrency(amount, {
              useCents: true
            })}
          />
        </div>
      </div>
      <div className={classes.bank_accounts_section_accounts}>
        <Typography variant={K.typographicVariants.heading4}>
          {intl.formatMessage(locales.amortization_flow_iban)}:
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage(locales.amortization_flow_choose_a_bank_account)}
        </Typography>
        <div>
          {bankAccounts.map(({ reference: bankReference, name, iban }) => (
            <div key={bankReference}>
              <img src={bankAccountLogoFetcher(bankReference)} alt={name} />
              <CopyBox label={iban} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

BankAccountsSection.propTypes = {
  beneficiary: PropTypes.string,
  reference: PropTypes.string,
  amount: PropTypes.number,
  bankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      name: PropTypes.string,
      iban: PropTypes.string
    })
  )
};

BankAccountsSection.defaultProps = {
  beneficiary: "SEQURA",
  reference: "",
  amount: 0,
  // Amortization object in graphql includes a list of bank accounts for that payment, we think is a good idea
  // and we'll include them in other payment options, until then we have a hardcoded list of bank accounts.
  bankAccounts: [
    {
      reference: "lacaixa",
      name: "CaixaBank",
      iban: "ES33 2100 0077 9802 0061 9826"
    },
    {
      reference: "sabadell",
      name: "Banco Sabadell",
      iban: "ES87 0081 0063 2100 0170 2177"
    },
    {
      reference: "santander",
      name: "Banco Santander",
      iban: "ES52 0049 4700 3622 1727 6477"
    },
    { reference: "bbva", name: "BBVA", iban: "ES36 0182 1797 3202 0172 5153" }
  ]
};

export default BankAccountsSection;
