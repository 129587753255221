import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import useProfile from "./hooks/useProfile";
import { PageTemplate, ErrorBoundary } from "../../templates";
import { AddCardToYourAccount } from "../../flows";
import ProfileBasicInfo from "./components/ProfileBasicInfo";
import ProfileSelector from "./components/ProfileSelector";
import AutoPayment from "./components/AutoPayment";
import UpdateCardInfo from "./components/UpdateCardInfo";
import CardList from "./components/CardList";
import NextPurchasesPopup from "./components/NextPurchasesPopup";
import MerchantsPromotions from "./components/MerchantsPromotions";
import { BarLoader } from "../../atoms/Loaders";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles({
  profile__row: {
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      flexDirection: "row",
      justifyContent: "space-between"
    }
  },
  profile__main_col: {
    "@media (min-width: 768px)": {
      width: "51%"
    }
  },
  profile__secondary_col: {
    "@media (min-width: 768px)": {
      width: "42%"
    }
  }
});

export const ProfilePage = ({
  fetching,
  basicInfo,
  profileSelectorsEmails,
  profileSelectorsPhones,
  onSuccessAddedSelector,
  profileCards,
  onChangeAutoPayment,
  hasFavoriteCard,
  showSnackBar,
  hasMerchantsPromotionsEnabled,
  renderNextPurchasesPopup,
  toggleNextPurchasesPopup,
  onClickCard,
  onClickRemoveCard,
  onClickRemoveSelector,
  onChangeDataProtection
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    givenNames,
    surnames,
    nin,
    hasAutoPaymentEnabled,
    realm,
    merchantsPromotions
  } = basicInfo;
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <PageTemplate
      title={intl.formatMessage(locales.general_page_title_profile)}
    >
      {query.process === K.process.addCardToYourAccount && (
        <AddCardToYourAccount />
      )}
      <div className={classes.profile__row}>
        <div className={classes.profile__main_col}>
          <ErrorBoundary component="ProfileBasicInfo">
            {fetching ? (
              <BarLoader />
            ) : (
              <ProfileBasicInfo
                name={givenNames}
                surnames={surnames}
                nin={nin}
              />
            )}
          </ErrorBoundary>

          <ErrorBoundary component="ProfileSelectorPhone">
            {fetching ? (
              <BarLoader />
            ) : (
              <ProfileSelector
                selectorType={K.selectors.phone}
                selectorData={profileSelectorsPhones}
                title={intl.formatMessage(
                  locales.profile_selectors_title_phone
                )}
                addSelectorText={intl.formatMessage(
                  locales.profile_add_selector_phone
                )}
                createSelectorPlaceHolder={intl.formatMessage(
                  locales.profile_create_selector_form_placeholder_phone
                )}
                selectorTooltipText={intl.formatMessage(
                  locales.profile_selectors_tooltip_phone
                )}
                onSuccessAddedSelector={onSuccessAddedSelector}
                onClickRemoveSelector={onClickRemoveSelector}
              />
            )}
          </ErrorBoundary>

          <ErrorBoundary component="ProfileSelectorEmail">
            {fetching ? (
              <BarLoader />
            ) : (
              <ProfileSelector
                selectorType={K.selectors.email}
                selectorData={profileSelectorsEmails}
                title={intl.formatMessage(
                  locales.profile_selectors_title_email
                )}
                addSelectorText={intl.formatMessage(
                  locales.profile_add_selector_email
                )}
                createSelectorPlaceHolder={intl.formatMessage(
                  locales.profile_create_selector_form_placeholder_email
                )}
                selectorTooltipText={intl.formatMessage(
                  locales.profile_selectors_tooltip_email
                )}
                onSuccessAddedSelector={onSuccessAddedSelector}
                onClickRemoveSelector={onClickRemoveSelector}
              />
            )}
          </ErrorBoundary>
        </div>

        <div className={classes.profile__secondary_col}>
          {fetching ? (
            <BarLoader />
          ) : (
            <CardList
              profileCards={profileCards}
              onClickCard={onClickCard}
              onClickRemoveCard={onClickRemoveCard}
              toggleNextPurchasesPopup={toggleNextPurchasesPopup}
            />
          )}

          <UpdateCardInfo
            title={intl.formatMessage(locales.update_card_info_title)}
            text={intl.formatMessage(locales.update_card_info_sub_title)}
            link="/orders"
            linkText={intl.formatMessage(locales.update_card_info_action)}
          />
          {fetching ? (
            <BarLoader />
          ) : (
            realm === K.realm.sequra && (
              <AutoPayment
                title={intl.formatMessage(locales.auto_payment_title)}
                description={intl.formatMessage(
                  locales.auto_payment_description
                )}
                moreInfo={intl.formatMessage(
                  locales.payment_cards_auto_payment_more_info
                )}
                snackBarMessage={intl.formatMessage(
                  locales.auto_payment_without_favorite_card_message
                )}
                toggleLabel={intl.formatMessage(locales.auto_payment_label)}
                hasAutoPaymentEnabled={hasAutoPaymentEnabled}
                onChangeAutoPayment={onChangeAutoPayment}
                hasFavoriteCard={hasFavoriteCard}
                showSnackBar={showSnackBar}
              />
            )
          )}
          {fetching ? (
            <BarLoader />
          ) : (
            realm === K.realm.svea &&
            merchantsPromotions?.promotionsAllowed !== "IN_RECOVERY" && (
              <MerchantsPromotions
                description={intl.formatMessage(
                  locales.data_protection_description
                )}
                toggleLabel={intl.formatMessage(locales.data_protection_label)}
                hasMerchantsPromotionsEnabled={hasMerchantsPromotionsEnabled}
                onChangeDataProtection={onChangeDataProtection}
              />
            )
          )}
        </div>
      </div>
      {renderNextPurchasesPopup && (
        <NextPurchasesPopup onClose={toggleNextPurchasesPopup} />
      )}
    </PageTemplate>
  );
};
ProfilePage.propTypes = {
  fetching: PropTypes.bool,
  basicInfo: PropTypes.shape({
    name: PropTypes.string,
    surnames: PropTypes.string,
    nin: PropTypes.string,
    givenNames: PropTypes.string,
    hasAutoPaymentEnabled: PropTypes.bool,
    realm: PropTypes.string,
    merchantsPromotions: PropTypes.shape()
  }).isRequired,
  profileSelectorsEmails: PropTypes.arrayOf(PropTypes.object).isRequired,
  profileSelectorsPhones: PropTypes.arrayOf(PropTypes.object).isRequired,
  profileCards: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSuccessAddedSelector: PropTypes.func.isRequired,
  onChangeAutoPayment: PropTypes.func.isRequired,
  hasFavoriteCard: PropTypes.bool.isRequired,
  showSnackBar: PropTypes.func.isRequired,
  hasMerchantsPromotionsEnabled: PropTypes.bool.isRequired,
  renderNextPurchasesPopup: PropTypes.bool.isRequired,
  toggleNextPurchasesPopup: PropTypes.func.isRequired,
  onClickCard: PropTypes.func.isRequired,
  onClickRemoveCard: PropTypes.func.isRequired,
  onClickRemoveSelector: PropTypes.func.isRequired,
  onChangeDataProtection: PropTypes.func.isRequired
};

ProfilePage.defaultProps = {
  fetching: true
};

const Profile = () => {
  const {
    basicInfo,
    profileSelectorsEmails,
    profileSelectorsPhones,
    fetching,
    hasFavoriteCard,
    hasMerchantsPromotionsEnabled,
    renderNextPurchasesPopup,
    profileCards,
    onSuccessAddedSelector,
    onChangeAutoPayment,
    showSnackBar,
    toggleNextPurchasesPopup,
    onClickCard,
    onClickRemoveCard,
    onClickRemoveSelector,
    onChangeDataProtection
  } = useProfile();
  return (
    <ProfilePage
      basicInfo={basicInfo}
      profileSelectorsEmails={profileSelectorsEmails}
      profileSelectorsPhones={profileSelectorsPhones}
      profileCards={profileCards}
      fetching={fetching}
      onSuccessAddedSelector={onSuccessAddedSelector}
      onChangeAutoPayment={onChangeAutoPayment}
      hasFavoriteCard={hasFavoriteCard}
      hasMerchantsPromotionsEnabled={hasMerchantsPromotionsEnabled}
      showSnackBar={showSnackBar}
      renderNextPurchasesPopup={renderNextPurchasesPopup}
      toggleNextPurchasesPopup={toggleNextPurchasesPopup}
      onClickCard={onClickCard}
      onClickRemoveCard={onClickRemoveCard}
      onClickRemoveSelector={onClickRemoveSelector}
      onChangeDataProtection={onChangeDataProtection}
    />
  );
};
export default Profile;
