import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import locales from "../../../locales";
import K from "../../../constants";
import { Typography } from "../../../atoms";
import { MailSent } from "../../../atoms/Illustrations";

const useStyles = createUseStyles(theme => ({
  email_sent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
    alignItems: "center"
  },
  email_sent__envelop_icon: {
    display: "flex",
    justifyContent: "center",
    marginTop: "45px",
    marginBottom: "60px"
  },
  email_sent__footer__button: {
    color: theme.palette.success.default,
    textDecoration: "none"
  },
  email_sent__link_to_login: {
    textAlign: "left",
    "& a": {
      color: theme.palette.primary.default,
      textDecoration: "underline"
    },
    "& a:active": {
      color: theme.palette.primary.default
    },
    "& a:visited": {
      color: theme.palette.primary.default
    },
    "& a:hover": {
      color: theme.palette.primary.default
    }
  }
}));

const EmailSent = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.email_sent}>
      <Typography variant={K.typographicVariants.heading2}>
        {intl.formatMessage(locales.login_by_email_description)}
      </Typography>
      <div className={classes.email_sent__envelop_icon}>
        <MailSent />
      </div>
      <Typography
        variant={K.typographicVariants.body2}
        color={K.colors.secondary}
        className={classes.email_sent__link_to_login}
      >
        {intl.formatMessage(locales.login_by_email_footer)}
        &nbsp;
        <a href="/login">{intl.formatMessage(locales.login_by_email_link)}</a>
      </Typography>
    </div>
  );
};

export default EmailSent;
