import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Typography } from "../../atoms";
import { MasterCard, Visa, Check } from "../../atoms/Icons";
import K from "../../constants";
import locales from "../../locales";

const useStyles = createUseStyles(theme => ({
  card: {
    width: "100%",
    minHeight: "56px",
    maxHeight: "56px",
    height: "100%",
    backgroundColor: theme.palette.white.default,
    display: "flex",
    alignItems: "center",
    padding: "16px",
    margin: "8px 0",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.shade.default}`,
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    position: "relative",
    "@media (min-width: 768px)": {
      width: "346px",
      maxHeight: "37px",
      minHeight: "37px",
      borderRadius: "8px",
      border: `1px solid ${theme.palette.shade.default}`,
      cursor: "pointer"
    }
  },
  card__left_side: {
    flex: 1,
    display: "flex"
  },
  card__left_side__issuer: {
    marginRight: "16px"
  },
  card__left_side__issuer__last_four__card_point: {
    display: "inline-block",
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.default,
    marginRight: "2px"
  },
  card__left_side__last_four: {
    display: "flex",
    alignItems: "center",
    "& > :last-child": {
      marginLeft: "8px"
    }
  },
  card__right_side: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "card__right_side::icon": {
    width: "32px",
    height: "32px"
  }
}));

const issuers = {
  Visa,
  MasterCard
};

const Card = ({ card, isDefault, canChange, onClick, onChange, className }) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const classNames = classnames(classes.card, className);
  const Issuer = issuers[card.issuer];

  return (
    <div className={classNames}>
      <div
        className={classes.card__left_side}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex="0"
      >
        <div className={classes.card__left_side__issuer}>
          <Issuer width={43} height={21} />
        </div>
        <div className={classes.card__left_side__last_four}>
          {[0, 1, 2, 3].map(n => (
            <span
              className={classes.card__left_side__issuer__last_four__card_point}
              key={n}
            />
          ))}
          <Typography variant={K.typographicVariants.body2}>
            {card.lastFour}
          </Typography>
        </div>
      </div>
      <div
        className={classnames(classes.card__right_side, {
          [classes["card__right_side::icon"]]: isDefault
        })}
        onClick={canChange ? onChange : onClick}
        onKeyPress={canChange ? onChange : onClick}
        role="button"
        tabIndex="0"
      >
        {isDefault ? (
          <Check color={theme.palette.info.default} />
        ) : (
          canChange && (
            <Typography color={theme.palette.success.default}>
              {intl.formatMessage(locales.change)}
            </Typography>
          )
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  card: PropTypes.shape(),
  isDefault: PropTypes.bool,
  canChange: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string
};

Card.defaultProps = {
  card: {},
  isDefault: false,
  canChange: false,
  onChange: () => {},
  onClick: () => {},
  className: ""
};

export default Card;
