import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { Typography } from "../../../atoms";
import { useOrder, useShopperDataQuery } from "../../../hooks";
import { Invoice } from "../components";
import locales from "../../../locales";
import K from "../../../constants";

const useStyles = createUseStyles({
  unpaid: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "16px"
    }
  }
});

const Unpaid = () => {
  const order = useOrder();
  const classes = useStyles();
  const intl = useIntl();
  const shopperResponse = useShopperDataQuery({
    fetchPolicy: K.fetchPolicy.cacheAndNetwork
  }); // TODO: It should be a context, not a query

  return (
    <div className={classes.unpaid}>
      <Typography
        variant={K.typographicVariants.visual2}
        color={K.colors.success}
      >
        {order.hasForcedCard ||
        (order.hasAutoPaymentEnabled &&
          shopperResponse.data?.shopper.hasAutoPaymentEnabled)
          ? intl.formatMessage(locales.auto_payment)
          : intl.formatMessage(locales.order_shipped)}
      </Typography>
      {order.unpaidInvoices.map(invoice => (
        <Invoice
          key={invoice.number}
          invoice={invoice}
          hasAutoPaymentEnabled={
            shopperResponse.data?.shopper.hasAutoPaymentEnabled
          }
        />
      ))}
    </div>
  );
};

export default Unpaid;
