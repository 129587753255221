import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Check = ({ width, height, viewBox, color }) => (
  <svg width={width} height={height} viewBox={viewBox}>
    <path
      d="M7.71 11.296a1 1 0 00-1.42 1.408l3.963 4a1 1 0 001.51-.104l5.99-8a1 1 0 00-1.6-1.2l-5.295 7.073-3.148-3.177z"
      fill={color}
    />
  </svg>
);

Check.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
  color: PropTypes.string
};

Check.defaultProps = {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  color: theme.palette.secondary.default
};

export default Check;
