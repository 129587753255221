import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Typography } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles({
  greeting: {
    padding: "15px 6px 18px 15px",
    "@media (min-width: 768px)": {
      padding: "15px 25px 20px 12px"
    }
  },
  greeting__hello: {
    marginBottom: "7px",
    "@media (min-width: 768px)": {
      marginBottom: "5px",
      marginLeft: "2px"
    }
  },
  greeting__name: {
    display: "block",
    minHeight: "32px",
    "@media (min-width: 768px)": {
      lineHeight: "40px",
      minHeight: "40px"
    }
  }
});

const Greeting = ({ givenNames }) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  return (
    <div className={classes.greeting}>
      <Typography
        variant={K.typographicVariants.visual2}
        className={classes.greeting__hello}
        color={theme.palette.primary.default}
      >
        {intl.formatMessage(locales.general_greeting)},
      </Typography>
      <Typography
        variant={K.typographicVariants.visual1}
        className={classes.greeting__name}
        color={theme.palette.primary.default}
      >
        {givenNames}
      </Typography>
    </div>
  );
};

Greeting.propTypes = {
  givenNames: PropTypes.string
};

Greeting.defaultProps = {
  givenNames: ""
};

export default Greeting;
