import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import Typography from "../Typography";
import { getIconColor, getTypographyColorName } from "../../theme";
import K from "../../constants";
import locales from "../../locales";

const useStyles = createUseStyles(theme => ({
  item_navigation: {
    height: "48px",
    cursor: ({ disabled }) => !disabled && "pointer",
    padding: "4px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  item_navigation__box: {
    display: "flex",
    alignItems: "center",
    padding: "8px 10px 8px 16px"
  },
  item_navigation__box__icon: {
    display: "flex",
    marginRight: "19px",
    "& svg path": {
      fill: ({ svgColor }) => svgColor
    }
  },
  item_navigation__box__name: {
    userSelect: "none"
  },
  item_navigation__beta: {
    backgroundColor: theme.palette.info.medium,
    borderRadius: "16px",
    padding: "0px 6px",
    fontWeight: "700"
  }
}));

const ItemNavigation = React.forwardRef((props, ref) => {
  const {
    icon,
    name,
    onClick,
    active,
    disabled,
    onClickDisabled,
    isBeta
  } = props;

  const theme = useTheme();
  const intl = useIntl();

  const classes = useStyles({
    svgColor: getIconColor(active, disabled, active ? "accent" : "default"),
    disabled
  });

  return (
    <div
      className={classes.item_navigation}
      onClick={disabled ? onClickDisabled : onClick}
      onKeyPress={disabled ? onClickDisabled : onClick}
      role="tab"
      tabIndex="0"
      ref={ref}
    >
      <div className={classes.item_navigation__box}>
        <span className={classes.item_navigation__box__icon}>{icon}</span>
        <Typography
          variant={
            active
              ? K.typographicVariants.heading4
              : K.typographicVariants.heading5
          }
          className={classes.item_navigation__box__name}
          color={getTypographyColorName(active, disabled)}
        >
          {name}
        </Typography>
      </div>
      {isBeta && (
        <Typography
          className={classes.item_navigation__beta}
          variant={K.typographicVariants.body3}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.general_navigation_beta_label)}
        </Typography>
      )}
    </div>
  );
});

ItemNavigation.propTypes = {
  name: PropTypes.node,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  onClickDisabled: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  isBeta: PropTypes.bool
};

ItemNavigation.defaultProps = {
  name: null,
  icon: null,
  onClick: () => {},
  onClickDisabled: () => {},
  active: false,
  disabled: false,
  isBeta: false
};

export default ItemNavigation;
