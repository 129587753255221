import { DateTime } from "luxon";

export const getRemainingDaysBetweenDates = (dateStr1, dateStr2) => {
  const date1 = DateTime.fromISO(dateStr1);
  const date2 = DateTime.fromISO(dateStr2);

  const diff = date2.diff(date1, ["days"]);

  return diff.days;
};

export const getRemainingDaysToDateFromNow = date => {
  const now = new Date();
  return getRemainingDaysBetweenDates(now, date);
};
