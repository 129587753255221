/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { UnicornHappy } from "../../../../../atoms/Illustrations";
import locales from "../../../../../locales";

import { Button } from "../../../../../atoms";

const useStyles = createUseStyles(theme => ({
  promote_merchants_pending: {
    position: "relative",
    borderRadius: "8px",
    fontFamily: theme.fontFamily,
    "&::before": {
      content: "''",
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      backgroundColor: "rgba(96,91,118,0.28)",
      zIndex: "1",
      borderRadius: "8px"
    }
  },
  promote_merchants_pending__info: {
    position: "absolute",
    top: "24px",
    left: "8px",
    right: "8px",
    zIndex: "2",
    boxSizing: "border-box",
    padding: "16px 25px 25px 25px",
    borderRadius: "8px",
    backgroundColor: theme.palette.white.default,
    fontSize: "12px",
    "@media (min-width: 768px)": {
      top: "16px",
      left: "50%",
      marginLeft: "-140px",
      width: "285px"
    }
  },
  promote_merchants_pending__content: {
    filter: `blur(8px)`
  },
  promote_merchants_pending__info_title: {
    margin: "6px 0 10px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px"
  },
  promote_merchants_pending__info_text: {
    fontSize: "14px",
    lineHeight: "19px"
  },
  promote_merchants_pending__info_actions: {
    display: "flex",
    marginTop: "20px",
    "& > button": {
      width: "115px",
      marginRight: "16px"
    },
    "& > button span": {
      fontSize: "11px"
    },
    "@media (min-width: 768px)": {
      marginRight: "0",
      justifyContent: "space-between",
      "& > button": {
        marginRight: "0"
      }
    }
  }
}));

const PromotedMerchantsPending = ({
  children,
  onClickDenied,
  onClickAllowed
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <div className={classes.promote_merchants_pending}>
      <div className={classes.promote_merchants_pending__info}>
        <UnicornHappy
          width={45}
          height={45}
          color={theme.palette.primary.default}
        />

        <p className={classes.promote_merchants_pending__info_title}>
          {intl.formatMessage(
            locales.dashboard_promote_merchants_pending_title
          )}
        </p>
        <p className={classes.promote_merchants_pending__info_text}>
          {intl.formatMessage(locales.dashboard_promote_merchants_pending_text)}
        </p>
        <div className={classes.promote_merchants_pending__info_actions}>
          <Button size="small" variant="outlined" onClick={onClickDenied}>
            {intl.formatMessage(
              locales.dashboard_promote_merchants_pending_denied
            )}
          </Button>
          <Button size="small" onClick={onClickAllowed}>
            {intl.formatMessage(
              locales.dashboard_promote_merchants_pending_allowed
            )}
          </Button>
        </div>
      </div>

      <div className={classes.promote_merchants_pending__content}>
        {children}
      </div>
    </div>
  );
};
PromotedMerchantsPending.propTypes = {
  children: PropTypes.node.isRequired,
  onClickAllowed: PropTypes.func.isRequired,
  onClickDenied: PropTypes.func.isRequired
};

export default PromotedMerchantsPending;
