import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { NextModal, Typography } from "../../../../atoms";
import {
  BankAccountsSection,
  AmortizationModalDescription
} from "../../components";
import { ErrorBoundary } from "../../../../templates";
import locales from "../../../../locales";
import { useOrder } from "../../../../hooks";
import K from "../../../../constants";

const useStyles = createUseStyles({
  modal_title: {
    marginBottom: "32px",
    "& > :first-child": {
      marginBottom: "8px"
    }
  }
});

const AmortizationBankAccountsModal = ({ modalTitle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const order = useOrder();
  const location = useLocation();
  const query = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: "comma"
  });

  const {
    total,
    bankTransferPayment: { beneficiary, reference, bankAccounts }
  } = order.amortization;

  return (
    <ErrorBoundary component="AmortizationBankAccountsModal">
      <NextModal
        open
        withDescription
        contentDescription={
          <AmortizationModalDescription modalTitle={modalTitle} />
        }
        hideBack={query.step === K.steps.summary}
      >
        <div className={classes.modal_title}>
          <Typography
            variant={K.typographicVariants.heading2}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(locales.amortization_flow_payment_by_transfer)}
          </Typography>
          <Typography
            variant={K.typographicVariants.body2}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(
              locales.amortization_flow_payment_by_transfer_description
            )}
          </Typography>
        </div>
        <BankAccountsSection
          beneficiary={beneficiary}
          reference={reference}
          amount={total}
          bankAccounts={bankAccounts}
        />
      </NextModal>
    </ErrorBoundary>
  );
};

AmortizationBankAccountsModal.propTypes = {
  modalTitle: PropTypes.shape({
    id: PropTypes.string,
    defaultMessages: PropTypes.string
  }).isRequired
};

export default AmortizationBankAccountsModal;
