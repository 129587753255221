import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Typography } from "../../../../atoms";
import { DesertBall } from "../../../../atoms/Illustrations";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  empty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "40px",
    "@media(min-width: 768px)": {
      alignSelf: "center",
      marginTop: "80px"
    },
    "& :first-child": {
      marginBottom: "24px"
    },
    "& :nth-child(2)": {
      marginBottom: "16px"
    }
  }
});

const EmptyFinished = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <div className={classes.empty}>
      <DesertBall
        color={theme.palette.primary.default}
        width={85}
        height={85}
      />
      <Typography
        variant={K.typographicVariants.heading2}
        color={K.colors.primary}
      >
        {intl.formatMessage(locales.empty_finished_title)}
      </Typography>
      <Typography variant={K.typographicVariants.body2}>
        {intl.formatMessage(locales.empty_finished_subtitle_one)}
      </Typography>
    </div>
  );
};

export default EmptyFinished;
