import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles({
  tab: {
    border: 0,
    backgroundColor: "transparent",
    padding: 0,
    paddingBottom: "8px",
    marginRight: "24px"
  }
});

const Tab = ({ children, active, onClick }) => {
  const classes = useStyles();

  return (
    <button type="button" className={classes.tab} onClick={onClick}>
      <Typography
        variant={
          active
            ? K.typographicVariants.heading4
            : K.typographicVariants.heading5
        }
        color={active ? K.colors.primary : K.colors.secondary}
      >
        {children}
      </Typography>
    </button>
  );
};

Tab.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

Tab.defaultProps = {
  children: null,
  active: false,
  onClick: () => {}
};

export default Tab;
