import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useMedia } from "react-use";
import { useIntl, defineMessages } from "react-intl";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import { Backdrop, Snackbar, StickyBanner } from "../../atoms";
import { ScaleLoader, BarLoader } from "../../atoms/Loaders";
import { NavBar, SideBar } from "../../organisms";
import { headerResolver, backPathResolver } from "../../utils";
import { useDocumentTitle } from "../../hooks";
import { RequestLoginModal, SessionExpiredModal } from "../../molecules";
import useShopper from "../../contexts/Shopper";

import {
  ORDER_PATH,
  ORDER_ARTICLES_PATH,
  ORDER_INVOICES_PATH,
  ORDER_INSTALMENTS_PATH
} from "../../routes";
import useNotificationContext from "../../contexts/Notification";
import useAppContext from "../../contexts/App";

const useStyles = createUseStyles(theme => ({
  layout_template: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    transition: theme.transitions.default,
    "@media (min-width: 768px)": {
      paddingLeft: 0,
      paddingRight: 0
    },
    "@media (min-width: 1024px)": {
      padding: 0,
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start"
    }
  },
  layout_template__navbar: {
    left: 0,
    right: 0,
    zIndex: 99999,
    height: "49px",
    width: "100%",
    "@media (min-width: 1024px)": {
      display: "none"
    }
  },
  layout_template__sidebar: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: -240,
    zIndex: 100001,
    transition: theme.transitions.default,
    "@media (min-width: 1024px)": {
      position: "static",
      zIndex: 0,
      height: "100vh"
    }
  },
  layout_template__content: {
    width: "100%",
    flex: 1,
    position: "relative",
    backgroundColor: theme.palette.background.default
  }
}));

const messages = defineMessages({
  [ORDER_PATH]: {
    id: "order_path_resolver.summary",
    defaultMessage: "Resumen"
  },
  [ORDER_INVOICES_PATH]: {
    id: "order_path_resolver.invoices",
    defaultMessage: "Pagos"
  },
  [ORDER_ARTICLES_PATH]: {
    id: "order_path_resolver.articles",
    defaultMessage: "Carrito"
  },
  [ORDER_INSTALMENTS_PATH]: {
    id: "order_path_resolver.instalments",
    defaultMessage: "Pagos"
  }
});

const LayoutTemplate = ({ children, className, loader, title }) => {
  const intl = useIntl();
  const { shopper } = useShopper();

  const showStickyBanner = [
    "requested",
    "rejected",
    "request_expired"
  ].includes(shopper?.mandatoryDocumentationRequestStatus);

  const classes = useStyles();
  const navigationPath = headerResolver();

  const location = useLocation();
  const { pathname } = location;

  const template = React.useRef(null);
  const sidebar = React.useRef(null);
  const isMobileDevice = useMedia("(max-width: 1024px)");
  const isMobile = useMedia("(max-width: 768px)");

  const { isSidebarOpen, toggleSidebar, closeSidebar } = useAppContext();

  const {
    snackbarLayoutProps,
    setSnackbarLayoutProps
  } = useNotificationContext();

  useDocumentTitle(title);

  useEffect(() => {
    if (isMobileDevice) {
      template.current.style.right = isSidebarOpen ? "240px" : 0;
      sidebar.current.style.right = isSidebarOpen ? 0 : "-240px";
    }
  }, [isSidebarOpen]);

  return (
    <>
      {showStickyBanner && (
        <StickyBanner
          uploadUrl={`${shopper?.uploaderUrl}?shopper_app_section=${pathname}&from_shopper_app=true`}
        />
      )}
      <div className={classes.layout_template} ref={template}>
        <div className={classes.layout_template__navbar}>
          <NavBar
            onClickMenu={toggleSidebar}
            onClickBack={backPathResolver()}
            label={
              messages[navigationPath] &&
              intl.formatMessage(messages[navigationPath])
            }
          />
        </div>
        <div className={classes.layout_template__sidebar} ref={sidebar}>
          <SideBar />
        </div>
        <div
          className={classnames(classes.layout_template__content, className)}
        >
          <Snackbar
            open={snackbarLayoutProps.open}
            onLeave={() =>
              setSnackbarLayoutProps({
                open: false,
                label: "",
                variant: snackbarLayoutProps.variant
              })
            }
            label={snackbarLayoutProps.label}
            variant={snackbarLayoutProps.variant}
          />
          <RequestLoginModal />
          <SessionExpiredModal />
          {loader && isMobile ? <ScaleLoader fixed /> : null}
          {loader && !isMobile ? <BarLoader /> : null}
          {children}
        </div>
        <Backdrop open={isSidebarOpen} onClick={closeSidebar} />
      </div>
    </>
  );
};

LayoutTemplate.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loader: PropTypes.bool,
  title: PropTypes.string
};

LayoutTemplate.defaultProps = {
  children: null,
  className: "",
  loader: false,
  title: ""
};

export default LayoutTemplate;
