import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const ArrowLeft = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path
      d="M10.414 12l6.293-6.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L10.414 12z"
      fill={color}
    />
  </svg>
);

ArrowLeft.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

ArrowLeft.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default ArrowLeft;
