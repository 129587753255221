import React from "react";
import Toggle from "react-toggle";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Paper, Typography } from "../../../../atoms";
import useTrackingContext from "../../../../contexts/Tracking";

import K from "../../../../constants";
import locales from "../../../../locales";

const useStyles = createUseStyles({
  payment_cards: {
    display: "flex",
    flexDirection: "column"
  },
  payment_cards__auto_payment: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "24px"
  },
  payment_cards__auto_payment__toggle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },
  payment_cards__auto_payment__toggle__title: {
    marginRight: "16px",
    marginBottom: "8px"
  },
  payment_cards__auto_payment__description: {
    marginBottom: "8px"
  },
  payment_cards__auto_payment__more_info: {
    alignSelf: "flex-end",
    textDecoration: "none"
  }
});

const AutoPayment = ({
  title,
  description,
  moreInfo,
  snackBarMessage,
  hasAutoPaymentEnabled,
  onChangeAutoPayment,
  toggleLabel,
  hasFavoriteCard,
  showSnackBar
}) => {
  const classes = useStyles();
  const {
    trackWithThirdPartyMetricTools,
    eventProperties,
    eventPropertyCategories
  } = useTrackingContext();
  const intl = useIntl();

  return (
    <>
      <Typography
        variant={K.typographicVariants.heading4}
        className={classes.payment_cards__auto_payment__toggle__title}
      >
        {title}
      </Typography>

      <Paper className={classes.payment_cards__auto_payment}>
        <div className={classes.payment_cards__auto_payment__toggle}>
          <Typography
            variant={K.typographicVariants.body1}
            className={classes.payment_cards__auto_payment__toggle__title}
          >
            {toggleLabel}
          </Typography>
          <div
            {...(!hasFavoriteCard && {
              onClick: () => showSnackBar(snackBarMessage)
            })}
          >
            <Toggle
              defaultChecked={hasAutoPaymentEnabled}
              onChange={onChangeAutoPayment}
              disabled={!hasFavoriteCard}
              icons={false}
            />
          </div>
        </div>
        <Typography
          variant={K.typographicVariants.body2}
          className={classes.payment_cards__auto_payment__description}
        >
          {description}{" "}
          <a
            href={intl.formatMessage(locales.automatic_payment_page_link)}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.payment_cards__auto_payment__more_info}
            onClick={() => {
              trackWithThirdPartyMetricTools(
                "Click on autopayment card legal text",
                {
                  [eventProperties.CATEGORY]:
                    eventPropertyCategories.EXTERNAL_URL
                }
              );
            }}
          >
            <Typography
              variant={K.typographicVariants.linkS}
              color={K.colors.primary}
            >
              {moreInfo}
            </Typography>
          </a>
        </Typography>
      </Paper>
    </>
  );
};

AutoPayment.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  moreInfo: PropTypes.string.isRequired,
  snackBarMessage: PropTypes.string.isRequired,
  toggleLabel: PropTypes.string.isRequired,
  hasAutoPaymentEnabled: PropTypes.bool.isRequired,
  hasFavoriteCard: PropTypes.bool.isRequired,
  onChangeAutoPayment: PropTypes.func.isRequired,
  showSnackBar: PropTypes.func.isRequired
};

export default AutoPayment;
