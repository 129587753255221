import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Close = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    data-testid="close-modal-cross"
  >
    <path
      d="M5.70710678,4.29289322 L12,10.585 L18.2928932,4.29289322 C18.6533772,3.93240926 19.2206082,3.90467972 19.6128994,4.20970461 L19.7071068,4.29289322 C20.0976311,4.68341751 20.0976311,5.31658249 19.7071068,5.70710678 L19.7071068,5.70710678 L13.4142136,12 L19.7071068,18.2928932 C20.0976311,18.6834175 20.0976311,19.3165825 19.7071068,19.7071068 C19.3165825,20.0976311 18.6834175,20.0976311 18.2928932,19.7071068 L18.2928932,19.7071068 L12,13.415 L5.70710678,19.7071068 C5.31658249,20.0976311 4.68341751,20.0976311 4.29289322,19.7071068 C3.90236893,19.3165825 3.90236893,18.6834175 4.29289322,18.2928932 L10.5857864,12 L4.29289322,5.70710678 C3.90236893,5.31658249 3.90236893,4.68341751 4.29289322,4.29289322 C4.68341751,3.90236893 5.31658249,3.90236893 5.70710678,4.29289322 Z"
      fill={color}
    />
  </svg>
);

Close.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Close.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Close;
