import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Transport = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 22 16">
      <path
        fill={color}
        d="M19 4h-3V0H2C.9 0 0 .9 0 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2V8l-3-4Zm-.5 1.5L20.46 8H16V5.5h2.5ZM5 14c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Zm2.22-3c-.55-.61-1.33-1-2.22-1-.89 0-1.67.39-2.22 1H2V2h12v9H7.22ZM17 14c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1Z"
      />
    </svg>
  );
};

Transport.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Transport.defaultProps = {
  width: 22,
  height: 16,
  color: theme.palette.tertiary.default
};

export default Transport;
