import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Card = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path
      d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm-1 14H5a1 1 0 01-1-1v-5h16v5a1 1 0 01-1 1zm1-10H4V7a1 1 0 011-1h14a1 1 0 011 1v1z"
      fill={color}
    />
  </svg>
);

Card.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Card.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Card;
