import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { useOrder, useLocaleCurrency } from "../../../../hooks";
import { Typography, Calendar } from "../../../../atoms";
import { InvoiceProgress } from "../../../../molecules";
import { Alert } from "../../../../atoms/Illustrations";
import K from "../../../../constants";

const useStyles = createUseStyles({
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    marginBottom: "16px",
    flex: 1,
    boxSizing: "border-box",
    "&:last-child": {
      marginBottom: 0
    },
    "@media(min-width: 768px)": {
      maxWidth: "291px"
    }
  },
  invoice__info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& > :first-child": {
      marginBottom: "8px"
    }
  }
});

const Invoice = ({ invoice, hasAutoPaymentEnabled }) => {
  const theme = useTheme();
  const classes = useStyles();
  const order = useOrder();
  const intl = useIntl();

  return (
    <div className={classes.invoice}>
      <div className={classes.invoice__info}>
        <Typography variant={K.typographicVariants.heading5}>
          {invoice.number}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual3}
          color={
            invoice.isOverdue
              ? theme.palette.error.default
              : theme.palette.secondary.default
          }
        >
          {useLocaleCurrency(invoice.unpaidAmount, { useCents: true })}
        </Typography>
      </div>
      {(order.hasForcedCard ||
        (order.hasAutoPaymentEnabled && hasAutoPaymentEnabled)) &&
      !invoice.isOverdue ? (
        <Calendar
          day={intl.formatDate(invoice.dueOn, {
            day: "2-digit",
            timeZone: "Europe/Madrid"
          })}
          month={intl.formatDate(invoice.dueOn, {
            month: "long",
            timeZone: "Europe/Madrid"
          })}
          color={theme.palette.success.default}
          backgroundColor={theme.palette.success.light}
        />
      ) : (
        <>
          {invoice.isOverdue ? (
            <Alert width={91} height={91} color={theme.palette.error.default} />
          ) : (
            <InvoiceProgress
              invoicingCreditDays={order.merchant.invoicingCreditDays}
              dueOn={invoice.dueOn}
              isOverdue={invoice.isOverdue}
            />
          )}
        </>
      )}
    </div>
  );
};

Invoice.propTypes = {
  invoice: PropTypes.shape().isRequired,
  hasAutoPaymentEnabled: PropTypes.bool
};

Invoice.defaultProps = {
  hasAutoPaymentEnabled: false
};

export default Invoice;
