import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { NextModal, Typography } from "../../../../atoms";
import { BankAccountsSection } from "../../../AmortizationFlow/components";
import { PaymentModalDescription } from "../../components";
import { useOrder } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  modal_title: {
    marginBottom: "32px",
    "& > :first-child": {
      marginBottom: "8px"
    }
  }
});

const PaymentBankAccountsModal = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const location = useLocation();
  const order = useOrder();
  const query = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: "comma"
  });

  const {
    bankTransferPayment: { beneficiary, reference, bankAccounts }
  } = order;

  const overdueAmount = order.isOverdue
    ? order.overdueAmount
    : order.unpaidAmount;
  const maxAmount =
    (order.isOverdue ? order.unpaidPlanAmount : order.unpaidAmount) +
    order.overdueAmount;
  const amountCents = Math.min(query.amount || overdueAmount, maxAmount);

  return (
    <NextModal
      open
      withDescription
      contentDescription={
        <PaymentModalDescription
          invoiceNumbers={query.invoice_numbers}
          amountCents={amountCents}
        />
      }
    >
      <div className={classes.modal_title}>
        <Typography
          variant={K.typographicVariants.heading2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.payment_by_transfer)}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.payment_by_transfer_description)}
        </Typography>
      </div>
      <BankAccountsSection
        beneficiary={beneficiary}
        reference={reference}
        amount={amountCents}
        bankAccounts={bankAccounts}
      />
    </NextModal>
  );
};

export default PaymentBankAccountsModal;
