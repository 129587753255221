import React from "react";
import { useMutation } from "@apollo/client";
import { TOGGLE_ORDER_AUTO_PAYMENT } from "../../graphql";
import { OrderContext } from "../../contexts";
import useTrackingContext from "../../contexts/Tracking";

export default () => {
  const order = React.useContext(OrderContext);
  const { track, buildOrderTrackingPayload } = useTrackingContext();

  return useMutation(TOGGLE_ORDER_AUTO_PAYMENT, {
    onCompleted: ({ toggleOrderAutoPayment: { hasAutoPaymentDisabled } }) => {
      const eventProps = buildOrderTrackingPayload(order);
      if (hasAutoPaymentDisabled) {
        track("Disable order auto payment", eventProps);
      } else {
        track("Enable order auto payment", eventProps);
      }
    }
  });
};
