/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { UnicornWaving } from "../../../../../atoms/Illustrations";
import { Typography } from "../../../../../atoms";
import locales from "../../../../../locales";
import K from "../../../../../constants";

const useStyles = createUseStyles(theme => ({
  promote_merchants_denied: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "60px",
    fontFamily: theme.fontFamily
  },
  promote_merchants_denied__info: {
    margin: "5px 0 10px 0",
    color: theme.palette.secondary.default,
    fontSize: "12px",
    "& > a": {
      display: "block",
      textAlign: "center",
      fontWeight: "600",
      color: theme.palette.success.default,
      textDecoration: "none",
      lineHeight: "20px"
    }
  }
}));

const PromotedMerchantsDenied = ({ onClick, numberOfPromotions }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  function onClickSeePromotions(event) {
    event.preventDefault();
    onClick();
  }

  return (
    <div className={classes.promote_merchants_denied}>
      <UnicornWaving
        width={80}
        height={80}
        color={theme.palette.secondary.default}
      />
      <p className={classes.promote_merchants_denied__info}>
        {intl.formatMessage(locales.dashboard_promote_merchants_denied, {
          promotions: numberOfPromotions
        })}
        <a href="" onClick={onClickSeePromotions}>
          <Typography variant={K.typographicVariants.linkS}>
            {intl.formatMessage(
              locales.dashboard_promote_merchants_denied_action,
              {
                promotions: numberOfPromotions
              }
            )}
          </Typography>
        </a>
      </p>
    </div>
  );
};
PromotedMerchantsDenied.propTypes = {
  onClick: PropTypes.func.isRequired,
  numberOfPromotions: PropTypes.number.isRequired
};

export default PromotedMerchantsDenied;
