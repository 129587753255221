import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Button, Typography } from "../../atoms";
import { BarLoader } from "../../atoms/Loaders";
import useTrackingContext from "../../contexts/Tracking";
import { isMobile } from "../../utils";
import { CREATE_SELECTOR } from "../../graphql";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  create: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "0"
  },
  create_input: {
    height: "34px",
    width: "100%",
    border: `1px solid ${theme.palette.shade.default}`,
    boxSizing: "border-box",
    padding: "16px 8px",
    transition: theme.transitions.default,
    transitionDuration: 100,
    borderRadius: "8px",
    "&:focus": {
      borderWidth: "1px"
    },
    "@media (min-width: 768px)": {
      width: "222px"
    }
  },
  create_input__buttons: {
    display: "flex",
    flexDirection: "row",
    marginTop: "16px",
    "@media (min-width: 768px)": {
      marginTop: "0",
      "& > :nth-child(1)": {
        marginRight: "4px"
      }
    }
  },
  create_input__error: {
    display: "block",
    color: theme.palette.error.default,
    marginTop: "8px"
  },
  create_add: {
    margin: "0 auto"
  },
  create_cancel: {
    width: "auto",
    marginLeft: "32px",
    "@media (min-width: 768px)": {
      marginLeft: "0"
    }
  },
  create_send: {
    width: "89px",
    height: "34px"
  },
  create_fieldset: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (min-width: 768px)": {
      flexDirection: "row"
    }
  }
}));

const CreateSelector = ({
  addSelectorText,
  createSelectorPlaceHolder,
  selectorType,
  onSuccessAddedSelector
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    track,
    eventProperties,
    eventPropertyCategories
  } = useTrackingContext();
  const [showForm, setShowForm] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorKey, setErrorKey] = React.useState("InvalidSelector");
  const inputRef = React.useRef(null);

  const [createSelector, { loading, client }] = useMutation(CREATE_SELECTOR, {
    update: () => {
      client.resetStore();
    },
    onCompleted: data => {
      const { __typename } = data.createSelector;

      track("Create selector", {
        [eventProperties.SELECTOR_TYPE]: __typename,
        [eventProperties.CATEGORY]: eventPropertyCategories.SHOPPER
      });

      switch (__typename) {
        case "InvalidSelector":
        case "AlreadyAddedSelector":
          setError(true);
          setErrorKey(__typename);
          break;
        default:
          onSuccessAddedSelector(
            selectorType === "phone"
              ? intl.formatMessage(locales.profile_success_selector_phone)
              : intl.formatMessage(locales.profile_success_selector_email)
          );
          setInputValue("");
          setShowForm(false);
          break;
      }
    },
    onError: err => {
      setError(err);
    }
  });

  React.useEffect(() => {
    if (showForm) inputRef.current.focus();
  }, [showForm]);

  const renderErrorMessage = selectorTypeParam => (
    <Typography
      variant={K.typographicVariants.body2}
      className={classes.create_input__error}
    >
      {intl.formatMessage(locales[errorKey], {
        selectorType:
          selectorTypeParam === "phone"
            ? intl.formatMessage(locales.AlreadyAddedSelectorNumber)
            : intl.formatMessage(locales.AlreadyAddedSelectorEmail)
      })}
    </Typography>
  );

  const renderForm = () => (
    <form
      onSubmit={e => {
        e.preventDefault();
        createSelector({
          variables: {
            emailOrPhone: inputValue
          }
        });
      }}
    >
      <fieldset className={classes.create_fieldset}>
        <input
          ref={inputRef}
          className={classes.create_input}
          type="text"
          value={inputValue}
          onChange={e => {
            setInputValue(e.target.value);
          }}
          placeholder={createSelectorPlaceHolder}
        />
        <div className={classes.create_input__buttons}>
          <Button
            disabled={!inputValue}
            fullWidth={isMobile.phone}
            size="small"
            type="submit"
            className={classes.create_send}
          >
            {intl.formatMessage(locales.profile_create_selector_form)}
          </Button>
          <Button
            className={classes.create_cancel}
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              setShowForm(false);
              setInputValue("");
            }}
            fullWidth={isMobile.phone}
          >
            {intl.formatMessage(locales.profile_close_selector_form)}
          </Button>
        </div>
      </fieldset>

      {error && renderErrorMessage(selectorType)}
    </form>
  );

  const renderButton = () => (
    <Button
      className={classes.create_add}
      variant="text"
      color="primary"
      size="small"
      onClick={() => {
        setShowForm(true);
      }}
    >
      {addSelectorText}
    </Button>
  );

  return (
    <>
      {loading && <BarLoader />}
      <div className={classes.create}>
        {showForm ? renderForm() : renderButton()}
      </div>
    </>
  );
};

CreateSelector.propTypes = {
  createSelectorPlaceHolder: PropTypes.string.isRequired,
  addSelectorText: PropTypes.string.isRequired,
  selectorType: PropTypes.string.isRequired,
  onSuccessAddedSelector: PropTypes.func.isRequired
};

export default CreateSelector;
