import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import locales from "../../../../../locales";
import { Banner } from "../../../../../atoms";
import {
  ReferralProgramHowToUseDiscountAction,
  ReferralProgramFAQsAction,
  ReferralProgramTermsAndConditionsAction
} from "../../../../../molecules";
import K from "../../../../../constants";

const useStyles = createUseStyles({
  voucher: {
    marginBottom: "48px"
  },
  voucher__banner: {
    "& p": {
      fontSize: "84px"
    }
  },
  voucher__actions: {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 16px 0",
    "@media(min-width: 768px)": {
      margin: "6px 45px 0"
    },
    "& button": {
      width: "100px",
      flexDirection: "column",
      "@media(min-width: 768px)": {
        flexDirection: "row",
        width: "280px"
      }
    }
  }
});

const Voucher = ({ balance }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.voucher}>
      <Banner
        balance={balance}
        title={intl.formatMessage(
          locales.dashboard_without_orders_voucher_title_banner
        )}
        subtitle={intl.formatMessage(
          locales.dashboard_without_orders_voucher_subtitle_banner
        )}
        className={classes.voucher__banner}
      />
      <div className={classes.voucher__actions}>
        <ReferralProgramHowToUseDiscountAction process={K.process.onBoarding} />
        <ReferralProgramFAQsAction process={K.process.whereToUseTheVoucher} />
        <ReferralProgramTermsAndConditionsAction
          process={K.process.referralTermsAndConditions}
        />
      </div>
    </div>
  );
};

Voucher.propTypes = {
  balance: PropTypes.string.isRequired
};

export default Voucher;
