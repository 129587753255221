import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import useSessionAuthenticationContext from "../../contexts/SessionAuthentication";
import MessageBox from "../MessageBox";
import locales from "../../locales";

const useStyles = createUseStyles({
  message_container: {
    marginTop: "5px"
  }
});

const ReadOnlySessionMufasaIframeMessage = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { shopperAccountReadOnly } = useSessionAuthenticationContext();

  return (
    <>
      {shopperAccountReadOnly && (
        <div className={classes.message_container}>
          <MessageBox
            type="warning"
            text={intl.formatMessage(locales.order_partial_session_iframe)}
          />
        </div>
      )}
    </>
  );
};

export default ReadOnlySessionMufasaIframeMessage;
