import React from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  drop_down_list__body__content__list: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "40px"
  }
});

const DropDownListContent = ({ children, isOpen }) => {
  const classes = useStyles();

  const props = useSpring({
    height: isOpen ? "auto" : 0,
    overflow: isOpen ? "auto" : "hidden",
    opacity: isOpen ? 1 : 0,
    paddingTop: isOpen ? 14 : 11,
    paddingBottom: isOpen ? 13 : 0
  });

  return (
    <animated.div
      className={classes.drop_down_list__body__content__list}
      style={props}
      data-testid="atom_dropdown_list_content"
    >
      {children}
    </animated.div>
  );
};

DropDownListContent.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool
};

DropDownListContent.defaultProps = {
  children: null,
  isOpen: false
};

DropDownListContent.displayName = "DropDownListContent";

export default DropDownListContent;
