import React from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";

import { withModal } from "../../../../hocs";
import { getIntl, goToStep } from "../../../../utils";
import locales from "../../../../locales";
import { useOrder } from "../../../../hooks";
import K from "../../../../constants";
import useTrackingContext from "../../../../contexts/Tracking";
import useNotificationContext from "../../../../contexts/Notification";
import { Typography } from "../../../../atoms";
import {
  MufasaPaymentPlanGateway,
  MufasaInvoiceGateway
} from "../../../../molecules";

const useStyles = createUseStyles({
  select_card_modal__title: {
    marginBottom: "32px"
  },
  select_card_modal__link: {
    appearance: "none",
    border: 0,
    padding: 0,
    margin: 0,
    position: "absolute",
    top: "calc(48px + 8px)",
    backgroundColor: "transparent",
    "@media(min-width: 768px)": {
      top: "calc(48px + 32px + 8px)"
    }
  }
});

const ContentModal = ({ gateway, invoiceNumbers, amountCents }) => {
  const order = useOrder();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();
  const isWideScreen = useMedia("(min-width: 768px)");
  const { setSnackbarLayoutProps } = useNotificationContext();

  const processSuccessfulCardOperation = ({
    trackEventProps,
    snackbarMessage
  }) => {
    track(eventNames[gateway].success, trackEventProps);
    setSnackbarLayoutProps({
      open: true,
      variant: "accent",
      label: snackbarMessage
    });
    window.location.replace(location.pathname);
  };

  const processFailedCardOperation = ({ trackEventProps }) => () => {
    track(eventNames[gateway].fail, trackEventProps);
    setSnackbarLayoutProps({
      open: true,
      variant: "error",
      label: intl.formatMessage(locales.ups_something_went_wrong)
    });
  };

  return (
    <>
      {isWideScreen ? null : (
        <Typography
          variant={K.typographicVariants.heading2}
          color={theme.palette.primary.default}
          className={classes.select_card_modal__title}
        >
          {intl.formatMessage(locales.new_card)}
        </Typography>
      )}
      <button
        className={classes.select_card_modal__link}
        type="button"
        onClick={() =>
          goToStep({
            step: K.steps.selectBankAccount,
            history,
            location
          })
        }
      >
        <Typography
          variant={K.typographicVariants.caption1}
          color={theme.palette.success.default}
        >
          {intl.formatMessage(locales.payment_by_transfer_or_account_income)}
        </Typography>
      </button>
      {/i\d+/.test(order.currentProductName) ? (
        <MufasaInvoiceGateway
          orderId={order.id}
          amountCents={amountCents}
          invoiceNumbers={invoiceNumbers}
          onSuccess={() =>
            processSuccessfulCardOperation({
              trackEventProps: buildOrderTrackingPayload(order, true, false),
              label: intl.formatMessage(locales.payment_finished)
            })
          }
          onError={() =>
            processFailedCardOperation({
              trackEventProps: buildOrderTrackingPayload(order, true, false)
            })
          }
        />
      ) : (
        <MufasaPaymentPlanGateway
          orderId={order.id}
          amountCents={amountCents}
          onSuccess={() =>
            processSuccessfulCardOperation({
              trackEventProps: buildOrderTrackingPayload(order, true, false),
              label: intl.formatMessage(locales.payment_finished)
            })
          }
          onError={() =>
            processFailedCardOperation({
              trackEventProps: buildOrderTrackingPayload(order, true, false)
            })
          }
        />
      )}
    </>
  );
};

ContentModal.propTypes = {
  gateway: PropTypes.oneOf([
    K.mufasaGateways.tokenization,
    K.mufasaGateways.payment,
    K.mufasaGateways.conversion,
    K.mufasaGateways.assignCard
  ]),
  invoiceNumbers: PropTypes.arrayOf(PropTypes.string),
  amountCents: PropTypes.number
};

ContentModal.defaultProps = {
  gateway: null,
  invoiceNumbers: [],
  amountCents: null
};

export default withModal(ContentModal, {
  title: getIntl.formatMessage(locales.new_card)
});
