import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Card, CardBody, Typography } from "../../../atoms";
import K from "../../../constants";
import locales from "../../../locales";
import { StatusCheck, StatusCross } from "../../../atoms/Illustrations";

const useStyles = createUseStyles(theme => ({
  FinishedCard: {
    display: "flex",
    flexDirection: "column",
    gap: "24px"
  },
  FinishedCard__details: {
    display: "flex",
    gap: "24px",
    flexDirection: "column"
  },
  FinishedCard__separator: {
    border: "1px solid #e8e8e8"
  },
  FinishedCard__linkContainer: {
    display: "flex",
    gap: "12px"
  },
  FinishedCard__link: {
    background: "none",
    border: "none",
    padding: "0",
    cursor: "pointer",
    textDecoration: `underline ${theme.palette.primary.default}`
  },
  FinishedCard__linkArrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px"
  }
}));

const statusMap = {
  paid: { icon: StatusCheck, title: locales.order_paid },
  cancelled: { icon: StatusCross, title: locales.order_cancelled }
};

const Finished = ({ status, date, paymentsLink }) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles(theme);

  const StatusIcon = statusMap[status].icon || null;
  const statusTitle = statusMap[status].title || locales.order_paid;

  return (
    <Card boxShadow>
      <CardBody>
        <div className={classes.FinishedCard}>
          <StatusIcon />
          <div className={classes.FinishedCard__details}>
            <Typography variant={K.typographicVariants.heading2}>
              {intl.formatMessage(statusTitle)}
            </Typography>
            {date && (
              <Typography variant={K.typographicVariants.body1}>
                {intl.formatMessage(locales.general_date, {
                  date: intl.formatDate(date, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    timeZone: "Europe/Madrid"
                  })
                })}
              </Typography>
            )}
            {paymentsLink && (
              <>
                <div className={classes.FinishedCard__separator} />
                <div className={classes.FinishedCard__linkContainer}>
                  <button
                    type="button"
                    onClick={paymentsLink}
                    className={classes.FinishedCard__link}
                  >
                    <Typography variant={K.typographicVariants.linkXL}>
                      {intl.formatMessage(locales.instalments)}
                    </Typography>
                  </button>
                  <div className={classes.FinishedCard__linkArrow}>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.29 9.88L5.17 6L1.29 2.12C0.899998 1.73 0.899998 1.1 1.29 0.709996C1.68 0.319996 2.31 0.319996 2.7 0.709996L7.29 5.3C7.68 5.69 7.68 6.32 7.29 6.71L2.7 11.3C2.31 11.69 1.68 11.69 1.29 11.3C0.909998 10.91 0.899998 10.27 1.29 9.88Z"
                        fill="#1C1C1C"
                      />
                    </svg>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

Finished.propTypes = {
  status: PropTypes.string.isRequired,
  date: PropTypes.string,
  paymentsLink: PropTypes.string
};

Finished.defaultProps = {
  date: null,
  paymentsLink: null
};

export default Finished;
