import { useEffect } from "react";
import { MufasaTrackEvents } from "../types";

const useMufasaListener = (eventHandler, trackHandler) => {
  useEffect(() => {
    trackHandler(MufasaTrackEvents.mufasa_form_viewed);
    window.addEventListener("message", eventHandler);
    return function cleanSubscription() {
      window.removeEventListener("message", eventHandler);
    };
  }, []);
};

export default useMufasaListener;
