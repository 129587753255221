import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import useTrackingContext from "../../../../../../contexts/Tracking";
import Layout from "../../Layout";
import { Button, Typography } from "../../../../../../atoms";
import { Fence } from "../../../../../../atoms/Illustrations";

import locales from "../../../../../../locales";
import K from "../../../../../../constants";
import { LOGIN_PATH } from "../../../../../../routes";

const useStyles = createUseStyles({
  account_existed__icon: {
    margin: "16px 0!important"
  },
  account_existed__claim: {
    marginTop: "14px",
    "& span ": {
      lineHeight: "normal"
    }
  }
});

const AccountExisted = ({ currentStepForm }) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { track, eventNames } = useTrackingContext();
  useEffect(() => {
    track(eventNames.signUp.stepForm.accountExisted.start);
  }, []);

  return (
    <Layout currentStep={currentStepForm}>
      <div className={classes.account_existed__icon}>
        <Fence width={80} height={80} />
      </div>

      <Typography
        variant={K.typographicVariants.heading1}
        color={theme.palette.primary.default}
      >
        {intl.formatMessage(locales.sign_up_step_form_account_existed_title)}
      </Typography>

      <p className={classes.account_existed__claim}>
        <Typography
          variant={K.typographicVariants.body1}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.sign_up_step_form_account_existed_claim, {
            br: <br />
          })}
        </Typography>
      </p>

      <div>
        <Button
          fullWidth
          onClick={() => {
            history.replace(LOGIN_PATH);
          }}
          size="large"
        >
          {intl.formatMessage(locales.sign_up_step_form_account_existed_action)}
        </Button>
      </div>
    </Layout>
  );
};

AccountExisted.propTypes = {
  currentStepForm: PropTypes.number.isRequired
};

export default AccountExisted;
