import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Paper, Typography } from "../../../../atoms";

import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  profile_basic_info: {
    marginBottom: "32px",
    "@media (min-width: 768px)": {
      marginBottom: "40px"
    }
  },
  profile_basic_info__title: {
    marginBottom: "16px"
  },
  profile_basic_info__data: {
    "@media (min-width: 768px)": {}
  },
  profile_basic_info__data__item: {
    marginBottom: "16px",
    "@media (min-width: 768px)": {}
  }
});

const ProfileBasicInfo = ({ name, surnames, nin }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Typography
        variant={K.typographicVariants.heading4}
        className={classes.profile_basic_info__title}
      >
        {intl.formatMessage(locales.profile_personal_info)}
      </Typography>
      <Paper className={classes.profile_basic_info}>
        <div className={classes.profile_basic_info__data}>
          <div className={classes.profile_basic_info__data__item}>
            <Typography
              variant={K.typographicVariants.heading5}
              color={K.colors.secondary}
            >
              {intl.formatMessage(locales.profile_info_name)}
            </Typography>
            <Typography variant={K.typographicVariants.visual2}>
              {name} {surnames}
            </Typography>
          </div>
          <div className={classes.profile_basic_info__data__item}>
            <Typography
              variant={K.typographicVariants.heading5}
              color={K.colors.secondary}
            >
              {intl.formatMessage(locales.profile_info_nin)}
            </Typography>
            <Typography variant={K.typographicVariants.visual2}>
              {nin}
            </Typography>
          </div>
        </div>
      </Paper>
    </>
  );
};

ProfileBasicInfo.propTypes = {
  name: PropTypes.string,
  surnames: PropTypes.string,
  nin: PropTypes.string
};

ProfileBasicInfo.defaultProps = {
  name: "",
  surnames: "",
  nin: ""
};

export default ProfileBasicInfo;
