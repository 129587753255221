import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  modal_footer: {
    backgroundColor: "transparent",
    "@media(min-width: 768px)": {
      display: "flex",
      flexDirection: ({ flexDirection }) => flexDirection,
      justifyContent: ({ justifyContent }) => justifyContent
    }
  }
});

const ModalFooter = React.forwardRef((props, ref) => {
  const { children, className, justifyContent, flexDirection } = props;
  const classes = useStyles({ justifyContent, flexDirection });
  const componentRef = ref || React.useRef(null);
  const classNames = classnames(classes.modal_footer, className);

  return (
    <footer className={classNames} ref={componentRef}>
      {children}
    </footer>
  );
});

ModalFooter.displayName = "ModalFooter";

ModalFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string
};

ModalFooter.defaultProps = {
  children: null,
  className: "",
  justifyContent: "space-between",
  flexDirection: "row"
};

export default ModalFooter;
