import React from "react";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useMedia } from "react-use";
import { useTheme, createUseStyles } from "react-jss";
import { useLocation, useHistory } from "react-router-dom";
import { NextModal, Typography, Button } from "../../../../atoms";
import { useOrder, useLocaleCurrency } from "../../../../hooks";
import { ModalOrderInfo } from "../../../ConversionFlow/components";
import { InstalmentDropDown } from "../../components";
import locales from "../../../../locales";
import K from "../../../../constants";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../../utils";

const useStyles = createUseStyles(theme => ({
  modal__title: {
    marginTop: "40px",
    marginBottom: "32px"
  },
  summary_list_total: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "48px"
  },
  summary_box: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    marginBottom: "16px",
    "& > :last-child": {
      borderBottom: "none",
      marginBottom: 0
    }
  },
  summary_checkbox: {
    marginRight: "8px",
    cursor: "pointer"
  }
}));

const PaymentPartPaymentSummaryModal = () => {
  const order = useOrder();
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const isWideScreen = useMedia("(min-width: 768px)");
  const instalments = order.instalments.filter(
    instalment => instalment.isOverdue
  );

  const query = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: "comma"
  });

  const overdueAmount = order.isOverdue
    ? order.overdueAmount
    : order.unpaidAmount;
  const maxAmount =
    (order.isOverdue ? order.unpaidPlanAmount : order.unpaidAmount) +
    order.overdueAmount;
  const amountCents = Math.min(query.amount || overdueAmount, maxAmount);

  const onNext = () => {
    const search = queryString.stringify(
      {
        ...queryString.parse(location.search),
        step: K.steps.selectCard,
        amount: query.amount
      },
      { arrayFormat: "comma" }
    );
    history.push(`${location.pathname}?${search}`);
  };

  return (
    <NextModal open hideBack>
      <ModalOrderInfo
        merchantLogoURL={order.merchant.logo}
        merchantName={order.merchant.name}
        orderConfirmedAt={order.confirmedAt}
        orderNumberOfArticles={getTotalCountFromItemsList(
          getArticlesListFromCart(order.cart)
        )}
      />
      <Typography
        variant={
          isWideScreen
            ? K.typographicVariants.heading1
            : K.typographicVariants.heading2
        }
        color={theme.palette.primary.default}
        className={classes.modal__title}
      >
        {intl.formatMessage(locales.payment_summary)}
      </Typography>

      {instalments.map(instalment => (
        <div className={classes.summary_box} key={instalment.instalmentNumber}>
          <InstalmentDropDown instalment={instalment} />
        </div>
      ))}

      <div className={classes.summary_list_total}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.total)}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {useLocaleCurrency(amountCents, {
            useCents: true
          })}
        </Typography>
      </div>
      <Button variant="outlined" fullWidth onClick={onNext}>
        {intl.formatMessage(locales.general_navigation_next)}
      </Button>
    </NextModal>
  );
};

export default PaymentPartPaymentSummaryModal;
