import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Logout = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" {...props}>
    <path
      d="M13.3,4.7 L13.3,4.7 C12.91,5.09 12.91,5.71 13.3,6.1 L15.2,8 L6,8 C5.45,8 5,8.45 5,9 L5,9 C5,9.55 5.45,10 6,10 L15.2,10 L13.3,11.9 C12.91,12.29 12.91,12.91 13.3,13.3 L13.3,13.3 C13.69,13.69 14.31,13.69 14.7,13.3 L18.29,9.71 C18.68,9.32 18.68,8.69 18.29,8.3 L14.7,4.7 C14.31,4.31 13.69,4.31 13.3,4.7 Z M2,16 L9,16 C9.55,16 10,16.45 10,17 C10,17.55 9.55,18 9,18 L2,18 C0.9,18 0,17.1 0,16 L0,2 C0,0.9 0.9,0 2,0 L9,0 C9.55,0 10,0.45 10,1 C10,1.55 9.55,2 9,2 L2,2 L2,16 Z"
      fill={color}
    />
  </svg>
);

Logout.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Logout.defaultProps = {
  width: 20,
  height: 20,
  color: theme.palette.secondary.default
};

export default Logout;
