import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useMedia, useSetState } from "react-use";
import { Button, Typography } from "../../../../atoms";
import { ScaleLoader } from "../../../../atoms/Loaders";
import PaymentCardList from "../../../../organisms/PaymentCardList";
import { withModal } from "../../../../hocs";
import {
  getIntl,
  goToStep,
  filterReusableCardReferences
} from "../../../../utils";
import {
  useOrder,
  useShopperCardsDataQuery,
  useLocaleCurrency
} from "../../../../hooks";
import K from "../../../../constants";
import useNotificationContext from "../../../../contexts/Notification";
import useTrackingContext from "../../../../contexts/Tracking";
import locales from "../../../../locales";
import {
  MufasaPaymentPlanGateway,
  MufasaInvoiceGateway
} from "../../../../molecules";

const useStyles = createUseStyles({
  select_card_modal: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  select_card_modal__link: {
    appearance: "none",
    border: 0,
    padding: 0,
    marginTop: "5px",
    display: "flex",
    marginBottom: "32px",
    backgroundColor: "transparent",
    "@media(min-width: 768px)": {
      marginBottom: "16px"
    }
  },
  buttons_container: {
    padding: "24px 0",
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "40px"
    }
  },
  modal_title_classname: {
    marginBottom: 0
  }
});

const SelectCardModal = ({ invoiceNumbers, paymentAmountCents }) => {
  const order = useOrder();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const amount = useLocaleCurrency(paymentAmountCents || order.overdueAmount, {
    useCents: true
  });
  const { setSnackbarLayoutProps } = useNotificationContext();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();
  const isWideScreen = useMedia("(min-width: 768px)");

  const [{ cardReference, performingPayment }, setState] = useSetState({
    cardReference: null,
    performingPayment: false
  });

  const resetPaymentProcess = () =>
    setState({ cardReference: null, performingPayment: false });

  const processSuccessfulCardOperation = ({
    trackEventProps,
    snackbarMessage
  }) => {
    const eventProps =
      trackEventProps || buildOrderTrackingPayload(order, true, true);
    track(eventNames.payment.success, eventProps);
    setSnackbarLayoutProps({
      open: true,
      variant: "accent",
      label: snackbarMessage
    });
    window.location.replace(location.pathname);
  };

  const processFailedCardOperation = ({ trackEventProps } = {}) => {
    const eventProps =
      trackEventProps || buildOrderTrackingPayload(order, true, true);
    track(eventNames.payment.fail, eventProps);
    resetPaymentProcess();
    setSnackbarLayoutProps({
      open: true,
      variant: "error",
      label: intl.formatMessage(locales.ups_something_went_wrong)
    });
  };

  const cardReferencesResponse = useShopperCardsDataQuery({
    fetchPolicy: K.fetchPolicy.cacheAndNetwork
  });

  if (cardReferencesResponse.loading || cardReferencesResponse.error) {
    return null;
  }

  const renderMufasaIframe = () => {
    if (!cardReference) {
      return null;
    }
    const mufasaPaymentGatewayProps = {
      orderId: order.id,
      cardReferenceId: cardReference.id,
      amountCents: paymentAmountCents,
      onError: processFailedCardOperation,
      onClose: resetPaymentProcess
    };

    if (/i\d+/.test(order.currentProductName)) {
      return (
        <MufasaInvoiceGateway
          {...mufasaPaymentGatewayProps}
          invoiceNumbers={invoiceNumbers}
          onSuccess={() =>
            processSuccessfulCardOperation({
              snackbarMessage: intl.formatMessage(locales.payment_finished)
            })
          }
        />
      );
    }

    return (
      <MufasaPaymentPlanGateway
        {...mufasaPaymentGatewayProps}
        onSuccess={() =>
          processSuccessfulCardOperation({
            snackbarMessage: intl.formatMessage(locales.payment_finished)
          })
        }
      />
    );
  };

  const filteredCardReferences = filterReusableCardReferences(
    cardReferencesResponse.data.shopper.cardReferences
  );

  return (
    <div className={classes.select_card_modal}>
      {performingPayment && <ScaleLoader />}
      {isWideScreen ? null : (
        <Typography
          variant={K.typographicVariants.heading2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.choose_a_card)}
        </Typography>
      )}

      <button
        className={classes.select_card_modal__link}
        type="button"
        onClick={() =>
          goToStep({
            step: K.steps.selectBankAccount,
            history,
            location
          })
        }
      >
        <Typography
          variant={K.typographicVariants.caption1}
          color={theme.palette.success.default}
        >
          {intl.formatMessage(locales.payment_by_transfer_or_account_income)}
        </Typography>
      </button>

      <PaymentCardList
        cardReferences={filteredCardReferences}
        selectedCard={cardReference}
        onSelectCard={value => setState({ cardReference: value })}
      />
      <div className={classes.buttons_container}>
        <Button
          size="large"
          fullWidth
          variant="text"
          onClick={() =>
            goToStep({
              step: K.steps.addCard,
              history,
              location
            })
          }
        >
          {intl.formatMessage(locales.new_card)}
        </Button>
        <Button
          size="large"
          fullWidth
          onClick={() => {
            setState({ performingPayment: true });
          }}
          disabled={!cardReference || performingPayment}
        >
          {intl.formatMessage(locales.order_action_pay_with_amount, { amount })}
        </Button>
        {performingPayment && renderMufasaIframe()}
      </div>
    </div>
  );
};

SelectCardModal.propTypes = {
  invoiceNumbers: PropTypes.arrayOf(PropTypes.string),
  instalmentCount: PropTypes.number,
  paymentAmountCents: PropTypes.number
};

SelectCardModal.defaultProps = {
  invoiceNumbers: [],
  instalmentCount: 0,
  paymentAmountCents: null
};

export default withModal(SelectCardModal, {
  title: getIntl.formatMessage(locales.my_cards)
});
