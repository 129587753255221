import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useMedia } from "react-use";
import { useTheme, createUseStyles } from "react-jss";
import { useLocation, useHistory } from "react-router-dom";
import { NextModal, Typography, Button } from "../../../../atoms";
import { useOrder } from "../../../../hooks";
import { ModalOrderInfo } from "../../../ConversionFlow/components";
import { AmortizationSummaryTable } from "../../components";
import { ErrorBoundary } from "../../../../templates";
import locales from "../../../../locales";
import K from "../../../../constants";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../../utils";

const useStyles = createUseStyles(theme => ({
  modal__title: {
    marginTop: "40px",
    marginBottom: "32px"
  },
  summary_list_total: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "48px"
  },
  summary_box: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    marginBottom: "16px",
    "& > :last-child": {
      borderBottom: "none",
      marginBottom: 0
    }
  },
  summary_checkbox: {
    marginRight: "8px",
    cursor: "pointer"
  }
}));

const AmortizationSummaryModal = ({ modalTitle }) => {
  const order = useOrder();
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const isWideScreen = useMedia("(min-width: 768px)");

  const onNext = () => {
    const search = queryString.stringify(
      {
        ...queryString.parse(location.search),
        step: K.steps.addCard
      },
      { arrayFormat: "comma" }
    );
    history.push(`${location.pathname}?${search}`);
  };

  return (
    <ErrorBoundary component="AmortizationSummaryModal">
      <NextModal open hideBack>
        <ModalOrderInfo
          merchantLogoURL={order.merchant.logo}
          merchantName={order.merchant.name}
          orderConfirmedAt={order.confirmedAt}
          orderNumberOfArticles={getTotalCountFromItemsList(
            getArticlesListFromCart(order.cart)
          )}
        />
        <Typography
          variant={
            isWideScreen
              ? K.typographicVariants.heading1
              : K.typographicVariants.heading2
          }
          color={theme.palette.primary.default}
          className={classes.modal__title}
        >
          {intl.formatMessage(modalTitle)}
        </Typography>
        <AmortizationSummaryTable />
        <Button variant="outlined" fullWidth onClick={onNext}>
          {intl.formatMessage(locales.general_navigation_next)}
        </Button>
      </NextModal>
    </ErrorBoundary>
  );
};

AmortizationSummaryModal.propTypes = {
  modalTitle: PropTypes.shape({
    id: PropTypes.string,
    defaultMessages: PropTypes.string
  }).isRequired
};

export default AmortizationSummaryModal;
