export default {
  primary: {
    default: "#1C1C1C"
  },
  secondary: {
    default: "#606060"
  },
  tertiary: {
    default: "#8E8E8E"
  },
  shade: {
    default: "#D2D2D2",
    light: "#DDDBE0"
  },
  stroke: {
    default: "#E8E8E8"
  },
  background: {
    default: "#F4F4F4"
  },
  success: {
    light: "#F2FCFA",
    default: "#00C2A3",
    dark: "#1B8D7D",
    medium: "#B6EEE5"
  },
  brand: {
    default: "#00C2A3"
  },
  info: {
    light: "#ECF3F9",
    default: "#3F88C5",
    medium: "#C1D8EC"
  },
  warning: {
    light: "#FFF8E6",
    medium: "#FFE9AF",
    default: "#FFBA08"
  },
  error: {
    light: "#FDF2F2",
    default: "#D00000",
    medium: "#F2B6B6",
    dark: "#B50000"
  },
  white: {
    default: "#FFFFFF"
  },
  black: {
    default: "#000000"
  }
};
