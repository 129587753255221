import { useState, useCallback } from "react";
import { useSetState } from "react-use";
import useSessionAuthenticationContext from "../../../contexts/SessionAuthentication";
import useCreateReferralGuestUser from "./mutations/useCreateReferralGuestUser";
import useValidateReferralGuestUserOtp from "./mutations/useValidateReferralGuestUserOtp";
import useReferralSignUpFieldValidation from "./queries/useReferralSignUpFieldValidation";
import useTrackingContext from "../../../contexts/Tracking";

export default () => {
  const { track } = useTrackingContext();
  const [currentStepForm, setCurrentStepForm] = useState(null);
  const [sessionIdHash, setSessionIdHash] = useState(null);
  const [stepFormInputsValues, setStepFormInputsValues] = useSetState({
    name: "",
    identificationNumber: "",
    email: "",
    phone: "",
    otp: ""
  });
  const initialFieldsValidationErrorsState = {
    NIN: false,
    EMAIL: false,
    PHONE: false,
    OTP: false
  };
  const [fieldsValidationErrors, setFieldsValidationErrors] = useState(
    initialFieldsValidationErrorsState
  );
  const { referralHostName, updateSession } = useSessionAuthenticationContext();
  const ninStep = 1;
  const emailStep = 2;
  const phoneStep = 3;
  const stepsWithValidations = [ninStep, emailStep, phoneStep];

  const [createReferralGuestUser] = useCreateReferralGuestUser({
    onCompleted: data => {
      const typeName = data?.createReferralGuestUser?.__typename;
      if (typeName === "EncryptedSelectors") {
        setCurrentStepForm(currentStepForm + 1);
        setSessionIdHash(data?.createReferralGuestUser?.otpHash);
      }
      if (typeName === "UserAlreadyRegistered") {
        setCurrentStepForm(-1);
      }
    },
    onError: () => {}
  });

  const submitStepPhone = () => {
    const { name, identificationNumber, email, phone } = stepFormInputsValues;
    createReferralGuestUser({
      variables: {
        name,
        email,
        phone,
        nin: identificationNumber,
        termsAndConditionsAccepted: true
      }
    });
  };

  const goToNextStep = () => {
    setCurrentStepForm(currentStepForm === null ? 0 : currentStepForm + 1);
  };

  const triggerNextAction = () => {
    if (currentStepForm !== phoneStep) {
      goToNextStep();
    } else {
      submitStepPhone();
    }
  };

  const [referralSignUpFieldValidation] = useReferralSignUpFieldValidation({
    onCompleted: data => {
      const fieldType = data?.referralSignUpFieldValidation?.fieldType;
      const valid = data?.referralSignUpFieldValidation?.valid;
      if (fieldType && valid) {
        setFieldsValidationErrors(initialFieldsValidationErrorsState);
        triggerNextAction();
      } else {
        setFieldsValidationErrors({
          ...fieldsValidationErrors,
          [fieldType]: !valid
        });
      }
    },
    onError: () => {}
  });

  const [validateReferralGuestUserOtp] = useValidateReferralGuestUserOtp({
    onCompleted: data => {
      const sessionAuthorization =
        data?.validateReferralGuestUserOtp?.sessionAuthorization;
      if (sessionAuthorization.__typename === "FullSession") {
        updateSession(sessionAuthorization);
        window.location.href = "/?process=referral";
      } else {
        setFieldsValidationErrors({
          ...fieldsValidationErrors,
          OTP: true
        });
      }
    },
    onError: () => {}
  });

  const onChangeInputValue = useCallback(
    inputData => {
      if (inputData?.target?.name) {
        setStepFormInputsValues({
          [inputData.target.name]: inputData.target.value
        });
      } else {
        setStepFormInputsValues({
          otp: inputData
        });
      }
    },
    [stepFormInputsValues]
  );

  const validateStep = () => {
    const getValidationStep = {
      1: {
        fieldType: "NIN",
        fieldValue: stepFormInputsValues.identificationNumber
      },
      2: {
        fieldType: "EMAIL",
        fieldValue: stepFormInputsValues.email
      },
      3: {
        fieldType: "PHONE",
        fieldValue: stepFormInputsValues.phone
      }
    };
    const { fieldType, fieldValue } = getValidationStep[currentStepForm];
    referralSignUpFieldValidation({
      variables: {
        fieldType,
        fieldValue
      }
    });
  };

  const onSubmitStep = event => {
    event.preventDefault();
    if (stepsWithValidations.includes(currentStepForm)) {
      validateStep();
    } else {
      goToNextStep();
    }
  };

  const onSubmitStepOtp = event => {
    event.preventDefault();
    const { otp } = stepFormInputsValues;
    validateReferralGuestUserOtp({
      variables: {
        sessionIdHash,
        otp
      }
    });
  };

  const onClickActionLanding = eventName => {
    track(eventName);
    goToNextStep();
  };

  const onClickPrevStep = useCallback(() => {
    setCurrentStepForm(currentStepForm === 0 ? null : currentStepForm - 1);
  }, [currentStepForm]);

  return {
    currentStepForm,
    referralHostName,
    onSubmitStep: e => {
      onSubmitStep(e);
    },
    onClickActionLanding: eventName => {
      onClickActionLanding(eventName);
    },
    onClickPrevStep: () => {
      onClickPrevStep();
    },
    onChangeInputValue: inputData => {
      onChangeInputValue(inputData);
    },
    onSubmitStepOtp,
    stepFormInputsValues,
    fieldsValidationErrors
  };
};
