import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useHistory, matchPath, useLocation } from "react-router-dom";
import { Typography } from "../../atoms";
import {
  Sequra,
  Menu,
  ArrowLeft,
  SveaPoweredBySequra,
  Invite
} from "../../atoms/Icons";
import K from "../../constants";
import useShopperContext from "../../contexts/Shopper";
import useTrackingContext from "../../contexts/Tracking";
import { REFERRAL_PROGRAM_PATH } from "../../routes";

const useStyles = createUseStyles(theme => ({
  nav_bar: {
    height: ({ label }) => (label ? "51px" : "56px"),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    boxSizing: "border-box",
    backgroundColor: theme.palette.white.default,
    "& > :nth-child(2)": {
      paddingLeft: "38px"
    },
    "@media (min-width: 1024px)": {
      display: "none"
    }
  },
  nav_bar__icons: {
    display: "flex",
    alignItems: "center",
    "& span svg": {
      cursor: "pointer",
      marginLeft: "14px"
    }
  }
}));

const NavBar = ({ onClickMenu, onClickBack, label }) => {
  const classes = useStyles({ label });
  const theme = useTheme();
  const history = useHistory();
  const { shopper } = useShopperContext();
  const { track, eventNames } = useTrackingContext();
  const location = useLocation();
  const { pathname } = location;

  const handleClickInviteIcon = () => {
    history.push(REFERRAL_PROGRAM_PATH);
    track(eventNames.referralProgram.userClicksNavBarInviteIcon);
  };

  return (
    <div className={classes.nav_bar}>
      {label ? (
        <>
          <span
            role="button"
            tabIndex="0"
            onKeyPress={onClickBack}
            onClick={onClickBack}
          >
            <ArrowLeft color={theme.palette.secondary.default} />
          </span>
          <Typography variant={K.typographicVariants.heading2}>
            {label}
          </Typography>
        </>
      ) : (
        <>
          {shopper.realm === "svea" ? (
            <SveaPoweredBySequra width={140} height={30} />
          ) : (
            <Sequra width={96} height={24} />
          )}
        </>
      )}
      <div className={classes.nav_bar__icons}>
        {shopper.referralProgram.enabledToBeHost && (
          <span
            tabIndex="0"
            role="button"
            onKeyPress={handleClickInviteIcon}
            onClick={handleClickInviteIcon}
          >
            <Invite
              color={
                matchPath(pathname, {
                  path: REFERRAL_PROGRAM_PATH,
                  exact: true
                })
                  ? theme.palette.brand.default
                  : theme.palette.secondary.default
              }
            />
          </span>
        )}
        <span
          tabIndex="0"
          role="button"
          onKeyPress={onClickMenu}
          onClick={onClickMenu}
        >
          <Menu color={theme.palette.secondary.default} />
        </span>
      </div>
    </div>
  );
};

NavBar.propTypes = {
  onClickMenu: PropTypes.func,
  onClickBack: PropTypes.func,
  label: PropTypes.string
};

NavBar.defaultProps = {
  onClickMenu: () => {},
  onClickBack: () => {},
  label: ""
};

export default NavBar;
