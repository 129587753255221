import React from "react";
import { useMedia } from "react-use";
import { createUseStyles } from "react-jss";
import { Card, CardBody, CardFooter } from "../../atoms";
import {
  OrderHeader,
  OrderPaymentCard,
  OrderPaymentCardErrors
} from "../../molecules";
import { ErrorBoundary } from "../../templates";
import { OrderContext } from "../../contexts";
import { useOrderAutopaymentToggleMutation } from "../../hooks";

const useStyles = createUseStyles(theme => ({
  order_details_header: {
    marginBottom: "24px",
    "@media (min-width: 768px)": {
      display: "flex",
      maxWidth: "947px",
      margin: "0 auto",
      flex: "1 1 auto",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "24px 0",
      borderBottom: `1px solid ${theme.palette.white.default}`,
      marginBottom: "12px"
    }
  }
}));

const OrderDetailsHeader = () => {
  const order = React.useContext(OrderContext);
  const isWide = useMedia("(min-width: 768px)");
  const classes = useStyles();
  const [toggleOrderAutoPayment] = useOrderAutopaymentToggleMutation();

  const renderOrderPaymentCard = () => {
    const Component = () => (
      <OrderPaymentCard
        orderCurrentProductName={order.currentProductName}
        orderCardIssuer={order.cardReference.issuer}
        orderCardLastFour={order.cardReference.lastFour}
        orderHasForcedCard={order.hasForcedCard}
        orderIsPaid={order.isPaid}
        orderIsOverdue={order.isOverdue}
        orderHasAutoPaymentEnabled={order.hasAutoPaymentEnabled}
        orderHasExpiredCard={order.hasExpiredCard}
        orderHasExpiringSoonCard={order.hasExpiringSoonCard}
        withinCard={isWide}
        onChange={() => {
          toggleOrderAutoPayment({
            variables: {
              orderId: order.id
            }
          });
        }}
      />
    );

    switch (true) {
      case order.isCancelled:
      case /i\d+/.test(order.currentProductName) &&
        (order.isOverdue || order.isPaid):
      case !order.cardReference:
        return null;
      case isWide:
        return <Component />;
      default:
        return (
          <CardFooter hideIcon>
            <Component />
          </CardFooter>
        );
    }
  };

  if (isWide) {
    return (
      <ErrorBoundary component="OrderDetailsHeader">
        <div className={classes.order_details_header}>
          <OrderHeader
            merchantLogo={order.merchant.logo}
            merchantName={order.merchant.name}
            merchantUrl={order.merchant.url}
            orderReference={order.primaryReference}
            orderConfirmedAt={order.confirmedAt}
          />
          {renderOrderPaymentCard()}
        </div>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary component="OrderDetailsHeader">
      <div className={classes.order_details_header}>
        <Card>
          <CardBody>
            <OrderHeader
              merchantLogo={order.merchant.logo}
              merchantName={order.merchant.name}
              merchantUrl={order.merchant.url}
              orderReference={order.primaryReference}
              orderConfirmedAt={order.confirmedAt}
            />
          </CardBody>
          {renderOrderPaymentCard()}
        </Card>
        {!order.isPaid && !order.isCancelled && (
          <OrderPaymentCardErrors
            orderHasExpiredCard={order.hasExpiredCard}
            orderHasExpiringSoonCard={order.hasExpiringSoonCard}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default OrderDetailsHeader;
