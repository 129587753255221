import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  state = {
    error: false
  };

  static propTypes = {
    component: PropTypes.string,
    children: PropTypes.node
  };

  static defaultProps = {
    component: "",
    children: null
  };

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error, info) {
    const { component } = this.props;

    /* eslint-disable no-console */
    /* $FlowFixMe */
    console.group(`Component: ${component}`);
    console.error(error);
    console.info(info);
    console.groupEnd();
    /* eslint-enable */

    Sentry.withScope(scope => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    return error ? null : children;
  }
}

export default ErrorBoundary;
