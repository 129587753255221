import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

import {
  ORDERS_PATH,
  ORDER_PATH,
  ORDER_ARTICLES_PATH,
  ORDER_INVOICES_PATH,
  ORDER_INSTALMENTS_PATH,
  paths
} from "../routes";

export default () => {
  const history = useHistory();

  const route = paths.reduce((acc, x) => {
    const match = useRouteMatch({ path: x.path, exact: true });
    if (match) return { ...acc, ...match };
    return acc;
  }, {});
  const orderId = route.params?.id;

  const goOrders = () => {
    history.push(ORDERS_PATH);
  };

  const goOrder = () => {
    history.push(generatePath(ORDER_PATH, { id: orderId }));
  };

  const callback = {
    [ORDER_PATH]: goOrders,
    [ORDER_ARTICLES_PATH]: goOrder,
    [ORDER_INVOICES_PATH]: goOrder,
    [ORDER_INSTALMENTS_PATH]: goOrder
  };

  return callback[route.path];
};
