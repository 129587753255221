import { useLazyQuery } from "@apollo/client";
import {
  ORDER_QUERY,
  ORDER_INFO_FRAGMENT,
  MERCHANT_FRAGMENT,
  INVOICES_FRAGMENT,
  SHIPPED_CART_FRAGMENT,
  UNSHIPPED_CART_FRAGMENT,
  CARD_REFERENCE_FRAGMENT,
  PAYMENT_PLAN_FRAGMENT,
  RENTING_PLAN_FRAGMENT,
  CONVERSION_PLANS_FRAGMENT,
  REFUND_PROCESS_FRAGMENT,
  AMORTIZATION_FRAGMENT
} from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError, orderId }) =>
  useLazyQuery(
    ORDER_QUERY(
      ORDER_INFO_FRAGMENT,
      MERCHANT_FRAGMENT,
      INVOICES_FRAGMENT,
      SHIPPED_CART_FRAGMENT,
      UNSHIPPED_CART_FRAGMENT,
      CARD_REFERENCE_FRAGMENT,
      PAYMENT_PLAN_FRAGMENT,
      RENTING_PLAN_FRAGMENT,
      CONVERSION_PLANS_FRAGMENT,
      REFUND_PROCESS_FRAGMENT,
      AMORTIZATION_FRAGMENT
    ),
    {
      variables: {
        orderId
      },
      fetchPolicy: K.fetchPolicy.networkOnly,
      onCompleted,
      onError
    }
  );
