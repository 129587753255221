import React from "react";
import { useOrder, useShopper } from "../../../hooks";
import { Unpaid } from "./Invoice";
import PartPayment from "./PartPayment";

export default () => {
  const order = useOrder();
  const shopper = useShopper();

  if (/[s|p]?[p|r]\d+/.test(order.currentProductName)) {
    const { count, paidCount } = order.payments;

    return (
      <PartPayment
        isCampaign={order.isCampaign}
        nextInstalment={order.paymentPlan.futureInstalments[0]}
        instalmentsCount={count}
        paidInstalmentsCount={paidCount}
        isOverdue={order.isOverdue}
      />
    );
  }

  return (
    <Unpaid
      hasAutoPaymentEnabled={
        (shopper?.hasAutoPaymentEnabled && order.hasAutoPaymentEnabled) ||
        order.hasForcedCard
      }
      unpaidAmount={order.invoices[0].unpaidAmount}
      invoicingCreditDays={order.merchant.invoicingCreditDays}
      dueOn={order.invoices[0].dueOn}
    />
  );
};
