import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import locales from "../../locales";
import { PageTemplate } from "../../templates";
import { HelpBox } from "../../molecules";
import { Envelop, Faqs, Phone } from "../../atoms/Icons";
import useTrackingContext from "../../contexts/Tracking";
import { useShopper } from "../../hooks";
import K from "../../constants";

const useStyles = createUseStyles({
  help: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (min-width: 1280px)": {
      flexDirection: "row",
      justifyContent: realm =>
        realm === K.realm.sequra ? "flex-start" : "center",
      "& > :nth-child(2)": {
        margin: "0 8px",
        "@media (min-width: 1440px)": {
          margin: "0 16px"
        }
      }
    }
  }
});

const Help = () => {
  const { realm } = useShopper();
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme(realm);
  const {
    trackWithThirdPartyMetricTools,
    eventProperties,
    eventPropertyCategories
  } = useTrackingContext();

  return (
    <PageTemplate
      title={intl.formatMessage(locales.general_page_title_help)}
      subtitle={intl.formatMessage(locales.general_page_subtitle_help)}
    >
      <div className={classes.help}>
        {realm === K.realm.sequra ? (
          <HelpBox
            icon={
              <Faqs
                color={theme.palette.success.default}
                height={120}
                width={120}
              />
            }
            title={intl.formatMessage(locales.faqs_title)}
            description={intl.formatMessage(locales.faqs_description)}
            label={intl.formatMessage(locales.faqs_button)}
            onClick={() => {
              trackWithThirdPartyMetricTools("Click on faqs link", {
                [eventProperties.CATEGORY]: eventPropertyCategories.EXTERNAL_URL
              });
              window.open(
                "https://www.sequra.com/preguntas-frecuentes?utm_source=sequra_app&utm_medium=app_link&utm_campaign=web_faq"
              );
            }}
          />
        ) : null}
        <HelpBox
          icon={
            <Envelop
              color={theme.palette.success.default}
              height={120}
              width={120}
            />
          }
          title={intl.formatMessage(locales.email_title)}
          description={intl.formatMessage(locales.email_description)}
          label={intl.formatMessage(locales.help_customer_service_email)}
          onClick={() => {
            trackWithThirdPartyMetricTools("Click on support mail link", {
              [eventProperties.CATEGORY]: eventPropertyCategories.EXTERNAL_URL
            });
            window.open(
              `mailto:SeQura<${intl.formatMessage(
                locales.help_customer_service_email
              )}>`
            );
          }}
        />
        <HelpBox
          icon={
            <Phone
              color={theme.palette.success.default}
              height={120}
              width={120}
            />
          }
          title={intl.formatMessage(locales.phone_title)}
          description={intl.formatMessage(locales.phone_description)}
          label={intl.formatMessage(locales.help_customer_service_phone_label)}
          onClick={() => {
            trackWithThirdPartyMetricTools("Click on support phone link", {
              [eventProperties.CATEGORY]: eventPropertyCategories.EXTERNAL_URL
            });
            window.open(
              `tel:${intl.formatMessage(locales.help_customer_service_phone)}`
            );
          }}
        />
      </div>
    </PageTemplate>
  );
};

export default Help;
