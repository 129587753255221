import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  product_label: {
    display: "flex"
  },
  product_label__label: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "2px 6px",
    backgroundColor: ({ color }) => theme.palette[color].medium
  }
}));

const Label = ({ className, title, color }) => {
  const classes = useStyles({ color });
  return (
    <div className={classnames(classes.product_label, className)}>
      <div className={classes.product_label__label}>
        <Typography
          color={K.colors.primary}
          variant={K.typographicVariants.body3}
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.oneOf([
    K.colors.warning,
    K.colors.info,
    K.colors.success,
    K.colors.shade,
    K.colors.error
  ]).isRequired
};

Label.defaultProps = {
  className: "",
  title: ""
};

export default Label;
