import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { Paper, ProgressBar, Typography } from "../../../../atoms";
import { Sequra, NavigationArrowBack } from "../../../../atoms/Icons";
import K from "../../../../constants";

const useStyles = createUseStyles({
  layout: {
    width: "432px",
    padding: "6px",
    minHeight: "100vh",
    "@media(min-width: 768px)": {
      minHeight: "666px"
    }
  },
  layout__logo: {
    position: "relative",
    padding: "6px",
    margin: "16px 0",
    textAlign: "center"
  },
  layout__content: {
    padding: "0 24px",
    "@media(min-width: 768px)": {
      padding: "0 32px"
    },
    "& form > div": {
      margin: "32px 0"
    }
  },
  layout__claim: {
    marginTop: "8px"
  },
  layout__back_button: {
    display: "flex",
    position: "absolute",
    top: "2px",
    left: "16px",
    width: "40px",
    height: "40px",
    cursor: "pointer"
  }
});

const Layout = ({
  children,
  title,
  subTitle,
  claim,
  currentStep,
  onClickPrevStep,
  onSubmitStep
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper className={classes.layout}>
      {currentStep >= 0 && (
        <ProgressBar numberOfSteps={5} currentStep={currentStep} />
      )}
      <div className={classes.layout__logo}>
        {currentStep >= 0 && (
          <div
            role="button"
            tabIndex="0"
            className={classes.layout__back_button}
            onClick={onClickPrevStep}
            onKeyPress={onClickPrevStep}
          >
            <NavigationArrowBack
              width={24}
              height={24}
              color={theme.palette.secondary.default}
            />
          </div>
        )}

        <Sequra
          width={109}
          height={22}
          color={theme.palette.tertiary.default}
        />
      </div>
      <div className={classes.layout__content}>
        {title && (
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.secondary.default}
          >
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
          >
            {subTitle}
          </Typography>
        )}

        {claim && (
          <p className={classes.layout__claim}>
            <Typography
              variant={K.typographicVariants.body2}
              color={theme.palette.primary.default}
            >
              {claim}
            </Typography>
          </p>
        )}
        <form onSubmit={onSubmitStep}>{children}</form>
      </div>
    </Paper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  claim: PropTypes.string,
  currentStep: PropTypes.number,
  onClickPrevStep: PropTypes.func,
  onSubmitStep: PropTypes.func
};

Layout.defaultProps = {
  title: "",
  subTitle: "",
  claim: "",
  currentStep: undefined,
  onClickPrevStep: () => {},
  onSubmitStep: () => {}
};

export default Layout;
