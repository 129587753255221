/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { UnicornSad } from "../../../../../atoms/Illustrations";
import { Typography } from "../../../../../atoms";
import locales from "../../../../../locales";
import K from "../../../../../constants";

const useStyles = createUseStyles(theme => ({
  promote_merchants_in_recovery: {
    boxSizing: "border-box",
    width: "100%",
    padding: "17px 24.1px 46px 22.9px",
    backgroundColor: theme.palette.white.default,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.09)",
    borderRadius: "8px",
    "@media (min-width: 768px)": {
      width: "381px"
    }
  },
  promote_merchants_in_recovery__title: {
    marginTop: "8px",
    marginBottom: "14px",
    color: theme.palette.secondary.default
  },
  promote_merchants_in_recovery__content: {
    display: "block",
    marginBottom: "14px",
    "& > span ": {
      color: theme.palette.primary.default,
      cursor: "pointer",
      textDecoration: "underline"
    }
  },
  promote_merchants_in_recovery__content_italic: {
    display: "block",
    fontStyle: "italic"
  },
  promote_merchants_in_recovery__content_emoji: {
    fontStyle: "normal"
  }
}));

const PromotedMerchantsInRecovery = ({ onClickTelephone }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <div className={classes.promote_merchants_in_recovery}>
      <UnicornSad
        width={45}
        height={45}
        color={theme.palette.secondary.default}
      />
      <Typography
        variant={K.typographicVariants.heading4}
        className={classes.promote_merchants_in_recovery__title}
        color={K.colors.secondary}
      >
        {intl.formatMessage(
          locales.dashboard_promote_merchants_in_recovery_title
        )}
      </Typography>
      <Typography
        variant={K.typographicVariants.body2}
        className={classes.promote_merchants_in_recovery__content}
        color={K.colors.secondary}
      >
        {intl.formatMessage(
          locales.dashboard_promote_merchants_in_recovery_first_text
        )}
      </Typography>
      <Typography
        variant={K.typographicVariants.body2}
        className={classes.promote_merchants_in_recovery__content}
        color={K.colors.secondary}
      >
        {intl.formatMessage(
          locales.dashboard_promote_merchants_in_recovery_second_text,
          {
            telephone: (
              <span
                role="button"
                tabIndex="0"
                onClick={onClickTelephone}
                onKeyPress={onClickTelephone}
              >
                605 980 142
              </span>
            )
          }
        )}
      </Typography>
      <Typography
        variant={K.typographicVariants.body2}
        className={classes.promote_merchants_in_recovery__content_italic}
        color={K.colors.secondary}
      >
        {intl.formatMessage(
          locales.dashboard_promote_merchants_in_recovery_third_text
        )}{" "}
        <span className={classes.promote_merchants_in_recovery__content_emoji}>
          💚
        </span>
      </Typography>
    </div>
  );
};
PromotedMerchantsInRecovery.propTypes = {
  onClickTelephone: PropTypes.func.isRequired
};

export default PromotedMerchantsInRecovery;
