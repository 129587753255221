import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  panel_section__footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
});

const PanelSectionFooter = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.panel_section__footer}>{children}</div>;
};

PanelSectionFooter.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default PanelSectionFooter;
