import React from "react";
import queryString from "query-string";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { useMedia } from "react-use";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardFooter, Typography, Button } from "../../atoms";
import { ErrorBoundary } from "../../templates";
import { Overdue, Unpaid, Finished } from "./Status";
import { Shipping } from "./Invoices";
import { PaymentButton, Help } from "./components";
import { useOrder, useLocaleCurrency } from "../../hooks";
import { useFeatureFlags } from "../../contexts/FeatureFlags";
import locales from "../../locales";
import useTracking from "../../contexts/Tracking";
import K from "../../constants";

const useStyles = createUseStyles({
  order_payment_status: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginBottom: "24px",
    "@media(min-width: 768px)": {
      maxWidth: "295px"
    }
  },
  order_payment_status__footer: {
    display: "flex",
    flexDirection: "column",
    pointerEvents: "all",
    "& > :first-child": {
      marginBottom: "8px"
    }
  }
});

const OrderPaymentStatus = () => {
  const featureFlags = useFeatureFlags();
  const order = useOrder();
  const classes = useStyles();
  const intl = useIntl();
  const isMobile = useMedia("(max-width: 768px)");
  const history = useHistory();
  const { track, eventNames, buildOrderTrackingPayload } = useTracking();
  let children = null;

  if (order.isOverdue) {
    children = <Overdue />;
  } else if (order.isUnshipped && order.currentProductName === "i1") {
    children = <Shipping />;
  } else if (order.isPartiallyShipped && order.currentProductName === "i1") {
    if (order.isPartiallyPaid && order.unpaidInvoices.length === 0) {
      children = <Shipping />;
    } else children = <Unpaid />;
  } else {
    children = <Unpaid />;
  }

  const onClickButton = orderContact => {
    const { url, phone, phonePrefix, reference } = orderContact;
    if (url) {
      window.open(url);
    } else {
      if (reference === "RECOVERY") {
        track(eventNames.orderDetail.clickInRecoveryTelephone);
      }
      window.open(`tel:${phonePrefix}${phone}`);
    }
  };

  const dispatchActionPaymentsLink = () => {
    return /[s|p]?[p|r]\d+/.test(order.currentProductName)
      ? history.push(`/order/${order.id}/instalments`)
      : history.push(`/order/${order.id}/invoices`);
  };

  const onClickConversionButton = () => {
    const query = {
      process: K.process.conversion,
      step: K.steps.selectPlan
    };

    history.push(`?${queryString.stringify(query, { skipNull: true })}`);
  };

  const onClickFlexiConversionButton = () => {
    const query = {
      process: K.process.flexiConversion,
      step: K.steps.flexiSelectPlan
    };
    history.push(`?${queryString.stringify(query, { skipNull: true })}`);
    track(
      eventNames.flexiConversion.clickAction,
      buildOrderTrackingPayload(order, true)
    );
  };

  if (order.isCancelled || order.isPaid) {
    return (
      <ErrorBoundary component="OrderPanelStatus">
        <Finished
          status={order.isCancelled ? "cancelled" : "paid"}
          date={order.isCancelled ? null : order.paidOn}
          paymentsLink={isMobile ? dispatchActionPaymentsLink : null}
        />
      </ErrorBoundary>
    );
  }

  if (featureFlags?.showStatusV2) {
    console.log("Showing status redesigned version");
  }

  return (
    <ErrorBoundary component="OrderPanelStatus">
      <div className={classes.order_payment_status}>
        <Card>
          <CardBody>
            <Typography variant={K.typographicVariants.heading4}>
              {intl.formatMessage(locales.payment_status)}
            </Typography>

            {children}
          </CardBody>
          {order.canBePaid || order.canBeConverted ? (
            <CardFooter
              hideIcon
              className={classes.order_payment_status__footer}
            >
              {order.canBePaid ? (
                <PaymentButton isOverdue={order.isOverdue} />
              ) : null}
              {order.canBeConverted && order.currentProductName !== "pp3" ? (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={onClickConversionButton}
                  dataTestId="conversion-button"
                >
                  {intl.formatMessage(locales.conversion)}
                </Button>
              ) : null}
              {order.canBeConverted && order.currentProductName === "pp3" && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={onClickFlexiConversionButton}
                  dataTestId="flexi-conversion-button"
                >
                  {intl.formatMessage(locales.flexi_conversion_action, {
                    totalAmount: useLocaleCurrency(
                      order.unpaidInstalments[0]?.instalmentTotal,
                      {
                        useCents: true
                      }
                    )
                  })}
                </Button>
              )}
            </CardFooter>
          ) : null}

          {isMobile && !order.isCancelled ? (
            <CardFooter onClick={dispatchActionPaymentsLink}>
              <Typography>
                {intl.formatMessage(locales.payment_history)}
              </Typography>
            </CardFooter>
          ) : null}
        </Card>
        {order.sequraContact && (
          <Help
            metadata={order.sequraContact}
            onClickButton={() => onClickButton(order.sequraContact)}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default OrderPaymentStatus;
