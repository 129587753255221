import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useTheme } from "react-jss";

import { ReferralButton, Typography } from "../../atoms";
import { Papyrus } from "../../atoms/Icons";

import K from "../../constants";
import locales from "../../locales";

const ReferralProgramTermsAndConditionsAction = ({ process, className }) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <ReferralButton
      process={process}
      className={className}
      dataTestId="terms-and-conditions-button"
    >
      <Papyrus width={20} height={20} color={theme.palette.success.default} />
      <Typography variant={K.typographicVariants.body3}>
        {intl.formatMessage(
          locales.referral_program_terms_and_conditions_action
        )}
      </Typography>
    </ReferralButton>
  );
};

ReferralProgramTermsAndConditionsAction.propTypes = {
  className: PropTypes.string,
  process: PropTypes.string
};

ReferralProgramTermsAndConditionsAction.defaultProps = {
  className: "",
  process: ""
};

export default ReferralProgramTermsAndConditionsAction;
