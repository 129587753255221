import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Bag = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <path
      d="M5.604 1.849C6.82.38 9.21.382 10.45 1.842c.395.467.58 1.115.577 1.947l-.005.231-.011.179.077.001a2 2 0 011.85 1.248l.057.154.044.16.03.158.931 6.6a2.001 2.001 0 01-1.569 2.236l-.15.027-.139.013-.122.004H3.98a2.001 2.001 0 01-1.994-1.866l-.005-.152.006-.14L2 12.52l.932-6.6a2.002 2.002 0 011.654-1.692l.164-.021.162-.007h.128l-.01-.172c-.031-.771.087-1.4.37-1.888l.101-.158.102-.133zm-.306 3.95l-.385.001a.408.408 0 00-.355.206l-.024.06-.017.078-.932 6.6a.406.406 0 00.253.435l.061.015.082.006 8.066-.001.028-.003a.408.408 0 00.337-.298l.008-.072-.005-.082-.932-6.6a.408.408 0 00-.253-.323l-.063-.015-.08-.006-.335-.001-.059.25-.058.229-.147.518.057.064c.134.172.222.38.247.604l.008.136a1.2 1.2 0 11-1.815-1.03l.053-.029.09-.304.119-.438H6.805l.132.474.093.308.078.05c.268.196.448.5.485.84L7.6 7.6a1.2 1.2 0 11-2.074-.822l.027-.028-.083-.286-.1-.37-.072-.295zm1.44-3.037c-.183.222-.274.644-.241 1.259l.012.178h3.033l.013-.184c.031-.557-.036-.952-.18-1.178l-.05-.069C8.674 2.001 7.371 2 6.739 2.762z"
      fill={color}
    />
  </svg>
);

Bag.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Bag.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Bag;
