import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { List } from "../../atoms";
import PaymentCard from "../PaymentCard";
import PaymentCardError from "../PaymentCardError";

const useStyles = createUseStyles(theme => ({
  payment_card_list: {
    width: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    "& > *": {
      width: "100%",
      borderBottom: `1px solid ${theme.palette.shade.default}`,
      borderTop: "none"
    },
    "& > :first-child": {
      borderTop: `1px solid ${theme.palette.shade.default}`
    }
  }
}));

const PaymentCardList = ({ cardReferences, selectedCard, onSelectCard }) => {
  const classes = useStyles();

  return (
    <List className={classes.payment_card_list}>
      {cardReferences.map(card => (
        <React.Fragment key={card.id}>
          <PaymentCard
            card={card}
            isSelected={selectedCard?.lastFour === card.lastFour}
            isDisabled={card.isExpiringSoon || card.isExpired || card.isBlocked}
            onSelect={onSelectCard}
          />
          <PaymentCardError
            isExpired={card.isExpired}
            isExpiringSoon={card.isExpiringSoon}
            isDisabled={selectedCard?.lastFour !== card.lastFour}
            isBlocked={card.isBlocked}
          />
        </React.Fragment>
      ))}
    </List>
  );
};

PaymentCardList.propTypes = {
  cardReferences: PropTypes.arrayOf(PropTypes.shape()),
  selectedCard: PropTypes.shape(),
  onSelectCard: PropTypes.func
};

PaymentCardList.defaultProps = {
  cardReferences: [],
  selectedCard: null,
  onSelectCard: () => {}
};

export default PaymentCardList;
