import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import locales from "../../../../locales";
import { Button, NextModal, Typography } from "../../../../atoms";
import K from "../../../../constants";
import NextPurchase from "./next_purchases.png";

const useStyles = createUseStyles(theme => ({
  modal: {
    position: "relative",
    maxHeight: "none",
    height: "auto",
    maxWidth: 514,
    borderRadius: 8,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0
  },
  modal__content: {
    marginTop: "16px",
    border: "1px solid blue"
  },
  next_purchases: {
    width: "100%",
    textAlign: "center"
  },
  next_purchases__header: {
    marginTop: -5,
    "@media(min-width: 768px)": {
      marginTop: -30,
      marginBottom: 20
    }
  },
  next_purchases__figure: {
    background: theme.palette.success.medium,
    margin: "16px 0 26px"
  },
  next_purchases__content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 20,
    padding: "0 16px",
    "@media(min-width: 768px)": {
      flex: 0,
      marginTop: 0,
      padding: "0 48px"
    }
  },
  next_purchases__content__subtitle: {
    marginTop: 20,
    lineHeight: "normal"
  },
  next_purchases__footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    width: "100%",
    marginTop: 32,
    padding: "0 16px",
    "@media(min-width: 768px)": {
      marginTop: 42,
      padding: "0 48px"
    }
  },
  next_purchases__footer__back_btn: {
    marginTop: 16
  }
}));

const NextPurchasesPopup = ({ onClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  return (
    <NextModal
      open
      hideBack
      onClose={onClose}
      withIconClose
      modalClassname={classes.modal}
      withFullWidth
    >
      <div className={classes.next_purchases}>
        <figure className={classes.next_purchases__figure}>
          <img src={NextPurchase} alt="" width="270" />
        </figure>

        <div className={classes.next_purchases__content}>
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(locales.next_purchases_modal_title)}
          </Typography>
          <Typography className={classes.next_purchases__content__subtitle}>
            {intl.formatMessage(locales.next_purchases_modal_first_paragraph)}
          </Typography>
          <Typography className={classes.next_purchases__content__subtitle}>
            {intl.formatMessage(locales.next_purchases_modal_second_paragraph)}
          </Typography>
        </div>
        <div className={classes.next_purchases__footer}>
          <Button fullWidth size="medium" onClick={onClose}>
            {intl.formatMessage(locales.next_purchases_modal_action)}
          </Button>
        </div>
      </div>
    </NextModal>
  );
};

NextPurchasesPopup.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default NextPurchasesPopup;
