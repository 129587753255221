import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  modal_content: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "@media(min-width: 768px)": {
      padding: "0 40px 24px"
    }
  }
});

const ModalContent = React.forwardRef((props, ref) => {
  const { children, className } = props;
  const classes = useStyles();
  const componentRef = ref || React.useRef(null);
  const classNames = classnames(className, classes.modal_content);

  return (
    <section className={classNames} ref={componentRef}>
      {children}
    </section>
  );
});

ModalContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

ModalContent.defaultProps = {
  children: null,
  className: ""
};

export default ModalContent;
