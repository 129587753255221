import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles(theme => ({
  separator: {
    width: "100%",
    backgroundColor: theme.palette.background.default
  },
  listing: {
    height: 1
  },
  section: {
    height: 1
  },
  title: {
    height: 8
  }
}));

const Line = props => {
  const { variant } = props;
  const classes = useStyles({ variant });
  const classNames = classnames(classes.separator, classes[variant]);
  return <div role="separator" className={classNames} />;
};

Line.propTypes = {
  variant: PropTypes.oneOf(["section", "listing", "title"])
};

Line.defaultProps = {
  variant: "title"
};

export default Line;
