import React from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";

const styles = theme => ({
  tabs_indicator: {
    position: "absolute",
    height: "4px",
    borderRadius: "2px",
    bottom: 0,
    width: "100%",
    backgroundColor: theme.palette.success.default,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  }
});

const TabsIndicator = React.forwardRef((props, ref) => {
  const { classes, style } = props;

  return <span className={classes.tabs_indicator} style={style} ref={ref} />;
});

TabsIndicator.propTypes = {
  classes: PropTypes.shape({
    tabs_indicator: PropTypes.string.isRequired
  }).isRequired,
  style: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(TabsIndicator);
