import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import locales from "../../locales";
import { Button, NextModal, Typography } from "../../atoms";
import { NavigationArrowRight, NavigationArrowBack } from "../../atoms/Icons";
import K from "../../constants";
import Step1 from "./images/step1.png";
import Step2 from "./images/step2.png";
import Step3 from "./images/step3.png";
import Step4 from "./images/step4.png";
import Step5 from "./images/step5.png";

const useStyles = createUseStyles(theme => ({
  modal: {
    position: "relative",
    maxHeight: "none",
    maxWidth: 514,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0
  },
  modal__figure: {
    minHeight: "224px",
    margin: "16px 0 26px",
    background: theme.palette.success.medium,
    textAlign: "center"
  },
  modal__content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: 20,
    padding: "0 16px",
    minHeight: "195px",
    textAlign: "center",
    "@media(min-width: 768px)": {
      flex: 0,
      marginTop: 0,
      padding: "0 48px"
    },
    "& p": {
      marginTop: "24px",
      "& span": {
        lineHeight: "normal"
      }
    }
  },
  modal__content__title: {
    margin: "24px 0",
    "& strong": {
      color: theme.palette.success.default
    }
  },
  modal__content__footer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    width: "100%",
    marginTop: 32,
    padding: "0 16px",
    "@media(min-width: 768px)": {
      marginTop: 42,
      padding: "0 48px"
    },
    "& ul": {
      display: "flex",
      alignItems: "center"
    },
    " & li": {
      width: "11px",
      height: "11px",
      marginRight: "10px",
      overflow: "hidden",
      borderRadius: "50%",
      backgroundColor: theme.palette.success.medium,
      textIndent: "-999px",
      "&.active": {
        backgroundColor: theme.palette.success.default
      }
    }
  },
  modal__content__footer__actions: {
    display: "flex",
    "& > button": {
      marginLeft: "16px",
      paddingTop: "6px",
      width: "48px"
    }
  },
  modal__content__footer__action_last_step: {
    paddingTop: "1px!important"
  }
}));
const ModalContent = ({ img, title, paragraph, claim, dataTestId }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div data-testid={dataTestId}>
      <figure className={classes.modal__figure}>
        <img src={img} alt="" height="224" />
      </figure>
      <div className={classes.modal__content}>
        <Typography
          variant={K.typographicVariants.heading1}
          color={theme.palette.primary.default}
        >
          {title}
        </Typography>
        <p>
          <Typography className={classes.next_purchases__content__subtitle}>
            {paragraph}
          </Typography>
        </p>
        {claim && (
          <p>
            <Typography className={classes.next_purchases__content__subtitle}>
              {claim}
            </Typography>
          </p>
        )}
      </div>
    </div>
  );
};

ModalContent.propTypes = {
  img: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  claim: PropTypes.string,
  dataTestId: PropTypes.string.isRequired
};
ModalContent.defaultProps = {
  claim: "",
  img: "",
  paragraph: ""
};

const renderModalContent = currentStep => {
  const intl = useIntl();
  const AMOUNT = "50€";
  switch (currentStep) {
    case 1:
      return (
        <ModalContent
          img={Step1}
          title={intl.formatMessage(locales.on_boarding_first_step_title, {
            amount: AMOUNT
          })}
          paragraph={intl.formatMessage(
            locales.on_boarding_first_step_paragraph
          )}
          dataTestId="step1"
        />
      );
    case 2:
      return (
        <ModalContent
          img={Step2}
          title={intl.formatMessage(locales.on_boarding_second_step_title)}
          paragraph={intl.formatMessage(
            locales.on_boarding_second_step_paragraph
          )}
          dataTestId="step2"
        />
      );
    case 3:
      return (
        <ModalContent
          img={Step3}
          title={intl.formatMessage(locales.on_boarding_third_step_title)}
          paragraph={intl.formatMessage(
            locales.on_boarding_third_step_paragraph
          )}
          dataTestId="step3"
        />
      );
    case 4:
      return (
        <ModalContent
          img={Step4}
          title={intl.formatMessage(locales.on_boarding_fourth_step_title)}
          paragraph={intl.formatMessage(
            locales.on_boarding_fourth_step_paragraph
          )}
          dataTestId="step4"
        />
      );
    case 5:
      return (
        <ModalContent
          img={Step5}
          title={intl.formatMessage(locales.on_boarding_fifth_step_title)}
          dataTestId="step5"
        />
      );

    default:
      return null;
  }
};

const OnBoardingModal = ({ onClose, numberOfSteps }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const onClickNextButton = () => setCurrentStep(currentStep + 1);
  const onClickPrevButton = () => setCurrentStep(currentStep - 1);
  return (
    <NextModal
      open
      hideBack
      onClose={onClose}
      withIconClose
      modalClassname={classes.modal}
      withFullWidth
    >
      {renderModalContent(currentStep)}
      <div className={classes.modal__content__footer}>
        <ul>
          {Array(numberOfSteps)
            .fill("")
            .map((_, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={index + 1 === currentStep ? "active" : undefined}
              >
                {index + 1}
              </li>
            ))}
        </ul>
        <div className={classes.modal__content__footer__actions}>
          {currentStep > 1 && (
            <Button
              variant="text"
              size="medium"
              onClick={onClickPrevButton}
              dataTestId="prev-step"
            >
              <NavigationArrowBack color={theme.palette.primary.default} />
            </Button>
          )}

          <Button
            size="medium"
            onClick={
              currentStep !== numberOfSteps ? onClickNextButton : onClose
            }
            dataTestId="next-step"
            variant={currentStep === numberOfSteps ? "text" : "contained"}
            className={
              currentStep === numberOfSteps
                ? classes.modal__content__footer__action_last_step
                : ""
            }
          >
            {currentStep !== numberOfSteps ? (
              <NavigationArrowRight color={theme.palette.white.default} />
            ) : (
              <Typography
                color={theme.palette.primary.default}
                variant={K.typographicVariants.linkS}
              >
                {intl.formatMessage(locales.general_close_label)}
              </Typography>
            )}
          </Button>
        </div>
      </div>
    </NextModal>
  );
};

OnBoardingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  numberOfSteps: PropTypes.number
};
OnBoardingModal.defaultProps = {
  numberOfSteps: 5
};

export default OnBoardingModal;
