import { useEffect, useState } from "react";
import useGenerateTokenizationPaymentGateway from "../mutations/useGenerateTokenizationPaymentGateway";

export default cardReferenceId => {
  const [iframeUrl, setIframeUrl] = useState(null);

  const [
    generateTokenizationPaymentGateway,
    { error }
  ] = useGenerateTokenizationPaymentGateway();

  const getIframeUrl = async variables => {
    const response = await generateTokenizationPaymentGateway({ variables });
    const {
      generateTokenizationPaymentGatewayRequest: { iframeUrl: url }
    } = response.data;
    setIframeUrl(url);
  };

  useEffect(() => {
    getIframeUrl({ cardReferenceId });
  }, []);

  return {
    iframeUrl,
    error
  };
};
