import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import {
  Typography,
  DropDownList,
  DropDownListHeader,
  DropDownListContent,
  DropDownListFooter
} from "../../atoms";
import { Visa, MasterCard } from "../../atoms/Icons";
import { BannerDiscountApplied } from "../../molecules";
import { OrderContext } from "../../contexts";
import { useLocaleCurrency, useStatus } from "../../hooks";
import { centsToBasicUnit } from "../../utils";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles({
  order_instalment_list: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginTop: "16px",
    "@media(min-width: 768px)": {
      maxWidth: "612px"
    }
  },
  order_instalment_list__content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "3px 0",
    "& > :first-child": {
      display: "flex",
      alignItems: "center"
    }
  },
  order_instalment_list__content__payment_method: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "30px",
    "& svg": {
      marginRight: "8px"
    }
  }
});

const OrderInstalmentList = ({ hasFutureInstalments, disableDropdown }) => {
  const order = React.useContext(OrderContext);
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  const pastInstalments = [...order.paymentPlan.pastInstalments].reverse();
  const futureInstalments = [...order.paymentPlan.futureInstalments];

  const instalments = hasFutureInstalments
    ? futureInstalments
    : pastInstalments;

  const rowInstalmentInfo = (label, value) => (
    <div className={classes.order_instalment_list__content}>
      <span>{intl.formatMessage(label)}</span>
      <span>{useLocaleCurrency(centsToBasicUnit(value))}</span>
    </div>
  );

  const rowInstalmentInfoWithPaymentMethod = (label, accountEvent, value) => (
    <div className={classes.order_instalment_list__content}>
      <span>{intl.formatMessage(label)}</span>
      <div className={classes.order_instalment_list__content__payment_method}>
        {accountEvent.paymentMethod === "CARD_PAYMENT" && (
          <>
            {accountEvent.cardReference.issuer === "Visa" ? (
              <Visa />
            ) : (
              <MasterCard />
            )}
            {intl.formatMessage(locales.credit_card_mask, {
              lastFour: accountEvent.cardReference.lastFour
            })}
          </>
        )}
        {accountEvent.paymentMethod === "BANK_TRANSFER" &&
          intl.formatMessage(locales.bank_transfer)}
      </div>
      <span>{useLocaleCurrency(centsToBasicUnit(value))}</span>
    </div>
  );

  return (
    <div className={classes.order_instalment_list}>
      {order.discountAppliedAmount > 0 && (
        <BannerDiscountApplied amount={order.discountAppliedAmount} />
      )}

      {instalments.map(instalment => {
        const status = useStatus({
          paymentStatus: instalment.paymentStatus,
          isOverdue: instalment.isOverdue,
          isInvoice: false
        });
        const amountToShow =
          instalment.paymentStatus === "PAID"
            ? instalment.paidAmount
            : instalment.instalmentTotal ?? instalment.amount;

        return (
          <DropDownList
            key={String(instalment.instalmentNumber)}
            hideDropdown={disableDropdown}
          >
            <DropDownListHeader
              title={`${intl.formatMessage(locales.PAYMENT)} ${Number(
                instalment.instalmentNumber
              ) + 1}`}
              subtitle={useLocaleCurrency(centsToBasicUnit(amountToShow))}
              colorSubtitle={
                instalment.isOverdue === true ? theme.palette.error.default : ""
              }
            />

            <DropDownListContent>
              {instalment.accountEvents.length === 0 && (
                <>
                  <Typography
                    variant={K.typographicVariants.body3}
                    color={theme.palette.secondary.default}
                  >
                    {rowInstalmentInfo(
                      locales.amount,
                      instalment.instalmentAmount
                    )}

                    {rowInstalmentInfo(
                      locales.INSTALMENT_FEE,
                      instalment.instalmentFee
                    )}

                    {rowInstalmentInfo(
                      locales.total,
                      instalment.instalmentTotal
                    )}
                  </Typography>
                </>
              )}

              <Typography
                variant={K.typographicVariants.body3}
                color={theme.palette.secondary.default}
              >
                {instalment.accountEvents &&
                  instalment.accountEvents.map(accountEvent => (
                    <div key={accountEvent.id}>
                      {rowInstalmentInfoWithPaymentMethod(
                        locales[accountEvent.type],
                        accountEvent,
                        accountEvent.amount
                      )}
                    </div>
                  ))}
              </Typography>
            </DropDownListContent>

            <DropDownListFooter
              title={intl.formatDate(instalment.dueOn, {
                day: "numeric",
                month: "short",
                year: "numeric",
                timeZone: "Europe/Madrid"
              })}
              subtitle={status.text}
              chipType={status.chipType}
            />
          </DropDownList>
        );
      })}
    </div>
  );
};

OrderInstalmentList.propTypes = {
  hasFutureInstalments: PropTypes.bool,
  disableDropdown: PropTypes.bool
};

OrderInstalmentList.defaultProps = {
  hasFutureInstalments: false,
  disableDropdown: false
};

export default OrderInstalmentList;
