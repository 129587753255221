import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import { Cross } from "../../../../atoms/Illustrations";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  cancelled: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

const Cancelled = () => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.cancelled}>
      <Typography variant={K.typographicVariants.visual3}>
        {intl.formatMessage(locales.order_cancelled)}
      </Typography>
      <Cross width={97} height={97} color={theme.palette.secondary.default} />
    </div>
  );
};

export default Cancelled;
