import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import locales from "../../locales";
import { Button, NextModal, Typography } from "../../atoms";
import { UnicornFloat } from "../../atoms/Icons";
import K from "../../constants";
import useTrackingContext from "../../contexts/Tracking";

const useStyles = createUseStyles({
  modal_wrapper: {
    padding: "1rem",
    "@media(min-width: 768px)": {
      padding: "0",
      width: "32rem"
    }
  },
  modal: {
    padding: "1rem",
    "@media(min-width: 768px)": {
      width: "fit-content",
      padding: "1rem 3rem 1rem 3rem"
    }
  },
  modal__content: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.5em",
    "& h5, & span": {
      marginTop: 24
    }
  },
  modal__content__title: {
    marginTop: "1.125rem"
  },
  modal__bullet_points: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    lineHeight: "1.5em",
    "& span": {
      marginTop: 0
    }
  },
  modal__footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 56
  },
  modal__link: {
    display: "inline",
    "& span:first-child": {
      paddingRight: 4
    }
  },
  modal__intro: {
    marginBottom: 24
  },
  modal__end: {
    marginTop: "48px !important"
  }
});

const ReferralProgramTermsAndConditionsModal = ({ onClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const { track, eventNames } = useTrackingContext();

  return (
    <NextModal
      open
      hideBack
      onClose={onClose}
      withIconClose
      modalClassname={classes.modal_wrapper}
      withFullWidth
    >
      <div className={classes.modal} data-testid="terms-and-conditions-modal">
        <UnicornFloat />
        <div className={classes.modal__content}>
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
            className={classes.modal__content__title}
          >
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_title
            )}
          </Typography>
          <Typography
            className={classes.modal__intro}
            variant={K.typographicVariants.body2}
          >
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_intro
            )}
          </Typography>
          <Typography variant={K.typographicVariants.heading5}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_reward_title
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_reward_content1
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_reward_content2
            )}
          </Typography>
          <Typography variant={K.typographicVariants.heading5}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_expiration_title
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_expiration_content
            )}
          </Typography>
          <Typography variant={K.typographicVariants.heading5}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_participate_title
            )}
          </Typography>
          <span className={classes.modal__bullet_points}>
            <Typography variant={K.typographicVariants.body2}>
              -{" "}
              {intl.formatMessage(
                locales.referral_program_terms_and_conditions_modal_participate_requirement1
              )}
            </Typography>
            <Typography variant={K.typographicVariants.body2}>
              -{" "}
              {intl.formatMessage(
                locales.referral_program_terms_and_conditions_modal_participate_requirement2
              )}
            </Typography>
            <Typography variant={K.typographicVariants.body2}>
              -{" "}
              {intl.formatMessage(
                locales.referral_program_terms_and_conditions_modal_participate_requirement3
              )}
            </Typography>
          </span>
          <Typography variant={K.typographicVariants.heading5}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_invite_limit_title
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_invite_limit_content
            )}
          </Typography>
          <Typography variant={K.typographicVariants.heading5}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_cancellations_title
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_cancellations_content
            )}
          </Typography>
          <Typography
            variant={K.typographicVariants.body2}
            className={classes.modal__end}
          >
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_end_content
            )}
          </Typography>
          <span className={classes.modal__link}>
            <Typography variant={K.typographicVariants.body2}>
              {intl.formatMessage(
                locales.referral_program_terms_and_conditions_modal_link_text
              )}
            </Typography>
            <a
              target="_blank"
              href={K.externalUrls.sequraWeb.referralTermsAndConditions}
              rel="noreferrer"
              onClick={() =>
                track(
                  eventNames.referralProgram
                    .openTermsAndConditionsExternalLinkFromModal
                )
              }
            >
              <Typography variant={K.typographicVariants.linkS}>
                {intl.formatMessage(
                  locales.referral_program_terms_and_conditions_modal_link_tag
                )}
              </Typography>
            </a>
          </span>
        </div>
        <div className={classes.modal__footer}>
          <Button fullWidth size="medium" onClick={onClose}>
            {intl.formatMessage(
              locales.referral_program_terms_and_conditions_modal_agreed
            )}
          </Button>
        </div>
      </div>
    </NextModal>
  );
};

ReferralProgramTermsAndConditionsModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ReferralProgramTermsAndConditionsModal;
