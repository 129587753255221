import "./bootstrap";
import React from "react";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "react-jss";
import theme from "./theme";
import client from "./api";
import { TrackingContextProvider } from "./contexts/Tracking";
import { MonitoringContextProvider } from "./contexts/Monitoring";
import { ShopperContextProvider } from "./contexts/Shopper";
import { NotificationContextProvider } from "./contexts/Notification";
import { AppContextProvider } from "./contexts/App";
import { SessionAuthorizationContextProvider } from "./contexts/SessionAuthentication";
import { CookiesConsentContextProvider } from "./contexts/CookiesConsent";
import { FeatureFlagsProvider } from "./contexts/FeatureFlags";
import App from "./App";
import getMessagesForLocale from "./locales/index";

const messages = getMessagesForLocale(window.locale);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <MonitoringContextProvider>
    <ApolloProvider client={client}>
      <IntlProvider locale={window.locale} messages={messages}>
        <FeatureFlagsProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <CookiesConsentContextProvider>
                <TrackingContextProvider>
                  <NotificationContextProvider>
                    <AppContextProvider>
                      <SessionAuthorizationContextProvider>
                        <ShopperContextProvider>
                          <App />
                        </ShopperContextProvider>
                      </SessionAuthorizationContextProvider>
                    </AppContextProvider>
                  </NotificationContextProvider>
                </TrackingContextProvider>
              </CookiesConsentContextProvider>
            </ThemeProvider>
          </BrowserRouter>
        </FeatureFlagsProvider>
      </IntlProvider>
    </ApolloProvider>
  </MonitoringContextProvider>
);
