import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography, Calendar } from "../../../../atoms";
import { PartPaymentProgress } from "../../../../molecules";
import { useLocaleCurrency } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  unpaid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  unpaid__info: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: ({ isCampaign }) => (isCampaign ? "16px" : "8px")
    },
    "& > :nth-child(3)": {
      marginTop: "8px"
    }
  }
});

const PartPayment = ({
  nextInstalment,
  isCampaign,
  isOverdue,
  instalmentsCount,
  paidInstalmentsCount
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles({ isCampaign });

  return (
    <div className={classes.unpaid}>
      <div className={classes.unpaid__info}>
        <Typography variant={K.typographicVariants.visual3}>
          {isCampaign
            ? intl.formatMessage(locales.auto_payment)
            : intl.formatMessage(locales.next_instalment)}
        </Typography>
        {isCampaign ? null : (
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatDate(nextInstalment.dueOn, {
              day: "2-digit",
              month: "long",
              timeZone: "Europe/Madrid"
            })}
          </Typography>
        )}
        <Typography variant={K.typographicVariants.visual3}>
          {useLocaleCurrency(
            nextInstalment.instalmentTotal || nextInstalment.amount,
            {
              useCents: true
            }
          )}
        </Typography>
      </div>
      <div>
        {isCampaign ? (
          <Calendar
            day={intl.formatDate(nextInstalment.dueOn, {
              day: "2-digit",
              timeZone: "Europe/Madrid"
            })}
            month={intl.formatDate(nextInstalment.dueOn, {
              month: "long",
              timeZone: "Europe/Madrid"
            })}
            color={theme.palette.info.default}
            backgroundColor={theme.palette.info.light}
          />
        ) : (
          <PartPaymentProgress
            instalmentsCount={instalmentsCount}
            paidInstalmentsCount={paidInstalmentsCount}
            isOverdue={isOverdue}
          />
        )}
      </div>
    </div>
  );
};

PartPayment.propTypes = {
  nextInstalment: PropTypes.shape().isRequired,
  isCampaign: PropTypes.bool,
  isOverdue: PropTypes.bool,
  instalmentsCount: PropTypes.number,
  paidInstalmentsCount: PropTypes.number
};

PartPayment.defaultProps = {
  isCampaign: false,
  isOverdue: false,
  instalmentsCount: 0,
  paidInstalmentsCount: 0
};

export default PartPayment;
