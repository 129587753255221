import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Typography } from "../../../../atoms";
import { ModalDescription } from "../../../ConversionFlow/components";
import { useOrder, useLocaleCurrency } from "../../../../hooks";
import InvoiceDropDown from "../InvoiceDropDown";
import InstalmentDropDown from "../InstalmentDropDown";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  summary_list_total: {
    maxWidth: "375px",
    display: "flex",
    justifyContent: "space-between"
  }
});

const PaymentModalDescription = ({ invoiceNumbers, amountCents }) => {
  const order = useOrder();
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();

  const invoices =
    typeof invoiceNumbers === "string"
      ? [order.invoices.find(invoice => invoice.number === invoiceNumbers)]
      : invoiceNumbers.map(number =>
          order.invoices.find(invoice => invoice.number === number)
        );

  const instalments =
    order.instalments?.filter(instalment => instalment.isOverdue) || [];

  return (
    <ModalDescription title={intl.formatMessage(locales.payment_summary)}>
      {invoices.map(invoice => (
        <InvoiceDropDown invoice={invoice} key={invoice.number} />
      ))}
      {instalments.map(instalment => (
        <InstalmentDropDown
          instalment={instalment}
          key={instalment.instalmentNumber}
        />
      ))}
      <div className={classes.summary_list_total}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.total)}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {useLocaleCurrency(amountCents, {
            useCents: true
          })}
        </Typography>
      </div>
    </ModalDescription>
  );
};

PaymentModalDescription.propTypes = {
  invoiceNumbers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  amountCents: PropTypes.number.isRequired
};

PaymentModalDescription.defaultProps = {
  invoiceNumbers: []
};

export default PaymentModalDescription;
