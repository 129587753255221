import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { isValidIBAN } from "ibantools";
import { Button, TextField } from "@sequra/telar";
import { Typography } from "../../../../../atoms";
import { isMobile } from "../../../../../utils";
import locales from "../../../../../locales";
import K from "../../../../../constants";

const useStyles = createUseStyles({
  IBANInput: {
    display: "flex",
    flexDirection: "column"
  },
  error_message: {
    margin: "12px",
    display: "block"
  }
});

const IBANInput = ({ onConfirm, error }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [iban, setIban] = useState("");
  const [isValidIban, setIsValidIban] = useState(false);
  const [isIbanValidated, setIsIbanValidated] = useState(false);
  const intl = useIntl();

  function handleInputChange(e) {
    const newIban = e.target.value;
    setIban(newIban);

    setTimeout(() => {
      setIsValidIban(isValidIBAN(newIban));
      setIsIbanValidated(true);
    }, 500);
  }

  return (
    <div className={classes.IBANInput}>
      <TextField
        onChange={handleInputChange}
        value={iban}
        data-testid="refund_iban_input"
        placeholder={intl.formatMessage(locales.iban_input_title)}
        label="IBAN"
      />

      <span className={classes.error_message}>
        <Typography
          variant={K.typographicVariants.body3}
          color={theme.palette.error.default}
        >
          {iban &&
            isIbanValidated &&
            !isValidIban &&
            intl.formatMessage(locales.wrong_IBAN)}
          {error && intl.formatMessage(locales.iban_input_error)}
        </Typography>
      </span>

      <Button
        submit
        fullWidth={isMobile.phone}
        onClick={() => {
          onConfirm(iban.replace(/\s/g, ""));
        }}
      >
        {intl.formatMessage(locales.iban_input_action)}
      </Button>
    </div>
  );
};

IBANInput.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  error: PropTypes.bool
};

IBANInput.defaultProps = {
  error: false
};

export default IBANInput;
