import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { withModal } from "../../../../hocs";
import { Typography } from "../../../../atoms";
import { Alert } from "../../../../atoms/Icons";
import { Card } from "../../../../atoms/Illustrations";
import OrderListToUpdate from "../OrderListToUpdate";
import OrderListUpdated from "../OrderListUpdated";
import { MessageBox } from "../../../../molecules";

import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  modal: {
    position: "relative",
    textAlign: "left"
  },
  modal__icon: {
    marginBottom: "20px"
  },
  modal__footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 56
  },
  modal__content__sub_title: {
    margin: "20px 0 25px"
  },
  modal__footer__back_btn: {
    position: "absolute",
    bottom: 0,
    display: "block",
    marginTop: 16
  }
});

const Modal = ({
  ordersToUpdate,
  numberOfOrdersWithOverdue,
  onClickUpdateCard,
  currentCardReferenceId,
  orderCardUpdated,
  currentOrder
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const renderSubtitle =
    orderCardUpdated.length > 0 ||
    ordersToUpdate.length > 0 ||
    numberOfOrdersWithOverdue > 0;
  return (
    <div className={classes.modal}>
      <div className={classes.modal__icon}>
        <Card width={76} height={46} />
      </div>

      <Typography
        className={classes.modal__content__title}
        variant={K.typographicVariants.heading1}
        color={theme.palette.primary.default}
      >
        {intl.formatMessage(locales.update_cards_flow_title)}
      </Typography>

      {renderSubtitle && (
        <Typography
          className={classes.modal__content__sub_title}
          variant={K.typographicVariants.heading4}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.update_cards_flow_sub_title)}
        </Typography>
      )}

      {orderCardUpdated.length > 0 && (
        <OrderListUpdated orders={orderCardUpdated} />
      )}

      {ordersToUpdate.length > 0 && (
        <OrderListToUpdate
          orders={ordersToUpdate}
          onClickUpdateCard={onClickUpdateCard}
          currentCardReferenceId={currentCardReferenceId}
          orderCardUpdated={orderCardUpdated}
          currentOrder={currentOrder}
        />
      )}

      {numberOfOrdersWithOverdue > 0 && (
        <MessageBox
          title={intl.formatMessage(
            locales.update_cards_flow_error_messages_title,
            {
              orders: numberOfOrdersWithOverdue
            }
          )}
          text={intl.formatMessage(
            locales.update_cards_flow_error_messages_sub_title
          )}
          link="/orders"
          linkText={intl.formatMessage(
            locales.update_cards_flow_error_messages_action
          )}
          icon={<Alert color={theme.palette.primary.default} />}
          testid="messages-component"
          outstanding
        />
      )}
    </div>
  );
};

Modal.propTypes = {
  ordersToUpdate: PropTypes.oneOfType([() => null, PropTypes.object]),
  orderCardUpdated: PropTypes.oneOfType([() => null, PropTypes.object]),
  currentOrder: PropTypes.oneOfType([() => null, PropTypes.object]),
  numberOfOrdersWithOverdue: PropTypes.number,
  currentCardReferenceId: PropTypes.string,
  onClickUpdateCard: PropTypes.func.isRequired
};

Modal.defaultProps = {
  ordersToUpdate: [],
  orderCardUpdated: [],
  currentOrder: [],
  numberOfOrdersWithOverdue: 0,
  currentCardReferenceId: ""
};

export default withModal(Modal, {
  open: true,
  hideBack: true,
  withIconClose: true
});
