import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { AmericanExpress, Check, MasterCard, Visa } from "../../atoms/Icons";
import { Typography } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  payment_card: {
    width: "100%",
    height: "56px",
    display: "grid",
    gridTemplateColumns: "64px 1fr auto",
    alignItems: "center",
    cursor: "pointer",
    borderTop: `1px solid ${theme.palette.shade.default}`,
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    padding: "0 16px",
    pointerEvents: ({ isDisabled }) => (isDisabled ? "none" : "auto"),
    boxSizing: "border-box",
    "& p": {
      fontWeight: ({ isSelected }) => (isSelected ? 600 : 400)
    },
    "@media(min-width: 768px)": {
      maxWidth: "375px"
    }
  },
  payment_card__logo: {
    opacity: ({ isDisabled }) => (isDisabled ? 0.7 : 1)
  }
}));

const PaymentCard = ({ card, isSelected, onSelect, isDisabled }) => {
  const theme = useTheme();
  const classes = useStyles({ isDisabled, isSelected });
  const intl = useIntl();

  const onSelectCard = () => {
    onSelect(card);
  };

  return (
    <div
      className={classes.payment_card}
      onClick={onSelectCard}
      onKeyPress={onSelectCard}
      tabIndex={0}
      role="row"
      aria-disabled={isDisabled}
      aria-selected={isSelected}
    >
      <div className={classes.payment_card__logo}>
        {card.issuer === "Visa" ? <Visa /> : null}
        {card.issuer === "MasterCard" ? <MasterCard /> : null}
        {card.issuer === "AmericanExpress" ? <AmericanExpress /> : null}
      </div>
      <div className={classes.payment_card__last_four}>
        <Typography
          variant={K.typographicVariants.body2}
          color={
            isDisabled
              ? theme.palette.secondary.default
              : theme.palette.primary.default
          }
        >
          {intl.formatMessage(locales.payment_card_mask_number, {
            lastFour: card.lastFour
          })}
        </Typography>
      </div>
      {isSelected ? (
        <div className={classes.payment_card__check}>
          <Check color={theme.palette.success.default} />
        </div>
      ) : null}
    </div>
  );
};

PaymentCard.propTypes = {
  card: PropTypes.shape(),
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func
};

PaymentCard.defaultProps = {
  card: null,
  isSelected: false,
  isDisabled: false,
  onSelect: () => {}
};

export default PaymentCard;
