import React from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { withModal } from "../../hocs";
import { getIntl } from "../../utils";
import locales from "../../locales";
import useTrackingContext from "../../contexts/Tracking";
import useNotificationContext from "../../contexts/Notification";
import { MufasaTokenizationGateway, MessageBox } from "../../molecules";

const AddCardModal = () => {
  const location = useLocation();
  const intl = useIntl();
  const {
    track,
    eventNames,
    eventProperties,
    eventPropertyCategories
  } = useTrackingContext();
  const { setSnackbarLayoutProps } = useNotificationContext();

  const processSuccessfulCardOperation = ({
    trackEventProps,
    snackbarMessage
  }) => {
    track(eventNames.tokenization.success, trackEventProps);
    setSnackbarLayoutProps({
      open: true,
      variant: "accent",
      label: snackbarMessage
    });
    window.location.replace(location.pathname);
  };

  const processFailedCardOperation = ({ trackEventProps }) => () => {
    track(eventNames.tokenization.fail, trackEventProps);
    setSnackbarLayoutProps({
      open: true,
      variant: "error",
      label: intl.formatMessage(locales.ups_something_went_wrong)
    });
  };

  return (
    <>
      <MufasaTokenizationGateway
        onSuccess={() =>
          processSuccessfulCardOperation({
            trackEventProps: {
              [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
            },
            label: intl.formatMessage(locales.updated_your_account_card)
          })
        }
        onError={() =>
          processFailedCardOperation({
            trackEventProps: {
              [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
            }
          })
        }
      />

      <MessageBox
        type="warning"
        text={intl.formatMessage(
          locales.verification_when_new_card_message_line,
          {
            br: <br />
          }
        )}
      />
    </>
  );
};

export default withModal(AddCardModal, {
  title: getIntl.formatMessage(locales.new_card)
});
