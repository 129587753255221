import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { useSpring, useTransition, animated } from "react-spring";
import { Typography } from "../../../../atoms";
import { CheckCircle, RadioButtonUnchecked } from "../../../../atoms/Icons";
import { useLocaleCurrency } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  conversion_plan_selector: {
    minWidth: "280px",
    width: "100%",
    height: "87px",
    borderRadius: "8px",
    backgroundColor: theme.palette.white.default,
    borderWidth: "2px",
    borderStyle: "solid",
    boxSizing: "border-box",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    cursor: "pointer",
    "@media(min-width: 768px)": {
      minWidth: "141px",
      maxWidth: "175px",
      width: "100%",
      height: "127px"
    }
  },
  conversion_plan_selector__checkbox: {
    height: "24px",
    width: "24px",
    boxSizing: "border-box",
    borderRadius: "100%",
    position: "absolute",
    right: "12px",
    top: "12px"
  },
  conversion_plan_selector__instalments: {
    textTransform: "uppercase"
  },
  conversion_plan_selector__amount: {
    display: "flex",
    alignItems: "baseline",
    "& > :last-child": {
      marginLeft: "8px"
    },
    "@media(min-width: 768px)": {
      flexDirection: "column",
      "& > :last-child": {
        marginLeft: 0
      }
    }
  }
}));

const ConversionPlanSelector = ({ conversionPlan, onClick, isSelected }) => {
  const classes = useStyles({ isSelected });
  const intl = useIntl();
  const theme = useTheme();

  const prop = useSpring({
    borderColor: isSelected
      ? theme.palette.success.default
      : theme.palette.shade.default
  });

  const transitions = useTransition(isSelected, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return (
    <animated.div
      role="row"
      tabIndex="0"
      onKeyPress={onClick}
      onClick={onClick}
      aria-selected={isSelected}
      className={classes.conversion_plan_selector}
      style={prop}
    >
      <div className={classes.conversion_plan_selector__checkbox}>
        {transitions.map(({ item, key, props }) =>
          item ? (
            <animated.div key={key} style={props}>
              <CheckCircle color={theme.palette.success.default} />
            </animated.div>
          ) : (
            <animated.div key={key} style={props}>
              <RadioButtonUnchecked color={theme.palette.shade.default} />
            </animated.div>
          )
        )}
      </div>
      <div className={classes.conversion_plan_selector__instalments}>
        <Typography
          variant={K.typographicVariants.heading4}
          color={theme.palette.secondary.default}
        >
          {intl.formatMessage(locales.quantity_instalments, {
            quantity: conversionPlan.instalmentCount
          })}
        </Typography>
      </div>
      <div className={classes.conversion_plan_selector__amount}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {useLocaleCurrency(conversionPlan.instalmentTotal, {
            useCents: true
          })}
        </Typography>
        <Typography>
          {intl.formatMessage(locales.order_conversion_per_month_label)}
        </Typography>
      </div>
    </animated.div>
  );
};

ConversionPlanSelector.propTypes = {
  conversionPlan: PropTypes.shape(),
  onClick: PropTypes.func,
  isSelected: PropTypes.bool
};

ConversionPlanSelector.defaultProps = {
  conversionPlan: {},
  onClick: () => {},
  isSelected: false
};

export default ConversionPlanSelector;
