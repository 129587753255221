import React from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useOrder } from "../../hooks";
import { ReturnRequestSummaryModal, ReturnRequestConfirmModal } from "./modals";
import useTrackingContext from "../../contexts/Tracking";
import K from "../../constants";

const ReturnRequestFlow = () => {
  const order = useOrder();
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  React.useEffect(() => {
    /*
    This avoid to send to mixpanel data when a user uses
    the return request (does not exist yet) shortened link
    to access to the app and redirect to the location.pathname
    */
    if (order.canBeReturned) {
      track(
        eventNames.returnRequest.start,
        buildOrderTrackingPayload(order, true)
      );
    } else {
      history.push(location.pathname);
    }
  }, []);

  if (!order.canBeReturned) {
    return null;
  }

  switch (query.step) {
    case K.steps.summary:
      return <ReturnRequestSummaryModal />;
    case K.steps.confirm:
      return <ReturnRequestConfirmModal />;
    default:
      return null;
  }
};

export default ReturnRequestFlow;
