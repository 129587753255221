import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Dashboard = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      d="M10,15 C10.5107143,15 10.9352041,15.3880102 10.9932398,15.883801 L11,16 L11,20 C11,20.5107143 10.6119898,20.9352041 10.116199,20.9932398 L10,21 L4,21 C3.48928571,21 3.06479592,20.6119898 3.0067602,20.116199 L3,20 L3,16 C3,15.4892857 3.3880102,15.0647959 3.88380102,15.0067602 L4,15 L10,15 Z M20,11 C20.5107143,11 20.9352041,11.3880102 20.9932398,11.883801 L21,12 L21,20 C21,20.5107143 20.6119898,20.9352041 20.116199,20.9932398 L20,21 L14,21 C13.4892857,21 13.0647959,20.6119898 13.0067602,20.116199 L13,20 L13,12 C13,11.4892857 13.3880102,11.0647959 13.883801,11.0067602 L14,11 L20,11 Z M9,17 L5,17 L5,19 L9,19 L9,17 Z M19,13 L15,13 L15,19 L19,19 L19,13 Z M10,3 C10.5107143,3 10.9352041,3.3880102 10.9932398,3.88380102 L11,4 L11,12 C11,12.5107143 10.6119898,12.9352041 10.116199,12.9932398 L10,13 L4,13 C3.48928571,13 3.06479592,12.6119898 3.0067602,12.116199 L3,12 L3,4 C3,3.48928571 3.3880102,3.06479592 3.88380102,3.0067602 L4,3 L10,3 Z M9,5 L5,5 L5,11 L9,11 L9,5 Z M20,3 C20.5107143,3 20.9352041,3.3880102 20.9932398,3.88380102 L21,4 L21,8 C21,8.51071429 20.6119898,8.93520408 20.116199,8.9932398 L20,9 L14,9 C13.4892857,9 13.0647959,8.6119898 13.0067602,8.11619898 L13,8 L13,4 C13,3.48928571 13.3880102,3.06479592 13.883801,3.0067602 L14,3 L20,3 Z M19,5 L15,5 L15,7 L19,7 L19,5 Z"
      fill={color}
    />
  </svg>
);

Dashboard.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Dashboard.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Dashboard;
