import { useLazyQuery } from "@apollo/client";
import { SHOPPER_QUERY, REFERRAL_PROGRAM_FRAGMENT } from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError }) =>
  useLazyQuery(SHOPPER_QUERY(REFERRAL_PROGRAM_FRAGMENT), {
    fetchPolicy: K.fetchPolicy.cacheAndNetwork,
    nextFetchPolicy: K.fetchPolicy.cacheFirst,
    onCompleted,
    onError
  });
