import React from "react";
import queryString from "query-string";
import { createUseStyles, useTheme } from "react-jss";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useMedia } from "react-use";
import { Button, Typography } from "../../../../atoms";
import { ScaleLoader } from "../../../../atoms/Loaders";
import PaymentCardList from "../../../../organisms/PaymentCardList";
import { withModal } from "../../../../hocs";
import {
  getIntl,
  goToStep,
  filterReusableCardReferences
} from "../../../../utils";
import {
  useOrder,
  useShopper,
  useShopperCardsDataQuery
} from "../../../../hooks";
import {
  ASSIGN_CARD,
  ORDER_QUERY,
  ORDER_INFO_FRAGMENT
} from "../../../../graphql";
import K from "../../../../constants";
import useNotificationContext from "../../../../contexts/Notification";
import useTrackingContext from "../../../../contexts/Tracking";
import locales from "../../../../locales";

const useStyles = createUseStyles({
  select_card_modal: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  select_card_modal__link: {
    appearance: "none",
    border: 0,
    padding: 0,
    marginTop: "5px",
    display: "flex",
    marginBottom: "32px",
    backgroundColor: "transparent",
    "@media(min-width: 768px)": {
      marginBottom: "16px"
    }
  },
  buttons_container: {
    padding: "24px 0",
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "40px"
    }
  },
  modal_title_classname: {
    marginBottom: 0
  }
});

const SelectCardModal = () => {
  const order = useOrder();
  const shopper = useShopper();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const { setSnackbarLayoutProps } = useNotificationContext();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();
  const isWideScreen = useMedia("(min-width: 768px)");
  const [cardReference, setCardReference] = React.useState(null);

  const resetPaymentProcess = () => {
    setCardReference(null);
  };

  const processSuccessfulUpdatedCardOperation = ({ trackEventProps }) => {
    track(eventNames.assignCard.success, trackEventProps);
    const success = queryString.stringifyUrl({
      url: location.pathname,
      query: {
        process: K.process.updateCards
      }
    });
    history.replace(success);
  };

  const processFailedCardOperation = ({ trackEventProps } = {}) => {
    const eventProps =
      trackEventProps || buildOrderTrackingPayload(order, true, true);
    track(eventNames.assignCard.fail, eventProps);
    resetPaymentProcess();
    setSnackbarLayoutProps({
      open: true,
      variant: "error",
      label: intl.formatMessage(locales.ups_something_went_wrong)
    });
  };

  const [assignCardMutation, { loading: assignCardLoading }] = useMutation(
    ASSIGN_CARD,
    {
      update: (cache, response) => {
        cache.writeQuery({
          query: ORDER_QUERY(ORDER_INFO_FRAGMENT),
          variables: { orderId: order.id },
          data: {
            shopper: {
              __typename: "Shopper",
              id: shopper.id,
              orderById: response.data.assignCard.order
            }
          }
        });
      },
      onCompleted: ({ assignCard: { __typename, order: updatedOrder } }) => {
        const trackEventProps = buildOrderTrackingPayload(
          updatedOrder,
          true,
          true
        );
        switch (__typename) {
          case "Successful":
            processSuccessfulUpdatedCardOperation({
              trackEventProps
            });
            break;
          case "Failed":
            processFailedCardOperation({ trackEventProps });
            break;
          default:
            break;
        }
      },
      onError: processFailedCardOperation
    }
  );

  const cardReferencesResponse = useShopperCardsDataQuery({
    fetchPolicy: K.fetchPolicy.cacheAndNetwork
  });

  if (cardReferencesResponse.loading || cardReferencesResponse.error) {
    return null;
  }

  const filteredCardReferences = filterReusableCardReferences(
    cardReferencesResponse.data.shopper.cardReferences
  );

  return (
    <div className={classes.select_card_modal}>
      {assignCardLoading && <ScaleLoader />}
      {isWideScreen ? null : (
        <Typography
          variant={K.typographicVariants.heading2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.choose_a_card)}
        </Typography>
      )}

      <PaymentCardList
        cardReferences={filteredCardReferences}
        selectedCard={cardReference}
        onSelectCard={setCardReference}
      />
      <div className={classes.buttons_container}>
        <Button
          size="large"
          fullWidth
          variant="text"
          onClick={() =>
            goToStep({
              step: K.steps.addCard,
              history,
              location
            })
          }
        >
          {intl.formatMessage(locales.new_card)}
        </Button>
        <Button
          size="large"
          fullWidth
          onClick={() => {
            const variables = {
              orderId: order.id,
              cardReferenceId: cardReference.id
            };
            assignCardMutation({ variables });
          }}
          disabled={!cardReference}
        >
          {intl.formatMessage(locales.confirm)}
        </Button>
      </div>
    </div>
  );
};

export default withModal(SelectCardModal, {
  title: getIntl.formatMessage(locales.my_cards)
});
