import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import use3DSPopup from "./js/hooks/use3DSPopup";
import useMufasaListener from "./js/hooks/useMufasaListener";
import { MUFASA_IFRAME_ID } from "./js/types";

const useStyles = createUseStyles({
  mufasa_iframe: {
    flex: 1
  }
});

const hiddenStyles = {
  opacity: "0",
  height: "0px",
  position: "absolute",
  left: "-999px",
  overflow: "hidden"
};

const MufasaIframe = ({
  mufasaUrl,
  iframeStyles,
  iframeRef,
  handleIframeEvent,
  handleTrackEvent,
  hidden
}) => {
  const classes = useStyles();
  const { mufasaEventHandler } = use3DSPopup(handleTrackEvent);

  useMufasaListener(event => {
    mufasaEventHandler(event, handleIframeEvent);
  }, handleTrackEvent);

  const style = hidden ? hiddenStyles : iframeStyles;

  return (
    <>
      <iframe
        title="mufasa-iframe"
        id={MUFASA_IFRAME_ID}
        ref={iframeRef}
        src={mufasaUrl}
        style={style}
        className={classes.mufasa_iframe}
      />
    </>
  );
};

MufasaIframe.propTypes = {
  mufasaUrl: PropTypes.string.isRequired,
  iframeStyles: PropTypes.shape(),
  iframeRef: PropTypes.shape(),
  handleIframeEvent: PropTypes.func,
  handleTrackEvent: PropTypes.func,
  hidden: PropTypes.bool
};

MufasaIframe.defaultProps = {
  iframeStyles: null,
  iframeRef: null,
  handleIframeEvent: () => {},
  handleTrackEvent: () => {},
  hidden: false
};

export default MufasaIframe;
