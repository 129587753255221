import { useSetState } from "react-use";
import useGenerateConversionGateway from "../mutations/useGenerateConversionGateway";

export default (orderId, instalmentCount) => {
  const initialState = {
    status: null,
    amount: "",
    dueOn: ""
  };
  const [
    conversionPlanSelectedDetails,
    setConversionPlanSelectedDetails
  ] = useSetState(initialState);
  const [generateConversionGateway] = useGenerateConversionGateway({
    onCompleted: data => {
      if (
        data?.generateConversionRequest?.__typename === "ConversionSuccessful"
      ) {
        setConversionPlanSelectedDetails({
          status: "success",
          amount:
            data?.generateConversionRequest?.instalment[0]?.instalmentTotal,
          dueOn: data?.generateConversionRequest?.instalment[0]?.dueOn
        });
      } else {
        setConversionPlanSelectedDetails({
          status: "error"
        });
      }
    },
    onError: () => {}
  });

  const onRestartConversion = () => {
    setConversionPlanSelectedDetails(initialState);
  };

  const onClickConversion = () => {
    generateConversionGateway({
      variables: {
        orderId,
        instalmentCount
      }
    });
  };

  return {
    onClickConversion,
    onRestartConversion,
    conversionPlanSelectedDetails
  };
};
