import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  panel_section: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "16px",
    boxSizing: "border-box",
    "@media(min-width: 768px)": {
      padding: "16px 0"
    }
  }
});

const PanelSection = React.forwardRef((props, ref) => {
  const { children, className } = props;
  const classes = useStyles();
  const classNames = classnames(className, classes.panel_section);

  return (
    <section ref={ref} className={classNames}>
      {children}
    </section>
  );
});

PanelSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

PanelSection.defaultProps = {
  children: null,
  className: ""
};

export default PanelSection;
