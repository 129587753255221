import React, { useState } from "react";
import PropTypes from "prop-types";
import store from "store";
import { useParams, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import useSessionAuthenticationContext from "../../contexts/SessionAuthentication";
import { LoginTemplate } from "../../templates";
import locales from "../../locales";
import { SMSCodeForm, EmailSent } from "./components";

export const ValidationPage = ({
  type,
  error,
  handleSubmitSMSCode,
  loading
}) => {
  const intl = useIntl();
  const history = useHistory();

  React.useEffect(() => {
    const otp = store.get("otpHash");
    if (!otp) history.replace("/login");
  }, []);

  return (
    <LoginTemplate
      title={intl.formatMessage(locales.login_account_verification)}
      padded={false}
      hideLanguageSelector
    >
      {type === "email" ? (
        <EmailSent />
      ) : (
        <SMSCodeForm
          error={error}
          handleSubmitSMSCode={handleSubmitSMSCode}
          loading={loading}
        />
      )}
    </LoginTemplate>
  );
};

ValidationPage.propTypes = {
  type: PropTypes.string.isRequired,
  error: PropTypes.bool,
  handleSubmitSMSCode: PropTypes.func,
  loading: PropTypes.bool
};

ValidationPage.defaultProps = {
  error: false,
  handleSubmitSMSCode: () => {},
  loading: false
};

const Validation = () => {
  const { type } = useParams();
  const {
    createNewSessionByPhone,
    isAuthorizing
  } = useSessionAuthenticationContext();
  const [error, setError] = useState(false);

  const handleSubmitForm = async code => {
    const ok = await createNewSessionByPhone(code);
    if (!ok) {
      setError(true);
    }
  };

  return (
    <ValidationPage
      type={type}
      error={error}
      handleSubmitSMSCode={handleSubmitForm}
      isCreatingOtp={isAuthorizing}
    />
  );
};

export default Validation;
