import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Invite = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 -3 23 23">
      <path
        fill={color}
        d="M18.942 10.45a2.48 2.48 0 0 1 1.053 1.944c.045 1.256-.732 2.335-2.04 3.636l-.408.398-.438.413-1.005.925a.893.893 0 0 1-1.21-.005l-.78-.713-.452-.42-.423-.405c-1.427-1.387-2.284-2.51-2.24-3.83a2.48 2.48 0 0 1 1.052-1.944c1.188-.816 2.654-.435 3.446.499.791-.934 2.258-1.32 3.445-.499ZM18 0c1.1 0 2 .9 2 2v6.749l-.167-.107a4.474 4.474 0 0 0-1.564-.58L18 8.025V4l-8 5-8-5v10h7.179c.189.663.54 1.287 1.12 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0Zm0 2H2l8 5 8-5Z"
      />
    </svg>
  );
};

Invite.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Invite.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Invite;
