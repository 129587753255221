export const eventProperties = Object.freeze({
  CURRENT_PRODUCT_NAME: "currentProductName",
  MERCHANT_NAME: "merchantName",
  CATEGORY: "category",
  VALUE: "value",
  REUSING_CARD: "reusingCard",
  SHORTENED_LINK_ACTION: "shortenedLinkAction",
  SELECTOR_TYPE: "selectorType"
});

export const eventPropertyCategories = Object.freeze({
  SHOPPER: "Shopper",
  CARDS: "Cards",
  ORDER: "Order",
  EXTERNAL_URL: "External URL"
});
