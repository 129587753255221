import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Envelope = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 20 16">
    <g fill="none" fillRule="evenodd">
      <path d="M-2-4h24v24H-2z" />
      <path
        d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm-1 14H3c-.55 0-1-.45-1-1V4l6.94 4.34c.65.41 1.47.41 2.12 0L18 4v9c0 .55-.45 1-1 1Zm-7-7L2 2h16l-8 5Z"
        fill={color}
      />
    </g>
  </svg>
);

Envelope.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Envelope.defaultProps = {
  width: 20,
  height: 16,
  color: theme.palette.secondary.default
};

export default Envelope;
