import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { useLocaleCurrency } from "../../../../hooks";
import locales from "../../../../locales";

const datesColWidth = 162;
const timeLineColWidth = 162;
const priceColWidth = 106;

const useStyles = createUseStyles(theme => ({
  timeline_payments: {
    padding: "24px 16px",
    marginTop: "24px",
    marginBottom: "32px",
    borderRadius: "8px",
    backgroundColor: theme.palette.background.default
  },
  timeline_payments__dates: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "0",
    marginRight: "0",
    "& > :first-child": {
      color: theme.palette.primary.default
    }
  },
  timeline_payments__dates_item: {
    color: theme.palette.secondary.default,
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    textAlign: "left",
    minHeight: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&.first-payment": {
      width: `${datesColWidth}px`
    },
    "&.second-payment": {
      width: `${timeLineColWidth}px`
    },
    "&.last-payment": {
      width: `${priceColWidth}px`,
      whiteSpace: "nowrap"
    }
  },
  timeline_payments__time_line: {
    position: "relative",
    display: "flex",
    height: "20px",
    flexDirection: "row",
    margin: "0",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "50%",
      left: "auto",
      backgroundColor: theme.palette.black.default,
      flexDirection: "row",
      margin: "0",
      width: "75%",
      height: "3px",
      marginTop: "-1.5px"
    },
    "& > :first-child:before": {
      top: "4px",
      left: "0",
      width: "8px",
      height: "8px",
      backgroundColor: theme.palette.black.default,
      border: `2px solid ${theme.palette.white.default}`
    }
  },
  timeline_payments__time_line_item: {
    fontSize: " 17px",
    "&::before": {
      content: "''",
      position: "relative",
      top: "5px",
      left: "-2px",
      display: "block",
      width: "7px",
      height: "7px",
      borderRadius: "50%",
      backgroundColor: theme.palette.white.default,
      border: `2px solid ${theme.palette.black.default}`
    },
    "&.first-payment": {
      width: `${datesColWidth}px`
    },
    "&.second-payment": {
      width: `${timeLineColWidth}px`
    },
    "&.last-payment": {
      width: `${priceColWidth}px`
    }
  },
  timeline_payments__amount_list: {
    display: "flex",
    flexDirection: "row",
    marginTop: "0",
    marginLeft: "0"
  },
  timeline_payments__amount_list_item: {
    fontSize: "14px",
    "&.first-payment": {
      width: `${datesColWidth}px`,
      fontSize: "19px"
    },
    "&.second-payment": {
      width: `${timeLineColWidth}px`,
      fontWeight: "normal",
      lineHeight: "normal"
    },
    "&.last-payment": {
      width: `${priceColWidth}px`,
      minHeight: "inherit",
      fontWeight: "normal",
      lineHeight: "normal"
    },
    span: {
      display: "block",
      fontSize: "12px",
      lineHeight: "2 ",
      whiteSpace: "nowrap",
      fontWeight: "normal"
    }
  },
  timeline_payments__additional_info: {
    marginTop: "16px",
    marginBottom: 0,
    fontSize: "14px",
    fontWeight: "bold"
  },
  more_than_three_instalment: {
    "&::before": {
      width: "38%",
      height: "3px",
      marginTop: "-1.5px"
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: "50%",
      left: "auto",
      flexDirection: "row",
      margin: " 0",
      width: "35%",
      height: "3px",
      marginLeft: "40%",
      marginTop: "-1.5px",
      borderTop: `3px dashed ${theme.palette.black.default}`
    }
  }
}));

const TimelinePayments = ({ instalments, instalmentCount }) => {
  const intl = useIntl();
  const ref = React.useRef(null);
  const classes = useStyles();

  const isMoreThanThreeInstalment = instalmentCount > 3;

  return (
    <div role="tab" tabIndex="0" ref={ref}>
      <div className={classes.timeline_payments}>
        <div className={classes.timeline_payments__dates}>
          <div
            className={classNames(
              classes.timeline_payments__dates_item,
              "first-payment"
            )}
          >
            {intl.formatDate(instalments[0]?.dueOn, {
              month: "short",
              day: "numeric",
              timeZone: "Europe/Madrid"
            })}
          </div>
          <div
            className={classNames(
              classes.timeline_payments__dates_item,
              "second-payment"
            )}
          >
            {intl.formatDate(instalments[1]?.dueOn, {
              month: "short",
              day: "numeric",
              timeZone: "Europe/Madrid"
            })}
          </div>
          <div
            className={classNames(
              classes.timeline_payments__dates_item,
              "last-payment"
            )}
          >
            {intl.formatDate(instalments[instalmentCount - 1]?.dueOn, {
              month: "short",
              day: "numeric",
              timeZone: "Europe/Madrid"
            })}
          </div>
        </div>

        <div
          className={classNames(classes.timeline_payments__time_line, {
            [classes.more_than_three_instalment]: isMoreThanThreeInstalment
          })}
        >
          <div
            className={classNames(
              classes.timeline_payments__time_line_item,
              "first-payment"
            )}
          />
          <div
            className={classNames(
              classes.timeline_payments__time_line_item,
              "second-payment"
            )}
          />
          <div
            className={classNames(
              classes.timeline_payments__time_line_item,
              "last-payment"
            )}
          />
        </div>

        <div className={classes.timeline_payments__amount_list}>
          <div
            className={classNames(
              classes.timeline_payments__amount_list_item,
              "first-payment"
            )}
          >
            {useLocaleCurrency(instalments[0]?.instalmentTotal, {
              useCents: true
            })}
          </div>
          <div
            className={classNames(
              classes.timeline_payments__amount_list_item,
              "second-payment"
            )}
          >
            {useLocaleCurrency(instalments[1]?.instalmentTotal, {
              useCents: true
            })}
          </div>
          <div
            className={classNames(
              classes.timeline_payments__amount_list_item,
              "last-payment"
            )}
          >
            {useLocaleCurrency(
              instalments[instalmentCount - 1]?.instalmentTotal,
              {
                useCents: true
              }
            )}
          </div>
        </div>
        <p className={classes.timeline_payments__additional_info}>
          {isMoreThanThreeInstalment &&
            intl.formatMessage(
              locales.flexi_conversion_confirmation_plan_number_of_instalments,
              {
                instalmentCount
              }
            )}
        </p>
      </div>
    </div>
  );
};

TimelinePayments.propTypes = {
  instalments: PropTypes.arrayOf(
    PropTypes.shape({
      dueOn: PropTypes.string,
      instalmentAmount: PropTypes.number,
      instalmentFee: PropTypes.number,
      instalmentTotal: PropTypes.number
    })
  ).isRequired,
  instalmentCount: PropTypes.number.isRequired
};

export default TimelinePayments;
