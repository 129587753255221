import { useLazyQuery } from "@apollo/client";
import {
  SHOPPER_QUERY,
  EMAILS_FRAGMENT,
  PHONES_FRAGMENT,
  SHOPPER_INFO_FRAGMENT,
  CARD_REFERENCES_FRAGMENT,
  MERCHANTS_PROMOTIONS_FRAGMENT
} from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError }) =>
  useLazyQuery(
    SHOPPER_QUERY(
      EMAILS_FRAGMENT,
      PHONES_FRAGMENT,
      SHOPPER_INFO_FRAGMENT,
      CARD_REFERENCES_FRAGMENT,
      MERCHANTS_PROMOTIONS_FRAGMENT
    ),
    {
      fetchPolicy: K.fetchPolicy.cacheAndNetwork,
      nextFetchPolicy: K.fetchPolicy.cacheFirst,
      onCompleted,
      onError
    }
  );
