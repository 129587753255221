import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Location = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 12 16">
    <g fill="none" fillRule="evenodd">
      <path d="M-3-1h18v18H-3z" />
      <path
        d="M6 .5A5.246 5.246 0 00.75 5.75c0 3.127 3.315 7.44 4.68 9.082.3.36.848.36 1.148 0C7.935 13.19 11.25 8.877 11.25 5.75A5.246 5.246 0 006 .5zm0 7.125a1.876 1.876 0 11.001-3.751A1.876 1.876 0 016 7.625z"
        fill={color}
      />
    </g>
  </svg>
);

Location.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Location.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Location;
