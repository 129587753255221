import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import {
  Route,
  Switch,
  useParams,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useMedia } from "react-use";
import { useIntl } from "react-intl";
import { Typography, Tabs, Tab, TabPanel } from "../../atoms";
import { ArrowLeft } from "../../atoms/Icons";
import {
  OrderRenderService,
  OrderRefund,
  OrderRefundConfirmed
} from "./components";
import {
  ORDER_PATH,
  ORDER_ARTICLES_PATH,
  ORDER_INVOICES_PATH,
  ORDER_INSTALMENTS_PATH,
  ORDER_BANK_ACCOUNT_REFUND_PATH,
  ORDER_BANK_ACCOUNT_REFUND_CONFIRMED_PATH,
  ORDER_RENDER_SERVICE_PATH
} from "../../routes";
import {
  OrderDetailsHeader,
  OrderDetailsSummary,
  OrderPanelStatus,
  OrderInstalmentList,
  OrderInvoiceList,
  OrderArticleList,
  OrderMoreOptions
} from "../../organisms";
import { MessageBox } from "../../molecules";
import {
  PaymentFlow,
  ConversionFlow,
  FlexiConversionFlow,
  ChangePaymentCardFlow,
  ReturnRequestFlow,
  AmortizationFlow,
  UpdateCardsFlow,
  VerifyCardFlow
} from "../../flows";
import { LayoutTemplate } from "../../templates";
import { getOrderDetail, backPathResolver } from "../../utils";
import { OrderContext } from "../../contexts";
import useSessionAuthenticationContext from "../../contexts/SessionAuthentication";
import useOrder from "./hooks";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  order_page: {
    backgroundColor: theme.palette.background.default,
    padding: "57px 2vh 49px",
    width: "100%",
    boxSizing: "border-box",
    overflow: "auto",
    "@media (min-width: 768px)": {
      height: "100vh"
    },
    "&::-webkit-scrollbar": {
      width: 0,
      background: "transparent"
    }
  },
  order_page__back_button: {
    display: "none",
    "@media (min-width: 768px)": {
      display: "flex",
      alignItems: "center",
      maxWidth: "947px",
      margin: "0 auto 16px auto",
      cursor: "pointer",
      "& p": {
        marginLeft: "4px"
      }
    }
  },
  order_page__content: {
    margin: "8px 0",
    "@media (min-width: 768px)": {
      margin: "24px auto",
      maxWidth: "947px",
      display: "flex"
    }
  },
  card: {
    maxWidth: "612px"
  },
  card_body: {
    maxWidth: "320px"
  },
  order__instalments__tabs: {
    marginBottom: "16px"
  },
  message_container: {
    position: "fixed",
    top: "50px",
    left: "8px",
    width: "97.5%",
    zIndex: "2",
    "@media (min-width: 768px)": {
      top: "5px",
      left: "20px",
      width: "95%"
    }
  }
}));

export const OrderPage = ({ order }) => {
  const location = useLocation();
  const classes = useStyles();
  const query = queryString.parse(location.search);
  const isWide = useMedia("(min-width: 768px)");
  const isOrderPath = useRouteMatch({ path: ORDER_PATH, exact: true });
  const { shopperAccountReadOnly } = useSessionAuthenticationContext();
  const intl = useIntl();
  const [value, setValue] = React.useState(0);

  const isRenting = order.currentProductName === "r1";
  const orderStatus = getOrderDetail({ order });

  const onChange = tabIndex => {
    setValue(tabIndex);
  };

  const orderInstalmentsTabList = /[s|p]?[p|r]\d+/.test(
    orderStatus.currentProductName
  ) ? (
    <>
      <Tabs value={value} onChange={onChange}>
        <Tab>{intl.formatMessage(locales.past_instalments)}</Tab>
        {orderStatus.paymentPlan.futureInstalments.length > 0 ? (
          <Tab>{intl.formatMessage(locales.future_instalments)}</Tab>
        ) : null}
      </Tabs>

      <TabPanel value={value} index={0}>
        <OrderInstalmentList disableDropdown={isRenting} />
      </TabPanel>

      {orderStatus.paymentPlan.futureInstalments.length > 0 ? (
        <TabPanel value={value} index={1}>
          <OrderInstalmentList
            hasFutureInstalments
            disableDropdown={isRenting}
          />
        </TabPanel>
      ) : null}
    </>
  ) : null;

  return (
    <OrderContext.Provider value={orderStatus}>
      {query.process === K.process.payment && <PaymentFlow />}
      {query.process === K.process.conversion && <ConversionFlow />}
      {query.process === K.process.flexiConversion && <FlexiConversionFlow />}
      {query.process === K.process.amortization && <AmortizationFlow />}
      {query.process === K.process.returnRequest && <ReturnRequestFlow />}
      {query.process === K.process.changePaymentCard && (
        <ChangePaymentCardFlow />
      )}
      {query.process === K.process.updateCards && <UpdateCardsFlow />}
      {query.process === K.process.verifyCard && <VerifyCardFlow />}
      <LayoutTemplate className={classes.order_page}>
        {shopperAccountReadOnly && (
          <div className={classes.message_container}>
            <div className={classes.message_container}>
              <MessageBox
                type="warning"
                text={intl.formatMessage(locales.order_partial_session_banner)}
              />
            </div>
          </div>
        )}

        {isWide ? <OrderDetailsHeader /> : null}
        {isOrderPath ? null : (
          <div
            onClick={backPathResolver()}
            onKeyPress={backPathResolver()}
            role="button"
            tabIndex="0"
            className={classes.order_page__back_button}
          >
            <ArrowLeft width={16} height={16} />
            <Typography variant={K.typographicVariants.body2}>
              {intl.formatMessage(locales.back)}
            </Typography>
          </div>
        )}
        <div className={classes.order_page__content}>
          <div style={{ flex: 1 }}>
            <Switch>
              <Route
                path={ORDER_PATH}
                render={() => (
                  <>
                    {isWide ? null : <OrderDetailsHeader />}
                    <OrderDetailsSummary
                      showArticleLink={
                        (!orderStatus.isCancelled && isWide) ||
                        (!orderStatus.isCancelled &&
                          /[s|p]?[p|r|i]\d+/.test(
                            orderStatus.currentProductName
                          ))
                      }
                    />
                    {isWide ? null : <OrderPanelStatus />}
                    {isWide || isRenting ? null : <OrderMoreOptions />}
                    {isWide &&
                      orderStatus.currentProductName === "i1" &&
                      !orderStatus.isCancelled && <OrderInvoiceList />}
                    {isWide && orderInstalmentsTabList}
                  </>
                )}
                exact
              />
              <Route
                path={ORDER_ARTICLES_PATH}
                render={() => (
                  <OrderArticleList
                    withShippingIcon={/[s|p]?[p|r]\d+/.test(
                      orderStatus.currentProductName
                    )}
                  />
                )}
                exact
              />
              <Route
                path={ORDER_INVOICES_PATH}
                render={() => <OrderInvoiceList />}
                exact
              />
              <Route
                path={ORDER_INSTALMENTS_PATH}
                render={() => orderInstalmentsTabList}
                exact
              />
              <Route
                path={ORDER_BANK_ACCOUNT_REFUND_PATH}
                component={OrderRefund}
                exact
              />
              <Route
                path={ORDER_BANK_ACCOUNT_REFUND_CONFIRMED_PATH}
                component={OrderRefundConfirmed}
              />
              <Route
                path={ORDER_RENDER_SERVICE_PATH}
                component={OrderRenderService}
              />
            </Switch>
          </div>
          {isWide ? (
            <div className={classes.order_page__status}>
              <OrderPanelStatus />
              {isRenting ? null : <OrderMoreOptions />}
            </div>
          ) : null}
        </div>
      </LayoutTemplate>
    </OrderContext.Provider>
  );
};

OrderPage.propTypes = {
  order: PropTypes.shape().isRequired
};

const Order = () => {
  const { id } = useParams();
  const { order, fetching, error } = useOrder({ orderId: id });

  if (error || fetching) return null;

  return <OrderPage order={order} />;
};

export default Order;
