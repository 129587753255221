import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useMedia } from "react-use";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  card_body: {
    padding: ({ isWide }) => (isWide ? "24px" : "24px 16px")
  }
});

const CardBody = ({ children, className }) => {
  const isWide = useMedia("(min-width: 768px)");
  const classes = useStyles({ isWide });

  return (
    <div
      className={classnames(classes.card_body, className)}
      data-testid="atom_card_body"
    >
      {children}
    </div>
  );
};

CardBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

CardBody.defaultProps = {
  className: ""
};

export default CardBody;
