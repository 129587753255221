export const SHOPPER_SELECTOR_INLINE_FRAGMENT = `
  __typename
  ... on ShopperEmail {
    id
    email
    isValidated
    isRemovable
    validationStartedAt
    validationExpired
  }
  ... on ShopperPhone {
    id
    phone
    isValidated
    isRemovable
    validationStartedAt
    validationExpired
  }
  ... on InvalidSelector {
    message
  }
  ... on AlreadyAddedSelector {
    belongsToShopper
    message
  }
  ... on AlreadyValidatedSelector {
    message
  }
  ... on SelectorValidationProcessExpired {
    message
  }
  ... on AlreadyBeingValidatedSelector {
    message
  }
  ... on UnremovableSelector {
    message
  }
`;

export const SESSION_AUTHORIZATION_INLINE_FRAGMENT = `
  __typename
  ... on FullSession {
    allowedShopperAccess
    currentShopperUserId
    shopperAccountReadOnly
  }
  ... on SelectorSession {
    allowedShopperAccess
    allowedShopperAccessScope
    currentSelectorId
    currentSelectorType
    currentShopperUserId
    shopperAccountReadOnly
  }
  ... on OrderSession {
    allowedShopperAccess
    allowedShopperAccessScope
    currentShopperUserId
    currentOrderId
    shopperAccountReadOnly
  }
  ... on NoSession {
    allowedShopperAccess
    shopperAccountReadOnly
  }
  ... on ReferralSignUpSession{
    allowedShopperAccess
    allowedShopperAccessScope
    referralHostName
  }
`;

export const SHORTENED_LINK_INLINE_FRAGMENT = `
  __typename
  ... on CreateSession {
    action
  }
  ... on ShowOrder {
    action
    orderId
  }
  ... on ShowOrderCards {
    action
    orderId
  }
  ... on ShowSelector {
    action
    selectorId
    selectorType
  }
  ... on ShowConversionProcess {
    action
    orderId
  }
  ... on ShowInvoicesPaymentProcess {
    action
    orderId
    invoiceNumbers
    amount
  }
  ... on ShowPaymentPlanPaymentProcess {
    action
    orderId
    amount
  }
  ... on AddBankAccountToRefundProcess {
    action
    orderId
  }
  ... on RenderService {
    action
    orderId
  }
  ... on VerifyCard {
    action
    orderId
  }
  ... on FlexiConversionProcess {
    action
    orderId
  }
`;

export const CARD_REFERENCE_INLINE_FRAGMENT = `
  id
  expiryDate
  expiryMonth
  expiryYear
  isExpired
  isExpiringSoon
  isDefault
  maskedPan
  lastFour
  issuer
`;

export const MERCHANTS_PROMOTIONS_INLINE_FRAGMENT = `
  promotionsAllowed
  availablePromotions
  promotedMerchants {
    reference
    url
    name
    description
    sector
    logo
    backgroundImage
    products
    promotion {
      reference
      title
      description
      url
      backgroundImage
      lastDate
      expiration
    }
    stores {
      findStoresUrl
    }
  }
`;

export const CREATE_REFERRAL_GUEST_USER_INLINE_FRAGMENT = `
__typename
... on EncryptedSelectors {
  otpHash
  selectorType
}
... on InvalidArguments {
  keys
}
... on InvalidUser {
  key
}
... on LockedUser {
  key
}
... on NotAllowedUser {
  key
}
... on UserAlreadyRegistered {
  key
}
`;

export const SHARE_REFERRAL_PROGRAM_INLINE_FRAGMENT = `
__typename
... on ShareURLCreated {
  shareUrl
}
... on UnableToCreateShareURL {
  message
}
`;

export const GENERATE_CONVERSION_INLINE_FRAGMENT = `
__typename
... on ConversionSuccessful {
  instalment {
    instalmentAmount
    instalmentTotal
    instalmentFee
    dueOn
  }
}
... on UnableToConvert {
  message
}
`;
