import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import { Transport } from "../../../../atoms/Icons";
import useStyles from "../styles";
import locales from "../../../../locales";
import K from "../../../../constants";

const Shipments = ({ orderId, totalSent, totalUnsent }) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Link to={`/order/${orderId}/articles`}>
        <Transport
          width={22}
          height={16}
          color={theme.palette.tertiary.default}
        />
      </Link>
      <div className={classes.box__info}>
        <Typography
          variant={K.typographicVariants.visual3}
        >{`${totalSent}/${totalUnsent}`}</Typography>
        <Typography
          variant={K.typographicVariants.body2}
          color={K.colors.secondary}
        >
          {intl.formatMessage(locales.shipped_plural).toLowerCase()}
        </Typography>
      </div>
    </div>
  );
};

Shipments.propTypes = {
  orderId: PropTypes.string,
  totalSent: PropTypes.number,
  totalUnsent: PropTypes.number
};

Shipments.defaultProps = {
  orderId: "",
  totalSent: 0,
  totalUnsent: 0
};

export default Shipments;
