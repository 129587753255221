import React from "react";
import Toggle from "react-toggle";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Paper, Typography } from "../../../../atoms";

import K from "../../../../constants";

const useStyles = createUseStyles({
  data_protection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "24px"
  },
  data_protection__toggle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },
  data_protection__title: {
    marginRight: "16px",
    marginBottom: "8px"
  },
  data_protection__description: {
    marginBottom: "8px"
  }
});

const MerchantsPromotions = ({
  title,
  description,
  toggleLabel,
  hasMerchantsPromotionsEnabled,
  onChangeDataProtection
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant={K.typographicVariants.heading4}
        className={classes.data_protection__title}
      >
        {title}
      </Typography>

      <Paper className={classes.data_protection}>
        <div className={classes.data_protection__toggle}>
          <Typography
            variant={K.typographicVariants.body1}
            className={classes.data_protection__title}
          >
            {toggleLabel}
          </Typography>
          <Toggle
            defaultChecked={hasMerchantsPromotionsEnabled}
            onChange={e => {
              onChangeDataProtection(e.target.checked);
            }}
            icons={false}
          />
        </div>
        <Typography
          variant={K.typographicVariants.body2}
          className={classes.data_protection__description}
        >
          {description}
        </Typography>
      </Paper>
    </>
  );
};

MerchantsPromotions.defaultProps = {
  title: ""
};

MerchantsPromotions.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  toggleLabel: PropTypes.string.isRequired,
  hasMerchantsPromotionsEnabled: PropTypes.bool.isRequired,
  onChangeDataProtection: PropTypes.func.isRequired
};

export default MerchantsPromotions;
