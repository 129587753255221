import ReactDOM from "react-dom";
import React, { useEffect } from "react";
import { getMufasaActionFromEvent, iframePostMessage } from "../utils";
import {
  AUTH_3DS_POPUP_ID,
  mufasaRequest3DSAuthenticationClosed,
  MufasaResponseMessages,
  MufasaTrackEvents,
  PopupActions
} from "../types";
import usePopupReducer from "../reducers/usePopupReducer";
import { Auth3DPopup } from "../../components";

const renderPopup = (node, iframeSrc) => {
  ReactDOM.render(<Auth3DPopup iframeSrc={iframeSrc} />, node);
};

const use3DSPopup = handleTrack => {
  const [
    { isLoading3DS, isWaiting3DS, iframe3DSUrl },
    dispatch
  ] = usePopupReducer();

  const handleCloseButtonClick = () => {
    iframePostMessage(mufasaRequest3DSAuthenticationClosed());
    handleTrack(MufasaTrackEvents.mufasa_3ds_authentication_closed);
    dispatch({ type: PopupActions.close });
  };

  const handleAccept3DS = message => {
    iframePostMessage(message);
    dispatch({ type: PopupActions.accept3ds });
  };

  const handleOpen3DSPopup = src => {
    dispatch({ type: PopupActions.open, payload: { iframeUrl: src } });
    handleTrack(MufasaTrackEvents.mufasa_3ds_authentication_viewed);
  };

  const handle3DSIframeLoaded = () => {
    dispatch({ type: PopupActions.iframeLoaded });
  };

  const mufasaEventHandler = (event, callback) => {
    const action = getMufasaActionFromEvent(event);
    if (action) {
      switch (action.mufasaResponseMessage) {
        case MufasaResponseMessages.authentication_3ds_closed:
          handleCloseButtonClick();
          break;
        case MufasaResponseMessages.authentication_3ds:
          handleOpen3DSPopup(action.payload.src);
          break;
        case MufasaResponseMessages.new_form_fields:
          handleAccept3DS(action.payload);
          break;
        case MufasaResponseMessages.authentication_3ds_loaded:
          handle3DSIframeLoaded();
          break;
        case MufasaResponseMessages.payment_successful:
          handleTrack(MufasaTrackEvents.mufasa_payment_successful);
          break;
        case MufasaResponseMessages.payment_failed:
          handleTrack(MufasaTrackEvents.mufasa_payment_failed);
          break;
        case MufasaResponseMessages.start_synchronization_polling:
          handleAccept3DS(action.payload);
          break;
        default:
          break;
      }
      callback(event);
    }
  };

  useEffect(() => {
    let popupElement = document.getElementById(AUTH_3DS_POPUP_ID);

    if (isWaiting3DS) {
      if (!popupElement) {
        const root = document.getElementById("root");
        popupElement = document.createElement("div");
        popupElement.setAttribute("id", AUTH_3DS_POPUP_ID);
        root.appendChild(popupElement);
      }
      renderPopup(
        popupElement,
        iframe3DSUrl,
        isLoading3DS,
        handleCloseButtonClick
      );
    } else if (popupElement) {
      popupElement.remove();
    }
  }, [isWaiting3DS, isLoading3DS, iframe3DSUrl]);

  return {
    isWaiting3DS,
    isLoading3DS,
    iframe3DSUrl,
    handleOpen3DSPopup,
    handle3DSIframeLoaded,
    handleAccept3DS,
    handleCloseButtonClick,
    mufasaEventHandler
  };
};

export default use3DSPopup;
