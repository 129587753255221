import React, { useState } from "react";
import PropTypes from "prop-types";
import store from "store";
import { createUseStyles } from "react-jss";
import Masonry from "react-masonry-css";
import { ErrorBoundary } from "../../../../../templates";
import useTracking from "../../../../../contexts/Tracking";
import MerchantPromotionCard from "../MerchantPromotionCard";
import PromotedMerchantsEmpty from "../PromotedMerchantsEmpty";
import PromotedMerchantsPending from "../PromotedMerchantsPending";
import PromotedMerchantsDenied from "../PromotedMerchantsDenied";
import PromotedMerchantsInRecovery from "../PromotedMerchantsInRecovery";
import useSetShopperAllowanceForMerchantsPromotions from "../../../hooks/useSetShopperAllowanceForMerchantsPromotions";
import PromotedMerchantsSection from "../PromotedMerchantsSection";
import K from "../../../../../constants";

const useStyles = createUseStyles(theme => ({
  dashboard__shops: {
    display: "flex",
    width: "100%",
    flexDirection: "column"
  },
  dashboard__shops__list: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: 0,
    [`${theme.breakpoints.desktop}`]: {
      justifyContent: "space-between"
    }
  },
  dashboard__shops__list__masonry: {
    display: "flex",
    width: "100%",
    marginTop: 0
  }
}));

const PENDING = "PENDING";
const ALLOWED = "ALLOWED";
const DENIED = "DENIED";
const IN_RECOVERY = "IN_RECOVERY";
const TELEPHONE_PROMOTED_MERCHANTS_IN_RECOVERY = {
  "es-ES": "+34605980142",
  "pt-PT": "+351800782221",
  "it-IT": "+39800745680",
  "fr-FR": "+33805988069"
};

const onClickDeniedButton = (
  setPromotionsStatus,
  onClickSetPromotionsStatus
) => {
  setPromotionsStatus(DENIED);
  onClickSetPromotionsStatus(false);
};

const onClickAllowedButton = (
  setPromotionsStatus,
  onClickSetPromotionsStatus
) => {
  setPromotionsStatus(ALLOWED);
  onClickSetPromotionsStatus(true);
};

const onClickDeniedButtonPendingComponent = (
  setPromotionsStatus,
  onClickSetPromotionsStatus,
  track,
  eventNames
) => {
  setPromotionsStatus(DENIED);
  onClickSetPromotionsStatus(false);
  track(eventNames.dashboard.clickMerchantPromotionDenied);
};

const onClickAllowedButtonPendingComponent = (
  setPromotionsStatus,
  onClickSetPromotionsStatus,
  track,
  eventNames
) => {
  setPromotionsStatus(ALLOWED);
  onClickSetPromotionsStatus(true);
  track(eventNames.dashboard.clickMerchantPromotionAllowed);
};

const renderMasonryComponent = (
  promotedMerchants,
  classes,
  promotionsStatus
) => (
  <Masonry
    breakpointCols={{ default: 2, 1147: 1 }}
    className={classes.dashboard__shops__list__masonry}
  >
    {promotedMerchants.map(shop => (
      <MerchantPromotionCard
        key={shop.reference}
        {...shop}
        isPromotionsAllowed={promotionsStatus === ALLOWED}
      />
    ))}
  </Masonry>
);

const onClickTelephonePromotedMerchantsInRecovery = (track, eventNames) => {
  const locale = store.get("locale") || K.supportedLocales.es;
  const phone = TELEPHONE_PROMOTED_MERCHANTS_IN_RECOVERY[locale];
  track(eventNames.dashboard.clickTelephoneMerchantPromotionInRecovery);
  window.open(`tel:${phone}`);
};

const renderPromotedMerchantComponent = (
  merchantsPromotions,
  classes,
  promotionsStatus,
  setPromotionsStatus,
  onClickSetPromotionsStatus,
  track,
  eventNames
) => {
  const { promotedMerchants } = merchantsPromotions;
  if (promotionsStatus === PENDING) {
    if (promotedMerchants.length === 0) {
      return <PromotedMerchantsEmpty />;
    }
    if (promotedMerchants.length > 0) {
      return (
        <>
          <PromotedMerchantsPending
            onClickDenied={() =>
              onClickDeniedButtonPendingComponent(
                setPromotionsStatus,
                onClickSetPromotionsStatus,
                track,
                eventNames
              )
            }
            onClickAllowed={() =>
              onClickAllowedButtonPendingComponent(
                setPromotionsStatus,
                onClickSetPromotionsStatus,
                track,
                eventNames
              )
            }
          >
            {renderMasonryComponent(
              promotedMerchants,
              classes,
              promotionsStatus
            )}
          </PromotedMerchantsPending>
        </>
      );
    }
  } else if (promotionsStatus === ALLOWED) {
    if (promotedMerchants.length > 0) {
      return (
        <>
          {renderMasonryComponent(promotedMerchants, classes, promotionsStatus)}
        </>
      );
    }
    if (promotedMerchants.length === 0) {
      return <PromotedMerchantsEmpty />;
    }
  } else if (promotionsStatus === DENIED) {
    if (promotedMerchants.length > 0) {
      return (
        <PromotedMerchantsDenied
          numberOfPromotions={promotedMerchants.length}
          onClick={() =>
            onClickAllowedButton(
              setPromotionsStatus,
              onClickSetPromotionsStatus
            )
          }
        />
      );
    }
  } else if (promotionsStatus === IN_RECOVERY) {
    return (
      <PromotedMerchantsInRecovery
        onClickTelephone={() =>
          onClickTelephonePromotedMerchantsInRecovery(track, eventNames)
        }
      />
    );
  }
  return null;
};

const shouldRenderDeniedPromotionsAction = promotionsStatus =>
  promotionsStatus === ALLOWED;

const shouldRenderPromotedMerchantsSection = (
  merchantsPromotions,
  promotionsStatus
) => {
  const { promotedMerchants, availablePromotions } = merchantsPromotions;
  return !(
    (promotionsStatus === DENIED && promotedMerchants.length === 0) ||
    !availablePromotions
  );
};

const PromotedMerchantsSectionContainer = ({ merchantsPromotions }) => {
  const [promotionsStatus, setPromotionsStatus] = useState(
    merchantsPromotions?.promotionsAllowed
  );

  const {
    onClickSetPromotionsStatus
  } = useSetShopperAllowanceForMerchantsPromotions();
  const { track, eventNames } = useTracking();
  const classes = useStyles();

  return (
    <div className={classes.dashboard__shops}>
      <ErrorBoundary component="PromotedMerchantsSectionContainer">
        {shouldRenderPromotedMerchantsSection(
          merchantsPromotions,
          promotionsStatus
        ) && (
          <PromotedMerchantsSection
            promotionComponentToRender={renderPromotedMerchantComponent(
              merchantsPromotions,
              classes,
              promotionsStatus,
              setPromotionsStatus,
              onClickSetPromotionsStatus,
              track,
              eventNames
            )}
            onClickDeniedButton={() =>
              onClickDeniedButton(
                setPromotionsStatus,
                onClickSetPromotionsStatus
              )
            }
            shouldRenderDeniedPromotionsAction={shouldRenderDeniedPromotionsAction(
              promotionsStatus
            )}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

PromotedMerchantsSectionContainer.propTypes = {
  merchantsPromotions: PropTypes.shape({
    availablePromotions: PropTypes.bool,
    promotionsAllowed: PropTypes.string,
    promotedMerchants: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired
};

export default PromotedMerchantsSectionContainer;
