import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const StatusCheck = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1544_5204"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={width}
      height={height}
    >
      <path
        d="M30 0C46.5416 0 60 13.4584 60 30C60 46.5416 46.5416 60 30 60C13.4584 60 0 46.5416 0 30C0 13.4584 13.4584 0 30 0ZM30 2.99755C15.1101 2.99755 2.99755 15.1101 2.99755 30C2.99755 44.8899 15.1101 57.0024 30 57.0024C44.8899 57.0024 57.0024 44.8899 57.0024 30C57.0024 15.1101 44.8899 2.99755 30 2.99755ZM40.3146 20.4085C40.7926 20.3755 41.2526 20.5305 41.6126 20.8435C41.9726 21.1555 42.1906 21.5905 42.2236 22.0665C42.2576 22.5425 42.1026 23.0045 41.7876 23.3665L28.0366 39.4065C27.6986 39.8015 27.2156 40.0235 26.6796 40.0315C26.1786 40.0315 25.6976 39.8195 25.3596 39.4495L18.6956 32.1585C18.0296 31.4315 18.0806 30.2975 18.8086 29.6325C19.1376 29.3305 19.5656 29.1645 20.0136 29.1645C20.5156 29.1645 20.9966 29.3765 21.3336 29.7455L26.6366 35.5475L39.0916 21.0195C39.4036 20.6595 39.8376 20.4425 40.3146 20.4085Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1544_5204)">
      <path
        d="M30 0C46.5416 0 60 13.4584 60 30C60 46.5416 46.5416 60 30 60C13.4584 60 0 46.5416 0 30C0 13.4584 13.4584 0 30 0ZM30 2.99755C15.1101 2.99755 2.99755 15.1101 2.99755 30C2.99755 44.8899 15.1101 57.0024 30 57.0024C44.8899 57.0024 57.0024 44.8899 57.0024 30C57.0024 15.1101 44.8899 2.99755 30 2.99755ZM40.3146 20.4085C40.7926 20.3755 41.2526 20.5305 41.6126 20.8435C41.9726 21.1555 42.1906 21.5905 42.2236 22.0665C42.2576 22.5425 42.1026 23.0045 41.7876 23.3665L28.0366 39.4065C27.6986 39.8015 27.2156 40.0235 26.6796 40.0315C26.1786 40.0315 25.6976 39.8195 25.3596 39.4495L18.6956 32.1585C18.0296 31.4315 18.0806 30.2975 18.8086 29.6325C19.1376 29.3305 19.5656 29.1645 20.0136 29.1645C20.5156 29.1645 20.9966 29.3765 21.3336 29.7455L26.6366 35.5475L39.0916 21.0195C39.4036 20.6595 39.8376 20.4425 40.3146 20.4085Z"
        fill={color}
      />
    </g>
  </svg>
);

StatusCheck.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

StatusCheck.defaultProps = {
  width: 60,
  height: 60,
  color: theme.palette.primary.default
};

export default StatusCheck;
