function makeEmptyData() {
  return {
    nodes: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      limit: 0,
      nodesCount: 0,
      offset: 0
    }
  };
}

// See for reference https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
export default function offsetPagination(keyArgs = false) {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      if (!existing) {
        // eslint-disable-next-line no-param-reassign
        existing = makeEmptyData();
      }

      if (!incoming) {
        return existing;
      }

      // Clone the existing nodes.
      const mergedNodes = existing.nodes.slice(0);

      if (args) {
        // Assume an offset of 0 if args.offset omitted.
        const { offset = 0 } = args;

        for (let i = 0; i < incoming.nodes.length; i += 1) {
          mergedNodes[offset + i] = incoming.nodes[i];
        }
      }

      let { hasPreviousPage, hasNextPage } = existing.pageInfo;

      if (incoming.pageInfo) {
        const incomingStartIndex = incoming.pageInfo.offset;
        const incomingEndIndex = incomingStartIndex + incoming.nodes.length - 1;

        if (!existing.nodes[incomingStartIndex]) {
          hasPreviousPage = incoming.pageInfo.hasPreviousPage;
        }

        if (!existing.nodes[incomingEndIndex]) {
          hasNextPage = incoming.pageInfo.hasNextPage;
        }
      }

      return {
        ...incoming,
        pageInfo: {
          ...incoming.pageInfo,
          hasPreviousPage,
          hasNextPage
        },
        nodes: mergedNodes
      };
    }
  };
}
