import { useEffect } from "react";
import { useSetState } from "react-use";
import useOrderDataQuery from "./queries/useOrderData";

export const initialState = {
  fetching: true,
  error: undefined
};

export default ({ orderId }) => {
  const [state, setState] = useSetState(initialState);

  const [fetchOrderData, { data }] = useOrderDataQuery({
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    },
    onCompleted: () => {
      setState({
        error: undefined,
        fetching: false
      });
    },
    orderId
  });

  useEffect(fetchOrderData, []);

  const order = data?.shopper?.orderById || {};

  return {
    ...state,
    order
  };
};
