import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  iframe: {
    width: "100%"
  }
});

const IFrame = React.forwardRef((props, ref) => {
  const { title, src } = props;
  const classes = useStyles();
  const componentRef = ref || React.useRef(null);

  return (
    <iframe
      ref={componentRef}
      title={title}
      src={src}
      className={classes.iframe}
    />
  );
});

IFrame.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string
};

IFrame.defaultProps = {
  title: "",
  src: ""
};

export default IFrame;
