import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles, useTheme } from "react-jss";
import { useSpring, animated } from "react-spring";
import { Check } from "../Icons";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  checkbox_field: {
    "--size": "24px",
    display: "flex",
    alignItems: "center",
    "@media(min-width: 768px)": {
      "--size": "20px"
    }
  },
  checkbox_field__checkbox: {
    cursor: ({ disabled }) => (disabled ? "default" : "pointer"),
    width: "var(--size)",
    height: "var(--size)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${theme.palette.shade.default}`,
    boxSizing: "border-box",
    borderRadius: "2px",
    marginRight: "8px",
    position: "relative",
    "& svg": {
      "& path": {
        fill: theme.palette.white.default
      }
    }
  },
  "checkbox_field__checkbox--disabled": {
    userSelect: "none",
    backgroundColor: [theme.palette.shade.default, "!important"],
    border: [["2px", "solid", `${theme.palette.shade.default}`], "!important"]
  },
  checkbox_field__label: {
    cursor: "default"
  }
}));

const Checkbox = React.forwardRef((props, ref) => {
  const { className, onChange, defaultSelected, label, disabled } = props;
  const theme = useTheme();
  const classes = useStyles({ disabled });
  const [selected, setSelected] = React.useState(defaultSelected);
  const componentRef = ref || React.useRef(null);

  React.useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  function toggleSelected() {
    if (disabled) return;
    setSelected(value => {
      const toggled = !value;
      if (typeof onChange === "function") {
        onChange(toggled);
      }
      return toggled;
    });
  }

  function handleCheckboxClick(event) {
    event.stopPropagation();
    toggleSelected();
  }

  function handleKeyPress(event) {
    event.preventDefault();
    toggleSelected();
  }

  const checkboxSpring = useSpring({
    backgroundColor: selected
      ? theme.palette.info.default
      : theme.palette.white.default,
    border: `2px solid ${
      selected ? theme.palette.info.default : theme.palette.shade.default
    }`
  });

  const checkSpring = useSpring({ opacity: selected ? 1 : 0 });

  return (
    <div
      ref={componentRef}
      className={classnames(classes.checkbox_field, className)}
      onClick={handleCheckboxClick}
      onKeyPress={handleKeyPress}
      role="checkbox"
      aria-checked={selected}
      aria-disabled={disabled}
      tabIndex="0"
    >
      <animated.div
        className={classnames(classes.checkbox_field__checkbox, {
          [classes["checkbox_field__checkbox--disabled"]]: disabled
        })}
        style={checkboxSpring}
      >
        <animated.span style={checkSpring}>
          <Check />
        </animated.span>
      </animated.div>
      {label && (
        <Typography
          variant={K.typographicVariants.body3}
          className={classes.checkbox_field__label}
        >
          {label}
        </Typography>
      )}
    </div>
  );
});

Checkbox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultSelected: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  className: "",
  onChange: () => {},
  defaultSelected: false,
  label: "",
  disabled: false
};

export default Checkbox;
