import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import useSignUp from "./hooks/useSignUp";
import Landing from "./components/Landing";
import StepForm from "./components/StepForm";

const useStyles = createUseStyles({
  sign_up: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    "& > :first-child": {
      boxShadow: "0px 2px 4px rgba(0,0,0,0.12)"
    },
    "& button": {
      boxShadow: "0px 2px 4px rgb(0 0 0 / 12%);"
    }
  }
});

export const SignUpPage = ({
  currentStepForm,
  onSubmitStep,
  onClickPrevStep,
  stepFormInputsValues,
  onChangeInputValue,
  invitedBy,
  onSubmitStepOtp,
  onClickActionLanding,
  fieldsValidationErrors
}) => {
  const classes = useStyles();

  return (
    <div className={classes.sign_up}>
      {currentStepForm === null ? (
        <Landing
          onClickActionLanding={onClickActionLanding}
          invitedBy={invitedBy}
        />
      ) : (
        <StepForm
          currentStepForm={currentStepForm}
          onSubmitStep={onSubmitStep}
          onClickPrevStep={onClickPrevStep}
          stepFormInputsValues={stepFormInputsValues}
          onChangeInputValue={onChangeInputValue}
          onSubmitStepOtp={onSubmitStepOtp}
          fieldsValidationErrors={fieldsValidationErrors}
        />
      )}
    </div>
  );
};

SignUpPage.propTypes = {
  currentStepForm: PropTypes.number,
  onSubmitStep: PropTypes.func.isRequired,
  onClickActionLanding: PropTypes.func.isRequired,
  onClickPrevStep: PropTypes.func.isRequired,
  stepFormInputsValues: PropTypes.shape({
    name: PropTypes.string,
    identificationNumber: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    otp: PropTypes.string
  }).isRequired,
  onChangeInputValue: PropTypes.func.isRequired,
  invitedBy: PropTypes.string.isRequired,
  onSubmitStepOtp: PropTypes.func.isRequired,
  fieldsValidationErrors: PropTypes.shape({
    fieldType: PropTypes.string,
    valid: PropTypes.bool
  }).isRequired
};

SignUpPage.defaultProps = {
  currentStepForm: undefined
};

const SignUp = () => {
  const {
    currentStepForm,
    onSubmitStep,
    onClickPrevStep,
    stepFormInputsValues,
    onChangeInputValue,
    referralHostName,
    onSubmitStepOtp,
    onClickActionLanding,
    fieldsValidationErrors
  } = useSignUp();
  return (
    <SignUpPage
      currentStepForm={currentStepForm}
      onSubmitStep={onSubmitStep}
      onClickPrevStep={onClickPrevStep}
      stepFormInputsValues={stepFormInputsValues}
      onChangeInputValue={onChangeInputValue}
      invitedBy={referralHostName}
      onSubmitStepOtp={onSubmitStepOtp}
      onClickActionLanding={onClickActionLanding}
      fieldsValidationErrors={fieldsValidationErrors}
    />
  );
};

export default SignUp;
