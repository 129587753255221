import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Calendar = ({ width, height, viewBox, color }) => (
  <svg width={width} height={height} viewBox={viewBox}>
    <path
      d="M8.66666667,7.15 L6.66666667,7.15 C6.3,7.15 6,7.4425 6,7.8 L6,9.75 C6,10.1075 6.3,10.4 6.66666667,10.4 L8.66666667,10.4 C9.03333333,10.4 9.33333333,10.1075 9.33333333,9.75 L9.33333333,7.8 C9.33333333,7.4425 9.03333333,7.15 8.66666667,7.15 Z M8.66666667,0.65 L8.66666667,1.3 L3.33333333,1.3 L3.33333333,0.65 C3.33333333,0.2925 3.03333333,0 2.66666667,0 C2.3,0 2,0.2925 2,0.65 L2,1.3 L1.33333333,1.3 C0.593333333,1.3 0.00666666667,1.885 0.00666666667,2.6 L0,11.7 C0,12.415 0.593333333,13 1.33333333,13 L10.6666667,13 C11.4,13 12,12.415 12,11.7 L12,2.6 C12,1.885 11.4,1.3 10.6666667,1.3 L10,1.3 L10,0.65 C10,0.2925 9.7,0 9.33333333,0 C8.96666667,0 8.66666667,0.2925 8.66666667,0.65 Z M10,11.7 L2,11.7 C1.63333333,11.7 1.33333333,11.4075 1.33333333,11.05 L1.33333333,4.55 L10.6666667,4.55 L10.6666667,11.05 C10.6666667,11.4075 10.3666667,11.7 10,11.7 Z"
      fill={color}
    />
  </svg>
);

Calendar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
  color: PropTypes.string
};

Calendar.defaultProps = {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  color: theme.palette.secondary.default
};

export default Calendar;
