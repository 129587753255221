import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(theme => ({
  list_item: {
    display: "flex",
    alignItems: "center",
    padding: "8px 0",
    borderBottom: ({ noBorderBottom }) =>
      noBorderBottom ? "none" : `1px solid ${theme.palette.shade.default}`
  }
}));

const ListItem = React.forwardRef((props, ref) => {
  const { children, className, noBorderBottom } = props;
  const classes = useStyles({ noBorderBottom });
  const componentRef = ref || React.useRef(null);
  const classNames = classnames(classes.list_item, className);

  return (
    <div ref={componentRef} className={classNames} role="listitem">
      {children}
    </div>
  );
});

ListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noBorderBottom: PropTypes.bool
};

ListItem.defaultProps = {
  children: null,
  className: "",
  noBorderBottom: false
};

export default ListItem;
