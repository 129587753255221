export const DASHBOARD_PATH = "/";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const VALIDATION_PATH = "/validation/:type";
export const VERIFICATION_PATH = "/verification";
export const HELP_PATH = "/help";
export const PROFILE_PATH = "/profile";
export const TOKEN_PATH = "/t/:token";
export const ORDERS_PATH = "/orders";
export const REFERRAL_PROGRAM_PATH = "/referral-program";
export const TERMS_CONDITIONS_PATH = "/terms-and-conditions";
export const PAYMENT_PROTECTION_INSURANCE_PATH =
  "/payment-protection-insurance";

export const ORDERS_PATH_ACTIVE_TAB = "actives";
export const ORDERS_PATH_FINISHED_TAB = "finished";

export const ORDER_PATH = "/order/:id";
export const ORDER_ARTICLES_PATH = "/order/:id/articles";
export const ORDER_INVOICES_PATH = "/order/:id/invoices";
export const ORDER_INSTALMENTS_PATH = "/order/:id/instalments";
export const ORDER_RENDER_SERVICE_PATH = "/order/:id/render-service";
export const ORDER_BANK_ACCOUNT_REFUND_PATH = "/order/:id/bank-account-refund";
export const ORDER_BANK_ACCOUNT_REFUND_CONFIRMED_PATH =
  "/order/:id/bank-account-refund/confirmed";

export const paths = [
  { name: "dashboard", path: "/" },
  { name: "login", path: "/login" },
  { name: "validation", path: "/validation/:type" },
  { name: "verification", path: "/verification" },
  { name: "help", path: "/help" },
  { name: "profile", path: "/profile" },
  { name: "token", path: "/t/:token" },
  { name: "orders", path: "/orders" },
  { name: "referral-program", path: "/referral-program" },

  { name: "order", path: "/order/:id" },
  { name: "order_articles", path: "/order/:id/articles" },
  { name: "order_invoices", path: "/order/:id/invoices" },
  { name: "order_instalments", path: "/order/:id/instalments" }
];

const routes = [
  {
    path: LOGIN_PATH,
    private: false,
    exact: true
  },
  {
    path: VALIDATION_PATH,
    private: false,
    exact: true
  },
  {
    path: SIGNUP_PATH,
    private: true,
    exact: true
  },
  {
    path: TOKEN_PATH,
    private: false,
    exact: true
  },
  {
    path: ORDERS_PATH,
    private: true,
    exact: true
  },
  {
    path: ORDER_PATH,
    private: true,
    exact: false
  },
  {
    path: HELP_PATH,
    private: true,
    exact: true
  },
  {
    path: PROFILE_PATH,
    private: true,
    exact: true
  },
  {
    path: VERIFICATION_PATH,
    private: true,
    exact: true
  },
  {
    path: DASHBOARD_PATH,
    private: true,
    exact: true
  },
  {
    path: REFERRAL_PROGRAM_PATH,
    private: true,
    exact: true
  },
  {
    path: PAYMENT_PROTECTION_INSURANCE_PATH,
    private: true,
    exact: true
  }
];

export default routes;
