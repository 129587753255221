import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { BarLoader as Loader } from "react-spinners";

const useStyles = createUseStyles({
  loader: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100009
  }
});

const BarLoader = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.loader} ref={ref}>
      <Loader width={window.innerWidth} color={theme.palette.success.default} />
    </div>
  );
});

export default BarLoader;
