import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";

import useEvent from "../../../../../hooks/mutations/useEvents";
import { Card, Typography, MerchantLogo } from "../../../../../atoms";
import { Location, Unicorn } from "../../../../../atoms/Icons";
import K from "../../../../../constants";
import useTrackingContext from "../../../../../contexts/Tracking";
import locales from "../../../../../locales";
import ProductLabel from "../../../../../atoms/ProductLabel";
import NewTabLink from "../../../../../atoms/NewTabLink";

const useStyles = createUseStyles(theme => ({
  merchant_dashboard_promotional_card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "459px",
    border: "none",
    marginTop: 12,
    boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.09)",
    height: ({ cardHeight }) => cardHeight
  },
  merchant_dashboard_promotional_card__content: {
    display: "flex",
    flexDirection: "column",
    padding: "16px"
  },
  merchant_dashboard_promotional_card__header: {
    display: "flex",
    flexDirection: "initial",
    minHeight: "62px"
  },
  merchant_dashboard_promotional_card__header__merchant_logo: {
    height: "62px",
    width: "62px",
    display: "flex",
    marginRight: 10
  },
  merchant_dashboard_promotional_card__header__merchant_logo_link: {
    display: "flex"
  },
  merchant_dashboard_promotional_card__header__merchant_information: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  merchant_dashboard_promotional_card__header__merchant_products: {
    display: "flex",
    flexDirection: "initial",
    flexWrap: "wrap",
    "& > *": {
      marginRight: "8px",
      "@media(max-width: 420px)": {
        marginBottom: "8px"
      }
    }
  },
  merchant_dashboard_promotional_card__body: {
    marginTop: "24px"
  },
  merchant_dashboard_promotional_card__body__background_image: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "0",
    paddingTop: "50%",
    position: "relative",
    overflow: "hidden",
    "& > a": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      "& > img": {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%"
      }
    }
  },
  merchant_dashboard_promotional_card__body__background_image_not_found: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "230px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
      marginBottom: "20px"
    }
  },
  merchant_dashboard_promotional_card__body__promotion_details: {
    marginTop: "24px",
    "& > *": {
      marginBottom: "4px",
      display: "block"
    }
  },
  merchant_dashboard_promotional_card__footer: {
    width: "100%",
    backgroundColor: theme.palette.shade.default,
    borderRadius: "0 0 8px 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    boxSizing: "border-box",
    "& > span": {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        marginLeft: "4px"
      }
    }
  },
  merchant_dashboard_promotional_card__footer__location_link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none"
  }
}));

function getCardHeight(promotion, stores) {
  let cardHeight;
  if (promotion) {
    if (stores) {
      cardHeight = 477;
    } else {
      cardHeight = 443;
    }
  } else if (stores) {
    cardHeight = 382;
  } else {
    cardHeight = 380;
  }
  return cardHeight;
}

const MerchantPromotionCard = ({
  reference,
  url,
  name,
  logo,
  description,
  products,
  backgroundImage,
  promotion,
  stores,
  isPromotionsAllowed
}) => {
  const [promotionViewed, setPromotionViewed] = useState(false);
  const classes = useStyles({
    backgroundImage,
    logo,
    cardHeight: getCardHeight(promotion, stores)
  });
  const theme = useTheme();
  const intl = useIntl();
  const { track, eventNames } = useTrackingContext();
  const containerPromo = useRef(null);

  const [event] = useEvent({
    onCompleted: () => {},
    onError: () => {}
  });

  function trackClick(
    eventName,
    merchantReference,
    promotionUrl,
    promotionReference
  ) {
    const variables = {
      type: "promotion_click",
      occurredAt: DateTime.utc(),
      reference: promotionReference
    };
    track(eventName, {
      merchantReference,
      url: promotionUrl
    });
    event({ variables });
  }

  function callbackViewEvent(entries) {
    const [entry] = entries;
    if (entry.isIntersecting && !promotionViewed) {
      const variables = {
        type: "promotion_view",
        occurredAt: DateTime.utc(),
        reference: promotion.reference
      };
      setPromotionViewed(true);
      event({ variables });
    }
  }

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackViewEvent, options);
    if (containerPromo.current && isPromotionsAllowed) {
      observer.observe(containerPromo.current);
    }
    return () => {
      if (containerPromo.current) {
        observer.unobserve(containerPromo.current);
      }
    };
  }, [containerPromo, options]);

  const promotionOrMerchantBackgroundImage =
    promotion?.backgroundImage || backgroundImage;
  const promotionUrlDestination = promotion?.url || url;

  return (
    <Card className={classes.merchant_dashboard_promotional_card}>
      <div className={classes.merchant_dashboard_promotional_card__content}>
        <div className={classes.merchant_dashboard_promotional_card__header}>
          <div
            className={
              classes.merchant_dashboard_promotional_card__header__merchant_logo
            }
          >
            <MerchantLogo
              merchantLogo={logo}
              merchantName={name}
              merchantUrl={url}
              logoClassName={
                classes.merchant_dashboard_promotional_card__header__merchant_logo
              }
              linkClassName={
                classes.merchant_dashboard_promotional_card__header__merchant_logo_link
              }
            />
          </div>
          <div
            className={
              classes.merchant_dashboard_promotional_card__header__merchant_information
            }
          >
            <Typography variant={K.typographicVariants.heading4}>
              {name}
            </Typography>
            <Typography variant={K.typographicVariants.body3}>
              {description}
            </Typography>
            <span
              className={
                classes.merchant_dashboard_promotional_card__header__merchant_products
              }
            >
              {products.map(p => (
                <ProductLabel key={p} product={p} />
              ))}
            </span>
          </div>
        </div>
        <div className={classes.merchant_dashboard_promotional_card__body}>
          {backgroundImage ? (
            <div
              ref={containerPromo}
              className={
                classes.merchant_dashboard_promotional_card__body__background_image
              }
            >
              <NewTabLink
                href={promotionUrlDestination}
                onClick={() => {
                  trackClick(
                    eventNames.dashboard.clickMerchantPromotion,
                    reference,
                    promotionUrlDestination,
                    promotion.reference
                  );
                }}
              >
                <img src={promotionOrMerchantBackgroundImage} alt={name} />
              </NewTabLink>
            </div>
          ) : (
            <div
              className={
                classes.merchant_dashboard_promotional_card__body__background_image_not_found
              }
            >
              <Unicorn
                width={68}
                height={82}
                color={theme.palette.tertiary.default}
              />
              <Typography
                variant={K.typographicVariants.body3}
                color={theme.palette.tertiary.default}
              >
                {intl.formatMessage(
                  locales.merchant_promotion_card_image_not_found
                )}
              </Typography>
            </div>
          )}

          {promotion && (
            <div
              className={
                classes.merchant_dashboard_promotional_card__body__promotion_details
              }
            >
              {promotion.expiration && (
                <Typography variant={K.typographicVariants.caption2}>
                  {promotion.expiration}
                </Typography>
              )}
              <Typography variant={K.typographicVariants.heading3}>
                {promotion.title}
              </Typography>
              <Typography variant={K.typographicVariants.body2}>
                {promotion.description}
              </Typography>
            </div>
          )}
        </div>
      </div>
      {stores && (
        <div className={classes.merchant_dashboard_promotional_card__footer}>
          <Typography variant={K.typographicVariants.caption1}>
            {intl.formatMessage(locales.merchant_promotion_card_also_in_stores)}
          </Typography>
          <Typography variant={K.typographicVariants.caption1}>
            <NewTabLink
              href={stores.findStoresUrl}
              className={
                classes.merchant_dashboard_promotional_card__footer__location_link
              }
              onClick={() => {
                track(eventNames.dashboard.clickMerchantLocation, {
                  merchantName: name,
                  url: stores.findStoresUrl
                });
              }}
            >
              {intl.formatMessage(
                locales.merchant_promotion_card_find_your_store
              )}
              <Location
                width={18}
                height={18}
                color={theme.palette.primary.default}
              />
            </NewTabLink>
          </Typography>
        </div>
      )}
    </Card>
  );
};

MerchantPromotionCard.propTypes = {
  reference: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  logo: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.string).isRequired,
  promotion: PropTypes.shape({
    reference: PropTypes.string,
    lastDate: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    expiration: PropTypes.string,
    url: PropTypes.string,
    backgroundImage: PropTypes.string
  }),
  stores: PropTypes.shape({
    findStoresUrl: PropTypes.string
  }),
  isPromotionsAllowed: PropTypes.bool.isRequired
};

MerchantPromotionCard.defaultProps = {
  url: "",
  logo: "",
  description: "",
  backgroundImage: "",
  promotion: undefined,
  stores: undefined
};

export default MerchantPromotionCard;
