import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const NoStore = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 47 42">
    <path
      d="M41.615 0c.357 0 .68.206.832.53l4.4 9.416c.096.146.153.32.153.508 0 1.91-.993 3.592-2.49 4.56v6.567a.917.917 0 01-1.835 0v-5.812a5.438 5.438 0 01-5.623-2.298 5.433 5.433 0 01-9.03 0 5.434 5.434 0 01-9.031 0 5.434 5.434 0 01-9.031 0 5.434 5.434 0 01-5.647 2.293v24.402h13.124v-22.19a.917.917 0 011.836 0V32.28h23.402V27.5a.917.917 0 011.835 0v12.666H46A.917.917 0 1146 42H.989a.917.917 0 110-1.834h1.489V14.999a5.427 5.427 0 01-2.462-4.375.916.916 0 01.072-.562L4.603.525A.918.918 0 015.433 0zm1.06 34.114H19.273v6.052h23.402v-6.052zM23 28c.263 0 .521.106.707.292.186.186.293.444.293.707a1.003 1.003 0 01-1.707.707A1.006 1.006 0 0122 29c0-.263.107-.52.293-.707.186-.186.444-.292.707-.292zm-9-1c.264 0 .52.107.707.293.186.186.293.443.293.707 0 .263-.107.521-.293.707A1.008 1.008 0 0114 29c-.263 0-.521-.107-.707-.293A1.007 1.007 0 0113 28c0-.264.107-.521.293-.707.186-.186.444-.293.707-.293zm16.443-6.733a.912.912 0 011.29 1.29l-6.177 6.176a.909.909 0 01-1.289 0 .912.912 0 010-1.29zm8.01-2.002a.906.906 0 011.282 1.282l-6.188 6.188a.903.903 0 01-1.282 0 .906.906 0 010-1.282zM44 24c.263 0 .521.107.707.293.186.186.293.444.293.707 0 .263-.107.521-.293.707A1.007 1.007 0 0144 26c-.263 0-.521-.107-.707-.293A1.007 1.007 0 0143 25c0-.264.107-.521.293-.707.186-.186.444-.293.707-.293zm1.045-12.629h-6.957a3.603 3.603 0 003.479 2.678 3.603 3.603 0 003.478-2.678zm-9.03 0h-6.957a3.603 3.603 0 003.478 2.678 3.603 3.603 0 003.479-2.678zm-9.03 0h-6.958a3.603 3.603 0 003.479 2.678 3.603 3.603 0 003.478-2.678zm-9.031 0h-6.957a3.603 3.603 0 003.478 2.678 3.603 3.603 0 003.479-2.678zm-9.03 0H1.965a3.603 3.603 0 003.479 2.678 3.603 3.603 0 003.478-2.678zM41.03 1.834H37.99v7.703h6.64l-3.6-7.703zm-4.877 0h-7.202v7.703h7.202V1.834zm-9.037 0h-7.202v7.703h7.202V1.834zm-9.038 0h-7.201v7.703h7.201V1.834zm-9.037 0H6.014L2.367 9.537h6.675V1.834z"
      fill={color}
    />
  </svg>
);

NoStore.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

NoStore.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default NoStore;
