import React from "react";
import PropTypes from "prop-types";

const WhatsApp = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 22 22">
    <g fill={color} fillRule="nonzero">
      <path d="M10.462.013C4.605.289-.018 5.128 0 10.945a10.793 10.793 0 0 0 1.197 4.921L.03 21.49a.427.427 0 0 0 .519.5l5.554-1.306a11.02 11.02 0 0 0 4.727 1.138c5.987.09 10.979-4.625 11.166-10.561C22.195 4.897 16.9-.291 10.462.013Zm6.628 16.94A8.592 8.592 0 0 1 11 19.454c-1.347 0-2.637-.3-3.833-.891l-.774-.383-3.406.8.717-3.451-.38-.74a8.393 8.393 0 0 1-.937-3.879c0-2.282.896-4.427 2.523-6.041A8.664 8.664 0 0 1 11 2.368c2.3 0 4.464.888 6.09 2.502a8.453 8.453 0 0 1 2.523 6.04c0 2.263-.91 4.443-2.523 6.042Z" />
      <path d="m16.339 13.277-2.131-.607a.799.799 0 0 0-.786.206l-.521.526a.78.78 0 0 1-.844.177c-1.008-.405-3.128-2.274-3.67-3.21a.765.765 0 0 1 .062-.853l.455-.584a.782.782 0 0 0 .098-.8L8.105 6.12a.798.798 0 0 0-1.24-.282c-.595.499-1.3 1.257-1.386 2.097-.152 1.48.489 3.347 2.91 5.589 2.797 2.59 5.036 2.931 6.495 2.58.827-.198 1.488-.995 1.905-1.647a.786.786 0 0 0-.45-1.18Z" />
    </g>
  </svg>
);

WhatsApp.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

WhatsApp.defaultProps = {
  width: 22,
  height: 22,
  color: "#7AD06D"
};

export default WhatsApp;
