import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import classnames from "classnames";
import K from "../../constants";
import { typographicVariants } from "../../theme";
import fonts from "../../theme/fonts";

const useStyles = createUseStyles({
  typography: {
    fontFamily: ({ font }) => font,
    color: ({ color }) => color,
    fontSize: ({ size }) => `${size}px`,
    fontWeight: ({ weight }) => weight,
    lineHeight: ({ lineHeight }) => `${lineHeight}px`,
    textDecoration: ({ textDecoration }) => textDecoration,
    padding: 0,
    margin: 0
  }
});

const Typography = React.forwardRef((props, ref) => {
  const { children, variant, color, className, font } = props;
  const theme = useTheme();
  const variants = typographicVariants(theme);

  const { size, weight, tag, lineHeight } =
    variants[variant].style || variants.body1.style;

  let fontColor;
  if (color === "") {
    fontColor = variants[variant].defaultColor;
  } else if (color[0] === "#" || color === "inherit") {
    fontColor = color;
  } else {
    fontColor = theme.palette[color].default;
  }

  let fontFamily;
  if (!font) {
    fontFamily = variants[variant].defaultFont;
  } else {
    fontFamily = fonts[font];
  }

  const textDecoration = variant.match("link") ? "underline" : "none";

  const classes = useStyles({
    size,
    weight,
    color: fontColor,
    lineHeight,
    font: fontFamily,
    textDecoration
  });

  const classNames = classnames(classes.typography, className);

  const TypographicElement =
    K.typographicElements.find(c => c === tag) || K.typographicElements[0];

  return (
    <TypographicElement className={classNames} ref={ref}>
      {children}
    </TypographicElement>
  );
});

Typography.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.keys(K.typographicVariants)),
  color: PropTypes.string,
  className: PropTypes.string,
  font: PropTypes.string
};

Typography.defaultProps = {
  children: null,
  variant: K.typographicVariants.body1,
  color: K.colors.primary,
  className: "",
  font: ""
};

export default Typography;
