import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import useTrackingContext from "../../../../../../contexts/Tracking";
import Layout from "../../Layout";
import { Button, Input, Typography } from "../../../../../../atoms";
import { CheckBoxOutlineBlank, CheckBox } from "../../../../../../atoms/Icons";
import locales from "../../../../../../locales";
import K from "../../../../../../constants";

const useStyles = createUseStyles(theme => ({
  phone__privacy_policy: {
    display: "flex",
    marginTop: "-20px",
    alignItems: "center",
    "& span:first-child": {
      cursor: "pointer",
      verticalAlign: "middle"
    },
    "& span a": {
      color: theme.palette.success.default
    },
    "& span + span": {
      marginLeft: "8px"
    }
  }
}));

const Phone = ({
  onClickPrevStep,
  onSubmitStep,
  currentStepForm,
  inputValue,
  onChangeInputValue,
  error
}) => {
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const intl = useIntl();
  const { track, eventNames } = useTrackingContext();
  useEffect(() => {
    track(eventNames.signUp.stepForm.phone.start);
  }, []);
  const classes = useStyles();
  const onClickPrivacyPolicy = () => setPrivacyPolicy(!privacyPolicy);
  const theme = useTheme();

  return (
    <Layout
      subTitle={intl.formatMessage(locales.sign_up_step_form_phone_subtitle)}
      claim={intl.formatMessage(locales.sign_up_step_form_phone_claim)}
      currentStep={currentStepForm}
      onClickPrevStep={onClickPrevStep}
      onSubmitStep={onSubmitStep}
    >
      <div>
        <Input
          label={intl.formatMessage(
            locales.sign_up_step_form_phone_label_input
          )}
          type="number"
          name="phone"
          inputRef={React.createRef()}
          focus
          onChange={inputData => {
            onChangeInputValue(inputData);
          }}
          value={inputValue}
          testid="phone"
          prefix="+34"
        />
        {error && (
          <p>
            <Typography
              variant={K.typographicVariants.body2}
              color={K.colors.error}
            >
              {intl.formatMessage(locales.sign_up_step_form_phone_error)}
            </Typography>
          </p>
        )}
      </div>
      <p className={classes.phone__privacy_policy} data-testid="privacy_policy">
        <span
          role="button"
          tabIndex="0"
          onClick={onClickPrivacyPolicy}
          onKeyPress={onClickPrivacyPolicy}
          data-testid="checkbox"
        >
          {!privacyPolicy ? (
            <CheckBoxOutlineBlank color={theme.palette.tertiary.default} />
          ) : (
            <CheckBox color={theme.palette.info.default} />
          )}
        </span>
        <Typography variant={K.typographicVariants.body2}>
          {intl.formatMessage(locales.sign_up_step_form_phone_privacy_policy, {
            link: (
              <a
                href={K.externalUrls.sequraWeb.privacyPolicy}
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage(
                  locales.terms_and_condition_modal_privacy_policy_link
                )}
              </a>
            )
          })}
        </Typography>
      </p>
      <div>
        <Button
          type="submit"
          fullWidth
          size="large"
          disabled={!inputValue || !privacyPolicy}
        >
          {intl.formatMessage(locales.general_navigation_next)}
        </Button>
      </div>
    </Layout>
  );
};

Phone.propTypes = {
  onSubmitStep: PropTypes.func.isRequired,
  onClickPrevStep: PropTypes.func.isRequired,
  currentStepForm: PropTypes.number.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChangeInputValue: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired
};

export default Phone;
