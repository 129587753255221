import React from "react";
import PropTypes from "prop-types";
import { useTheme, createUseStyles } from "react-jss";
import { Chip } from "@sequra/telar";
import { Check, Calendar, Alert } from "../Icons";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles({
  drop_down_list__footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  drop_down_list__footer__right: {
    display: "flex",
    alignItems: "center"
  },
  drop_down_list__footer__arrow_space: {
    width: "30px",
    display: "block"
  }
});

const DropDownListFooter = ({ title, subtitle, chipType }) => {
  const theme = useTheme();
  const classes = useStyles();
  const chipTextColor = theme.palette.primary.default;

  const chipIcon = () => {
    switch (chipType) {
      case "success":
        return <Check width={15} height={15} color={chipTextColor} />;
      case "error":
        return <Alert width={15} height={15} color={chipTextColor} />;
      default:
        return (
          <Calendar
            width={13}
            height={13}
            viewBox="0 0 13 13"
            color={chipTextColor}
          />
        );
    }
  };

  return (
    <div className={classes.drop_down_list__footer}>
      <Typography
        variant={K.typographicVariants.body2}
        color={theme.palette.primary.default}
      >
        {title}
      </Typography>

      <div className={classes.drop_down_list__footer__right}>
        <Chip type={chipType} size="small">
          <span>{subtitle}</span>
          {chipIcon()}
        </Chip>
        <div className={classes.drop_down_list__footer__arrow_space} />
      </div>
    </div>
  );
};

DropDownListFooter.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  chipType: PropTypes.string
};

DropDownListFooter.defaultProps = {
  title: "",
  subtitle: "",
  chipType: ""
};

DropDownListFooter.displayName = "DropDownListFooter";

export default DropDownListFooter;
