import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { DateTime } from "luxon";
import { Typography, Progress } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles({
  invoice__progress: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textTransform: "lowercase",
    flex: 1,
    width: "100%",
    paddingTop: "18px",
    paddingBottom: "26px"
  },
  invoice__progress__days: {
    display: "flex",
    alignItems: "baseline"
  }
});

const InvoiceProgress = ({ invoicingCreditDays, dueOn, isOverdue }) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  const end = DateTime.fromISO(dueOn);
  const start = DateTime.now();
  const days = Math.abs(Math.trunc(start.diff(end, "days").values.days));
  return (
    <Progress
      maxValue={invoicingCreditDays}
      value={days}
      backgroundColor={
        isOverdue ? theme.palette.error.light : theme.palette.success.light
      }
      borderColor={
        isOverdue ? theme.palette.error.default : theme.palette.success.default
      }
    >
      <div className={classes.invoice__progress}>
        <div className={classes.invoice__progress__days}>
          <Typography
            variant={K.typographicVariants.visual1}
            color={isOverdue ? K.colors.error : K.colors.success}
          >
            {days}
          </Typography>
          <Typography
            variant={K.typographicVariants.heading4}
            color={isOverdue ? K.colors.error : K.colors.success}
          >
            {days > 1
              ? intl.formatMessage(locales.days)
              : intl.formatMessage(locales.day)}
          </Typography>
        </div>
        <Typography
          variant={K.typographicVariants.caption1}
          color={isOverdue ? K.colors.error : K.colors.success}
        >
          {isOverdue
            ? intl.formatMessage(locales.non_payment)
            : intl.formatMessage(locales.for_payment)}
        </Typography>
      </div>
    </Progress>
  );
};

InvoiceProgress.propTypes = {
  dueOn: PropTypes.string.isRequired,
  invoicingCreditDays: PropTypes.number,
  isOverdue: PropTypes.bool
};

InvoiceProgress.defaultProps = {
  invoicingCreditDays: 0,
  isOverdue: false
};

export default InvoiceProgress;
