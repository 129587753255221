import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import merchantsList from "./merchants_list.json";
import { PageTemplate } from "../../templates";
import { useVouchers } from "./hooks";
import { useLocaleCurrency } from "../../hooks";
import SuccessfulModal from "../SignUp/components/SuccessfulModal";
import Merchants from "./components/forShopppersWithoutOrders/Merchants";
import Voucher from "./components/forShopppersWithoutOrders/Voucher";
import MerchantsBanner from "./components/forShopppersWithoutOrders/MerchantsBanner";
import MerchantsWithoutCostModal from "./components/forShopppersWithoutOrders/MerchantsWithoutCostModal";
import useTrackingContext from "../../contexts/Tracking";

import K from "../../constants";
import {
  OnBoardingModal,
  ReferralProgramFAQsModal,
  ReferralProgramTermsAndConditionsModal
} from "../../molecules";

const DEFAULT_MERCHANT_SELECTED = "fashion";

export const DashboardPage = ({ vouchers, fetching }) => {
  const [merchantsListBySector, setMerchantsListBySector] = useState([]);
  const [merchantSectorSelected, setMerchantSectorSelected] = useState(
    DEFAULT_MERCHANT_SELECTED
  );
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { balance } = vouchers;
  const balanceValue = useLocaleCurrency(balance, { useCents: true });
  const { track, eventNames } = useTrackingContext();

  useEffect(() => {
    track(eventNames.dashboard.dashboardForShoppersWithoutOrders);
  }, []);

  const getMerchantListBySector = (sector = DEFAULT_MERCHANT_SELECTED) => {
    const { merchants } = merchantsList;
    setMerchantSectorSelected(sector);
    setMerchantsListBySector(
      merchants.filter(merchant => merchant.sector === sector)
    );
  };

  const openModalMerchantsWithoutCost = () => {
    history.push(`${location.pathname}?process=${K.process.merchants}`);
  };

  const onClickSeeAllSectors = () => {
    getMerchantListBySector();
    openModalMerchantsWithoutCost();
    track(eventNames.dashboard.seeAllMerchantsBySector);
  };

  const onClickMerchantSector = sector => {
    getMerchantListBySector(sector);
    openModalMerchantsWithoutCost();
    const sectorsEvents = eventNames.dashboard.sectors;
    track(sectorsEvents[sector.replace("&", "and")]);
  };

  const onClickSeeAllShops = () => {
    window.open(K.externalUrls.sequraWeb.merchantsList);
    track(eventNames.dashboard.seeAllStores);
  };

  return (
    <PageTemplate>
      <div>
        {query.process === K.process.referral && (
          <SuccessfulModal
            onClickNext={() =>
              history.replace(`/?process=${K.process.onBoarding}`)
            }
          />
        )}
        {query.process === K.process.onBoarding && (
          <OnBoardingModal onClose={() => history.replace(location.pathname)} />
        )}

        {query.process === K.process.merchants && (
          <MerchantsWithoutCostModal
            merchants={merchantsListBySector}
            onClick={onClickMerchantSector}
            onClose={() => history.replace(location.pathname)}
            sectorSelected={merchantSectorSelected}
            onClickSeeAllShops={onClickSeeAllShops}
          />
        )}

        {query.process === K.process.whereToUseTheVoucher && (
          <ReferralProgramFAQsModal
            onClose={() => history.replace(location.pathname)}
          />
        )}

        {query.process === K.process.referralTermsAndConditions && (
          <ReferralProgramTermsAndConditionsModal
            onClose={() => history.replace(location.pathname)}
          />
        )}
        <Voucher balance={fetching ? "" : balanceValue} />
        <Merchants
          onClick={onClickMerchantSector}
          onClickSeeAll={onClickSeeAllSectors}
        />
        <MerchantsBanner onClickButton={onClickSeeAllShops} />
      </div>
    </PageTemplate>
  );
};

DashboardPage.propTypes = {
  vouchers: PropTypes.shape({
    balance: PropTypes.number,
    visible: PropTypes.bool
  }),
  fetching: PropTypes.bool.isRequired
};

DashboardPage.defaultProps = {
  vouchers: {}
};

const DashBoardForShoppersWithoutOrders = () => {
  const { vouchers, fetching } = useVouchers();
  return <DashboardPage vouchers={vouchers} fetching={fetching} />;
};

export default DashBoardForShoppersWithoutOrders;
