import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useTheme, createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button, Typography } from "../../../../atoms";
import { Card } from "../../../../atoms/Icons";
import { PROFILE_PATH } from "../../../../routes";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  card_expiring: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    "& > button": {
      minWidth: "144px"
    }
  },
  card_expiring__message: {
    display: "flex",
    alignItems: "center",
    "& > :first-child": {
      marginRight: "8px"
    },
    "& > :last-child": {
      flex: 1
    }
  }
});

const CardExpiration = ({ currentProductName, isExpired, orderId }) => {
  const history = useHistory();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();

  const goToPayment = () => {
    if (/[s|p]?[p|r]\d+/.test(currentProductName)) {
      const query = {
        process: K.process.changePaymentCard,
        order_id: orderId,
        gateway: K.mufasaGateways.assignCard
      };
      history.replace(`/?${queryString.stringify(query)}`);
      return;
    }

    history.push(PROFILE_PATH);
  };

  return (
    <div className={classes.card_expiring}>
      <div className={classes.card_expiring__message}>
        <Card color={theme.palette.error.default} />
        <Typography
          variant={K.typographicVariants.caption1}
          color={theme.palette.error.default}
        >
          {isExpired
            ? intl.formatMessage(locales.expired)
            : intl.formatMessage(locales.expiring_soon)}
        </Typography>
      </div>
      <Button color="error" size="small" onClick={goToPayment}>
        {intl.formatMessage(locales.change_card)}
      </Button>
    </div>
  );
};

CardExpiration.propTypes = {
  isExpired: PropTypes.bool,
  currentProductName: PropTypes.string,
  orderId: PropTypes.string
};

CardExpiration.defaultProps = {
  isExpired: false,
  currentProductName: "",
  orderId: ""
};

export default CardExpiration;
