import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  tab_panel: {
    overflowY: "auto"
  }
});

const TabPanel = React.forwardRef((props, ref) => {
  const { value, index, children } = props;
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={classes.tab_panel}
      role="tabpanel"
      hidden={value !== index}
    >
      {children}
    </div>
  );
});

TabPanel.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
  children: PropTypes.node
};

TabPanel.defaultProps = {
  value: 0,
  index: 0,
  children: null
};

export default TabPanel;
