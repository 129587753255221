import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Phone = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 18 18">
    <path
      d="M17.01 12.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 00-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H1.19C.65 0 0 .24 0 .99 0 10.28 7.73 18 17.01 18c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"
      fill={color}
    />
  </svg>
);

Phone.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Phone.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Phone;
