import React from "react";
import ReactTooltip from "react-tooltip";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import { Info } from "../../../../atoms/Icons";
import { useLocaleCurrency, useOrder } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  amortization_summary_table: {
    margin: "24px 0",
    maxWidth: "375px",
    minWidth: "275px",
    "@media(min-width: 768px)": {
      alignSelf: "flex-end",
      marginTop: 32
    }
  },
  amortization_summary_table__content: {
    display: "flex",
    flexDirection: "column",
    margin: ({ instalmentFee }) => (instalmentFee > 0 ? "16px 0" : "8px 0"),
    "& > div": {
      marginBottom: "16px"
    },
    "& > hr": {
      height: "1px",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      border: 0,
      background: theme.palette.background.default
    }
  },
  amortization_summary_table__content__row: {
    display: "flex",
    justifyContent: "space-between",
    "& > p": {
      position: "relative"
    }
  },
  amortization_summary_table__content__row__text: {
    display: "flex"
  },
  amortization_summary_table__content__info: {
    cursor: "pointer",
    marginLeft: "10px",
    "& > svg": {
      width: "20px",
      height: "20px"
    }
  },
  amortization_summary_table__content__tooltip: {
    maxWidth: "224px",
    padding: "16px !important",
    borderRadius: "8px !important",
    opacity: "1 !important",
    "& > p": {
      marginBottom: "4px"
    },
    "@media(max-width: 320px)": {
      maxWidth: "160px"
    }
  },
  amortization_summary_table__content__tooltip__title: {
    display: "block",
    marginBottom: "6px"
  }
}));

const AmortizationSummaryTable = () => {
  const order = useOrder();
  const { amortization } = order;
  const classes = useStyles({ instalmentFee: order.paymentPlan.instalmentFee });
  const intl = useIntl();
  const theme = useTheme();
  const [isTooltipOpened, setTooltipOpened] = React.useState(false);
  const { total, amount, fee } = amortization;
  const orderWithFee = fee > 0;

  return (
    <div className={classes.amortization_summary_table}>
      <Typography
        variant={K.typographicVariants.heading4}
        color={theme.palette.primary.default}
      >
        {intl.formatMessage(locales.amortization_flow_payment_summary)}
      </Typography>
      <div className={classes.amortization_summary_table__content}>
        <>
          <div className={classes.amortization_summary_table__content__row}>
            <div
              className={classes.amortization_summary_table__content__row__text}
            >
              <Typography variant={K.typographicVariants.body2}>
                {intl.formatMessage(
                  locales.amortization_flow_total_unpaid_instalments
                )}
              </Typography>
              {orderWithFee && (
                <span
                  data-tip
                  data-for="fees_saved_info"
                  data-event="click"
                  className={classes.amortization_summary_table__content__info}
                >
                  <Info
                    color={
                      isTooltipOpened
                        ? theme.palette.info.light
                        : theme.palette.info.default
                    }
                  />
                </span>
              )}
            </div>

            <Typography variant={K.typographicVariants.body2}>
              {useLocaleCurrency(amount, {
                useCents: true
              })}
            </Typography>
          </div>
          {orderWithFee && (
            <div className={classes.amortization_summary_table__content__row}>
              <Typography variant={K.typographicVariants.body2}>
                {intl.formatMessage(locales.amortization_flow_instalment_fees)}
              </Typography>

              <Typography variant={K.typographicVariants.body2}>
                {useLocaleCurrency(fee, {
                  useCents: true
                })}
              </Typography>
            </div>
          )}
          <ReactTooltip
            id="fees_saved_info"
            backgroundColor={theme.palette.info.default}
            effect="solid"
            globalEventOff="click"
            className={classes.amortization_summary_table__content__tooltip}
            afterShow={() => {
              setTooltipOpened(true);
            }}
            afterHide={() => {
              setTooltipOpened(false);
            }}
          >
            <Typography
              color={theme.palette.white.default}
              className={
                classes.amortization_summary_table__content__tooltip__title
              }
            >
              {intl.formatMessage(locales.amortization_flow_tooltip_title)}
            </Typography>
            <Typography
              color={theme.palette.white.default}
              variant={K.typographicVariants.body2}
            >
              {intl.formatMessage(locales.amortization_flow_tooltip_subtitle)}
            </Typography>
          </ReactTooltip>
          <hr />
        </>
      </div>
      <div className={classes.amortization_summary_table__content__row}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.amortization_flow_total)}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {useLocaleCurrency(total, { useCents: true })}
        </Typography>
      </div>
    </div>
  );
};

export default AmortizationSummaryTable;
