import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useOrder } from "../../../../hooks";
import { Button } from "../../../../atoms";
import locales from "../../../../locales";
import { paymentSummaryPath, goToOrderPath } from "../../../../utils";

const PaymentButton = ({ isOverdue }) => {
  const order = useOrder();
  const history = useHistory();
  const intl = useIntl();

  const onClick = () => {
    goToOrderPath({
      order,
      history,
      pathFunction: paymentSummaryPath
    });
  };

  return (
    <Button
      variant="contained"
      fullWidth
      color={isOverdue ? "error" : "primary"}
      onClick={onClick}
    >
      {isOverdue
        ? intl.formatMessage(locales.pay_debt)
        : intl.formatMessage(locales.go_to_payment)}
    </Button>
  );
};

PaymentButton.propTypes = {
  isOverdue: PropTypes.bool
};

PaymentButton.defaultProps = {
  isOverdue: false
};

export default PaymentButton;
