import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    border: 0,
    userSelect: "none",
    cursor: "pointer",
    color: "var(--color)",
    backgroundColor: "var(--background-color)",
    pointerEvents: ({ disabled }) => disabled && "none",
    width: ({ fullWidth, size }) => {
      if (fullWidth) return "100%";
      switch (size) {
        case "large":
          return "172px";
        case "small":
          return "144px";
        default:
          return "138px";
      }
    },
    height: ({ size }) => {
      switch (size) {
        case "large":
          return "56px";
        case "small":
          return "34px";
        default:
          return "48px";
      }
    },
    "& > *": {
      pointerEvents: "none"
    }
  },
  contained: {
    "--color": ({ disabled }) => {
      if (disabled) return theme.palette.secondary.default;
      return theme.palette.white.default;
    },
    "--background-color": ({ disabled, alert, color }) => {
      if (disabled && color === "primary") return theme.palette.shade.default;
      if (disabled && color === "error") return theme.palette.error.medium;
      if (alert) return theme.palette.error.default;

      switch (color) {
        case "error":
          return theme.palette.error.default;
        default:
          return theme.palette.primary.default;
      }
    }
  },
  outlined: {
    "--background-color": theme.palette.white.default,
    "--color": ({ disabled, alert, color }) => {
      if (disabled) return theme.palette.tertiary.default;
      if (alert) return theme.palette.error.default;

      switch (color) {
        case "error":
          return theme.palette.error.default;
        default:
          return theme.palette.primary.default;
      }
    },
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: ({ disabled, alert, color }) => {
      if (disabled && color === "primary") return theme.palette.shade.default;
      if (disabled && color === "error") return theme.palette.error.medium;
      if (alert) return theme.palette.error.default;

      switch (color) {
        case "error":
          return theme.palette.error.default;
        default:
          return theme.palette.primary.default;
      }
    }
  },
  text: {
    "--background-color": "transparent",
    textDecoration: "underline",
    "--color": ({ disabled, alert, color }) => {
      if (disabled) return theme.palette.success.light;
      if (alert) return theme.palette.error.default;

      switch (color) {
        case "success":
          return theme.palette.info.default;
        case "primary":
          return theme.palette.primary.default;
        case "error":
          return theme.palette.error.default;
        default:
          return theme.palette.primary.default;
      }
    }
  }
}));

const Button = React.forwardRef((props, ref) => {
  const {
    children,
    onClick,
    to,
    className,
    variant,
    type,
    disabled,
    fullWidth,
    size,
    alert,
    color,
    dataTestId
  } = props;

  const classes = useStyles({
    disabled,
    fullWidth,
    size,
    alert,
    color
  });

  const history = useHistory();
  const [isCurrentHost, setCurrentHost] = React.useState(false);
  const classNames = classnames(classes.button, className, classes[variant]);

  React.useEffect(() => {
    if (to) {
      const parser = document.createElement("a");
      parser.href = to;
      setCurrentHost(parser.host === window.location.host);
    }
  }, []);

  return (
    <button
      ref={ref}
      className={classNames}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      onClick={
        to
          ? () => {
              if (isCurrentHost) history.push(to);
              else window.open(to, "_blank", "noopener, noreferrer");
            }
          : onClick
      }
      data-testid={dataTestId}
    >
      <Typography
        variant={
          size === "small"
            ? K.typographicVariants.buttonS
            : K.typographicVariants.buttonL
        }
        color="inherit"
      >
        {children}
      </Typography>
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  type: PropTypes.oneOf(["submit", "button"]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  alert: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "error"]),
  dataTestId: PropTypes.string
};

Button.defaultProps = {
  children: null,
  onClick: () => {},
  to: "",
  className: "",
  variant: "contained",
  type: "button",
  disabled: false,
  fullWidth: false,
  size: "medium",
  alert: false,
  color: "primary",
  dataTestId: ""
};

export default Button;
