import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../../atoms";
import { useLocaleCurrency } from "../../../../../hooks";
import locales from "../../../../../locales";
import K from "../../../../../constants";

const useStyles = createUseStyles({
  unshipped: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  unshipped__info: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "8px"
    },
    "& > :last-child": {
      marginTop: "16px"
    }
  }
});

const Unshipped = ({ currentOrderValue, invoicingCreditDays }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.unshipped}>
      <div className={classes.unshipped__info}>
        <Typography variant={K.typographicVariants.visual3}>
          {intl.formatMessage(locales.unshipped)}
        </Typography>
        <Typography variant={K.typographicVariants.body2}>
          {intl.formatMessage(locales.invoice_unshipped_days_for_payment, {
            days: invoicingCreditDays
          })}
        </Typography>
        <Typography variant={K.typographicVariants.body2}>
          {useLocaleCurrency(currentOrderValue, { useCents: true })}
        </Typography>
      </div>
      <div />
    </div>
  );
};

Unshipped.propTypes = {
  currentOrderValue: PropTypes.number,
  invoicingCreditDays: PropTypes.number
};

Unshipped.defaultProps = {
  currentOrderValue: 0,
  invoicingCreditDays: 0
};

export default Unshipped;
