import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Security = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={color}
        d="M12 19.8984C13.6167 19.3984 14.9667 18.4109 16.05 16.9359C17.1333 15.4609 17.7667 13.8151 17.95 11.9984H12V4.12344L6 6.37344V11.5484C6 11.6651 6.01667 11.8151 6.05 11.9984H12V19.8984ZM12 21.8984C11.8833 21.8984 11.775 21.8901 11.675 21.8734C11.575 21.8568 11.475 21.8318 11.375 21.7984C9.125 21.0484 7.33333 19.6609 6 17.6359C4.66667 15.6109 4 13.4318 4 11.0984V6.37344C4 5.95677 4.12083 5.58177 4.3625 5.24844C4.60417 4.9151 4.91667 4.67344 5.3 4.52344L11.3 2.27344C11.5333 2.1901 11.7667 2.14844 12 2.14844C12.2333 2.14844 12.4667 2.1901 12.7 2.27344L18.7 4.52344C19.0833 4.67344 19.3958 4.9151 19.6375 5.24844C19.8792 5.58177 20 5.95677 20 6.37344V11.0984C20 13.4318 19.3333 15.6109 18 17.6359C16.6667 19.6609 14.875 21.0484 12.625 21.7984C12.525 21.8318 12.425 21.8568 12.325 21.8734C12.225 21.8901 12.1167 21.8984 12 21.8984Z"
      />
    </svg>
  );
};

Security.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Security.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Security;
