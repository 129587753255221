import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Shipping = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 20">
      <g transform="translate(0 .25)" fill="none" fillRule="evenodd">
        <path
          d="M8.775 12.375h-5.4a1.5 1.5 0 00-1.5 1.5v3a1.5 1.5 0 001.5 1.5h5.4a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5zM3.007 5.278c-.565 0-1.135.186-1.677.498a4.66 4.66 0 00-.36.23l-.045.034 4.699 5.011V6.202l-.083-.054a7.311 7.311 0 00-.423-.242l-.23-.117c-.595-.289-1.182-.473-1.716-.506a2.696 2.696 0 00-.165-.005zm5.99 0c-.173 0-.36.021-.56.062-.4.082-.837.24-1.285.45a8.716 8.716 0 00-.467.24l-.062.034v4.72l4.45-4.745c-.62-.462-1.245-.71-1.876-.754l-.2-.007zM6 0a6 6 0 015.998 6.04l-.005.03a.363.363 0 01-.102.195l-5.026 5.359 1.91.001a2.25 2.25 0 012.245 2.096l.005.154v3a2.25 2.25 0 01-2.25 2.25h-5.4a2.25 2.25 0 01-2.25-2.25v-3a2.25 2.25 0 012.25-2.25l1.758-.001L.106 6.262a.364.364 0 01-.099-.185l-.003-.032.001.018-.002-.018A.377.377 0 010 6.025L0 6.007l.002.014L0 6a6 6 0 016-6zm0 .75A5.252 5.252 0 00.867 4.89l-.043.224-.016.1.147-.087c.647-.373 1.339-.599 2.052-.599.07 0 .14.002.21.007.647.04 1.323.252 1.999.58.173.083.338.17.493.26l.226.135.07.044.084-.049c.228-.134.478-.267.743-.392a6.507 6.507 0 011.455-.508c.247-.05.484-.077.71-.077.648 0 1.282.17 1.899.507l.23.134.064.041-.015-.096A5.253 5.253 0 006.415.766L6.18.753 6 .75z"
          fill={color}
        />
      </g>
    </svg>
  );
};

Shipping.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Shipping.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Shipping;
