import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Button, NextModal, Typography, Checkbox } from "../../atoms";
import { UnicornWaving } from "../../atoms/Illustrations";
import hexToRGBA from "../../theme/hexToRGBA";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  backdrop: {
    backgroundColor: theme.palette.primary.default,
    opacity: "0.3 !important"
  },
  wrapper: {
    width: "100%",
    "@media(max-width: 320px)": {
      top: "0 !important"
    }
  },
  modal: {
    width: "343px",
    height: "606px",
    padding: "29px 16px 48px",
    borderRadius: "8px",
    boxShadow: `-1px 0 37px -12px ${hexToRGBA(
      theme.palette.black.default,
      16
    )}`,
    "@media(min-width: 768px)": {
      padding: "47px 42px 50px 48px",
      width: "514px",
      height: "577px"
    },
    "@media(max-width: 320px)": {
      borderRadius: 0,
      height: "auto"
    }
  },
  modal__content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginBottom: 0
  },
  content: {
    flex: 1
  },
  content__unicorn: {
    "@media(max-width: 768px)": {
      width: "85px",
      height: "85px",
      padding: "9px 4px 8px 4px",
      boxSizing: "border-box"
    }
  },
  content__title: {
    margin: "24px 0"
  },
  content__description: {
    lineHeight: "normal"
  },
  footer__checkbox: {
    display: "flex",
    alignItems: "flex-start",
    "& div[role=checkbox]": {
      "& div": {
        paddingTop: "2px"
      }
    }
  },
  footer__checkbox__text: {
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginBottom: "16px",
    display: "block",
    "& a": {
      color: theme.palette.primary.default,
      textDecoration: "underline"
    }
  },
  footer__acceptance: {
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    "& a": {
      color: theme.palette.primary.default,
      textDecoration: "underline"
    }
  },
  footer__button: {
    marginTop: "32px"
  }
}));

const TermsConditionsModal = () => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  const [checked, setChecked] = React.useState(false);

  return (
    <NextModal
      open
      hideBack
      hideClose
      withFullWidth
      hideNavigation
      backdropClassname={classes.backdrop}
      wrapperClassname={classes.wrapper}
      modalClassname={classes.modal}
      contentClassname={classes.modal__content}
    >
      <div className={classes.content}>
        <div className={classes.content__unicorn}>
          <UnicornWaving width={77} height={64} />
        </div>
        <div data-testid="content_title" className={classes.content__title}>
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(locales.terms_and_condition_modal_title)}
          </Typography>
        </div>

        <p data-testid="content_description">
          <Typography
            variant={K.typographicVariants.body1}
            color={theme.palette.primary.default}
            className={classes.content__description}
          >
            {intl.formatMessage(locales.terms_and_condition_modal_description)}
          </Typography>
        </p>
      </div>

      <div className={classes.footer__checkbox}>
        <Checkbox onChange={setChecked} />
        <p data-testid="footer_checkbox">
          <Typography
            variant={K.typographicVariants.body2}
            color={theme.palette.primary.default}
            className={classes.footer__checkbox__text}
          >
            {intl.formatMessage(locales.terms_and_condition_modal_checkbox, {
              link: (
                <a href="/">
                  {intl.formatMessage(
                    locales.terms_and_condition_modal_privacy_policy_link
                  )}
                </a>
              )
            })}
          </Typography>
        </p>
      </div>

      <p data-testid="footer_acceptance">
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.primary.default}
          className={classes.footer__acceptance}
        >
          {intl.formatMessage(locales.terms_and_condition_modal_acceptance, {
            link: (
              <a href="/">
                {intl.formatMessage(
                  locales.terms_and_condition_modal_terms_condition_link
                )}
              </a>
            )
          })}
        </Typography>
      </p>

      <Button
        onClick={() => {}}
        className={classes.footer__button}
        fullWidth
        disabled={!checked}
      >
        {intl.formatMessage(locales.continue)}
      </Button>
    </NextModal>
  );
};

export default TermsConditionsModal;
