import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Articles = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 18 21">
      <path
        fill={color}
        d="M12.661 1.636c.592.697.868 1.665.864 2.908l-.008.346-.016.268.115.001a2.99 2.99 0 0 1 2.767 1.866l.084.229.066.238.044.238 1.393 9.863a2.991 2.991 0 0 1-2.346 3.341l-.223.04-.209.02-.183.006H2.99a2.992 2.992 0 0 1-2.982-2.789L0 17.985l.01-.21.019-.182L1.422 7.73a2.993 2.993 0 0 1 2.474-2.528l.246-.033.241-.01h.192l-.015-.256c-.047-1.154.13-2.094.554-2.823l.15-.235.153-.2c1.818-2.193 5.392-2.191 7.244-.01Zm-1.797 5.913H7.213l.197.708.14.46.116.076c.401.293.67.745.725 1.254l.01.193A1.794 1.794 0 1 1 5.3 9.011l.04-.041-.124-.428-.148-.552-.109-.441-.575.001a.61.61 0 0 0-.532.308l-.035.09-.026.116-1.393 9.864a.608.608 0 0 0 .378.65l.092.022.122.009 12.06-.002.043-.004a.61.61 0 0 0 .504-.445l.012-.108-.008-.122-1.393-9.864a.61.61 0 0 0-.379-.482l-.094-.023-.12-.009-.5-.001-.088.374-.088.341-.219.775.085.095c.201.257.332.568.37.903l.012.203a1.794 1.794 0 1 1-2.714-1.54l.079-.042.135-.455.177-.654Zm-3.75-4.54c-.276.332-.41.963-.362 1.882l.018.267h4.535l.02-.276c.046-.832-.055-1.422-.269-1.76l-.075-.103c-.974-1.147-2.923-1.15-3.868-.01Z"
      />
    </svg>
  );
};

Articles.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Articles.defaultProps = {
  width: 18,
  height: 21,
  color: theme.palette.tertiary.default
};

export default Articles;
