import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Gift = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 14 13">
    <path
      fill={color}
      d="M13.667 3H10.88c.073-.207.12-.434.12-.667a1.997 1.997 0 0 0-3.667-1.1L7 1.68l-.333-.454A2.008 2.008 0 0 0 5 .333c-1.107 0-2 .893-2 2 0 .233.047.46.12.667H.34v10h13.327V3ZM9 1.666c.367 0 .667.3.667.667C9.667 2.7 9.367 3 9 3a.669.669 0 0 1-.667-.667c0-.367.3-.667.667-.667Zm-4 0c.367 0 .667.3.667.667C5.667 2.7 5.367 3 5 3a.669.669 0 0 1-.667-.667c0-.367.3-.667.667-.667Zm7.333 10H1.667v-1.333h10.666v1.333Zm0-3.333H1.667v-4h3.386L3.667 6.22l1.08.78L7 3.933 9.253 7l1.08-.78-1.386-1.887h3.386v4Z"
    />
  </svg>
);

Gift.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Gift.defaultProps = {
  width: 14,
  height: 13,
  color: theme.palette.primary.default
};

export default Gift;
