import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const NavigationArrowRight = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path
      d="M9.29,6.71 C8.9,7.1 8.9,7.73 9.29,8.12 L13.17,12 L9.29,15.88 C8.9,16.27 8.9,16.9 9.29,17.29 C9.68,17.68 10.31,17.68 10.7,17.29 L15.29,12.7 C15.68,12.31 15.68,11.68 15.29,11.29 L10.7,6.7 C10.32,6.32 9.68,6.32 9.29,6.71 Z"
      fill={color}
    />
  </svg>
);

NavigationArrowRight.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

NavigationArrowRight.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default NavigationArrowRight;
