import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { createUseStyles } from "react-jss";
import TabsIndicator from "./TabsIndicator";

const useStyles = createUseStyles({
  tabs: {
    display: "flex",
    position: "relative"
  },
  tabs__children: {
    display: "flex",
    flex: 1
  }
});

const Tabs = ({ value, onChange, children: childrenProp, className }) => {
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [indicatorStyle, setIndicatorStyle] = React.useState({});
  const valueToIndex = new Map();
  const tabsRef = React.useRef(null);
  const childrenWrapperRef = React.useRef(null);

  const getTabsMeta = () => {
    const tabsNode = tabsRef.current;
    let tabsMeta;
    if (tabsNode) {
      const rect = tabsNode.getBoundingClientRect();

      tabsMeta = {
        scrollLeft: tabsNode.scrollLeft,
        left: rect.left
      };
    }

    let tabMeta;
    if (tabsNode) {
      const { children } = childrenWrapperRef.current;

      if (children.length > 0) {
        const tab = children[valueToIndex.get(value)];
        tabMeta = tab ? tab.getBoundingClientRect() : null;
      }
    }
    return { tabsMeta, tabMeta };
  };

  const updateIndicatorState = () => {
    const { tabsMeta, tabMeta } = getTabsMeta();
    let startValue = 0;

    if (tabMeta && tabsMeta) {
      const correction = tabsMeta.scrollLeft;
      startValue = tabMeta.left - tabsMeta.left + correction;
    }

    const newIndicatorStyle = {
      left: startValue,
      width: tabMeta ? tabMeta.width : 0
    };

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(indicatorStyle.left) || isNaN(indicatorStyle.width)) {
      setIndicatorStyle(newIndicatorStyle);
    } else {
      const dStart = Math.abs(indicatorStyle.left - newIndicatorStyle.left);
      const dSize = Math.abs(indicatorStyle.width - newIndicatorStyle.width);

      if (dStart >= 1 || dSize >= 1) {
        setIndicatorStyle(newIndicatorStyle);
      }
    }
  };

  React.useEffect(() => {
    updateIndicatorState();
  });

  React.useLayoutEffect(() => {
    setMounted(true);
  }, []);

  const children = React.Children.map(childrenProp, (child, index) => {
    if (!React.isValidElement(child)) return null;
    valueToIndex.set(index, index);
    const selected = index === value;

    return React.cloneElement(child, {
      active: selected,
      onClick: () => {
        onChange(index);
      }
    });
  });

  return (
    <div className={classNames(classes.tabs, className)} ref={tabsRef}>
      <div
        className={classes.tabs__children}
        role="tablist"
        ref={childrenWrapperRef}
      >
        {children}
      </div>
      {mounted && <TabsIndicator style={indicatorStyle} />}
    </div>
  );
};

Tabs.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Tabs.defaultProps = {
  className: ""
};

export default Tabs;
