import K from "../constants";
import fonts from "./fonts";

export const createTypographicVariant = (
  size = 0,
  weight = 0,
  tag = "",
  lineHeight
) => ({
  size,
  weight,
  tag,
  lineHeight
});

export default theme => ({
  [K.typographicVariants.heading1]: {
    style: createTypographicVariant(28, 600, "h1", 36),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.headings
  },
  [K.typographicVariants.heading2]: {
    style: createTypographicVariant(24, 600, "h2", 26),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.headings
  },
  [K.typographicVariants.heading3]: {
    style: createTypographicVariant(20, 600, "h3", 22),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.headings
  },
  [K.typographicVariants.heading4]: {
    style: createTypographicVariant(16, 700, "h4", 18),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.heading5]: {
    style: createTypographicVariant(14, 700, "h5", 18),
    defaultColor: theme.palette.secondary.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.body1]: {
    style: createTypographicVariant(16, 400, "span"),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.body2]: {
    style: createTypographicVariant(14, 400, "span", 20),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.body3]: {
    style: createTypographicVariant(12, 400, "span", 18),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.caption1]: {
    style: createTypographicVariant(13, 600, "span", 16),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.caption2]: {
    style: createTypographicVariant(11, 600, "span", 14),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.buttonS]: {
    style: createTypographicVariant(14, 600, "span", 18),
    defaultColor: theme.palette.success.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.buttonL]: {
    style: createTypographicVariant(16, 600, "span"),
    defaultColor: theme.palette.success.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.linkS]: {
    style: createTypographicVariant(14, 600, "span", 18),
    defaultColor: theme.palette.success.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.linkM]: {
    style: createTypographicVariant(14, 600, "span", 20),
    defaultColor: theme.palette.success.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.linkL]: {
    style: createTypographicVariant(16, 600, "span"),
    defaultColor: theme.palette.success.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.linkXL]: {
    style: createTypographicVariant(16, 600, "span", 24),
    defaultColor: theme.palette.success.default,
    defaultFont: fonts.primary
  },
  [K.typographicVariants.visual1]: {
    style: createTypographicVariant(40, 400, "span", 52),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.headings
  },
  [K.typographicVariants.visual2]: {
    style: createTypographicVariant(24, 400, "span", 32),
    defaultColor: theme.palette.primary.default,
    defaultFont: fonts.headings
  },
  [K.typographicVariants.visual3]: {
    style: createTypographicVariant(17, 400, "span", 24),
    defaultColor: theme.palette.success.default,
    defaultFont: fonts.headings
  },
  [K.typographicVariants.visual4]: {
    style: createTypographicVariant(18, 400, "span", 24),
    defaultColor: theme.palette.success.default,
    defaultFont: fonts.headings
  }
});
