import React from "react";
import PropTypes from "prop-types";
import { List } from "../../atoms";
import Article from "../Article";

const ArticleList = ({ articles }) => (
  <List>
    {articles.map(
      (article, index) /* eslint-disable react/no-array-index-key */ => (
        <Article key={`${article.reference}-${index}`} article={article} />
      )
    )}
  </List>
);

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape())
};

ArticleList.defaultProps = {
  articles: []
};

export default ArticleList;
