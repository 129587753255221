import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import useTrackingContext from "../../../../../../contexts/Tracking";
import Layout from "../../Layout";
import { Button, Input } from "../../../../../../atoms";
import locales from "../../../../../../locales";

const Name = ({
  currentStepForm,
  inputValue,
  onChangeInputValue,
  onSubmitStep,
  onClickPrevStep
}) => {
  const intl = useIntl();
  const { track, eventNames } = useTrackingContext();
  useEffect(() => {
    track(eventNames.signUp.stepForm.name.start);
  }, []);
  return (
    <Layout
      title={intl.formatMessage(locales.sign_up_step_form_name_title)}
      subTitle={intl.formatMessage(locales.sign_up_step_form_name_subtitle)}
      currentStep={currentStepForm}
      onSubmitStep={onSubmitStep}
      onClickPrevStep={onClickPrevStep}
    >
      <div>
        <Input
          label={intl.formatMessage(locales.sign_up_step_form_name_label_input)}
          name="name"
          inputRef={React.createRef()}
          focus
          onChange={inputData => {
            onChangeInputValue(inputData);
          }}
          value={inputValue}
        />
      </div>
      <div>
        <Button type="submit" fullWidth size="large" disabled={!inputValue}>
          {intl.formatMessage(locales.general_navigation_next)}
        </Button>
      </div>
    </Layout>
  );
};

Name.propTypes = {
  onSubmitStep: PropTypes.func.isRequired,
  onClickPrevStep: PropTypes.func.isRequired,
  currentStepForm: PropTypes.number.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChangeInputValue: PropTypes.func.isRequired
};

export default Name;
