import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import locales from "../../../../../locales";
import K from "../../../../../constants";
import { Typography, Button } from "../../../../../atoms";
import { Shopper } from "../../../../../atoms/Illustrations";

const useStyles = createUseStyles(theme => ({
  merchants_banner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px 24px",
    marginBottom: "48px",
    borderRadius: "8px",
    backgroundColor: theme.palette.white.default,
    boxShadow: "0px 4px 8px rgba(73, 73, 73, 0.08)",
    "& > *": {
      marginBottom: "16px"
    },
    "& button": {
      width: "248px",
      margin: "22px auto 16px",
      boxShadow:
        "0 2px 4px 0 rgba(44, 39, 56, 0.08), 0 1px 2px 0 rgba(44, 39, 56, 0.08)"
    },
    "&  p": {
      marginTop: "8px"
    },
    "@media(min-width: 768px)": {
      flexDirection: "row"
    }
  },
  merchants_banner__content: {
    display: "flex",
    flexDirection: "column",
    "@media(min-width: 768px)": {
      flexDirection: "row",
      alignItems: "center"
    },
    "& > div ": {
      marginTop: "16px",
      "@media(min-width: 768px)": {
        marginTop: 0
      }
    }
  }
}));

const MerchantsBanner = ({ onClickButton }) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  return (
    <div className={classes.merchants_banner}>
      <div className={classes.merchants_banner__content}>
        <Shopper />
        <div>
          <Typography
            variant={K.typographicVariants.heading2}
            color={theme.palette.success.default}
            className={classes.merchants_title}
          >
            {intl.formatMessage(
              locales.dashboard_without_orders_merchants_banner_title
            )}
          </Typography>

          <p>
            <Typography variant={K.typographicVariants.body1}>
              {intl.formatMessage(
                locales.dashboard_without_orders_merchants_banner_text
              )}
            </Typography>
          </p>
        </div>
      </div>

      <div>
        <Button size="small" variant="outlined" onClick={onClickButton}>
          {intl.formatMessage(
            locales.dashboard_without_orders_merchants_banner_action
          )}
        </Button>
      </div>
    </div>
  );
};

MerchantsBanner.propTypes = {
  onClickButton: PropTypes.func.isRequired
};

export default MerchantsBanner;
