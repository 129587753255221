import { useEffect, useState } from "react";
import useGeneratePaymentPlanAmortizationPaymentGateway from "../mutations/useGeneratePaymentPlanAmortizationPaymentGateway";

export default orderId => {
  const [iframeUrl, setIframeUrl] = useState(null);

  const [
    generatePaymentPlanAmortizationPaymentGateway,
    { loading, error }
  ] = useGeneratePaymentPlanAmortizationPaymentGateway();

  const getIframeUrl = async variables => {
    const response = await generatePaymentPlanAmortizationPaymentGateway({
      variables
    });
    if (response.data === null) return;
    const {
      generatePaymentPlanAmortizationPaymentGatewayRequest: { iframeUrl: url }
    } = response.data;
    setIframeUrl(url);
  };

  useEffect(() => {
    getIframeUrl({ orderId });
  }, []);

  return {
    iframeUrl,
    loading,
    error
  };
};
