import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Menu = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 32 32">
    <path
      d="M7,24 L25.6666667,24 C26.4030463,24 27,23.4030463 27,22.6666667 C27,21.930287 26.4030463,21.3333333 25.6666667,21.3333333 L7,21.3333333 C6.26362033,21.3333333 5.66666667,21.930287 5.66666667,22.6666667 C5.66666667,23.4030463 6.26362033,24 7,24 Z M4.33333333,17.3333333 L23,17.3333333 C23.7363797,17.3333333 24.3333333,16.7363797 24.3333333,16 C24.3333333,15.2636203 23.7363797,14.6666667 23,14.6666667 L4.33333333,14.6666667 C3.59695367,14.6666667 3,15.2636203 3,16 C3,16.7363797 3.59695367,17.3333333 4.33333333,17.3333333 Z M11,9.33333333 C11,10.069713 11.5969537,10.6666667 12.3333333,10.6666667 L25.6666667,10.6666667 C26.4030463,10.6666667 27,10.069713 27,9.33333333 C27,8.59695367 26.4030463,8 25.6666667,8 L12.3333333,8 C11.5969537,8 11,8.59695367 11,9.33333333 Z"
      fill={color}
    />
  </svg>
);

Menu.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Menu.defaultProps = {
  width: 32,
  height: 32,
  color: theme.palette.secondary.default
};

export default Menu;
