import React from "react";
import queryString from "query-string";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useOrder } from "../../../../hooks";
import { CardBody, CardFooter, Typography } from "../../../../atoms";
import { Flag } from "../../../../atoms/Icons";
import locales from "../../../../locales";
import K from "../../../../constants";
import isProductEqualToCurrentProduct from "../../../../utils/isProductEqualToCurrentProduct";

const useStyles = createUseStyles({
  amortization_option: {
    height: "56px",
    boxSizing: "border-box",
    alignItems: "center",
    cursor: "pointer",
    "& > p": {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        marginRight: "16px"
      }
    }
  },
  amortization_option__container: {
    display: "flex",
    alignItems: "center"
  },
  amortization_option__container__text: {
    marginLeft: "16px"
  }
});

const AmortizationOption = () => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const { currentProductName } = useOrder();
  const isPP5 = isProductEqualToCurrentProduct(
    K.products.pp5,
    currentProductName
  );

  return (
    <>
      <CardBody>
        <Typography variant={K.typographicVariants.heading4}>
          {intl.formatMessage(locales.more_options)}
        </Typography>
      </CardBody>
      <CardFooter
        onClick={() => {
          const search = queryString.stringifyUrl({
            url: location.pathname,
            query: {
              process: K.process.amortization,
              step: K.steps.summary
            }
          });
          history.push(search);
        }}
        className={classes.amortization_option}
      >
        <div className={classes.amortization_option__container}>
          <Flag width={32} height={32} color={theme.palette.success.default} />
          <Typography className={classes.amortization_option__container__text}>
            {intl.formatMessage(
              isPP5
                ? locales.amortization_flow_advance_payment
                : locales.amortization_flow_amortize_plan
            )}
          </Typography>
        </div>
      </CardFooter>
    </>
  );
};

export default AmortizationOption;
