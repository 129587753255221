import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const NavigationArrowBack = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path
      d="M14.71 6.71a.996.996 0 0 0-1.41 0L8.71 11.3a.996.996 0 0 0 0 1.41l4.59 4.59a.996.996 0 1 0 1.41-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41z"
      fill={color}
    />
  </svg>
);

NavigationArrowBack.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

NavigationArrowBack.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default NavigationArrowBack;
