import React, { createContext, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

const CookiesConsentContext = createContext();

export const CookiesConsentContextProvider = ({ children }) => {
  const [cookiesAllowed, setCookiesAllowed] = React.useState(
    Cookies.get("cookies_consent") === "allow"
  );

  const [cookiesConsentAnswered, setCookiesConsentAnswered] = React.useState(
    Cookies.get("cookies_consent") !== undefined
  );

  const [
    showCookiesConfiguration,
    setShowCookiesConfiguration
  ] = React.useState(false);

  const setCookiesConsent = useCallback((value, expires = 365) => {
    Cookies.set("cookies_consent", value ? "allow" : "deny", {
      expires
    });
    setCookiesAllowed(value);
    setCookiesConsentAnswered(true);
    setShowCookiesConfiguration(false);
  });

  const contextValue = {
    setCookiesConsent,
    setCookiesAllowed,
    cookiesAllowed,
    cookiesConsentAnswered,
    setShowCookiesConfiguration,
    showCookiesConfiguration
  };

  return (
    <CookiesConsentContext.Provider value={contextValue}>
      {children}
    </CookiesConsentContext.Provider>
  );
};

CookiesConsentContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(CookiesConsentContext);
