import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import locales from "../../locales";
import { Button, NextModal, Typography } from "../../atoms";
import { Peace } from "../../atoms/Icons";
import K from "../../constants";
import { useLocaleCurrency } from "../../hooks";

const useStyles = createUseStyles({
  modal: {
    padding: "1.34rem",
    "@media(min-width: 768px)": {
      padding: "0 3rem 3rem 3rem"
    }
  },
  modal__title: {
    marginBottom: "2.5rem",
    marginTop: "1.125rem"
  },
  modal__sections: {
    marginBottom: "2.5rem",
    "& span": {
      display: "block",
      marginBottom: "1rem"
    },
    "&:last-of-type": {
      marginBottom: 0
    }
  },
  modal__sectionsTitle: {
    marginBottom: "1rem"
  },
  modal__button: {
    marginTop: "3.3rem"
  },
  modal__list: {
    listStyleType: "decimal",
    fontSize: "0.875rem",
    paddingLeft: "1rem",
    marginBottom: "1rem",
    lineHeight: "1.8rem"
  }
});

const ReferralProgramFAQsModal = ({ onClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  return (
    <NextModal
      open
      hideBack
      onClose={onClose}
      withIconClose
      modalClassname={classes.modal_wrapper}
      withFullWidth
    >
      <div className={classes.modal} data-testid="referral-program-faqs-modal">
        <Peace />
        <Typography
          variant={K.typographicVariants.heading1}
          color={theme.palette.primary.default}
          className={classes.modal__title}
        >
          {intl.formatMessage(locales.referral_program_faqs)}
        </Typography>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_1)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(locales.referral_program_faqs_term_paragraph_1)}
          </Typography>
        </div>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_2)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_2_1
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_2_2,
              {
                amount: useLocaleCurrency("50")
              }
            )}
          </Typography>
        </div>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_3)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_3_1,
              {
                amount1: useLocaleCurrency("20"),
                amount2: useLocaleCurrency("10")
              }
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_3_2,
              {
                amount1: useLocaleCurrency("60"),
                amount2: useLocaleCurrency("2.95"),
                amount3: useLocaleCurrency("50"),
                days: "45"
              }
            )}
          </Typography>
        </div>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_4)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(locales.referral_program_faqs_term_paragraph_4)}
          </Typography>
        </div>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_5)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(locales.referral_program_faqs_term_paragraph_5)}
          </Typography>
        </div>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_6)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_6_1
            )}
          </Typography>
          <ul className={classes.modal__list}>
            <li>
              {intl.formatMessage(locales.referral_program_faqs_term_list_6_1)}
            </li>
            <li>
              {intl.formatMessage(locales.referral_program_faqs_term_list_6_2)}
            </li>
            <li>
              {intl.formatMessage(locales.referral_program_faqs_term_list_6_3)}
            </li>
          </ul>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_6_2
            )}
          </Typography>
        </div>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_7)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(locales.referral_program_faqs_term_paragraph_7)}
          </Typography>
        </div>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_8)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_8_1,
              {
                amount: useLocaleCurrency("50")
              }
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_8_2
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_8_3,
              {
                days: "15"
              }
            )}
          </Typography>
        </div>

        <div className={classes.modal__sections}>
          <Typography
            className={classes.modal__sectionsTitle}
            variant={K.typographicVariants.heading5}
          >
            {intl.formatMessage(locales.referral_program_faqs_term_title_9, {
              amount: useLocaleCurrency("10")
            })}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_9_1,
              {
                amount: useLocaleCurrency("10"),
                days: "15"
              }
            )}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(
              locales.referral_program_faqs_term_paragraph_9_2,
              {
                amount: useLocaleCurrency("50")
              }
            )}
          </Typography>
        </div>

        <Button
          fullWidth
          size="medium"
          onClick={onClose}
          className={classes.modal__button}
        >
          {intl.formatMessage(locales.referral_program_faqs_modal_close)}
        </Button>
      </div>
    </NextModal>
  );
};

ReferralProgramFAQsModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ReferralProgramFAQsModal;
