import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { hotjar } from "react-hotjar";
import { eventProperties, eventPropertyCategories } from "./constants";
import { centsToBasicUnit, getDocumentType } from "../../utils";

const eventNames = {
  tokenization: {
    start: "Start payment card tokenization process",
    success: "Successfully added new shopper card",
    fail: "Unsuccessfully added new shopper card"
  },
  assignCard: {
    start: "Start payment card order assign process",
    success: "Successfully added new order card",
    fail: "Unsuccessfully added new order card"
  },
  payment: {
    start: "Start payment process",
    success: "Successfully paid order amount",
    fail: "Unsuccessfully paid order amount"
  },
  conversion: {
    start: "Start conversion process",
    success: "Successfully converted order",
    fail: "Unsuccessfully converted order"
  },
  flexiConversion: {
    clickAction: "Click on flexi Conversion Button",
    selectPlan: "Loaded select plan step",
    confirmation: "Loaded confirmation step",
    success: "Successfully flexi converted order",
    fail: "Unsuccessfully flexi converted order",
    fromEmail: "flexi_conversion_modal_viewed_from_email"
  },
  amortization: {
    start: "Start amortization process",
    success: "Successfully amortized order",
    fail: "Unsuccessfully amortized order"
  },
  returnRequest: {
    start: "Start return request process",
    success: "Successfully return requested order",
    fail: "Unsuccessfully return requested order"
  },
  orderDetail: {
    clickMerchantLogo: "Click on merchant's logo",
    clickInRecoveryTelephone:
      "Click on DC telephone in orderDetail when order is in recovery"
  },
  dashboard: {
    dashboardViewed: "Dashboard page viewed",
    clickDashboardNavBarIcon: "Click on Dashboard's Navigation bar icon",
    clickSeeAllShops: "Click on See all shops button",
    clickMerchantCard: "Click on merchant card",
    clickMerchantLocation: "Click on merchant location",
    clickMerchantPromotion: "Click on merchant promotion",
    clickMerchantPromotionDenied:
      "Click on merchant promotions denied button in GDPR modal",
    clickMerchantPromotionAllowed:
      "Click on merchant promotions allowed button in GDPR modal",
    clickTelephoneMerchantPromotionInRecovery:
      "Click on DC telephone when order is in recovery from GDPR modal in Dashboard",
    dashboardForShoppersWithoutOrders:
      "Dashboard for shoppers without others viewed",
    sectors: {
      fashion: "Click on fashion sector",
      sports: "Click on sports sector",
      home: "Click on home sector",
      babyandkids: "Click on baby&kids sector",
      vehicles: "Click on vehicles sector",
      musicandsound: "Click on music&sound sector",
      electronics: "Click on electronics sector",
      hobby: "Click on hobby sector",
      healthandbeauty: "Click on health&beauty sector",
      pets: "Click on pets sector",
      optics: "Click on optics sector",
      handiwork: "Click on handiwork sector",
      bazaar: "Click on bazaar sector",
      foodanddrinks: "Click on food&drinks sector",
      jewelry: "Click on jewelry sector"
    },
    seeAllMerchantsBySector: "Click on see all merchants filtered by sector",
    seeAllStores: "Click on see all Stores"
  },
  referralProgram: {
    userVisitsPage: "User visited the referral program page",
    userClicksSidebarLink:
      "User clicked in the referral's program link in the sidebar menu",
    userClicksNavBarInviteIcon:
      "referral_program_navbar_invite_icon_clicked_from_mobile",
    shareInvitationFromWebsite:
      "User clicked the 'send invitation to SeQura' button in the website",
    shareInvitationFromWebsiteByCopyingLink:
      "User decided to send invitation to seQura by copying link",
    shareInvitationFromWebsiteBySendingEmail:
      "User decided to send invitation to seQura by sending email",
    shareInvitationFromWebsiteByWhatsappWeb:
      "User decided to send invitation to seQura by whatsapp web",
    shareInvitationFromMobile:
      "User shared an invitation to SeQura from a mobile device, natively with shareAPI",
    shareInvitationFromMobileFailed:
      "User clicked the 'send invitation to SeQura' button from a mobile device, but didn't send it",
    showHowToUseVoucherModalFromReferralButton:
      "Show 'how to use the voucher' modal from referral button",
    showWhereToUseVoucherModalFromReferralButton:
      "Show 'where to use the voucher' modal from referral button",
    showTermsAndConditionsModalFromReferralButton:
      "Show referral's terms and conditions modal from referral button",
    showTermsAndConditionsModalFromLinkReferralPage:
      "Show referral's terms and conditions modal from link in referral page",
    openTermsAndConditionsExternalLinkFromModal:
      "Open referral's terms and conditions external page from the link in the terms modal's",
    openTermsAndConditionsExternalLinkFromLandingPage:
      "Open referral's terms and conditions external page from the link in referral landing page",
    referralLandingPageClickFAQLink:
      "Click FAQ link and open its external page from referral landing page",
    referralLandingPageClickLegalTerms:
      "Click Legal terms link and open its external page from referral landing page"
  },
  updatedCard: {
    start: {
      withSuggestion: "Card updated, with suggestion to update others cards",
      withoutSuggestion: "Card updated without available suggestions"
    },
    success: "Successfully updated card suggested"
  },
  verifyCard: {
    start: "Start PSD2 card verification",
    mufasaFormDisplayed: "Mufasa form has been rendered",
    attempt: {
      withCardFingerprint: "Attempt to verify a card with internal token",
      withoutCardFingerprint: "Attempt to verify a card with external token"
    },
    success: "Successfully verified card",
    fail: "Unsuccessfully verified card",
    retry: "Retry card verification",
    newCard: "Try new card"
  },
  signUp: {
    landing: {
      start: "SignUp landing page visited",
      clickedSection1Intro:
        "SignUp landing page - User clicked section 1: intro",
      clickedSection2Brands:
        "SignUp landing page - User clicked section 2: brands",
      clickedSection3Instructions:
        "SignUp landing page - User clicked section 3: instructions",
      clickedSection4Description:
        "SignUp landing page - User clicked section 4: description"
    },
    stepForm: {
      name: {
        start: "SignUp StepForm name step visited"
      },
      nin: {
        start: "SignUp StepForm nin visited"
      },
      email: {
        start: "SignUp StepForm email visited"
      },
      phone: {
        start: "SignUp StepForm phone visited"
      },
      otp: {
        start: "SignUp StepForm otp visited"
      },
      accountExisted: {
        start: "SignUp account-existed visited"
      }
    }
  },
  mandatoryDocumentation: {
    banner: {
      uploadButtonClicked: "Upload mandatory documentation button clicked",
      viewed: "Mandatory documentation banner viewed"
    }
  },
  generalVouchers: {
    bannerDiscountViewed:
      "general_vouchers_dashboard_page_discount_banner_viewed",
    howToUseItModal: {
      viewed: "general_vouchers_how_to_use_it_modal_viewed",
      clickedGoToShopsButton:
        "general_vouchers_how_to_use_it_modal_go_to_shops_button_clicked"
    }
  }
};

// Not camelcased event name aliases
eventNames["assign-card"] = eventNames.assignCard;
eventNames["return-request"] = eventNames.returnRequest;
eventNames["order-detail"] = eventNames.orderDetail;
eventNames["verify-card"] = eventNames.verifyCard;

const initTrackers = () => {
  const isDevelopment = process.env.NODE_ENV === "development";

  mixpanel.init(window.env.mixPanel.apiKey, {
    track_pageview: false,
    debug: isDevelopment,
    verbose: isDevelopment,
    loaded: () => {
      window.trackingIsInitialized = true;
    }
  });

  ReactGA.initialize(
    [
      {
        trackingId: window.env.ga.trackingID
      },
      {
        trackingId: window.env.ga.trackingGA4ID
      }
    ],
    {
      debug: isDevelopment,
      testMode: isDevelopment,
      alwaysSendToDefaultTracker: false
    }
  );

  hotjar.initialize(window.env.hotjar.siteID, window.env.hotjar.snippetVersion);
};

const reset = () => {
  mixpanel.reset();
};

const track = (action, payload = {}) => {
  const isDevelopment = process.env.NODE_ENV === "development";

  let trackingInitAttempts = 0;
  const mixpanelTracking = () => {
    if (trackingInitAttempts > 20) {
      return;
    }

    if (!window.trackingIsInitialized) {
      setTimeout(() => {
        trackingInitAttempts += 1;
        mixpanelTracking();
      }, 500);
    } else {
      mixpanel.track(action, payload);
    }
  };

  try {
    mixpanelTracking();
  } catch (error) {
    if (isDevelopment) {
      console.warn("Mixpanel track failed");
    }
  }

  try {
    hotjar.event(action);
  } catch (error) {
    if (isDevelopment) {
      console.warn("Hotjar track failed");
    }
  }
};

const trackWithThirdPartyMetricTools = (action, payload = {}) => {
  track(action, payload);
};

const setMixpanelShopperProps = shopperProps => {
  const isDevelopment = process.env.NODE_ENV === "development";

  try {
    mixpanel.register(shopperProps);
  } catch (error) {
    if (isDevelopment) {
      console.warn("Mixpanel register failed");
    }
  }
};

const buildShopperTrackingData = shopper => {
  const payload = {
    id: shopper.id,
    name: shopper.givenNames,
    "Document Type": getDocumentType(shopper.nin),
    "Created At": shopper.createdAt,
    "Date of birth": shopper.dateOfBirth,
    email: shopper.emails?.find(x => x.isValidated)?.email
  };

  shopper.segmentation.forEach(({ key, value }) => {
    payload[key] = value;
  });

  return payload;
};

const buildOrderTrackingPayload = (
  order,
  includeValue = false,
  reusingCard = null
) => {
  const payload = {
    [eventProperties.CURRENT_PRODUCT_NAME]: order?.currentProductName,
    [eventProperties.MERCHANT_NAME]: order?.merchant?.name,
    [eventProperties.CATEGORY]: eventPropertyCategories.ORDER
  };
  if (includeValue) {
    payload[eventProperties.VALUE] = centsToBasicUnit(order?.currentOrderValue);
  }
  if (reusingCard !== null) {
    payload[eventProperties.REUSING_CARD] = reusingCard;
  }
  return payload;
};

const TrackingContext = createContext();

export const TrackingContextProvider = ({ children }) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    try {
      ReactGA.send({ hitType: "pageview", page: `${pathname}${search}` });
    } catch (error) {
      console.warn("ReactGA pageview failed");
    }
  }, [pathname]);

  const contextValue = {
    track,
    trackWithThirdPartyMetricTools,
    initTrackers,
    reset,
    buildShopperTrackingData,
    buildOrderTrackingPayload,
    setMixpanelShopperProps,
    eventProperties,
    eventPropertyCategories,
    eventNames
  };

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  );
};

TrackingContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(TrackingContext);
