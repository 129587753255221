import React from "react";
import queryString from "query-string";
import { useTheme, createUseStyles } from "react-jss";
import { useMedia } from "react-use";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useOrder } from "../../../../hooks";
import { NextModal, Typography, Button } from "../../../../atoms";
import {
  ModalOrderInfo,
  ConversionPlanSelectorList,
  ConversionPlanInfo
} from "../../components";
import locales from "../../../../locales";
import K from "../../../../constants";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../../utils";

const useStyles = createUseStyles({
  modal: {
    "@media(min-width: 768px)": {
      height: "715px"
    }
  },
  conversion_plan_modal__title: {
    marginTop: "40px",
    marginBottom: "32px"
  }
});

const ConversionSelectPlanModal = () => {
  const order = useOrder();
  const theme = useTheme();
  const classes = useStyles();
  const isWideScreen = useMedia("(min-width: 768px)");
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const [conversionPlan, setConversionPlan] = React.useState(null);

  React.useEffect(() => {
    setConversionPlan(order.conversionPlans.find(plan => plan.default));
  }, []);

  const onNext = () => {
    const search = queryString.stringify({
      ...queryString.parse(location.search),
      step: K.steps.selectCard,
      instalment_count: conversionPlan.instalmentCount
    });
    history.push(`${location.pathname}?${search}`);
  };

  return (
    <NextModal open modalClassname={classes.modal} hideBack>
      <ModalOrderInfo
        merchantLogoURL={order.merchant.logo}
        merchantName={order.merchant.name}
        orderConfirmedAt={order.confirmedAt}
        orderNumberOfArticles={getTotalCountFromItemsList(
          getArticlesListFromCart(order.cart)
        )}
      />
      <Typography
        variant={
          isWideScreen
            ? K.typographicVariants.heading1
            : K.typographicVariants.heading2
        }
        color={theme.palette.primary.default}
        className={classes.conversion_plan_modal__title}
      >
        {intl.formatMessage(locales.choose_your_plan_payment)}
      </Typography>
      <ConversionPlanSelectorList
        conversionPlans={order.conversionPlans}
        selectedPlan={conversionPlan}
        onSelectPlan={setConversionPlan}
      />
      <ConversionPlanInfo conversionPlan={conversionPlan} />
      <Button variant="outlined" fullWidth size="large" onClick={onNext}>
        {intl.formatMessage(locales.general_navigation_next)}
      </Button>
    </NextModal>
  );
};

export default ConversionSelectPlanModal;
