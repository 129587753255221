import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const StatusCross = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1544_4113"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="60"
      height="60"
    >
      <path
        d="M30 0C46.5416 0 60 13.4584 60 30C60 46.5416 46.5416 60 30 60C13.4584 60 0 46.5416 0 30C0 13.4584 13.4584 0 30 0ZM30 2.99755C15.1101 2.99755 2.99755 15.1101 2.99755 30C2.99755 44.8899 15.1101 57.0024 30 57.0024C44.8899 57.0024 57.0024 44.8899 57.0024 30C57.0024 15.1101 44.8899 2.99755 30 2.99755ZM35.8238 22.0595C36.4111 21.4723 37.3532 21.4723 37.9405 22.0595C38.5277 22.6468 38.5277 23.5889 37.9405 24.1762L32.1166 30L37.9649 35.8238C38.5522 36.4111 38.5522 37.3532 37.9649 37.9405C37.6713 38.2341 37.292 38.3809 36.9005 38.3809C36.509 38.3809 36.1297 38.2341 35.8361 37.9405L30.0122 32.1166L24.1884 37.9405C23.8948 38.2341 23.5155 38.3809 23.124 38.3809C22.7325 38.3809 22.3532 38.2341 22.0595 37.9405C21.4723 37.3532 21.4723 36.4111 22.0595 35.8238L27.8834 30L22.0595 24.1762C21.4723 23.5889 21.4723 22.6468 22.0595 22.0595C22.6468 21.4723 23.5889 21.4723 24.1762 22.0595L30 27.8834L35.8238 22.0595Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask0_1544_4113)">
      <path
        d="M30 0C46.5416 0 60 13.4584 60 30C60 46.5416 46.5416 60 30 60C13.4584 60 0 46.5416 0 30C0 13.4584 13.4584 0 30 0ZM30 2.99755C15.1101 2.99755 2.99755 15.1101 2.99755 30C2.99755 44.8899 15.1101 57.0024 30 57.0024C44.8899 57.0024 57.0024 44.8899 57.0024 30C57.0024 15.1101 44.8899 2.99755 30 2.99755ZM35.8238 22.0595C36.4111 21.4723 37.3532 21.4723 37.9405 22.0595C38.5277 22.6468 38.5277 23.5889 37.9405 24.1762L32.1166 30L37.9649 35.8238C38.5522 36.4111 38.5522 37.3532 37.9649 37.9405C37.6713 38.2341 37.292 38.3809 36.9005 38.3809C36.509 38.3809 36.1297 38.2341 35.8361 37.9405L30.0122 32.1166L24.1884 37.9405C23.8948 38.2341 23.5155 38.3809 23.124 38.3809C22.7325 38.3809 22.3532 38.2341 22.0595 37.9405C21.4723 37.3532 21.4723 36.4111 22.0595 35.8238L27.8834 30L22.0595 24.1762C21.4723 23.5889 21.4723 22.6468 22.0595 22.0595C22.6468 21.4723 23.5889 21.4723 24.1762 22.0595L30 27.8834L35.8238 22.0595Z"
        fill="#1C1C1C"
      />
    </g>
  </svg>
);

StatusCross.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

StatusCross.defaultProps = {
  width: 60,
  height: 60,
  color: theme.palette.primary.default
};

export default StatusCross;
