import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import useTracking from "../../contexts/Tracking";
import useShopperContext from "../../contexts/Shopper";
import {
  useNextShopperAction,
  useMerchantsPromotions,
  useRecentMerchants,
  useVouchers
} from "./hooks";
import { useLocaleCurrency } from "../../hooks";
import { PageTemplate } from "../../templates";
import { REFERRAL_PROGRAM_PATH } from "../../routes";
import {
  RecentMerchantsSection,
  PromotedMerchantsSectionContainer,
  NextActionAndMessageSection
} from "./components/ForShoppersWithOrders";
import { centsToBasicUnit } from "../../utils";
import { Banner, BannerDiscount } from "../../atoms";
import { HowToUseDiscounts } from "../../molecules";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  dashboard: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  dashboard__recent_merchants_card: {
    display: "none",
    [`${theme.breakpoints.dashboard}`]: {
      display: "flex"
    }
  },
  referral_program__voucher_balance_banner: {
    marginTop: "15px",
    paddingBottom: "12px",
    width: "100%",
    height: "176px",
    "@media (min-width: 768px)": {
      marginTop: "0",
      width: "240px",
      height: "251px"
    }
  }
}));

export const DashboardPage = ({
  nextShopperAction: {
    nextActionCardProps,
    messageProps,
    onClickNextActionCard,
    onClickMessage,
    fetching: nextShopperActionFetching
  },
  recentMerchants: { recentMerchants, fetching: recentMerchantsFetching },
  merchantsPromotions: {
    merchantsPromotions,
    fetching: merchantsPromotionsFetching
  },
  vouchers: { vouchers, fetching: vouchersFetching, generalVouchers }
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { track, eventNames } = useTracking();
  const { shopper } = useShopperContext();
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const promotedMerchants = merchantsPromotions?.promotedMerchants;
  const promotionsStatus = merchantsPromotions?.promotionsAllowed;

  const dashboardWithPromotions =
    promotedMerchants?.filter(merchants => merchants.promotion)?.length > 0;

  const renderAdditionalComponentWithShopperInfo = () => {
    let componentToRender;
    if (vouchers?.balance > 0) {
      componentToRender = (
        <Banner
          className={classes.referral_program__voucher_balance_banner}
          title={intl.formatMessage(locales.referral_program_voucher_balance)}
          balance={
            vouchersFetching
              ? ""
              : useLocaleCurrency(vouchers.balance, { useCents: true })
          }
          linkUrl={
            shopper.referralProgram.enabledToBeHost ? REFERRAL_PROGRAM_PATH : ""
          }
          linkText={intl.formatMessage(
            locales.dashboard_referral_program_banner_link
          )}
        />
      );
    } else if (generalVouchers?.visible) {
      componentToRender = (
        <BannerDiscount
          balance={intl.formatNumber(
            centsToBasicUnit(generalVouchers?.balance),
            {
              style: "currency",
              currency: "eur"
            }
          )}
          onClickButton={() =>
            history.push(
              `${location.pathname}?process=${K.process.howToUseDiscounts}`
            )
          }
        />
      );
    } else {
      componentToRender = (
        <RecentMerchantsSection
          className={classes.dashboard__recent_merchants_card}
          merchants={recentMerchants}
          fetching={recentMerchantsFetching}
        />
      );
    }
    return componentToRender;
  };

  React.useEffect(() => {
    if (promotedMerchants?.length > 0) {
      track(eventNames.dashboard.dashboardViewed, {
        includePromotions: dashboardWithPromotions,
        promotionsStatus,
        merchantsPromotions: promotedMerchants?.map(
          merchant => merchant?.reference
        )
      });
    }
  }, [promotedMerchants]);

  return (
    <PageTemplate
      title={intl.formatMessage(locales.general_page_title_dashboard)}
      loader={
        nextShopperActionFetching ||
        recentMerchantsFetching ||
        merchantsPromotionsFetching
      }
    >
      <div className={classes.dashboard}>
        {query.process === K.process.howToUseDiscounts && (
          <HowToUseDiscounts
            onClose={() => history.replace(location.pathname)}
          />
        )}

        <NextActionAndMessageSection
          messageProps={messageProps}
          nextActionCardProps={nextActionCardProps}
          onClickNextActionCard={onClickNextActionCard}
          onClickMessage={onClickMessage}
          fetching={nextShopperActionFetching}
          dashboard_referral_program_banner_link
        />
        {vouchersFetching ? null : renderAdditionalComponentWithShopperInfo()}
        {!merchantsPromotionsFetching && (
          <PromotedMerchantsSectionContainer
            merchantsPromotions={merchantsPromotions}
          />
        )}
      </div>
    </PageTemplate>
  );
};

DashboardPage.propTypes = {
  nextShopperAction: PropTypes.shape({
    nextActionCardProps: PropTypes.shape({
      title: PropTypes.string,
      withoutNextAction: PropTypes.bool,
      amountColor: PropTypes.string,
      nextPaymentAmountStr: PropTypes.number,
      nextPaymentDateStr: PropTypes.string,
      orderMerchantName: PropTypes.string,
      orderDateStr: PropTypes.string,
      orderItemsTotal: PropTypes.number
    }),
    messageProps: PropTypes.shape({
      message: PropTypes.string,
      cardColor: PropTypes.string,
      textColor: PropTypes.string,
      buttonText: PropTypes.string
    }),
    onClickNextActionCard: PropTypes.func,
    onClickMessage: PropTypes.string,
    fetching: PropTypes.bool
  }),
  recentMerchants: PropTypes.shape({
    recentMerchants: PropTypes.arrayOf(PropTypes.object),
    fetching: PropTypes.bool
  }),
  merchantsPromotions: PropTypes.shape({
    merchantsPromotions: PropTypes.shape(),
    fetching: PropTypes.bool
  }),
  vouchers: PropTypes.shape({
    vouchers: PropTypes.shape({
      balance: PropTypes.number,
      visible: PropTypes.bool
    }),
    fetching: PropTypes.bool,
    generalVouchers: PropTypes.shape({
      balance: PropTypes.number,
      visible: PropTypes.bool
    })
  })
};

DashboardPage.defaultProps = {
  nextShopperAction: {
    nextActionCardProps: {},
    messageProps: {},
    onClickNextActionCard: undefined,
    onClickMessage: undefined,
    fetching: true
  },
  recentMerchants: { recentMerchants: [], fetching: true },
  merchantsPromotions: { promotedMerchants: {}, fetching: true },
  vouchers: {}
};

const Dashboard = () => {
  const {
    nextActionCardProps,
    messageProps,
    onClickNextActionCard,
    onClickMessage,
    fetching: nextShopperActionFetching
  } = useNextShopperAction();
  const {
    recentMerchants,
    fetching: recentMerchantsFetching
  } = useRecentMerchants();
  const {
    merchantsPromotions,
    fetching: merchantsPromotionsFetching
  } = useMerchantsPromotions();
  const {
    vouchers,
    fetching: vouchersFetching,
    generalVouchers
  } = useVouchers();

  return (
    <DashboardPage
      nextShopperAction={{
        nextActionCardProps,
        messageProps,
        onClickNextActionCard,
        onClickMessage,
        fetching: nextShopperActionFetching
      }}
      recentMerchants={{ recentMerchants, fetching: recentMerchantsFetching }}
      merchantsPromotions={{
        merchantsPromotions,
        fetching: merchantsPromotionsFetching
      }}
      vouchers={{ vouchers, fetching: vouchersFetching, generalVouchers }}
    />
  );
};

export default Dashboard;
