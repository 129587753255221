import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../atoms";
import { useOrder, useLocaleCurrency } from "../../../hooks";
import { PartPayment } from "../components";
import locales from "../../../locales";

import K from "../../../constants";

const useStyles = createUseStyles({
  part_payment: {
    display: "flex",
    flexDirection: "column"
  },
  part_payments__info: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "16px"
    },
    "& > :last-child": {
      alignSelf: ({ isRenting }) => (isRenting ? "center" : "flex-end")
    }
  }
});

const PartPayments = () => {
  const order = useOrder();
  const isRenting = order.currentProductName === "r1";
  const classes = useStyles({ isRenting });
  const intl = useIntl();
  const theme = useTheme();

  const nextInstalment = order.unpaidInstalments[0];

  const totalAmount = nextInstalment?.instalmentTotal || nextInstalment?.amount;

  return (
    <div className={classes.part_payments}>
      {order.currentProductName === "pp5" ? (
        <Typography
          variant={K.typographicVariants.visual2}
          color={
            order.isOverdue
              ? theme.palette.error.default
              : theme.palette.success.default
          }
        >
          {order.isOverdue
            ? intl.formatMessage(locales.order_overdue)
            : intl.formatMessage(locales.auto_payment)}
        </Typography>
      ) : null}
      <PartPayment />
      {order.currentProductName !== "pp5" && nextInstalment ? (
        <div className={classes.part_payments__info}>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(locales.order_next_intalment_payment, {
              date: intl.formatDate(nextInstalment.dueOn, {
                day: "numeric",
                month: "short",
                timeZone: "Europe/Madrid"
              }),
              amount: useLocaleCurrency(totalAmount, {
                useCents: true
              })
            })}
          </Typography>
          {!isRenting && order.paymentPlan?.instalmentFee > 0 ? (
            <Typography variant={K.typographicVariants.caption2}>
              {intl.formatMessage(locales.order_unique_instalment_included)}
            </Typography>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default PartPayments;
