import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Typography, Button } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  cookies_banner: {
    position: "fixed",
    zIndex: 999999,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    padding: "16px 8px",
    boxSizing: "border-box",
    backgroundColor: theme.palette.white.default,
    boxShadow: `0px -1px 4px 0px ${theme.palette.tertiary.default}`,
    "@media(min-width: 768px)": {
      padding: "16px 24px",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > :first-child": {
        marginRight: "16px"
      }
    }
  },
  cookies_banner__links: {
    margin: "16px 0 8px 0",
    display: "flex",
    flexDirection: "row",
    "& > :first-child": {
      marginRight: "8px"
    },
    "& > span > a": {
      color: theme.palette.primary.default,
      textDecoration: "underline"
    }
  },
  cookies_banner__buttons: {
    margin: "8px 0",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    "& > :last-child": {
      color: theme.palette.tertiary.default,
      borderColor: theme.palette.tertiary.default,
      marginRight: "16px"
    },
    "& > :first-child": {
      "@media(min-width: 768px)": {
        marginBottom: "16px"
      }
    },
    "& > span > a": {
      color: theme.palette.primary.default,
      textDecoration: "underline"
    },
    "@media(min-width: 768px)": {
      flexDirection: "column"
    }
  }
}));

const CookiesBanner = ({ cookiesPolicyLink, onConfiguration, onAccept }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.cookies_banner}>
      <div>
        <Typography variant="body3">
          {intl.formatMessage(locales.cookies_banner_text)}
        </Typography>
        <div className={classes.cookies_banner__links}>
          <Typography variant="caption1">
            <a href={cookiesPolicyLink} target="_blank" rel="noreferrer">
              {intl.formatMessage(locales.cookies_banner_policy_link)}
            </a>
          </Typography>
        </div>
      </div>
      <div className={classes.cookies_banner__buttons}>
        <Button size="small" onClick={onAccept}>
          {intl.formatMessage(locales.cookies_banner_accept_button)}
        </Button>
        <Button size="small" variant="outlined" onClick={onConfiguration}>
          {intl.formatMessage(locales.cookies_banner_configuration_button)}
        </Button>
      </div>
    </div>
  );
};

CookiesBanner.propTypes = {
  cookiesPolicyLink: PropTypes.string,
  onConfiguration: PropTypes.func,
  onAccept: PropTypes.func
};

CookiesBanner.defaultProps = {
  cookiesPolicyLink: K.links.cookies_policy,
  onConfiguration: () => {},
  onAccept: () => {}
};

export default CookiesBanner;
