import { useReducer } from "react";

export const MufasaActions = {
  loaded: "loaded",
  error: "error",
  resize: "resize",
  submit: "submit"
};
export const initialState = {
  loading: false,
  error: false,
  height: 0
};

export const mufasaReducer = (state, action) => {
  switch (action.type) {
    case MufasaActions.submit:
      return {
        ...state,
        loading: true,
        error: false
      };
    case MufasaActions.resize:
      return {
        loading: false,
        error: false,
        height: action.payload.height
      };
    case MufasaActions.error:
      return {
        ...state,
        loading: false,
        error: true
      };
    case MufasaActions.loaded:
    default:
      return {
        ...state,
        loading: false
      };
  }
};

export default () => useReducer(mufasaReducer, initialState);
