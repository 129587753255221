import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import classnames from "classnames";
import { ErrorBoundary } from "../../../../../templates";
import { Typography } from "../../../../../atoms";
import K from "../../../../../constants";
import { NavigationArrowRight } from "../../../../../atoms/Icons";

const useStyles = createUseStyles({
  message_card: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "0px 24px 24px",
    backgroundColor: ({ cardColor }) => cardColor,
    boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.09)",
    borderRadius: "8px",
    "& span": {
      hyphens: "auto"
    },
    "@media(min-width: 768px)": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  message_card__link: {
    display: "inherit",
    height: "inherit",
    flexDirection: "inherit",
    justifyContent: "inherit",
    textDecoration: "none"
  },
  message_card__message: {
    paddingTop: 32,
    paddingBottom: 24
  },
  message_card__button_container: {
    display: "flex",
    justifyContent: "flex-end",
    "& :hover": {
      textDecoration: "underline"
    }
  },
  message_card__button: {
    border: "none",
    background: "none",
    color: ({ textColor }) => textColor,
    padding: 0,
    margin: 0,
    outline: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& :active": {
      outline: "none"
    },
    "& svg": {
      marginLeft: "5px",
      marginTop: "2px"
    }
  }
});
const MessageCard = ({
  className,
  message,
  cardColor,
  textColor,
  buttonText,
  linkUrl,
  fetching
}) => {
  const theme = useTheme();
  const classes = useStyles({
    // eslint-disable-next-line no-nested-ternary
    cardColor: fetching
      ? theme.palette.white.default
      : theme.palette[cardColor].medium === undefined
      ? theme.palette[cardColor].default
      : theme.palette[cardColor].medium,
    textColor
  });

  return (
    <div className={classnames(classes.message_card, className)}>
      <ErrorBoundary component="MessageCard">
        {!fetching && (
          <>
            {buttonText !== "" ? (
              <Link
                to={{ pathname: linkUrl }}
                target="parent"
                className={classes.message_card__link}
              >
                <Typography
                  className={classes.message_card__message}
                  variant={K.typographicVariants.visual1}
                  color={textColor}
                >
                  {message}
                </Typography>
                <div className={classes.message_card__button_container}>
                  <button
                    className={classes.message_card__button}
                    type="button"
                  >
                    <Typography
                      color={textColor}
                      className={classes.message_card__button_text}
                      variant={K.typographicVariants.linkS}
                    >
                      {buttonText}
                    </Typography>
                    <NavigationArrowRight
                      color={theme.palette[textColor].default}
                    />
                  </button>
                </div>
              </Link>
            ) : (
              <Typography
                className={classes.message_card__message}
                variant={K.typographicVariants.visual1}
                color={textColor}
              >
                {message}
              </Typography>
            )}
          </>
        )}
      </ErrorBoundary>
    </div>
  );
};

MessageCard.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  cardColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonText: PropTypes.string,
  linkUrl: PropTypes.string,
  fetching: PropTypes.bool
};

MessageCard.defaultProps = {
  className: "",
  message: PropTypes.string,
  cardColor: "white",
  textColor: "primary",
  buttonText: "",
  fetching: true,
  linkUrl: ""
};

export default MessageCard;
