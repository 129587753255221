import React from "react";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";
import { Peace } from "../../atoms/Illustrations";

const useStyles = createUseStyles(() => ({
  order_unshipped: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "24px"
  },
  order_unshipped_text: {
    display: "block",
    marginTop: "20px"
  }
}));

const OrderInvoiceUnshipped = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <div className={classes.order_unshipped}>
        <Peace />
        <Typography
          variant={K.typographicVariants.body2}
          color={K.colors.secondary}
          className={classes.order_unshipped_text}
        >
          {intl.formatMessage(locales.order_page_unshipped, {
            br: <br />
          })}
        </Typography>
      </div>
    </>
  );
};

export default OrderInvoiceUnshipped;
