import React from "react";

const ORDER_CONTEXT = React.createContext();
const PAYMENT_CARD_CONTEXT = React.createContext();
const PAYMENT_CARDS_CONTEXT = React.createContext();
const SHOPPER_CONTEXT = React.createContext();

export {
  ORDER_CONTEXT as OrderContext,
  PAYMENT_CARD_CONTEXT as PaymentCardContext,
  PAYMENT_CARDS_CONTEXT as PaymentCardsContext,
  SHOPPER_CONTEXT as ShopperContext
};
