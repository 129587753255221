import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { useCopyToClipboard } from "react-use";
import useNotificationContext from "../../../../contexts/Notification";
import useTrackingContext from "../../../../contexts/Tracking";
import locales from "../../../../locales";
import { NextModal, Typography } from "../../../../atoms";
import { FileCopy, WhatsApp, Envelope } from "../../../../atoms/Icons";
import K from "../../../../constants";

const useStyles = createUseStyles({
  modal: {
    position: "relative",
    borderRadius: 8,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0,
    "@media(min-width: 768px)": {
      maxHeight: 517
    }
  },
  share_referral_modal__header: {
    marginBottom: "40px"
  },
  share_referral_modal__share_option: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px",
    borderBottom: "1px solid #DDDBE0",
    marginTop: "24px",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  }
});

const ShareReferralModal = ({ onClose, contentToShare }) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const [, copyToClipboard] = useCopyToClipboard();
  const { setSnackbarLayoutProps } = useNotificationContext();
  const { track, eventNames } = useTrackingContext();

  const copyShareContentToClipBoard = () => {
    copyToClipboard(contentToShare);
    setSnackbarLayoutProps({
      open: true,
      variant: "accent",
      label: intl.formatMessage(
        locales.referral_program_share_popup_copy_link_confirmation
      )
    });
  };

  const handleClickShareInvitationByCopyingLink = () => {
    track(eventNames.referralProgram.shareInvitationFromWebsiteByCopyingLink);
    copyShareContentToClipBoard();
  };

  useEffect(() => {
    track(eventNames.referralProgram.shareInvitationFromWebsite);
  }, []);

  return (
    <NextModal
      open
      hideBack
      onClose={onClose}
      withIconClose
      modalClassname={classes.modal}
    >
      <div className={classes.share_referral_modal}>
        <div>
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
            className={classes.share_referral_modal__header}
          >
            {intl.formatMessage(locales.referral_program_share_popup_title)}
          </Typography>
          <div
            onClick={handleClickShareInvitationByCopyingLink}
            onKeyPress={handleClickShareInvitationByCopyingLink}
            className={classes.share_referral_modal__share_option}
            role="button"
            tabIndex={0}
          >
            <Typography>
              {intl.formatMessage(
                locales.referral_program_share_popup_copy_link
              )}
            </Typography>
            <FileCopy />
          </div>
          <a
            onClick={() => {
              track(
                eventNames.referralProgram
                  .shareInvitationFromWebsiteBySendingEmail
              );
            }}
            className={classes.share_referral_modal__share_option}
            href={`mailto:?body=${contentToShare}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography>
              {intl.formatMessage(locales.referral_program_share_popup_by_mail)}
            </Typography>
            <Envelope />
          </a>
          <a
            onClick={() => {
              track(
                eventNames.referralProgram
                  .shareInvitationFromWebsiteByWhatsappWeb
              );
            }}
            className={classes.share_referral_modal__share_option}
            href={`http://web.whatsapp.com/send?text=${contentToShare}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography>
              {intl.formatMessage(
                locales.referral_program_share_popup_by_whatsapp
              )}
            </Typography>
            <WhatsApp />
          </a>
        </div>
      </div>
    </NextModal>
  );
};

ShareReferralModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  contentToShare: PropTypes.string
};

ShareReferralModal.defaultProps = {
  contentToShare: ""
};

export default ShareReferralModal;
