import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import { useOrder } from "../../hooks";
import {
  AmortizationSummaryModal,
  AmortizationMufasaModal,
  AmortizationBankAccountsModal
} from "./modals";
import { SuccessPlanModal } from "../../molecules";
import useTrackingContext from "../../contexts/Tracking";
import K from "../../constants";
import locales from "../../locales";
import isProductEqualToCurrentProduct from "../../utils/isProductEqualToCurrentProduct";

const AmortizationFlow = () => {
  const order = useOrder();
  const { amortization, currentProductName } = order;
  const isPP5 = isProductEqualToCurrentProduct(
    K.products.pp5,
    currentProductName
  );
  const modalTitle = isPP5
    ? locales.amortization_flow_advance_payment
    : locales.amortization_flow_amortize_plan;
  const location = useLocation();
  const query = queryString.parse(location.search);
  const isWideScreen = useMedia("(min-width: 768px)");
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  if (!amortization) {
    window.location.replace(location.pathname);
    return null;
  }

  React.useEffect(() => {
    /*
    This avoid to send to mixpanel data when a user uses
    the amortization (does not exist yet) shortened link
    to access to the app and redirect to the location.pathname
    */
    if (amortization) {
      track(
        eventNames.amortization.start,
        buildOrderTrackingPayload(order, true)
      );
    }
  }, []);

  switch (query.step) {
    case K.steps.summary:
      if (isWideScreen) {
        return <AmortizationMufasaModal modalTitle={modalTitle} />;
      }
      return <AmortizationSummaryModal modalTitle={modalTitle} />;
    case K.steps.selectBankAccount:
      return <AmortizationBankAccountsModal modalTitle={modalTitle} />;
    case K.steps.addCard:
      return <AmortizationMufasaModal modalTitle={modalTitle} />;
    case K.steps.success:
      return (
        <SuccessPlanModal
          onClose={() => window.location.replace(location.pathname)}
        />
      );
    default:
      return null;
  }
};

export default AmortizationFlow;
