/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import {
  Heart,
  HeartBorder,
  Bin,
  Visa,
  MasterCard
} from "../../../../atoms/Icons";
import { useOrder } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  payment_card: {
    margin: "0",
    boxSizing: "border-box",
    position: "relative",
    maxWidth: "632px",
    width: "100%",
    "@media (min-width: 768px)": {
      alignItems: "center",
      maxWidth: "632px",
      width: "100%"
    }
  },

  payment_card__content: {
    display: "flex",
    height: "54px",
    padding: "0 16px",
    borderBottom: `1px solid ${theme.palette.shade.default}`
  },
  payment_card__default: {
    width: "20px",
    height: "19px",
    appearance: "none",
    border: "0",
    backgroundColor: "transparent",
    padding: "0",
    cursor: "pointer"
  },
  payment_card__info: {
    display: "flex",
    flexWrap: "wrap",
    height: "48px",
    flex: "1",
    alignItems: "center",
    "@media (min-width: 768px)": {
      flexWrap: "nowrap",
      flex: "1",
      height: "100%"
    }
  },
  payment_card__info__issuer: {
    backgroundColor: theme.palette.white.default,
    width: "48px",
    height: "24px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  payment_card__info__number: {
    height: "24px",
    display: "flex",
    alignItems: "center",
    margin: "0 8px",
    "@media (min-width: 768px)": {
      margin: "0 16px",
      height: "auto"
    }
  },
  payment_card__status: {
    display: "flex",
    padding: "8px 16px 24px",
    borderBottom: `1px solid ${theme.palette.shade.default}`
  },
  payment_card__actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button": {
      marginLeft: "20px"
    }
  },
  payment_card__status__message: {
    color: theme.palette.error.default
  },
  payment_card__delete: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    appearance: "none",
    border: "0",
    backgroundColor: "transparent",
    padding: "0",
    "& svg": {
      width: "20px",
      height: "20px"
    }
  },
  payment_card__delete__icon: {
    color: theme.palette.shade.default,
    cursor: "pointer"
  }
}));

const PaymentCard = ({ card, isDefault, hideDelete, onSelect, onDelete }) => {
  const intl = useIntl();
  const classes = useStyles({ isDefault });
  const theme = useTheme();
  const order = useOrder();

  const onSelectCard = e => {
    e.stopPropagation();
    if (isDefault || card.isExpired) return;

    const message = order
      ? intl.formatMessage(locales.set_default_order_card_reference, {
          lastFour: card.lastFour
        })
      : intl.formatMessage(locales.set_default_shopper_card_reference, {
          lastFour: card.lastFour
        });

    const confirmed = confirm(message);
    if (confirmed) onSelect();
  };

  const onRemoveCard = e => {
    e.stopPropagation();
    if (isDefault) return;

    const message = order
      ? intl.formatMessage(locales.remove_order_card_reference, {
          lastFour: card.lastFour
        })
      : intl.formatMessage(locales.remove_shopper_card_reference, {
          lastFour: card.lastFour
        });

    const confirmed = confirm(message);
    if (confirmed) onDelete();
  };

  const isRenderCardStatus = card.isExpired || card.isEpiringSoon;

  return (
    <div
      className={classes.payment_card}
      onClick={onSelectCard}
      onKeyPress={onSelectCard}
      role="button"
      tabIndex="0"
    >
      <div className={classes.payment_card__content}>
        <div className={classes.payment_card__info}>
          <div className={classes.payment_card__info__issuer}>
            {card.issuer === "Visa" && <Visa />}
            {card.issuer === "MasterCard" && <MasterCard />}
          </div>
          <Typography
            variant={K.typographicVariants.caption1}
            className={classes.payment_card__info__number}
            color={
              card.isExpired
                ? theme.palette.error.default
                : theme.palette.primary.default
            }
          >
            {intl.formatMessage(locales.general_payment_card_mask, {
              lastFour: card.lastFour
            })}
          </Typography>
          <Typography
            variant={K.typographicVariants.caption1}
            className={classes.payment_card__info__expiration}
            color={
              card.isExpired
                ? theme.palette.error.default
                : theme.palette.primary.default
            }
          >
            {intl.formatMessage(locales.general_payment_card_expiry_date, {
              expiryMonth: card.expiryMonth,
              expiryYear: card.expiryYear
            })}
          </Typography>
        </div>

        <div className={classes.payment_card__actions}>
          {!card.isExpired && (
            <button
              type="button"
              onClick={onSelectCard}
              className={classes.payment_card__default}
            >
              {isDefault ? (
                <Heart color={theme.palette.success.default} />
              ) : (
                <HeartBorder color={theme.palette.tertiary.default} />
              )}
            </button>
          )}

          <button
            type="button"
            onClick={onRemoveCard}
            className={classes.payment_card__delete}
            disabled={hideDelete}
          >
            <Bin
              className={classes.payment_card__delete__icon}
              color={
                hideDelete
                  ? theme.palette.shade.default
                  : theme.palette.tertiary.default
              }
            />
          </button>
        </div>
      </div>
      {isRenderCardStatus && (
        <div className={classes.payment_card__status}>
          <Typography
            variant={K.typographicVariants.caption1}
            className={classes.payment_card__status__message}
          >
            {card.isExpired &&
              intl.formatMessage(locales.general_payment_card_expired)}
            {card.isEpiringSoon &&
              intl.formatMessage(locales.general_payment_card_expiring_soon)}
          </Typography>
        </div>
      )}
    </div>
  );
};

PaymentCard.propTypes = {
  card: PropTypes.shape().isRequired,
  isDefault: PropTypes.bool,
  hideDelete: PropTypes.bool,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func
};

PaymentCard.defaultProps = {
  isDefault: false,
  hideDelete: false,
  onSelect: () => {},
  onDelete: () => {}
};

export default PaymentCard;
