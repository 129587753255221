import React from "react";
import PropTypes from "prop-types";
import { useMedia } from "react-use";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { useHistory, Link } from "react-router-dom";
import { CardFooter, Typography } from "../../atoms";
import { ErrorBoundary } from "../../templates";
import { OrderContext } from "../../contexts";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../utils";
import { Total, Articles, Shipments } from "./components";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  order_details_summary: {
    justifyContent: "space-between",
    marginBottom: "24px",
    backgroundColor: theme.palette.white.default,
    borderRadius: "8px",
    boxSizing: "border-box",
    maxWidth: ({ isWide }) => isWide && "612px",
    padding: ({ isWide }) => isWide && "24px",
    pointerEvents: ({ isRenting }) => (isRenting ? "none" : "auto")
  },
  order_details_summary__title: {
    padding: ({ isWide }) => !isWide && "24px 0 0 16px"
  },
  order_details_summary__content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: ({ isWide }) => (isWide ? "0" : "0 16px")
  },
  order_details_summary__content_cards: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "16px",
    marginTop: "24px",
    width: ({ isWide }) => (isWide ? "65%" : "100%"),
    marginBottom: ({ isWide }) => !isWide && "24px",
    flexWrap: ({ isWide }) => !isWide && "wrap",
    rowGap: ({ isWide }) => !isWide && "16px"
  },
  order_details_summary__content_action: {
    paddingBottom: "3px"
  }
}));

const OrderDetailsSummary = ({ showArticleLink }) => {
  const order = React.useContext(OrderContext);
  const isRenting = order.currentProductName === "r1";
  const isWide = useMedia("(min-width: 768px)");
  const classes = useStyles({ isRenting, isWide });
  const history = useHistory();
  const intl = useIntl();

  const totalItems = getTotalCountFromItemsList(
    getArticlesListFromCart(order.cart)
  );
  const totalItemsShipped = getTotalCountFromItemsList(
    getArticlesListFromCart(order.shippedCart.items)
  );

  const OrderDetailsSummaryCards = () => (
    <div className={classes.order_details_summary__content_cards}>
      {isRenting ? null : (
        <Total orderId={order.id} currentOrderValue={order.currentOrderValue} />
      )}

      <Articles orderId={order.id} totalArticles={totalItems} />

      <Shipments
        orderId={order.id}
        totalSent={totalItemsShipped}
        totalUnsent={totalItems}
      />
    </div>
  );

  return (
    <ErrorBoundary component="OrderDetailsSummary">
      <div className={classes.order_details_summary}>
        <Typography
          variant={K.typographicVariants.heading4}
          className={classes.order_details_summary__title}
        >
          {intl.formatMessage(locales.purchase_summary)}
        </Typography>

        <div className={classes.order_details_summary__content}>
          <OrderDetailsSummaryCards />

          {isWide && (
            <div className={classes.order_details_summary__content_action}>
              {showArticleLink && !isRenting && (
                <Link to={`/order/${order.id}/articles`}>
                  <Typography variant={K.typographicVariants.linkS}>
                    {intl.formatMessage(locales.shopping_basket)}
                  </Typography>
                </Link>
              )}
            </div>
          )}
        </div>

        {!isWide && showArticleLink && !order.isCancelled && !isRenting && (
          <CardFooter
            onClick={() => {
              history.push(`/order/${order.id}/articles`);
            }}
          >
            <Typography>
              {intl.formatMessage(locales.shopping_basket)}
            </Typography>
          </CardFooter>
        )}
      </div>
    </ErrorBoundary>
  );
};

OrderDetailsSummary.propTypes = {
  showArticleLink: PropTypes.bool
};

OrderDetailsSummary.defaultProps = {
  showArticleLink: false
};

export default OrderDetailsSummary;
