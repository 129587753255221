import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { Typography } from "../../../atoms";
import { useOrder, useLocaleCurrency } from "../../../hooks";
import locales from "../../../locales";
import K from "../../../constants";

const useStyles = createUseStyles({
  shipping: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "8px"
    },
    "& > :nth-child(2)": {
      marginBottom: "16px"
    }
  },
  shipping__total: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "8px"
    }
  }
});

const Shipping = () => {
  const order = useOrder();
  const classes = useStyles();
  const intl = useIntl();
  let title = null;

  if (order.isUnshipped) {
    title = intl.formatMessage(locales.unshipped);
  } else if (order.isPartiallyShipped) {
    title = intl.formatMessage(locales.partially_paid_amount_from, {
      paid: useLocaleCurrency(order.paidAmount, { useCents: true }),
      unpaid: useLocaleCurrency(order.currentOrderValue, { useCents: true })
    });
  }

  return (
    <div className={classes.shipping}>
      {title ? (
        <Typography variant={K.typographicVariants.visual2}>{title}</Typography>
      ) : null}
      <Typography variant={K.typographicVariants.body2}>
        {intl.formatMessage(locales.invoice_unshipped_days_for_payment, {
          days: order.merchant.invoicingCreditDays
        })}
      </Typography>
      <div className={classes.shipping__total}>
        <Typography variant={K.typographicVariants.heading5}>
          {intl.formatMessage(locales.amount)}
        </Typography>
        <Typography variant={K.typographicVariants.visual3}>
          {useLocaleCurrency(order.unchargedOrderValueForInvoicingOrders, {
            useCents: true
          })}
        </Typography>
      </div>
    </div>
  );
};

export default Shipping;
