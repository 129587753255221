import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Typography, Progress } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles({
  part_payments__progress: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    paddingTop: "23px",
    paddingBottom: "24px",
    "& > :last-child": {
      textTransform: "lowercase"
    }
  }
});

const PartPaymentProgress = ({
  instalmentsCount,
  paidInstalmentsCount,
  isOverdue
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Progress
      maxValue={instalmentsCount}
      value={paidInstalmentsCount}
      backgroundColor={
        isOverdue ? theme.palette.error.light : theme.palette.info.light
      }
      borderColor={
        isOverdue ? theme.palette.error.default : theme.palette.info.default
      }
    >
      <div className={classes.part_payments__progress}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={isOverdue ? K.colors.error : K.colors.info}
        >
          {`${paidInstalmentsCount}/${instalmentsCount}`}
        </Typography>
        <Typography
          variant={K.typographicVariants.heading4}
          color={isOverdue ? K.colors.error : K.colors.info}
        >
          {intl.formatMessage(locales.instalments)}
        </Typography>
      </div>
    </Progress>
  );
};

PartPaymentProgress.propTypes = {
  instalmentsCount: PropTypes.number,
  paidInstalmentsCount: PropTypes.number,
  isOverdue: PropTypes.bool
};

PartPaymentProgress.defaultProps = {
  instalmentsCount: 0,
  paidInstalmentsCount: 0,
  isOverdue: false
};

export default PartPaymentProgress;
