import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography, Calendar } from "../../../../../atoms";
import { InvoiceProgress } from "../../../../../molecules";
import { useLocaleCurrency } from "../../../../../hooks";
import locales from "../../../../../locales";
import K from "../../../../../constants";

const useStyles = createUseStyles({
  unpaid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  unpaid__info: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "8px"
    }
  }
});

const Unpaid = ({
  hasAutoPaymentEnabled,
  unpaidAmount,
  invoicingCreditDays,
  dueOn,
  isOverdue
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.unpaid}>
      <div className={classes.unpaid__info}>
        <Typography
          variant={K.typographicVariants.visual3}
          color={
            isOverdue
              ? K.colors.error
              : `${
                  hasAutoPaymentEnabled ? K.colors.secondary : K.colors.success
                }`
          }
        >
          {isOverdue
            ? intl.formatMessage(locales.order_overdue)
            : `${
                hasAutoPaymentEnabled
                  ? intl.formatMessage(locales.auto_payment)
                  : intl.formatMessage(locales.order_shipped)
              }`}
        </Typography>
        <Typography variant={K.typographicVariants.visual3}>
          {useLocaleCurrency(unpaidAmount, { useCents: true })}
        </Typography>
      </div>
      <div>
        {hasAutoPaymentEnabled ? (
          <Calendar
            day={intl.formatDate(dueOn, {
              day: "2-digit",
              timeZone: "Europe/Madrid"
            })}
            month={intl.formatDate(dueOn, {
              month: "long",
              timeZone: "Europe/Madrid"
            })}
            color={theme.palette.success.default}
            backgroundColor={theme.palette.success.light}
          />
        ) : (
          <InvoiceProgress
            invoicingCreditDays={invoicingCreditDays}
            dueOn={dueOn}
            isOverdue={isOverdue}
          />
        )}
      </div>
    </div>
  );
};

Unpaid.propTypes = {
  dueOn: PropTypes.string.isRequired,
  unpaidAmount: PropTypes.number,
  hasAutoPaymentEnabled: PropTypes.bool,
  invoicingCreditDays: PropTypes.number,
  isOverdue: PropTypes.bool
};

Unpaid.defaultProps = {
  unpaidAmount: 0,
  hasAutoPaymentEnabled: false,
  invoicingCreditDays: 0,
  isOverdue: false
};

export default Unpaid;
