import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import classnames from "classnames";
import { NavigationArrowRight } from "../Icons";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  banner: {
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "24px",
    boxSizing: "border-box",
    borderRadius: "8px",
    backgroundImage: `linear-gradient(45deg, ${theme.palette.info.medium} 0%, ${theme.palette.success.medium} 100%)`,
    textDecoration: "none",
    "& > *": {
      color: theme.palette.primary.default
    }
  },
  banner__balance: {
    textAlign: "right",
    fontSize: "40px",
    fontFamily: theme.fontFamily
  },
  banner__action: {
    textAlign: "right",
    "& > svg": {
      verticalAlign: "middle"
    }
  }
}));

const Banner = ({ balance, title, subtitle, className, linkUrl, linkText }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      {linkUrl ? (
        <Link
          to={{ pathname: linkUrl }}
          className={classnames(classes.banner, className)}
        >
          <Typography variant={K.typographicVariants.heading3}>
            <span data-testid="title">{title}</span>
          </Typography>
          <Typography
            variant={K.typographicVariants.body2}
            color={theme.palette.secondary.default}
          >
            <span data-testid="subtitle">{subtitle}</span>
          </Typography>
          <p className={classes.banner__balance} data-testid="balance">
            {balance}
          </p>
          <p className={classes.banner__action} data-testid="action">
            <Typography variant={K.typographicVariants.linkS}>
              {linkText}
            </Typography>
            <NavigationArrowRight color={theme.palette.primary.default} />
          </p>
        </Link>
      ) : (
        <div className={classnames(classes.banner, className)}>
          <Typography variant={K.typographicVariants.heading3}>
            <span data-testid="title">{title}</span>
          </Typography>
          <Typography
            variant={K.typographicVariants.body2}
            color={theme.palette.secondary.default}
          >
            <span data-testid="subtitle">{subtitle}</span>
          </Typography>
          <p className={classes.banner__balance} data-testid="balance">
            {balance}
          </p>
        </div>
      )}
    </>
  );
};

Banner.propTypes = {
  className: PropTypes.string,
  balance: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string
};

Banner.defaultProps = {
  className: "",
  balance: "",
  title: "",
  subtitle: "",
  linkUrl: "",
  linkText: ""
};

export default Banner;
