import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useMutation } from "@apollo/client";
import { BounceLoader } from "react-spinners";
import { useIntl } from "react-intl";
import { LoaderBox } from "../../../../components";
import { RENDER_SERVICE } from "../../../../graphql";
import { OrderContext } from "../../../../contexts";
import useTrackingContext from "../../../../contexts/Tracking";
import { isMobile } from "../../../../utils";
import { Button } from "../../../../atoms";
import locales from "../../../../locales";

const useStyles = createUseStyles(theme => ({
  box: {
    marginTop: "16px",
    "@media (min-width: 768px)": {
      display: "flex",
      flexDirection: "row-reverse",
      marginTop: "60px"
    }
  },
  imageBox: {
    width: "202px",
    height: "164px",
    margin: "0 auto",
    "@media (min-width: 768px)": {
      width: "369px",
      height: "301px"
    }
  },
  image: {
    width: "100%",
    height: "100%"
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      width: "286px"
    },
    "@media (min-width: 1024px)": {
      marginRight: "50px",
      width: "486px"
    },
    "@media (min-width: 1280px)": {
      marginRight: "147px",
      width: "486px"
    }
  },
  confirmedText: {
    textAlign: "center",
    color: theme.palette.secondary.default,
    fontFamily: "Oxygen",
    lineHeight: "1.33",
    fontSize: "24px",
    fontWeight: "bold",
    "@media (min-width: 768px)": {
      fontSize: "32px",
      lineHeight: "1.25",
      textAlign: "left",
      marginTop: "0"
    }
  },
  confirmedMessage: {
    color: theme.palette.secondary.default,
    lineHeight: "1.57",
    fontSize: "14px",
    fontWeight: "normal",
    "@media (min-width: 768px)": {
      fontSize: "16px",
      lineHeight: "1.5"
    }
  },
  backToOrdersButton: {
    width: "100%",
    marginTop: "10px"
  },
  backToOrdersLink: {
    textDecoration: "none",
    color: theme.palette.success.default,
    fontSize: "16px",
    marginTop: "42px"
  }
}));

const OrderRenderService = () => {
  const classes = useStyles();
  const theme = useTheme();
  const order = React.useContext(OrderContext);
  const { track, buildOrderTrackingPayload } = useTrackingContext();
  const eventPops = buildOrderTrackingPayload(order);
  const intl = useIntl();

  const [renderService, { error, loading, called, data }] = useMutation(
    RENDER_SERVICE,
    {
      onCompleted: () => {
        track("Rendered service successfully", eventPops);
      },
      onError: () => {
        track("Rendered service failed", eventPops);
      }
    }
  );

  React.useEffect(() => {
    track("Start rendered service", eventPops);

    renderService({ variables: { orderId: order.id } });
  }, []);

  if (error) return null;

  if (loading || !called) {
    return (
      <LoaderBox>
        <BounceLoader color={theme.palette.success.default} />
      </LoaderBox>
    );
  }

  const {
    renderService: { merchant, shippedCart }
  } = data;
  const service = shippedCart.items.find(item => item.type === "SERVICE");

  if (!service) return null;

  return (
    <div className={classes.box}>
      <div className={classes.imageBox}>
        <img
          src="/images/request-finished.png"
          alt=""
          className={classes.image}
        />
      </div>
      <div className={classes.contentBox}>
        <div>
          <h2 className={classes.confirmedText}>
            {intl.formatMessage(
              locales.request_finished_service_confirmed_text
            )}
          </h2>
          <p className={classes.confirmedMessage}>
            {intl.formatMessage(
              locales.request_finished_service_confirmed_message,
              {
                service_type:
                  merchant.servicesSector === "courses" ? "curso" : "servicio",
                service_name: <b>{service?.name}</b>,
                merchant: merchant.name
              }
            )}
          </p>
          <p className={classes.confirmedMessage}>
            {intl.formatMessage(
              locales.request_finished_service_goodbye_message
            )}
          </p>
        </div>
        {isMobile.phone ? (
          <Button
            className={classes.backToOrdersButton}
            to={`/order/${order.id}`}
          >
            {intl.formatMessage(
              locales.request_finished_service_back_to_orders
            )}
          </Button>
        ) : (
          <a href={`/order/${order.id}`} className={classes.backToOrdersLink}>
            {intl.formatMessage(
              locales.request_finished_service_back_to_orders
            )}
          </a>
        )}
      </div>
    </div>
  );
};

export default OrderRenderService;
