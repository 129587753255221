import { useTheme } from "react-jss";

const iconTags = {
  Deliver: "Deliver",
  Return: "Return",
  TagPrice: "TagPrice"
};

const getRoundedIconColors = color => {
  const theme = useTheme();
  let circleColor;
  let iconColor;
  switch (color) {
    case "info":
      circleColor = theme.palette.info.light;
      iconColor = theme.palette.info.default;
      break;
    case "warning":
      circleColor = theme.palette.warning.light;
      iconColor = theme.palette.warning.default;
      break;
    case "success":
    default:
      circleColor = theme.palette.success.light;
      iconColor = theme.palette.success.default;
      break;
  }

  return {
    circleColor,
    iconColor
  };
};

export { iconTags, getRoundedIconColors };
