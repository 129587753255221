import { useTheme } from "react-jss";

export default (active, disabled, variant = "default") => {
  const theme = useTheme();
  let iconColor;
  if (variant === "default") {
    if (disabled) {
      iconColor = theme.palette.tertiary.default;
    } else if (active) {
      iconColor = theme.palette.primary.default;
    } else {
      iconColor = theme.palette.secondary.default;
    }
  } else if (variant === "accent") {
    iconColor = theme.palette.brand.default;
  }
  return iconColor;
};
