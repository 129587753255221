import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { withModal } from "../../../../hocs";
import { getIntl } from "../../../../utils";
import locales from "../../../../locales";
import { useOrder } from "../../../../hooks";
import K from "../../../../constants";
import useTrackingContext from "../../../../contexts/Tracking";
import useNotificationContext from "../../../../contexts/Notification";
import { MufasaConversionPaymentGateway } from "../../../../molecules";

const ContentModal = ({ gateway, instalmentCount }) => {
  const order = useOrder();
  const location = useLocation();
  const intl = useIntl();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();
  const { setSnackbarLayoutProps } = useNotificationContext();

  const processSuccessfulCardOperation = ({
    trackEventProps,
    snackbarMessage
  }) => {
    track(eventNames[gateway].success, trackEventProps);
    setSnackbarLayoutProps({
      open: true,
      variant: "accent",
      label: snackbarMessage
    });
    window.location.replace(location.pathname);
  };

  const processFailedCardOperation = ({ trackEventProps }) => () => {
    track(eventNames[gateway].fail, trackEventProps);
    setSnackbarLayoutProps({
      open: true,
      variant: "error",
      label: intl.formatMessage(locales.ups_something_went_wrong)
    });
  };

  return (
    <MufasaConversionPaymentGateway
      orderId={order.id}
      instalmentCount={instalmentCount}
      onSuccess={() =>
        processSuccessfulCardOperation({
          trackEventProps: buildOrderTrackingPayload(order, true, false),
          label: intl.formatMessage(locales.order_converted_successfully)
        })
      }
      onError={() =>
        processFailedCardOperation({
          trackEventProps: buildOrderTrackingPayload(order, true, false)
        })
      }
    />
  );
};

ContentModal.propTypes = {
  gateway: PropTypes.oneOf([
    K.mufasaGateways.tokenization,
    K.mufasaGateways.payment,
    K.mufasaGateways.conversion,
    K.mufasaGateways.assignCard
  ]),
  instalmentCount: PropTypes.number
};

ContentModal.defaultProps = {
  gateway: null,
  instalmentCount: 0
};

export default withModal(ContentModal, {
  title: getIntl.formatMessage(locales.new_card)
});
