import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import useTrackingContext from "../../../../../../contexts/Tracking";
import Layout from "../../Layout";
import { Button, Typography } from "../../../../../../atoms";
import { Otp } from "../../../../../../molecules";
import { Pen } from "../../../../../../atoms/Icons";
import locales from "../../../../../../locales";
import K from "../../../../../../constants";

const useStyles = createUseStyles({
  otp__claim: {
    display: "flex",
    marginTop: "8px",
    "& strong ": {
      cursor: "pointer",
      "& span ": {
        margin: "0 8px 0 4px",
        fontWeight: "bold",
        verticalAlign: "top"
      }
    }
  },
  otp__action: {
    "& button": {
      height: "auto",
      boxShadow: "none!important"
    }
  }
});

const OtpStep = ({
  onClickPrevStep,
  currentStepForm,
  phone,
  onSubmitStep,
  onChangeInputValue,
  otpValue,
  error
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const { track, eventNames } = useTrackingContext();
  useEffect(() => {
    track(eventNames.signUp.stepForm.otp.start);
  }, []);

  return (
    <Layout
      subTitle={intl.formatMessage(locales.sign_up_step_form_otp_subtitle)}
      currentStep={currentStepForm}
      onClickPrevStep={onClickPrevStep}
      onSubmitStep={onSubmitStep}
    >
      <p className={classes.otp__claim}>
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.sign_up_step_form_otp_claim)}
        </Typography>
        <strong
          role="button"
          tabIndex="0"
          onClick={onClickPrevStep}
          onKeyPress={onClickPrevStep}
        >
          <Typography variant={K.typographicVariants.linkS}>{phone}</Typography>
          <Pen width={20} height={20} color={theme.palette.primary.default} />
        </strong>
      </p>
      <div>
        <Otp numberOfItems={6} onChangeOTP={otp => onChangeInputValue(otp)} />
      </div>

      {error && (
        <p>
          <Typography
            variant={K.typographicVariants.body2}
            color={K.colors.error}
          >
            {intl.formatMessage(locales.sign_up_step_form_otp_error)}
          </Typography>
        </p>
      )}

      <div>
        <Button
          type="submit"
          fullWidth
          size="large"
          disabled={otpValue.length !== 6}
        >
          {intl.formatMessage(locales.general_navigation_finish)}
        </Button>
      </div>
      <div className={classes.otp__action}>
        <Button variant="text" fullWidth>
          <Typography variant={K.typographicVariants.linkS}>
            {intl.formatMessage(locales.sign_up_step_form_otp_action)}
          </Typography>
        </Button>
      </div>
    </Layout>
  );
};

OtpStep.propTypes = {
  onClickPrevStep: PropTypes.func.isRequired,
  currentStepForm: PropTypes.number.isRequired,
  phone: PropTypes.string.isRequired,
  onSubmitStep: PropTypes.func.isRequired,
  onChangeInputValue: PropTypes.func.isRequired,
  otpValue: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired
};

export default OtpStep;
