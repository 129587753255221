import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import useTrackingContext from "../../contexts/Tracking";

const useStyles = createUseStyles(theme => ({
  referral_button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    width: "100%",
    backgroundColor: theme.palette.white.default,
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.03)",
    border: "none",
    borderRadius: "8px",
    "& > *": {
      marginRight: "8px",
      "@media(min-width: 768px)": {
        marginRight: "16px"
      }
    }
  }
}));

const ReferralButton = props => {
  const { children, process, className, dataTestId } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { track, eventNames } = useTrackingContext();

  const handleClickReferralButton = () => {
    history.push(`${location.pathname}?process=${process}`);

    switch (process) {
      case "referral-terms-and-conditions":
        track(
          eventNames.referralProgram
            .showTermsAndConditionsModalFromReferralButton
        );
        break;
      case "using-the-voucher":
        track(
          eventNames.referralProgram
            .showWhereToUseVoucherModalFromReferralButton
        );
        break;
      case "on-boarding":
        track(
          eventNames.referralProgram.showHowToUseVoucherModalFromReferralButton
        );
        break;
      default:
    }
  };

  return (
    <button
      type="button"
      className={classnames(classes.referral_button, className)}
      onClick={handleClickReferralButton}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
};

ReferralButton.propTypes = {
  children: PropTypes.node.isRequired,
  process: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string
};

ReferralButton.defaultProps = {
  className: "",
  dataTestId: ""
};

export default ReferralButton;
