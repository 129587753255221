import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme, createUseStyles } from "react-jss";
import { useMedia } from "react-use";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { useOrder } from "../../../../hooks";
import useTrackingContext from "../../../../contexts/Tracking";
import { NextModal, Typography, Button } from "../../../../atoms";
import { Check } from "../../../../atoms/Illustrations";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  modal: {
    "@media(min-width: 768px)": {
      height: "715px"
    }
  },
  modal__content: {
    padding: "24px 40px"
  },
  success_modal__title: {
    marginTop: "40px",
    marginBottom: "32px"
  },
  success_modal__claim: {
    marginBottom: "24px"
  }
});

const SuccessModal = ({ amount, dueOn }) => {
  const order = useOrder();
  const theme = useTheme();
  const classes = useStyles();
  const isWideScreen = useMedia("(min-width: 768px)");
  const location = useLocation();
  const intl = useIntl();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  const onClickAction = () => {
    window.location.replace(location.pathname);
  };

  useEffect(() => {
    track(
      eventNames.flexiConversion.success,
      buildOrderTrackingPayload(order, true)
    );
  }, []);

  return (
    <NextModal
      open
      modal
      modalClassname={classes.modal}
      contentClassname={classes.modal__content}
      hideBack
      onClose={onClickAction}
    >
      <Check width={97} height={97} />
      <Typography
        variant={
          isWideScreen
            ? K.typographicVariants.heading1
            : K.typographicVariants.heading2
        }
        color={theme.palette.primary.default}
        className={classes.success_modal__title}
      >
        {intl.formatMessage(locales.flexi_conversion_success_modal_title, {
          br: <br />,
          amount
        })}
      </Typography>
      <p className={classes.success_modal__claim}>
        <Typography
          variant={K.typographicVariants.body1}
          color={theme.palette.secondary.default}
          className={classes.confirmation_plan_modal__title}
        >
          {intl.formatMessage(
            locales.flexi_conversion_success_modal_first_claim
          )}{" "}
          {dueOn}
        </Typography>
      </p>

      <p className={classes.success_modal__claim}>
        <Typography
          variant={K.typographicVariants.body1}
          color={theme.palette.secondary.default}
          className={classes.confirmation_plan_modal__title}
        >
          {intl.formatMessage(
            locales.flexi_conversion_success_modal_second_claim
          )}
        </Typography>
      </p>

      <Button fullWidth size="large" onClick={onClickAction}>
        {intl.formatMessage(locales.flexi_conversion_success_modal_action)}
      </Button>
    </NextModal>
  );
};

SuccessModal.propTypes = {
  amount: PropTypes.string,
  dueOn: PropTypes.string
};

SuccessModal.defaultProps = {
  amount: "",
  dueOn: ""
};

export default SuccessModal;
