import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Card } from "../Card";
import DropDownListHeader from "./DropDownListHeader";
import DropDownListContent from "./DropDownListContent";
import DropDownListFooter from "./DropDownListFooter";

const useStyles = createUseStyles({
  drop_down_list__wrapper: {
    maxWidth: "612px",
    margin: "4px 0",
    padding: "16px 24px"
  }
});

const DropDownList = ({ children, toggled, hideDropdown }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(toggled);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const childrenProps = {
    header: {},
    content: {},
    footer: {}
  };

  React.Children.map(children, child => {
    if (child.type.displayName === "DropDownListHeader") {
      childrenProps.header = child.props;
    }

    if (child.type.displayName === "DropDownListContent") {
      childrenProps.content = child.props;
    }

    if (child.type.displayName === "DropDownListFooter") {
      childrenProps.footer = child.props;
    }
  });

  return (
    <Card className={classes.drop_down_list__wrapper}>
      <DropDownListHeader
        {...childrenProps.header}
        onToggle={() => {
          if (!hideDropdown) onToggle();
        }}
        isOpen={!hideDropdown && isOpen}
        hideExpand={hideDropdown}
      />
      {!hideDropdown && (
        <DropDownListContent {...childrenProps.content} isOpen={isOpen} />
      )}
      <DropDownListFooter {...childrenProps.footer} />
    </Card>
  );
};

DropDownList.propTypes = {
  children: PropTypes.node,
  toggled: PropTypes.bool,
  hideDropdown: PropTypes.bool
};

DropDownList.defaultProps = {
  children: null,
  toggled: false,
  hideDropdown: false
};

export default DropDownList;
