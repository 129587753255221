import React, { useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import isMobile from "ismobilejs";
import { useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { OrderContext } from "../../../../contexts";
import { OrderCard } from "../../../../organisms";
import { ChangePaymentCardFlow, PaymentFlow } from "../../../../flows";
import { Button } from "../../../../atoms";
import Typography from "../../../../atoms/Typography";
import { getOrderDetail } from "../../../../utils";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  orders: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "24px"
  },
  orders__content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    "& > *": {
      marginBottom: "16px"
    },
    "@media (min-width: 768px)": {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      "& > *": {
        marginBottom: "16px",
        marginRight: "16px",
        marginLeft: "16px"
      }
    },
    "@media (min-width: 1280px)": {
      "& > *": {
        marginRight: 0,
        marginLeft: 0,
        "&:nth-child(3n - 1)": {
          marginRight: "16px",
          marginLeft: "16px"
        }
      }
    }
  },
  orders__more_button: {
    margin: "32px 0",
    alignSelf: "center",
    backgroundColor: "transparent"
  },
  orders__no_more_orders_text: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 0",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  orders__no_more_orders_text_emojis: {
    display: "flex",
    gap: "4px"
  }
});

const OrderList = ({
  orders,
  hasMoreOrders,
  fetchMoreOrders,
  fetching,
  error,
  keyPrefix,
  emptyOrderListNode
}) => {
  const location = useLocation();
  const intl = useIntl();
  const classes = useStyles();
  const [order, setOrder] = useState(null);
  const [moreOrdersLoading, setMoreOrdersLoading] = useState(false);

  const query = queryString.parse(location.search);

  React.useEffect(() => {
    setOrder(orders.find(o => o.id === query.order_id));
  });

  if (error) {
    return null;
  }

  const handleOnFetchMore = async () => {
    setMoreOrdersLoading(true);
    try {
      await fetchMoreOrders(orders[orders.length - 1].id);
      setMoreOrdersLoading(false);
    } catch (e) {
      console.error("Error", e);
      setMoreOrdersLoading(false);
    }
  };

  return (
    <>
      {order ? (
        <OrderContext.Provider value={getOrderDetail({ order })}>
          {query.process === K.process.payment && <PaymentFlow />}
          {query.process === K.process.changePaymentCard && (
            <ChangePaymentCardFlow />
          )}
        </OrderContext.Provider>
      ) : null}
      <div className={classes.orders}>
        {orders.length > 0 ? (
          <>
            <div className={classes.orders__content}>
              {orders.map(o => (
                <OrderContext.Provider
                  key={`${keyPrefix}-${o.id}`}
                  value={getOrderDetail({ order: o })}
                >
                  <OrderCard />
                </OrderContext.Provider>
              ))}
            </div>
            {hasMoreOrders && (
              <Button
                size={isMobile().phone ? "large" : "small"}
                className={classes.orders__more_button}
                variant="outlined"
                onClick={handleOnFetchMore}
              >
                {intl.formatMessage(locales.orders_more_button)}
              </Button>
            )}
            {!hasMoreOrders && !moreOrdersLoading && (
              <div className={classes.orders__no_more_orders_text}>
                <Typography
                  color={K.colors.secondary}
                  variant={K.typographicVariants.body2}
                >
                  {intl.formatMessage(locales.orders_no_more_orders_text)}
                </Typography>
                <div className={classes.orders__no_more_orders_text_emojis}>
                  <Typography
                    variant={K.typographicVariants.body2}
                    role="img"
                    aria-label="shopping bags"
                  >
                    🛍
                  </Typography>
                  <Typography
                    variant={K.typographicVariants.body2}
                    role="img"
                    aria-label="smiling face with open mouth and smiling eyes"
                  >
                    😄
                  </Typography>
                </div>
              </div>
            )}
          </>
        ) : (
          <div hidden={fetching} style={{ width: "100%" }}>
            {emptyOrderListNode}
          </div>
        )}
      </div>
    </>
  );
};

OrderList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()),
  hasMoreOrders: PropTypes.bool.isRequired,
  fetchMoreOrders: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  keyPrefix: PropTypes.string.isRequired,
  emptyOrderListNode: PropTypes.node.isRequired
};

OrderList.defaultProps = {
  orders: []
};

export default OrderList;
