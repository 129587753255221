import getUserLocale from "get-user-locale";
import store from "store";
import K from "./constants";
import "./global.css";

const locale = K.supportedLocales[getUserLocale()] || K.supportedLocales.es;

if (!store.get("locale")) {
  store.set("locale", locale);
}

window.locale = store.get("locale");
