import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { withModal } from "../../../../hocs";
import { Button, Typography } from "../../../../atoms";
import K from "../../../../constants";
import PaymentCard from "../../../../organisms/PaymentCard";
import locales from "../../../../locales";
import { getIntl } from "../../../../utils";
import useTrackingContext from "../../../../contexts/Tracking";
import { MessageBox } from "../../../../molecules";

const useStyles = createUseStyles({
  verify_card_modal: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  description: {
    lineHeight: "1.5em",
    margin: "1em 0"
  },
  cta: {
    marginTop: "2em"
  }
});

const StartCardVerificationModal = ({ cardReference, onVerify, verifying }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { track, eventNames } = useTrackingContext();

  React.useEffect(() => {
    track(eventNames.verifyCard.start, { cardReferenceId: cardReference.id });
  }, []);

  return (
    <div className={classes.verify_card_modal}>
      <Typography
        variant={K.typographicVariants.body1}
        color={theme.palette.secondary.default}
        className={classes.description}
      >
        {intl.formatMessage(
          locales.verify_card_flow_start_verification_modal_content
        )}
      </Typography>
      <PaymentCard card={cardReference} />
      <Button
        size="large"
        fullWidth
        onClick={onVerify}
        className={classes.cta}
        disabled={verifying}
      >
        {intl.formatMessage(verifying ? locales.verifying : locales.verify)}
      </Button>
      <MessageBox
        type="warning"
        text={intl.formatMessage(
          locales.verification_when_new_card_message_line,
          {
            br: <br />
          }
        )}
      />
    </div>
  );
};

StartCardVerificationModal.propTypes = {
  cardReference: PropTypes.shape().isRequired,
  onVerify: PropTypes.func,
  verifying: PropTypes.bool
};

StartCardVerificationModal.defaultProps = {
  onVerify: () => {},
  verifying: false
};

export default withModal(StartCardVerificationModal, {
  title: getIntl.formatMessage(
    locales.verify_card_flow_start_verification_modal_title
  ),
  open: true,
  hideBack: true,
  withBackdropClose: false
});
