import React from "react";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useMedia } from "react-use";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import {
  Typography,
  Button,
  DropDownList,
  DropDownListHeader,
  DropDownListContent,
  DropDownListFooter
} from "../../atoms";
import { OrderInvoiceUnshipped, BannerDiscountApplied } from "../../molecules";
import { OrderContext } from "../../contexts";
import { useLocaleCurrency, useStatus } from "../../hooks";
import {
  centsToBasicUnit,
  getArticlesListFromCart,
  getTotalCountFromItemsList,
  getOrderDetail
} from "../../utils";
import locales from "../../locales";
import K from "../../constants";
import theme from "../../theme";

const useStyles = createUseStyles({
  order_invoice_list: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginTop: "16px",
    "@media(min-width: 768px)": {
      maxWidth: "612px"
    }
  },
  order_invoice_list__banner_discount_applied: {
    "@media(min-width: 768px)": {
      maxWidth: "612px"
    }
  },
  order_invoice_list__content: {
    margin: "3px 0",
    display: "grid",
    gridTemplateColumns: "3fr 0.5fr 1fr",
    justifyItems: "start",
    "& > :last-child": {
      justifySelf: "end"
    }
  },
  order_invoice_list__extend_link: {
    width: "auto",
    "&:hover": {
      textDecoration: "underline"
    }
  }
});

const OrderInvoiceList = () => {
  const order = React.useContext(OrderContext);
  const orderStatus = getOrderDetail({ order });
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const isWide = useMedia("(min-width: 768px)");

  return (
    <>
      {isWide && (
        <Typography variant={K.typographicVariants.heading4}>
          {intl.formatMessage(locales.order_payment_header_title)}
        </Typography>
      )}

      <div className={classes.order_invoice_list__banner_discount_applied}>
        {order.discountAppliedAmount > 0 && (
          <BannerDiscountApplied amount={order.discountAppliedAmount} />
        )}
      </div>

      {orderStatus.invoices?.length === 0 ? (
        <OrderInvoiceUnshipped />
      ) : (
        <div className={classes.order_invoice_list}>
          {order.invoices.map(invoice => {
            const status = useStatus({
              paymentStatus: invoice.paymentStatus,
              isOverdue: invoice.isOverdue,
              isInvoice: true
            });

            return (
              <DropDownList key={invoice.number}>
                <DropDownListHeader
                  title={intl.formatMessage(locales.items_quantity, {
                    quantity: getTotalCountFromItemsList(
                      getArticlesListFromCart(invoice.cart.items)
                    )
                  })}
                  subtitle={useLocaleCurrency(
                    centsToBasicUnit(invoice.invoiceAmount)
                  )}
                />
                <DropDownListContent>
                  {invoice.cart.items.map((item, i) => (
                    <Typography
                      variant={K.typographicVariants.body3}
                      color={theme.palette.secondary.default}
                    >
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${item.reference}-${i}`}
                        className={classes.order_invoice_list__content}
                      >
                        <span>{item.name}</span>
                        <span>
                          {intl.formatMessage(locales.item_quantity, {
                            quantity: item.quantity
                          })}
                        </span>
                        <span>
                          {useLocaleCurrency(
                            centsToBasicUnit(item.totalWithTax)
                          )}
                        </span>
                      </div>
                    </Typography>
                  ))}
                </DropDownListContent>
                <DropDownListFooter
                  title={invoice.number}
                  subtitle={status.text}
                  chipType={status.chipType}
                />
              </DropDownList>
            );
          })}
          {order.canBeReturned && (
            <Typography variant={K.typographicVariants.linkS}>
              <Button
                variant="text"
                size="small"
                className={classes.order_invoice_list__extend_link}
                onClick={() => {
                  const query = {
                    process: K.process.returnRequest,
                    step: K.steps.summary
                  };
                  history.push(`?${queryString.stringify(query)}`);
                }}
              >
                {intl.formatMessage(locales.order_return_link)}
              </Button>
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

export default OrderInvoiceList;
