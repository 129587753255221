import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme, createUseStyles } from "react-jss";
import { useMedia } from "react-use";
import { useIntl } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";
import { useOrder } from "../../../../hooks";
import { NextModal, Typography, Button } from "../../../../atoms";
import { ModalOrderInfo, TimelinePayments } from "../../components";
import useTrackingContext from "../../../../contexts/Tracking";

import locales from "../../../../locales";
import K from "../../../../constants";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../../utils";

const useStyles = createUseStyles({
  modal: {
    "@media(min-width: 768px)": {
      height: "715px"
    }
  },
  modal__content: {
    padding: "24px 40px"
  },
  confirmation_plan_modal__title: {
    marginTop: "40px",
    marginBottom: "32px"
  }
});

const ConfirmationPlanModal = ({
  selectedPlan,
  onClickConversion,
  conversionPlanStatus
}) => {
  const order = useOrder();
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const isWideScreen = useMedia("(min-width: 768px)");
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  const intl = useIntl();

  const onNext = () => {
    onClickConversion();
  };

  useEffect(() => {
    track(
      eventNames.flexiConversion.confirmation,
      buildOrderTrackingPayload(order, true)
    );
  }, []);

  useEffect(() => {
    if (conversionPlanStatus === "success") {
      history.push(
        `${location.pathname}?process=${K.process.flexiConversion}&step=${K.steps.flexiSuccess}`
      );
    } else if (conversionPlanStatus === "error") {
      history.push(
        `${location.pathname}?process=${K.process.flexiConversion}&step=${K.steps.flexiError}`
      );
    }
  }, [conversionPlanStatus]);

  return (
    <NextModal
      open
      modal
      modalClassname={classes.modal}
      contentClassname={classes.modal__content}
      hideBack
    >
      <ModalOrderInfo
        merchantLogoURL={order.merchant.logo}
        merchantName={order.merchant.name}
        orderConfirmedAt={order.confirmedAt}
        orderNumberOfArticles={getTotalCountFromItemsList(
          getArticlesListFromCart(order.cart)
        )}
      />
      <Typography
        variant={
          isWideScreen
            ? K.typographicVariants.heading1
            : K.typographicVariants.heading2
        }
        color={theme.palette.primary.default}
        className={classes.confirmation_plan_modal__title}
      >
        {intl.formatMessage(locales.flexi_conversion_confirmation_plan_title)}
      </Typography>

      <TimelinePayments
        instalments={selectedPlan?.futureInstalments}
        instalmentCount={selectedPlan?.instalmentCount}
      />

      <Button fullWidth size="large" onClick={onNext}>
        {intl.formatMessage(locales.flexi_conversion_confirmation_plan_action)}
      </Button>
    </NextModal>
  );
};

ConfirmationPlanModal.propTypes = {
  selectedPlan: PropTypes.shape(),
  onClickConversion: PropTypes.func,
  conversionPlanStatus: PropTypes.string
};

ConfirmationPlanModal.defaultProps = {
  selectedPlan: null,
  onClickConversion: () => {},
  conversionPlanStatus: ""
};

export default ConfirmationPlanModal;
