import { useLazyQuery } from "@apollo/client";
import { REFERRAL_SIGNUP_FIELD_VALIDATION } from "../../../../graphql/queries/queries";
import K from "../../../../constants";

export default ({ onCompleted, onError }) =>
  useLazyQuery(REFERRAL_SIGNUP_FIELD_VALIDATION, {
    fetchPolicy: K.fetchPolicy.cacheAndNetwork,
    nextFetchPolicy: K.fetchPolicy.cacheFirst,
    onCompleted,
    onError
  });
