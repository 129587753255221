import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const CheckBox = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8.29 13.29a.996.996 0 01-1.41 0L5.71 12.7a.996.996 0 111.41-1.41L10 14.17l6.88-6.88a.996.996 0 111.41 1.41l-7.58 7.59z"
        fill={color}
      />
    </g>
  </svg>
);

CheckBox.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

CheckBox.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default CheckBox;
