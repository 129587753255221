import { useLazyQuery } from "@apollo/client";
import { SHOPPER_QUERY, LAST_USED_STORES } from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError }) =>
  useLazyQuery(SHOPPER_QUERY(LAST_USED_STORES), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: K.fetchPolicy.cacheAndNetwork,
    onCompleted,
    onError
  });
