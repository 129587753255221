import { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";

const IntercomMessenger = ({ ...props }) => {
  useEffect(() => {
    Intercom({
      app_id: window.env.intercom.appID,
      region: window.env.intercom.region,
      ...props
    });
  }, []);
};

export default IntercomMessenger;
