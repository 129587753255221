import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Faqs = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm-1-4h2v2H9v-2Zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79C6 7.41 6.44 8 7.05 8h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47Z"
    />
  </svg>
);

Faqs.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Faqs.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Faqs;
