import { useIntl } from "react-intl";
import { centsToBasicUnit } from "../../utils";

export default (amount, { useCents, decimalPrecision = 2 } = {}) => {
  const intl = useIntl();

  return intl.formatNumber(useCents ? centsToBasicUnit(amount) : amount, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: decimalPrecision
  });
};
