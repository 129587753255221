import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import { Check } from "../../../../atoms/Illustrations";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  paid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  paid__info: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "16px"
    }
  }
});

const Paid = ({ paidOn }) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.paid}>
      <div className={classes.paid__info}>
        <Typography variant={K.typographicVariants.visual3}>
          {intl.formatMessage(locales.order_paid)}
        </Typography>
        <Typography variant={K.typographicVariants.visual3}>
          {intl.formatDate(paidOn, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "Europe/Madrid"
          })}
        </Typography>
      </div>
      <Check width={97} height={97} color={theme.palette.success.default} />
    </div>
  );
};

Paid.propTypes = {
  paidOn: PropTypes.string.isRequired
};

export default Paid;
