import React from "react";
import PropTypes from "prop-types";
import palette from "../../../theme/colorPalette";

const OpenInNew = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 18 18">
    <path
      fill={color}
      d="M15.5 16h-12c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1h-6a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1Zm-4-15c0 .55.45 1 1 1h2.59l-9.13 9.13a.996.996 0 1 0 1.41 1.41l9.13-9.13V6c0 .55.45 1 1 1s1-.45 1-1V1c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1Z"
    />
  </svg>
);

OpenInNew.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

OpenInNew.defaultProps = {
  width: 18,
  height: 18,
  color: palette.primary.default
};

export default OpenInNew;
