import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Typography } from "../../../atoms";
import { useOrder, useShopperDataQuery } from "../../../hooks";
import { Invoice } from "../components";
import locales from "../../../locales";
import K from "../../../constants";

const useStyles = createUseStyles({
  overdue: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "16px"
    }
  }
});

const Overdue = () => {
  const order = useOrder();
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const shopperResponse = useShopperDataQuery({
    fetchPolicy: K.fetchPolicy.cacheAndNetwork
  }); // TODO: It should be a context, not a query

  return (
    <div className={classes.overdue}>
      <Typography
        variant={K.typographicVariants.visual2}
        color={theme.palette.error.default}
      >
        {intl.formatMessage(locales.order_overdue)}
      </Typography>
      {order.overdueInvoices.map(invoice => (
        <Invoice
          key={invoice.number}
          invoice={invoice}
          hasAutoPaymentEnabled={
            shopperResponse.data?.shopper?.hasAutoPaymentEnabled
          }
        />
      ))}
    </div>
  );
};

export default Overdue;
