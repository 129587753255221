import useSetShopperAllowanceForMerchantsPromotions from "./mutations/useSetShopperAllowanceForMerchantsPromotions";

export default () => {
  const [
    setShopperPromotionsDecision
  ] = useSetShopperAllowanceForMerchantsPromotions({
    onCompleted: () => {},
    onError: () => {}
  });

  const onClickSetPromotionsStatus = value => {
    setShopperPromotionsDecision({
      variables: {
        value
      }
    });
  };

  return {
    onClickSetPromotionsStatus: value => {
      onClickSetPromotionsStatus(value);
    }
  };
};
