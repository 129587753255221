import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { withModal } from "../../../../hocs";
import K from "../../../../constants";
import locales from "../../../../locales";
import { Cross } from "../../../../atoms/Illustrations";
import { Button, Typography } from "../../../../atoms";
import { useOrder } from "../../../../hooks";
import { changeCardPath, goToOrderPath } from "../../../../utils";

const useStyles = createUseStyles({
  cross: {
    marginBottom: "20px"
  },
  title: {
    lineHeight: "1.3em",
    marginBottom: "1em"
  },
  modal__content: {
    marginTop: "2em",
    marginBottom: "1em",
    lineHeight: "1.5em",
    "& > p": {
      marginBottom: "1em"
    }
  },
  buttons_container: {
    padding: "1em 0",
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "10px"
    }
  }
});

const ErrorModal = ({ onRetry, onNewCard }) => {
  const order = useOrder();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div className={classes.cross}>
        <Cross width={85} height={85} color={theme.palette.error.default} />
      </div>
      <div className={classes.modal__content}>
        <Typography
          variant={K.typographicVariants.heading1}
          className={classes.title}
        >
          {intl.formatMessage(locales.verify_card_flow_error_modal_title)}
        </Typography>
        <p>
          <Typography>
            {intl.formatMessage(locales.verify_card_flow_error_modal_text_1)}
          </Typography>
        </p>
        <p>
          <Typography>
            {intl.formatMessage(locales.verify_card_flow_error_modal_text_2)}
          </Typography>
          <Typography color={K.colors.success}>
            {intl.formatMessage(locales.help_phone_number)}
          </Typography>
        </p>
      </div>
      <div className={classes.buttons_container}>
        <Button fullWidth size="large" variant="contained" onClick={onRetry}>
          <Typography
            variant={K.typographicVariants.buttonL}
            color={K.colors.white}
          >
            {intl.formatMessage(locales.verify_card_flow_error_modal_try_again)}
          </Typography>
        </Button>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => {
            onNewCard();
            goToOrderPath({
              order,
              history,
              pathFunction: changeCardPath
            });
          }}
        >
          <Typography
            variant={K.typographicVariants.buttonL}
            color={K.colors.success}
          >
            {intl.formatMessage(locales.change_card)}
          </Typography>
        </Button>
      </div>
    </>
  );
};

ErrorModal.propTypes = {
  onRetry: PropTypes.string.isRequired,
  onNewCard: PropTypes.string.isRequired
};

export default withModal(ErrorModal, {
  withIconClose: true,
  open: true,
  hideBack: true
});
