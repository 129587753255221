import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { DateTime } from "luxon";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useOrder, useLocaleCurrency } from "../../../../hooks";
import { Typography, Calendar } from "../../../../atoms";
import { Alert } from "../../../../atoms/Illustrations";
import { PartPaymentProgress } from "../../../../molecules";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  part_payment: {
    display: "flex",
    justifyContent: ({ isRenting }) => (isRenting ? "center" : "space-between"),
    alignItems: "center",
    margin: "24px 0",
    flex: 1,
    padding: ({ isCampaign }) => (isCampaign ? "0 16px" : 0),
    boxSizing: "border-box",
    "& > a > div": {
      width: ({ isRenting }) => isRenting && "107px",
      height: ({ isRenting }) => isRenting && "107px",
      "& > div": {
        width: ({ isRenting }) => isRenting && "95px",
        height: ({ isRenting }) => isRenting && "95px"
      }
    },
    "@media(min-width: 768px)": {
      maxWidth: "291px"
    }
  },
  part_payment__progress_link: {
    textDecoration: "none"
  },
  part_payment__info: {
    "& > div": {
      marginBottom: "16px"
    },
    "& > :last-child": {
      marginBottom: 0
    }
  },
  part_payment__info__section: {
    position: "relative",
    "& > :first-child": {
      marginBottom: "4px"
    },
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      left: "-16px",
      top: "4px",
      width: "9px",
      height: "9px",
      borderRadius: "50%"
    },
    "&:nth-child(1)::before": {
      backgroundColor: theme.palette.info.default
    },
    "&:nth-child(2)::before": {
      backgroundColor: theme.palette.secondary.default
    },
    "&:nth-child(3)::before": {
      backgroundColor: theme.palette.error.default
    }
  },
  part_payment__info__campaign: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& > :first-child": {
      marginBottom: "8px"
    }
  }
}));

const PartPayment = () => {
  const order = useOrder();
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles({
    isCampaign: order.currentProductName === "pp5",
    isRenting: order.currentProductName === "r1"
  });
  const dueOn = DateTime.fromISO(order.paymentPlan.nextChargeDate).toISODate();

  const Campaign = () => (
    <>
      <div className={classes.part_payment__info__campaign}>
        <Typography variant={K.typographicVariants.heading5}>
          {order.paymentPlan.number}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual3}
          color={order.isOverdue ? K.colors.error : K.colors.secondary}
        >
          {useLocaleCurrency(order.unpaidAmount, { useCents: true })}
        </Typography>
      </div>
      {order.isOverdue ? (
        <Alert width={91} height={91} color={theme.palette.error.default} />
      ) : (
        <Calendar
          day={intl.formatDate(dueOn, {
            day: "2-digit",
            timeZone: "Europe/Madrid"
          })}
          month={intl.formatDate(dueOn, {
            month: "long",
            timeZone: "Europe/Madrid"
          })}
          color={theme.palette.success.default}
          backgroundColor={theme.palette.success.light}
        />
      )}
    </>
  );

  const Others = () => (
    <>
      <Link
        to={`/order/${order.id}/instalments`}
        className={classes.part_payment__progress_link}
      >
        <PartPaymentProgress
          instalmentsCount={order.payments.count}
          paidInstalmentsCount={order.payments.paidCount}
          isOverdue={order.isOverdue}
        />
      </Link>
      <div
        className={classes.part_payment__info}
        hidden={order.currentProductName === "r1"}
      >
        <div className={classes.part_payment__info__section}>
          <Typography
            variant={K.typographicVariants.heading5}
            color={K.colors.secondary}
          >
            {intl.formatMessage(locales.total_paid)}
          </Typography>
          <Typography variant={K.typographicVariants.visual3}>
            {useLocaleCurrency(order.paidAmount, { useCents: true })}
          </Typography>
        </div>
        <div className={classes.part_payment__info__section}>
          <Typography
            variant={K.typographicVariants.heading5}
            color={K.colors.secondary}
          >
            {intl.formatMessage(locales.total_unpaid)}
          </Typography>
          <Typography variant={K.typographicVariants.visual3}>
            {useLocaleCurrency(order.unpaidAmountTotal, { useCents: true })}
          </Typography>
        </div>
        {order.isOverdue && (
          <div className={classes.part_payment__info__section}>
            <Typography
              variant={K.typographicVariants.heading5}
              color={K.colors.secondary}
            >
              {intl.formatMessage(locales.total_overdue)}
            </Typography>
            <Typography
              color={theme.palette.error.default}
              variant={K.typographicVariants.visual3}
            >
              {useLocaleCurrency(order.overdueAmount, { useCents: true })}
            </Typography>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className={classes.part_payment}>
      {order.currentProductName === "pp5" ? <Campaign /> : <Others />}
    </div>
  );
};

export default PartPayment;
