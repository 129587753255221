import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Invoice = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 80 80">
    <path
      d="M74.945 65.683 57.41 75.205V55.338l17.535-8.64v18.985zM36.998 46.698l17.565 8.654v19.87l-17.565-9.539V46.698zm18.99-10.726 17.144 8.44-17.176 8.45-17.145-8.434 17.176-8.456zm21.107 7.221c-.072-.04-.148-.067-.224-.096l-20.248-9.96a1.473 1.473 0 0 0-1.242 0L34.999 43.164l.005.006c-.042.02-.086.03-.126.054-.424.244-.666.7-.666 1.188v22.094c0 .518.273 1.005.726 1.25l20.383 11.06c.042.026.255.12.317.137.111.03.186.05.35.047.28-.005.799-.296.992-.39l20.055-10.885c.454-.244.726-.732.726-1.25V44.382c0-.488-.242-.944-.666-1.189zM29.397 55.097v.757c0 .509-.41.921-.915.921h-6.567a.919.919 0 0 1-.915-.921v-.757c0-.508.41-.92.915-.92h6.567c.505 0 .915.412.915.92m1.728-7.961-.531.535a.912.912 0 0 1-1.296 0l-4.642-4.672a.924.924 0 0 1 0-1.303l.532-.535a.911.911 0 0 1 1.294 0l4.643 4.672a.924.924 0 0 1 0 1.303m0 16.68-.531-.536a.912.912 0 0 0-1.296 0l-4.642 4.672a.924.924 0 0 0 0 1.303l.532.535c.357.36.937.36 1.294 0l4.643-4.672a.924.924 0 0 0 0-1.303m51.478-8.718v.757c0 .509.41.921.916.921h6.566a.919.919 0 0 0 .915-.921v-.757a.918.918 0 0 0-.915-.92h-6.566a.919.919 0 0 0-.916.92m-1.727-7.961.531.535a.91.91 0 0 0 1.295 0l4.643-4.672a.924.924 0 0 0 0-1.303l-.532-.535a.912.912 0 0 0-1.295 0l-4.642 4.672a.924.924 0 0 0 0 1.303m0 16.68.531-.536a.91.91 0 0 1 1.295 0l4.643 4.672a.924.924 0 0 1 0 1.303l-.532.535a.912.912 0 0 1-1.295 0l-4.642-4.672a.924.924 0 0 1 0-1.303"
      fill={color}
      transform="translate(-16 -16)"
    />
  </svg>
);

Invoice.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Invoice.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Invoice;
