import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { useSpring, animated } from "react-spring";
import { ScaleLoader } from "../Loaders";
import Backdrop from "../Backdrop";

const useStyles = createUseStyles(theme => ({
  modal_mobile: {
    display: "flex",
    height: "100%",
    width: "100%",
    marginTop: "40px",
    paddingBottom: "40px",
    boxSizing: "border-box",
    backgroundColor: theme.palette.white.default,
    position: "fixed",
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 100001,
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px"
  },
  modal_mobile__content: {
    position: "relative",
    flex: 1,
    display: "flex"
  },
  modal_mobile__content__children: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  }
}));

const ModalMobile = React.forwardRef((props, ref) => {
  const { children, open, loading, className } = props;
  const classes = useStyles();
  const componentRef = ref || React.useRef(null);
  const classNames = classnames(classes.modal_mobile, className);

  const spring = useSpring({
    to: { top: open ? 0 : window.innerHeight }
  });

  const springChildren = useSpring({
    to: { opacity: loading && open ? 0 : 1 }
  });

  return (
    <>
      <Backdrop open={open} />
      <animated.div ref={componentRef} className={classNames} style={spring}>
        <div className={classes.modal_mobile__content}>
          {loading ? (
            <ScaleLoader />
          ) : (
            <animated.div
              className={classes.modal_mobile__content__children}
              style={springChildren}
            >
              {children}
            </animated.div>
          )}
        </div>
      </animated.div>
    </>
  );
});

ModalMobile.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string
};

ModalMobile.defaultProps = {
  children: null,
  open: false,
  loading: false,
  className: ""
};

export default ModalMobile;
