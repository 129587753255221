import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { ErrorBoundary } from "../../../../../templates";
import { Typography } from "../../../../../atoms";
import K from "../../../../../constants";
import locales from "../../../../../locales";
import {
  Bag,
  Calendar,
  NavigationArrowRight
} from "../../../../../atoms/Icons";
import { Submarine } from "../../../../../atoms/Illustrations";

const useStyles = createUseStyles(theme => ({
  next_action_card: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "32px 24px 24px 24px",
    marginTop: 0,
    backgroundColor: theme.palette.white.default,
    borderRadius: "8px",
    boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.09)",
    "@media(min-width: 768px)": {
      width: 378,
      height: 251,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  next_action_card__empty: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    boxSizing: "border-box",
    "& svg": {
      marginBottom: "16px"
    }
  },
  next_action_card__container: {
    display: "inherit",
    flexDirection: "inherit",
    justifyContent: "inherit",
    minHeight: "195px",
    textDecoration: "none"
  },
  next_action_card__content: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box"
  },
  next_action_card__content__item: {
    display: "flex",
    flexDirection: "column"
  },
  next_action_card__content__item__subtitle: {
    marginTop: "8px"
  },

  next_action_card__footer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },

  next_action_card__merchant_info: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },

  next_action_card__merchant_info__order_info: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px"
  },
  next_action_card__merchant_info__order_info__items: {
    display: "flex",
    alignItems: "center",
    "& :first-child": {
      marginRight: "10px"
    }
  },
  next_action_card__merchant_info__order_info__item: {
    display: "flex",
    alignItems: "center",
    "& :first-child": {
      marginRight: "6px"
    }
  },
  next_action_card__link: {
    display: "flex",
    alignSelf: "flex-end",
    "& :hover": {
      "& > span ": {
        textDecoration: "underline"
      }
    }
  },
  next_action_card__link__button: {
    alignSelf: "flex-end",
    border: "none",
    background: "none",
    padding: 0,
    margin: 0,
    outline: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.primary.default,
    "& :active": {
      outline: "none"
    },
    "& :hover": {
      color: theme.palette.primary.default
    },
    "& svg": {
      marginLeft: "5px",
      marginTop: "2px"
    }
  }
}));

const NextActionCard = ({
  title,
  withoutNextAction,
  amountColor,
  nextPaymentAmountStr,
  nextPaymentDateStr,
  orderMerchantName,
  orderDateStr,
  orderItemsTotal,
  onClickLink,
  fetching
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles({
    withoutNextAction,
    amountColor
  });

  const CardContent = () =>
    withoutNextAction ? (
      <>
        <Typography variant={K.typographicVariants.heading3}>
          {intl.formatMessage(locales.next_payment)}
        </Typography>
        <div className={classes.next_action_card__empty}>
          <Submarine width={85} height={85} />
          <Typography variant={K.typographicVariants.heading3}>
            {intl.formatMessage(locales.dashboard_without_next_payments)}
          </Typography>
        </div>
      </>
    ) : (
      <>
        <Link to={onClickLink} className={classes.next_action_card__container}>
          <Typography variant={K.typographicVariants.heading3}>
            {intl.formatMessage({ id: title })}
          </Typography>
          <div className={classes.next_action_card__content}>
            <div className={classes.next_action_card__content__item}>
              <Typography
                variant={K.typographicVariants.visual1}
                color={amountColor}
              >
                {nextPaymentAmountStr}
              </Typography>
              <Typography
                className={classes.next_action_card__content__item__subtitle}
                variant={K.typographicVariants.heading5}
                color={K.colors.secondary}
              >
                {intl.formatMessage(locales.amount)}
              </Typography>
            </div>
            <div
              className={classes.next_action_card__content__item}
              color={amountColor}
            >
              <Typography
                variant={K.typographicVariants.visual1}
                color={amountColor}
              >
                {nextPaymentDateStr || "--"}
              </Typography>
              <Typography
                className={classes.next_action_card__content__item__subtitle}
                variant={K.typographicVariants.heading5}
                color={K.colors.secondary}
              >
                {intl.formatMessage(locales.date)}
              </Typography>
            </div>
          </div>
          <div className={classes.next_action_card__footer}>
            <div className={classes.next_action_card__merchant_info}>
              <Typography
                variant={K.typographicVariants.visual3}
                color={K.colors.secondary}
              >
                {orderMerchantName}
              </Typography>
              <div
                className={classes.next_action_card__merchant_info__order_info}
              >
                <div
                  className={
                    classes.next_action_card__merchant_info__order_info__items
                  }
                >
                  <div
                    className={
                      classes.next_action_card__merchant_info__order_info__item
                    }
                  >
                    <Calendar width={16} height={16} />
                    <Typography
                      variant={K.typographicVariants.caption1}
                      color={K.colors.secondary}
                    >
                      {orderDateStr}
                    </Typography>
                  </div>
                  <div
                    className={
                      classes.next_action_card__merchant_info__order_info__item
                    }
                  >
                    <Bag width={16} height={16} />
                    <Typography
                      variant={K.typographicVariants.caption1}
                      color={K.colors.secondary}
                    >
                      {orderItemsTotal} {intl.formatMessage(locales.articles)}
                    </Typography>
                  </div>
                </div>
                <div className={classes.next_action_card__link}>
                  <button
                    className={classes.next_action_card__link__button}
                    onClick={onClickLink}
                    type="button"
                  >
                    <Typography
                      color={K.colors.primary}
                      variant={K.typographicVariants.linkS}
                    >
                      {intl.formatMessage(locales.dashboard_see_order)}
                    </Typography>
                    <NavigationArrowRight
                      color={theme.palette[K.colors.primary].default}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </>
    );

  return (
    <div className={classes.next_action_card}>
      <ErrorBoundary component="NextActionCard">
        {!fetching && <CardContent />}
      </ErrorBoundary>
    </div>
  );
};

NextActionCard.propTypes = {
  withoutNextAction: PropTypes.bool,
  title: PropTypes.string,
  amountColor: PropTypes.oneOf([
    K.colors.secondary,
    K.colors.success,
    K.colors.info,
    K.colors.error,
    ""
  ]),
  nextPaymentAmountStr: PropTypes.string,
  nextPaymentDateStr: PropTypes.string,
  orderMerchantName: PropTypes.string,
  orderDateStr: PropTypes.string,
  orderItemsTotal: PropTypes.number,
  onClickLink: PropTypes.func,
  fetching: PropTypes.bool
};

NextActionCard.defaultProps = {
  withoutNextAction: false,
  title: "",
  amountColor: "",
  nextPaymentAmountStr: "",
  nextPaymentDateStr: "",
  orderMerchantName: "",
  orderDateStr: "",
  orderItemsTotal: 0,
  onClickLink: () => {},
  fetching: false
};

export default NextActionCard;
