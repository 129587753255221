import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Store = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path
      d="M18.36,8 L18.96,11 L5.04,11 L5.64,8 L18.36,8 L18.36,8 Z M19.1801961,6 L4.8198039,6 C4.34312288,6 3.93270807,6.33645967 3.83922323,6.80388386 L3,11 L3,11 L3,12 C3,12.5522847 3.44771525,13 4,13 L4,13 L4,13 L4,18 C4,18.5522847 4.44771525,19 5,19 L13,19 C13.5522847,19 14,18.5522847 14,18 L14,14 C14,13.4477153 14.4477153,13 15,13 L17,13 C17.5522847,13 18,13.4477153 18,14 L18,18 C18,18.5522847 18.4477153,19 19,19 C19.5522847,19 20,18.5522847 20,18 L20,13 L20,13 C20.5522847,13 21,12.5522847 21,12 L21,11 L21,11 L20.1607768,6.80388386 C20.0672919,6.33645967 19.6568771,6 19.1801961,6 Z M6,17 L6,13 L12,13 L12,17 L6,17 Z"
      fill={color}
    />
  </svg>
);

Store.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Store.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Store;
