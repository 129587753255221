import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { useMedia } from "react-use";
import { Typography } from "../../../../atoms";
import { NoStore, Event, Bag } from "../../../../atoms/Icons";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  modal_order_info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media(min-width: 768px)": {
      flexDirection: "row"
    }
  },
  modal_order_info__logo: {
    width: "72px",
    height: "72px",
    marginBottom: "16px",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: ({ useLogo }) =>
      useLogo ? "none" : `2px solid ${theme.palette.secondary.default}`,
    boxSizing: "border-box",
    "@media(min-width: 768px)": {
      width: "51px",
      height: "51px",
      marginBottom: 0,
      marginRight: "16px"
    },
    "& > img": {
      width: "100%",
      height: "100%"
    }
  },
  modal_order_info__content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > :first-child": {
      marginBottom: "8px"
    },
    "@media(min-width: 768px)": {
      alignItems: "flex-start"
    }
  },
  modal_order_info__content__order: {
    display: "flex",
    "& > div": {
      display: "flex",
      alignItems: "flex-end"
    },
    "& > div:first-child": {
      marginRight: "16px"
    }
  },
  modal_order_info__content__order__text: {
    marginLeft: "4px"
  }
}));

const ModalOrderInfo = ({
  merchantLogoURL,
  merchantName,
  orderConfirmedAt,
  orderNumberOfArticles
}) => {
  const classes = useStyles({ useLogo: Boolean(merchantLogoURL) });
  const intl = useIntl();
  const theme = useTheme();
  const isWideScreen = useMedia("(min-width: 768px)");

  return (
    <div className={classes.modal_order_info}>
      <div className={classes.modal_order_info__logo}>
        {merchantLogoURL ? (
          <img src={merchantLogoURL} alt={merchantName} />
        ) : (
          <NoStore width={39} height={isWideScreen ? 28 : 39} />
        )}
      </div>
      <div className={classes.modal_order_info__content}>
        <Typography
          variant={
            isWideScreen
              ? K.typographicVariants.visual3
              : K.typographicVariants.visual2
          }
          color={theme.palette.primary.default}
        >
          {merchantName}
        </Typography>
        <div className={classes.modal_order_info__content__order}>
          <div>
            <Event width={16} height={16} />
            <Typography
              variant={K.typographicVariants.caption1}
              color={K.colors.secondary}
              className={classes.modal_order_info__content__order__text}
            >
              {intl.formatDate(orderConfirmedAt, {
                day: "numeric",
                month: "short",
                year: "numeric"
              })}
            </Typography>
          </div>
          <div>
            <Bag width={16} height={16} />
            <Typography
              variant={K.typographicVariants.caption1}
              color={K.colors.secondary}
              className={classes.modal_order_info__content__order__text}
            >
              {intl.formatMessage(locales.items_quantity, {
                quantity: orderNumberOfArticles
              })}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalOrderInfo.propTypes = {
  merchantLogoURL: PropTypes.string,
  merchantName: PropTypes.string,
  orderConfirmedAt: PropTypes.string,
  orderNumberOfArticles: PropTypes.number
};

ModalOrderInfo.defaultProps = {
  merchantLogoURL: "",
  merchantName: "",
  orderConfirmedAt: "",
  orderNumberOfArticles: 0
};

export default ModalOrderInfo;
