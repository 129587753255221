import React from "react";
import { createUseStyles } from "react-jss";
import { Card } from "../../atoms";
import { ErrorBoundary } from "../../templates";
import { AmortizationOption } from "./components";
import { useOrder } from "../../hooks";

const useStyles = createUseStyles({
  order_more_options: {
    width: "100%",
    marginBottom: "24px",
    "@media(min-width: 768px)": {
      width: "295px"
    }
  },
  order_more_options__link: {
    borderTop: 0,
    height: "56px",
    boxSizing: "border-box",
    alignItems: "center",
    cursor: "pointer",
    "& > p": {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        marginRight: "16px"
      }
    }
  }
});

const OrderMoreOptions = () => {
  const classes = useStyles();
  const order = useOrder();

  if (!order.isAmortizable) return null;

  return (
    <ErrorBoundary component="OrderMoreOptions">
      <div className={classes.order_more_options}>
        <Card>
          <AmortizationOption />
        </Card>
      </div>
    </ErrorBoundary>
  );
};

export default OrderMoreOptions;
