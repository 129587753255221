import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { useMedia } from "react-use";
import { Button, Banner } from "@sequra/telar";
import { OpenInNew } from "../Icons";
import Typography from "../Typography";
import locales from "../../locales";
import K from "../../constants";
import useTrackingContext from "../../contexts/Tracking";

const useStyles = createUseStyles(theme => ({
  banner: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    width: "100%",
    backgroundColor: "#FFBA08",
    fontFamily: theme.fontFamily,
    boxSizing: "border-box",
    textAlign: "center",
    justifyContent: ({ isWide }) => isWide && "space-between",
    flexDirection: ({ isWide }) => (isWide ? "row" : "column")
  },
  banner__content: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "@media (min-width: 1280px)": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }
  },
  banner__content__button: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "12px"
  },
  banner__content__boldText: {
    fontWeight: 700
  },
  banner__collapsed_content: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%"
  },
  banner__collapsed_content__link: {
    color: "inherit",
    "&:hover, &:visited, &:active": {
      color: "inherit"
    }
  }
}));

const StickyBanner = ({ uploadUrl }) => {
  const isWide = useMedia("(min-width: 1280px)");
  const theme = useTheme();
  const classes = useStyles({ isWide });
  const { track, eventNames } = useTrackingContext();
  const intl = useIntl();

  const handleDocumentationButtonClick = () => {
    track(eventNames.mandatoryDocumentation.banner.uploadButtonClicked);
    window.open(uploadUrl, "_self");
  };

  useEffect(() => {
    track(eventNames.mandatoryDocumentation.banner.viewed);
  }, []);

  const BannerContent = () => {
    return (
      <div className={classes.banner}>
        <div className={classes.banner__content}>
          <Typography variant={K.typographicVariants.heading5}>
            {intl.formatMessage(locales.sticky_banner_title)}
          </Typography>

          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(locales.sticky_banner_subtitle)}{" "}
            <span className={classes.banner__content__boldText}>
              {intl.formatMessage(locales.sticky_banner_subtitle_bold_text)}
            </span>
          </Typography>
        </div>

        <div className={classes.banner__content}>
          <Button size="small" onClick={handleDocumentationButtonClick}>
            <div className={classes.banner__content__button}>
              <Typography
                variant={K.typographicVariants.buttonS}
                color="#FFFFFF"
              >
                {intl.formatMessage(locales.sticky_banner_button_text)}
              </Typography>
              <OpenInNew color={theme.palette.white.default} />
            </div>
          </Button>

          <Typography variant={K.typographicVariants.body3}>
            ({intl.formatMessage(locales.sticky_banner_button_tooltip_text)})
          </Typography>
        </div>
      </div>
    );
  };

  const CollapsedContent = () => {
    return (
      <div className={classes.banner__collapsed_content}>
        <Typography variant={K.typographicVariants.linkM}>
          <a
            href={uploadUrl}
            className={classes.banner__collapsed_content__link}
            onClick={() => {
              track(
                eventNames.mandatoryDocumentation.banner.uploadButtonClicked
              );
            }}
          >
            {intl.formatMessage(locales.sticky_banner_title)}
          </a>
        </Typography>
        <Typography variant={K.typographicVariants.body2}>
          {" "}
          {intl.formatMessage(locales.sticky_collapsed_explanatory_text)}
        </Typography>
      </div>
    );
  };

  return (
    <Banner type="warning" collapsedContent={<CollapsedContent />}>
      <BannerContent />
    </Banner>
  );
};

StickyBanner.propTypes = {
  uploadUrl: PropTypes.string.isRequired
};

export default StickyBanner;
