import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { Paper, Typography } from "../../../../atoms";
import { NavigationArrowRight } from "../../../../atoms/Icons";

import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  update_card_info: {
    margin: "8px 0 24px",
    backgroundColor: theme.palette.warning.light
  },

  update_card_info__link: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "8px",
    textAlign: "right",
    textDecoration: "none"
  }
}));

const UpdateCardInfo = ({ title, text, link, linkText }) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.update_card_info}>
        <Typography
          variant={K.typographicVariants.heading4}
          className={classes.update_card_info__title}
        >
          {title}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          className={classes.update_card_info__text}
        >
          {text}
        </Typography>

        <Link to={link} className={classes.update_card_info__link}>
          <Typography
            variant={K.typographicVariants.linkS}
            className={classes.update_card_info__text}
          >
            {linkText}
          </Typography>
          <NavigationArrowRight />
        </Link>
      </Paper>
    </>
  );
};

UpdateCardInfo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default UpdateCardInfo;
