import { useLazyQuery } from "@apollo/client";
import {
  ORDER_INFO_FRAGMENT,
  MERCHANT_FRAGMENT,
  INVOICES_FRAGMENT,
  UNSHIPPED_CART_FRAGMENT,
  CARD_REFERENCE_FRAGMENT,
  PAYMENT_PLAN_FRAGMENT,
  RENTING_PLAN_FRAGMENT,
  SHIPPED_CART_FRAGMENT,
  CONVERSION_PLANS_FRAGMENT,
  ORDERS_PAGINATED_QUERY
} from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError, state, offset, limit }) => {
  return useLazyQuery(
    ORDERS_PAGINATED_QUERY(
      ORDER_INFO_FRAGMENT,
      MERCHANT_FRAGMENT,
      INVOICES_FRAGMENT,
      SHIPPED_CART_FRAGMENT,
      UNSHIPPED_CART_FRAGMENT,
      CARD_REFERENCE_FRAGMENT,
      PAYMENT_PLAN_FRAGMENT,
      RENTING_PLAN_FRAGMENT,
      CONVERSION_PLANS_FRAGMENT
    ),
    {
      variables: {
        offset,
        limit,
        state
      },
      fetchPolicy: K.fetchPolicy.networkOnly,
      nextFetchPolicy: K.fetchPolicy.cacheFirst,
      onCompleted,
      onError
    }
  );
};
