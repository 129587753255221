import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useMedia } from "react-use";
import { createUseStyles } from "react-jss";
import { ArrowRight } from "../Icons";

const useStyles = createUseStyles(theme => ({
  card_footer: {
    padding: ({ isWide }) => (isWide ? "16px 24px" : "16px"),
    borderTop: `1px solid ${theme.palette.background.default}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pointerEvents: "all",
    cursor: "pointer"
  }
}));

const CardFooter = ({ children, icon, hideIcon, onClick, className }) => {
  const isWide = useMedia("(min-width: 768px)");
  const classes = useStyles({ isWide });
  const Icon = icon || <ArrowRight />;

  return (
    <div
      className={classnames(classes.card_footer, className)}
      data-testid="atom_card_footer"
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      {children}
      {hideIcon || Icon}
    </div>
  );
};

CardFooter.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  hideIcon: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};

CardFooter.defaultProps = {
  icon: null,
  hideIcon: false,
  onClick: () => {},
  className: ""
};

export default CardFooter;
