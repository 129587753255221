import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Typography } from "../../atoms";
import { Card as CardIcon, Visa, MasterCard } from "../../atoms/Icons";
import K from "../../constants";

const useStyles = createUseStyles({
  payment_card_info: {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > :nth-child(1), & > :nth-child(2)": {
      marginRight: "8px"
    }
  }
});

const OrderPaymentCardInfo = ({ issuer, lastFour, hasAutoPaymentEnabled }) => {
  const classes = useStyles();

  return (
    <div>
      {hasAutoPaymentEnabled && (
        <Typography variant={K.typographicVariants.caption1}>
          Pago automático
        </Typography>
      )}
      <div className={classes.payment_card_info}>
        {hasAutoPaymentEnabled || <CardIcon />}
        {issuer === "MasterCard" ? (
          <MasterCard
            width={hasAutoPaymentEnabled ? 16 : 28}
            height={hasAutoPaymentEnabled ? 10 : 24}
          />
        ) : (
          <Visa
            width={hasAutoPaymentEnabled ? 28 : 32}
            height={hasAutoPaymentEnabled ? 10 : 24}
          />
        )}
        <Typography
          variant={hasAutoPaymentEnabled ? "caption2" : "body2"}
          color={K.colors.secondary}
        >
          {`···· ···· ···· ${lastFour}`}
        </Typography>
      </div>
    </div>
  );
};

OrderPaymentCardInfo.propTypes = {
  issuer: PropTypes.string,
  lastFour: PropTypes.string,
  hasAutoPaymentEnabled: PropTypes.bool
};

OrderPaymentCardInfo.defaultProps = {
  issuer: "",
  lastFour: "",
  hasAutoPaymentEnabled: false
};

export default OrderPaymentCardInfo;
