import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Typography, MerchantLogo } from "../../../../../atoms";
import K from "../../../../../constants";

const useStyles = createUseStyles(theme => ({
  merchant: {
    padding: "16px",
    border: `1px solid ${theme.palette.shade.default}`,
    borderRadius: "8px",
    overflow: "hidden"
  },
  merchant_title: {
    marginBottom: "4px"
  },
  merchant_content: {
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

const MerchantCard = ({ title, content, logo }) => {
  const classes = useStyles();

  return (
    <div className={classes.merchant}>
      <MerchantLogo merchantLogo={logo} />
      <div>
        <Typography
          variant={K.typographicVariants.heading3}
          className={classes.merchant_title}
        >
          {title}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          className={classes.merchant_content}
        >
          {content}
        </Typography>
      </div>
    </div>
  );
};

MerchantCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  logo: PropTypes.string
};

MerchantCard.defaultProps = {
  title: "",
  content: "",
  logo: ""
};

export default MerchantCard;
