import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { Typography } from "../../../../atoms";
import { useOrder } from "../../../../hooks";
import ModalOrderInfo from "../ModalOrderInfo";
import K from "../../../../constants";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../../utils";

const useStyles = createUseStyles(theme => ({
  modal_description: {
    height: "686px",
    backgroundColor: theme.palette.white.default,
    borderRadius: "8px",
    padding: "56px 48px 32px",
    boxSizing: "border-box",
    overflow: "auto",
    "@media(min-width: 768px)": {
      width: "470px"
    }
  },
  modal_description__title: {
    marginTop: "40px",
    marginBottom: ({ hasSubtitle }) => (hasSubtitle ? "8px" : "24px")
  },
  modal_description__subtitle: {
    marginBottom: "32px"
  }
}));

const ModalDescription = ({ title, subtitle, children }) => {
  const order = useOrder();
  const classes = useStyles({ hasSubtitle: subtitle });
  const theme = useTheme();

  return (
    <div className={classes.modal_description}>
      <ModalOrderInfo
        merchantLogoURL={order.merchant.logo}
        merchantName={order.merchant.name}
        orderConfirmedAt={order.confirmedAt}
        orderNumberOfArticles={getTotalCountFromItemsList(
          getArticlesListFromCart(order.cart)
        )}
      />
      <Typography
        variant={K.typographicVariants.heading1}
        color={theme.palette.primary.default}
        className={classes.modal_description__title}
      >
        {title}
      </Typography>
      {subtitle ? (
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.primary.default}
          className={classes.modal_description__subtitle}
        >
          {subtitle}
        </Typography>
      ) : null}
      <div>{children}</div>
    </div>
  );
};

ModalDescription.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node
};

ModalDescription.defaultProps = {
  title: "",
  subtitle: "",
  children: null
};

export default ModalDescription;
