import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useTheme, createUseStyles } from "react-jss";
import { useSpring, animated } from "react-spring";
import { Typography } from "../../../../atoms";
import { ExpandMore } from "../../../../atoms/Icons";
import { useLocaleCurrency } from "../../../../hooks";
import ModalDescription from "../ModalDescription";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  summary_list: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    borderBottom: `1px solid ${theme.palette.shade.default}`
  },
  summary_list__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: ({ isOpen }) => (isOpen ? "16px" : 0),
    cursor: "pointer",
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& > :first-child": {
        marginRight: "8px"
      },
      "& > :last-child": {
        display: "flex",
        transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        transform: ({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(0)")
      }
    }
  },
  summary_list__entries: {
    display: "flex",
    flexDirection: "column",
    "& > div": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "8px"
    }
  },
  summary_list__subheader: {
    marginBottom: "16px"
  },

  summary_list_total: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const ConversionModalDescription = ({ conversionPlan }) => {
  const intl = useIntl();
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyles({ isOpen });

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const props = useSpring({
    height: isOpen ? "auto" : 0,
    overflow: isOpen ? "hidden" : "auto"
  });

  return (
    <ModalDescription title={intl.formatMessage(locales.payment_summary)}>
      <div className={classes.summary_list}>
        <div className={classes.summary_list__header}>
          <div>
            <Typography
              variant={K.typographicVariants.body2}
              color={theme.palette.primary.default}
            >
              {intl.formatDate(Date.now(), {
                year: "numeric",
                month: "short",
                day: "2-digit"
              })}
            </Typography>
            <div
              onClick={toggleIsOpen}
              onKeyPress={toggleIsOpen}
              role="tab"
              tabIndex="0"
            >
              <ExpandMore color={theme.palette.primary.default} />
            </div>
          </div>
          <Typography
            variant={K.typographicVariants.body2}
            color={theme.palette.primary.default}
          >
            {useLocaleCurrency(conversionPlan.downPaymentTotal, {
              useCents: true
            })}
          </Typography>
        </div>
        <animated.div
          style={props}
          className={classes.summary_list__entries}
          role="list"
        >
          {[
            <>
              <Typography
                variant={K.typographicVariants.body3}
                color={theme.palette.secondary.default}
              >
                {intl.formatMessage(locales.DOWN_PAYMENT)}
              </Typography>
              <Typography
                variant={K.typographicVariants.body3}
                color={theme.palette.secondary.default}
              >
                {useLocaleCurrency(conversionPlan.downPaymentAmount, {
                  useCents: true
                })}
              </Typography>
            </>,
            <>
              <Typography
                variant={K.typographicVariants.body3}
                color={theme.palette.secondary.default}
              >
                {intl.formatMessage(locales.DOWN_PAYMENT_FEE)}
              </Typography>
              <Typography
                variant={K.typographicVariants.body3}
                color={theme.palette.secondary.default}
              >
                {useLocaleCurrency(conversionPlan.downPaymentFees, {
                  useCents: true
                })}
              </Typography>
            </>
          ].map((item, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <div role="listitem" key={key}>
              {item}
            </div>
          ))}
        </animated.div>
        <div className={classes.summary_list__subheader}>
          <Typography
            variant={K.typographicVariants.caption1}
            color={theme.palette.secondary.default}
          >
            {intl.formatMessage(locales.quantity_instalments, {
              quantity: 1
            })}
          </Typography>
        </div>
      </div>
      <div className={classes.summary_list_total}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.total)}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {useLocaleCurrency(conversionPlan.downPaymentTotal, {
            useCents: true
          })}
        </Typography>
      </div>
    </ModalDescription>
  );
};

ConversionModalDescription.propTypes = {
  conversionPlan: PropTypes.shape().isRequired
};

export default ConversionModalDescription;
