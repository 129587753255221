import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  StartCardVerificationModal,
  SuccessfullyVerifiedCardModal,
  MufasaFormModal,
  ErrorModal
} from "./modals";
import { useOrder } from "../../hooks";
import { ORDER_QUERY, PSD2_CARD_VERIFICATION_FRAGMENT } from "../../graphql";
import K from "../../constants";
import { MufasaTokenizationGateway } from "../../molecules";
import useTrackingContext from "../../contexts/Tracking";

const VerifyCardFlow = () => {
  const [verifying, setVerifying] = useState(false);
  const [errorVerification, setErrorVerification] = useState(false);
  const [successVerification, setSuccessVerification] = useState(false);
  const order = useOrder();
  const { track, eventNames } = useTrackingContext();

  const { data, loading } = useQuery(
    ORDER_QUERY(PSD2_CARD_VERIFICATION_FRAGMENT),
    {
      variables: { orderId: order.id },
      fetchPolicy: K.fetchPolicy.cacheAndNetwork
    }
  );

  if (loading) {
    return null;
  }
  // TODO: handle error?

  const { psd2CardVerification } = data.shopper?.orderById;

  if (!psd2CardVerification) {
    return <Redirect to={`/order/${order.id}`} replace />;
  }

  const { cardReference, verified } = psd2CardVerification;
  const cardReferenceId = cardReference.id;

  const onVerificationSuccess = () => {
    track(eventNames.verifyCard.success, { cardReferenceId });
    setVerifying(false);
    setSuccessVerification(true);
  };

  const onVerificationError = () => {
    track(eventNames.verifyCard.fail, { cardReferenceId });
    setVerifying(false);
    setErrorVerification(true);
  };

  if (errorVerification) {
    return (
      <ErrorModal
        onRetry={() => {
          track(eventNames.verifyCard.retry, {
            cardReferenceId
          });
          setVerifying(false);
          setErrorVerification(false);
        }}
        onNewCard={() => {
          track(eventNames.verifyCard.newCard, {
            cardReferenceId
          });
        }}
      />
    );
  }

  if (verified || successVerification) {
    return (
      <SuccessfullyVerifiedCardModal
        lastFour={cardReference.cardFingerprint ? cardReference.lastFour : null}
      />
    );
  }

  if (verifying && !cardReference.cardFingerprint) {
    return (
      <MufasaFormModal
        cardReferenceId={cardReferenceId}
        onSuccess={onVerificationSuccess}
        onError={onVerificationError}
        onCancel={() => setVerifying(false)}
        onSubmit={() => {
          track(eventNames.verifyCard.attempt.withoutCardFingerprint, {
            cardReferenceId
          });
        }}
        onBack={() => setVerifying(false)}
      />
    );
  }

  return (
    <>
      <StartCardVerificationModal
        cardReference={cardReference}
        onVerify={() => setVerifying(true)}
        verifying={verifying}
      />
      {verifying && (
        <MufasaTokenizationGateway
          hidden
          cardReferenceId={cardReferenceId}
          onSuccess={onVerificationSuccess}
          onError={onVerificationError}
          onCancel={() => setVerifying(false)}
          onSubmit={() => {
            track(eventNames.verifyCard.attempt.withCardFingerprint, {
              cardReferenceId
            });
          }}
        />
      )}
    </>
  );
};

export default VerifyCardFlow;
