import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const ArrowDropUp = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g transform="matrix(-1 0 0 1 19 8)">
      <g transform="rotate(-90 6.5 4.002)">
        <path
          d="M3-.998v10c0 .89 1.077 1.337 1.707.707l5-5a1 1 0 000-1.414l-5-5C4.077-2.335 3-1.89 3-.998z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

ArrowDropUp.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

ArrowDropUp.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default ArrowDropUp;
