import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(theme => ({
  order_skeleton: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "100vh",
    "@media(min-width: 1024px)": {
      display: "flex",
      filter: "blur(5px)"
    }
  },
  order_skeleton__sidebar: {
    display: "none",
    "@media (min-width: 1280px)": {
      display: "block",
      width: "240px",
      height: "100vh",
      backgroundColor: theme.palette.shade.default
    }
  },
  order_skeleton__content: {
    display: "none",
    "@media (min-width: 1024px)": {
      width: "947px",
      height: "100vh",
      backgroundColor: theme.palette.background.default,
      boxSizing: "border-box",
      margin: "0 auto",
      display: "flex",
      padding: "57px 1vh 49px",
      "& > div:first-child": {
        marginRight: "24px"
      },
      "@media (min-width: 1280px)": {
        padding: "57px 2vh 49px",
        "& > div:first-child": {
          marginRight: "39px"
        }
      }
    }
  },
  order_skeleton__content__left__header: {
    display: "flex",
    marginBottom: "115px",
    "& > div:first-child": {
      width: "78px",
      height: "77px",
      borderRadius: "8px",
      backgroundColor: theme.palette.shade.default,
      marginRight: "9px"
    },
    "& > div:last-child": {
      "& > div:first-child": {
        width: "144px",
        height: "26px",
        backgroundColor: theme.palette.shade.default,
        marginBottom: "6px"
      },
      "& > div:last-child": {
        width: "223px",
        height: "43px",
        backgroundColor: theme.palette.shade.default
      }
    }
  },
  order_skeleton__content__left__description: {
    display: "flex",
    marginBottom: "77px",
    "& > div": {
      width: "187px",
      height: "116px",
      borderRadius: "8px",
      backgroundColor: theme.palette.shade.default,
      marginRight: "27px"
    },
    "& > div:last-child": {
      marginRight: 0
    }
  },
  order_skeleton__content__left__items: {
    "& div": {
      width: "615px",
      height: "87px",
      borderRadius: "8px",
      backgroundColor: theme.palette.shade.default,
      marginBottom: "6px"
    }
  },
  order_skeleton__content__right__header: {
    marginBottom: "115px",
    "& > div": {
      width: "293px",
      height: "68px",
      borderRadius: "8px",
      backgroundColor: theme.palette.shade.default
    }
  },
  order_skeleton__content__right__description: {
    marginBottom: "70px",
    "& > div": {
      width: "293px",
      height: "216px",
      borderRadius: "8px",
      backgroundColor: theme.palette.shade.default
    }
  },
  order_skeleton__content__right__items: {
    "& > div": {
      width: "293px",
      height: "57px",
      borderRadius: "8px",
      backgroundColor: theme.palette.shade.default
    },
    "& > div:first-child": {
      marginBottom: "6px"
    }
  }
}));

const OrderSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.order_skeleton}>
      <div className={classes.order_skeleton__sidebar} />
      <div className={classes.order_skeleton__content}>
        <div>
          <div className={classes.order_skeleton__content__left__header}>
            <div />
            <div>
              <div />
              <div />
            </div>
          </div>

          <div className={classes.order_skeleton__content__left__description}>
            <div />
            <div />
            <div />
          </div>

          <div className={classes.order_skeleton__content__left__items}>
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <div>
          <div className={classes.order_skeleton__content__right__header}>
            <div />
          </div>

          <div className={classes.order_skeleton__content__right__description}>
            <div />
          </div>

          <div className={classes.order_skeleton__content__right__items}>
            <div />
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSkeleton;
