import React, { useEffect } from "react";
import useTrackingContext from "../../contexts/Tracking";
import AddCardModal from "./AddCardModal";

const AddCardToYourAccount = () => {
  const {
    track,
    eventProperties,
    eventPropertyCategories,
    eventNames
  } = useTrackingContext();
  useEffect(() => {
    track(eventNames.tokenization.start, {
      [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
    });
  }, []);

  return <AddCardModal open hideBack />;
};

export default AddCardToYourAccount;
