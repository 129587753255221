import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import {
  Typography,
  ListHeader,
  ListItem,
  Collapse,
  Checkbox
} from "../../atoms";
import { ExpandMore } from "../../atoms/Icons";
import { centsToBasicUnit } from "../../utils";
import locales from "../../locales";
import ArticleList from "../ArticleList";
import K from "../../constants";

const useStyles = createUseStyles({
  invoice: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  invoice__checkbox: {
    marginRight: "8px",
    "& span": {
      width: "24px",
      height: "24px",
      "& svg": {
        bottom: "-2px",
        left: "-2px"
      }
    }
  },
  invoice__content__first_column: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "8px"
    }
  },
  invoice__content__first_column__dropdown: {
    display: "flex",
    "& > :last-child": {
      transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      transform: ({ collapsed }) => {
        if (collapsed) return "rotate(90deg)";
        return "rotate(0)";
      },
      marginTop: "-5px"
    }
  },
  invoice__content__second_column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& :first-child": {
      marginBottom: "8px"
    }
  }
});

const Invoice = ({
  invoice,
  selectable,
  selected,
  collapsible,
  collapsed,
  showRemainingDays,
  onChange,
  onCollapse
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles({ collapsed });

  const getRemainingDays = () => {
    const end = DateTime.fromISO(invoice.dueOn);
    const start = DateTime.now();

    return Math.abs(Math.trunc(start.diff(end, "days").values.days));
  };

  const getRemainingDaysColor = () => {
    const days = getRemainingDays(invoice);
    if (days < 0) return theme.palette.error.default;
    if (days <= 2) return theme.palette.warning.default;
    return theme.palette.success.default;
  };

  return (
    <>
      <ListItem>
        {selectable && (
          <Checkbox
            defaultSelected={invoice.isOverdue || selected}
            onChange={checked => {
              onChange(checked, invoice);
            }}
            className={classes.invoice__checkbox}
            disabled={invoice.isOverdue}
          />
        )}
        <ListHeader
          onClick={() => {
            onCollapse(invoice);
          }}
        >
          <div className={classes.invoice__content__first_column}>
            <Typography variant={K.typographicVariants.body2}>
              {invoice.number}
            </Typography>
            <Typography
              variant={K.typographicVariants.caption1}
              className={classes.invoice__content__first_column__dropdown}
            >
              {intl.formatMessage(
                locales.order_shipment_articles_count_plural,
                {
                  quantity: invoice.cart.items.length
                }
              )}
              <i hidden={!collapsible}>
                <ExpandMore />
              </i>
            </Typography>
          </div>
          <div className={classes.invoice__content__second_column}>
            <Typography>
              {intl.formatNumber(centsToBasicUnit(invoice.invoiceAmount), {
                style: "currency",
                currency: "eur"
              })}
            </Typography>
            {showRemainingDays && (
              <Typography
                variant={K.typographicVariants.caption1}
                color={getRemainingDaysColor()}
              >
                {invoice.isOverdue
                  ? intl.formatMessage(locales.order_payment_overdue)
                  : intl.formatMessage(locales.order_payment_pending_days, {
                      quantity: getRemainingDays()
                    })}
              </Typography>
            )}
          </div>
        </ListHeader>
      </ListItem>
      <Collapse collapsed={collapsed}>
        <ArticleList articles={invoice.cart.items} />
      </Collapse>
    </>
  );
};

Invoice.propTypes = {
  invoice: PropTypes.shape(),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  showRemainingDays: PropTypes.bool,
  onChange: PropTypes.func,
  onCollapse: PropTypes.func
};

Invoice.defaultProps = {
  invoice: {},
  selectable: false,
  selected: false,
  collapsible: false,
  collapsed: false,
  showRemainingDays: false,
  onChange: () => {},
  onCollapse: () => {}
};

export default Invoice;
