import { useEffect, useState } from "react";
import useGenerateConversionPaymentGateway from "../mutations/useGenerateConversionPaymentGateway";

export default (orderId, instalmentCount, cardReferenceId) => {
  const [iframeUrl, setIframeUrl] = useState(null);

  const [
    generateConversionPaymentGateway
  ] = useGenerateConversionPaymentGateway();

  const getIframeUrl = async variables => {
    const response = await generateConversionPaymentGateway({ variables });
    const {
      generateConversionPaymentGatewayRequest: { iframeUrl: url }
    } = response.data;
    setIframeUrl(url);
  };

  useEffect(() => {
    getIframeUrl({ orderId, instalmentCount, cardReferenceId });
  }, []);

  return {
    iframeUrl
  };
};
