import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const StarBorder = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 20 19">
    <path
      d="M20,7.24 L12.81,6.62 L10,0 L7.19,6.63 L0,7.24 L5.46,11.97 L3.82,19 L10,15.27 L16.18,19 L14.55,11.97 L20,7.24 Z M10,13.4 L6.24,15.67 L7.24,11.39 L3.92,8.51 L8.3,8.13 L10,4.1 L11.71,8.14 L16.09,8.52 L12.77,11.4 L13.77,15.68 L10,13.4 Z"
      fill={color}
    />
  </svg>
);

StarBorder.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

StarBorder.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default StarBorder;
