import React from "react";
import PropTypes from "prop-types";

const Visa = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 43 21">
    <path
      fill="#3C58BF"
      d="M13.639 0l-2.095 12.974h3.348L16.986 0h-3.347zm10.099 5.285c-1.17-.575-1.887-.963-1.887-1.552.014-.534.606-1.082 1.929-1.082 1.088-.027 1.887.227 2.494.48l.303.135.456-2.714c-.662-.255-1.71-.535-3.005-.535-3.307 0-5.635 1.71-5.65 4.158-.027 1.806 1.667 2.808 2.935 3.409 1.295.617 1.736 1.016 1.736 1.564-.014.842-1.047 1.23-2.01 1.23-1.336 0-2.054-.2-3.143-.669l-.44-.2-.469 2.821c.787.348 2.233.654 3.734.67 3.514 0 5.8-1.685 5.83-4.293.01-1.43-.883-2.526-2.813-3.422zM35.616.04h-2.59c-.799 0-1.405.229-1.75 1.044l-4.974 11.89h3.514l.967-2.574h3.932l.502 2.584h3.099L35.616.04zm-3.858 7.762c.068.006 1.349-4.202 1.349-4.202l1.02 4.202h-2.37zM8.747 0l-3.28 8.815-.358-1.739C4.502 5.071 2.601 2.891.479 1.808l3.004 11.153h3.542L12.289.001H8.747V0z"
      transform="translate(-861.000000, -229.000000) translate(828.000000, 125.000000) translate(1.000000, 35.000000) translate(16.000000, 52.000000) translate(15.992958, 17.000000) translate(0.000000, 4.000000) translate(3.275439, 0.000000)"
    />
    <path
      fill="#F7981D"
      d="M7.218 1.63C6.98.666 6.218.015 5.182 0H.325l-.05.234c3.789.97 6.969 3.957 8 6.766L7.218 1.63z"
      transform="translate(-861.000000, -229.000000) translate(828.000000, 125.000000) translate(1.000000, 35.000000) translate(16.000000, 52.000000) translate(15.992958, 17.000000) translate(0.000000, 4.000000)"
    />
  </svg>
);

Visa.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

Visa.defaultProps = {
  width: 43,
  height: 21
};

export default Visa;
