import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const MailSent = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 74 68">
    <path
      fill={color}
      d="M64.0023849,21.9608362 L64.0023849,3 C64.0023849,1.34314575 62.6592392,9.90969264e-15 61.0023849,0 L12.9959809,0 C11.3391267,-1.89307914e-14 9.99598092,1.34314575 9.99598092,3 L9.99598092,21.9622934 L9.99598092,21.9622934 L0.548414451,28.1677508 C0.218156523,28.3855231 0,28.7572742 0,29.1798657 L0,64 C2.705415e-16,66.209139 1.790861,68 4,68 L70,68 C72.209139,68 74,66.209139 74,64 L74,29.1798657 L74,29.1798657 C74,28.7572742 73.7818435,28.3855231 73.4514221,28.1679128 L64.0023849,21.9608362 Z M9.99598092,32.414715 L6.15168713,30.3942092 L9.99598092,30.3942092 L9.99598092,32.414715 Z M2.45119689,31.1985904 L30.6358758,46.0119625 L2.45119689,64.5229297 L2.45119689,31.1985904 Z M68.7077025,65.5713129 L5.29148043,65.5713129 L36.9995098,44.7459689 L68.7077025,65.5713129 Z M43.363634,46.0121244 L71.5486397,31.1985904 L71.5486397,64.5235773 L43.363634,46.0121244 L43.363634,46.0121244 Z M64.0023849,30.3942092 L67.8484763,30.3942092 L64.0023849,32.4155245 L64.0023849,30.3942092 Z M68.7077025,27.9655222 L64.0023849,27.9655222 L64.0023849,24.8746131 L68.7077025,27.9655222 Z M61.5511881,4.42868708 L61.5511881,33.7038621 L61.5511881,33.7038621 L41.0384286,44.484966 L37.6765303,42.2769656 C37.2662,42.0073813 36.7326561,42.0073813 36.3223258,42.2769656 L32.9609178,44.484966 L12.4471778,33.7030525 L12.4471778,4.42868708 C12.4471778,3.32411758 13.3426083,2.42868708 14.4471778,2.42868708 L59.5511881,2.42868708 C60.6557576,2.42868708 61.5511881,3.32411758 61.5511881,4.42868708 Z M9.99598092,27.9655222 L5.29262433,27.9655222 L9.99598092,24.8762322 L9.99598092,27.9655222 Z"
    />
    <path
      fill={color}
      d="M37,8 C44.1797017,8 50,13.8202983 50,21 C50,28.1797017 44.1797017,34 37,34 C29.8202983,34 24,28.1797017 24,21 C24,13.8202983 29.8202983,8 37,8 Z M37,10.3111111 C31.0966897,10.3111111 26.3111111,15.0966897 26.3111111,21 C26.3111111,26.9033103 31.0966897,31.6888889 37,31.6888889 C42.9033103,31.6888889 47.6888889,26.9033103 47.6888889,21 C47.6888889,15.0966897 42.9033103,10.3111111 37,10.3111111 Z M35.7540097,15.721477 C36.3601509,15.2129269 37.1499082,14.9782493 37.9354023,15.0732727 C38.6381041,15.1582805 39.1666667,15.7546383 39.1666667,16.4624633 L39.1666667,16.4624633 L39.1666667,26.2687356 C39.1666667,26.9487442 38.6154108,27.5 37.9354023,27.5 C37.2553938,27.5 36.7041379,26.9487442 36.7041379,26.2687356 L36.7041379,26.2687356 L36.7041379,20.3953488 L36.728046,19.2280362 L36.7678927,17.9515504 C36.3587995,18.3826443 36.0745594,18.6653747 35.9151724,18.7997416 L35.9151724,18.7997416 L35.5189206,19.1352824 C34.995962,19.5781168 34.2144347,19.5194503 33.7634483,19.0035053 C33.7580719,18.9973545 33.7527579,18.9911494 33.7475072,18.9848911 C33.3178491,18.4727813 33.3846894,17.7093275 33.8967992,17.2796694 L33.8967992,17.2796694 Z"
    />
  </svg>
);

MailSent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

MailSent.defaultProps = {
  width: 74,
  height: 68,
  color: theme.palette.primary.default
};

export default MailSent;
