import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useLocation, useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { DASHBOARD_PATH } from "../../routes";
import useReferralProgram from "./hooks/useReferralProgram";
import useShopperContext from "../../contexts/Shopper";
import useTrackingContext from "../../contexts/Tracking";
import ShareReferralModal from "./components/ShareReferralModal";
import { PageTemplate } from "../../templates";
import { useLocaleCurrency } from "../../hooks";
import locales from "../../locales";
import K from "../../constants";
import { Paper, Typography, Button, Banner } from "../../atoms";
import {
  ReferralProgramHowToUseDiscountAction,
  ReferralProgramFAQsAction,
  OnBoardingModal,
  ReferralProgramFAQsModal,
  ReferralProgramTermsAndConditionsModal
} from "../../molecules";
import {
  SmailyFlatUnicorn,
  SmailyColoredUnicorn
} from "../../atoms/Illustrations";
import theme from "../../theme";

const UNICORN_COLORS = [
  theme.palette.info.light,
  theme.palette.success.light,
  theme.palette.warning.light,
  theme.palette.error.light
];

const unicornColorsStyles = {};

UNICORN_COLORS.forEach((color, index) => {
  unicornColorsStyles[
    `referral_program__panel__unicorn_box__icon__active__${index}`
  ] = {
    backgroundColor: color
  };
});

const useStyles = createUseStyles(() => ({
  referral_program__row: {
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      flexDirection: "row",
      justifyContent: "space-between"
    }
  },
  referral_program__main_col: {
    "@media (min-width: 768px)": {
      width: "57%"
    }
  },
  referral_program__secondary_col: {
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      width: "40%"
    }
  },
  referral_program__panel: {
    marginBottom: "16px",
    padding: "24px",
    minHeight: "210px"
  },
  referral_program__panel__title: {
    marginBottom: "16px"
  },
  referral_program__panel__description: {
    marginBottom: "32px",
    display: "block",
    "& strong": {
      fontWeight: "700"
    }
  },
  referral_program__panel__actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      flexDirection: "row"
    },
    "& > button + button": {
      width: "auto"
    }
  },
  referral_program__panel__actions__button: {
    width: "100%",
    "@media (min-width: 768px)": {
      width: "200px"
    }
  },
  referral_program__panel__unicorn_box__wrapper: {
    display: "flex",
    marginTop: "24px",
    marginBottom: "22px",
    overflow: "hidden",
    overflowX: "scroll",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "@media (min-width: 768px)": {
      flexWrap: "wrap"
    }
  },
  referral_program__panel__unicorn_box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "12px",
    borderRadius: "8px",
    marginBottom: "8px"
  },
  referral_program__panel__unicorn_box__inactive: {
    opacity: "50%"
  },
  referral_program__panel__unicorn_box__icon: {
    position: "relative",
    width: "82px",
    height: "82px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    marginBottom: "8px",
    whiteSpace: "nowrap"
  },
  referral_program__panel__unicorn_box__icon__countdown: {
    position: "absolute",
    display: "block",
    padding: "4px 12px",
    border: `1px solid ${theme.palette.info.default}`,
    borderRadius: "8px",
    color: theme.palette.info.default,
    transform: "rotate(-45deg)",
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  referral_program__panel__unicorn_box__icon__inactive: {
    border: `1.5px dashed ${theme.palette.shade.default}`
  },

  referral_program__voucher_balance_banner: {
    height: "200px",
    width: "100%",
    marginBottom: "16px"
  },
  referral_program__voucher_popups_buttons_wrapper: {
    margin: "4px 16px"
  },
  ...unicornColorsStyles
}));

export const ReferralProgramPage = ({
  fetching,
  referralProgramGuests,
  vouchers,
  shareReferralProgram,
  shareUrl,
  fetchingShareUrl
}) => {
  const location = useLocation();
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const query = queryString.parse(location.search);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [termsPopupOpen, setTermsPopupOpen] = useState(false);

  const { track, eventNames } = useTrackingContext();

  useEffect(() => {
    track(eventNames.referralProgram.userVisitsPage);
  }, []);

  const requestAndShowSharePopup = () => {
    shareReferralProgram();
    setSharePopupOpen(true);
  };

  const openTermsAndConditionsPopup = () => {
    setTermsPopupOpen(true);
    track(
      eventNames.referralProgram.showTermsAndConditionsModalFromLinkReferralPage
    );
  };

  const buildContentToShare = `${intl.formatMessage(
    locales.referral_program_share_content,
    {
      hostVoucherAmount: useLocaleCurrency(K.referralVoucherAmounts.guest, {
        decimalPrecision: 0
      })
    }
  )} \n${intl.formatMessage(locales.referral_program_share_content_subline, {
    shareUrl
  })}`;

  const shareNatively = () => {
    window.navigator
      .share({
        text: buildContentToShare
      })
      .then(() => {
        track(eventNames.referralProgram.shareInvitationFromMobile);
      })
      .catch(() => {
        setSharePopupOpen(false);
        track(eventNames.referralProgram.shareInvitationFromMobileFailed);
      });
  };

  return (
    <PageTemplate
      title={intl.formatMessage(locales.referral_program_page_title)}
    >
      {query.process === K.process.onBoarding && (
        <OnBoardingModal onClose={() => history.replace(location.pathname)} />
      )}
      {query.process === K.process.whereToUseTheVoucher && (
        <ReferralProgramFAQsModal
          onClose={() => history.replace(location.pathname)}
        />
      )}
      {termsPopupOpen && (
        <ReferralProgramTermsAndConditionsModal
          onClose={() => setTermsPopupOpen(false)}
        />
      )}

      {sharePopupOpen && !fetchingShareUrl && (
        <>
          {window.navigator.share !== undefined ? (
            shareNatively()
          ) : (
            <ShareReferralModal
              contentToShare={buildContentToShare}
              onClose={() => setSharePopupOpen(false)}
            />
          )}
        </>
      )}

      <div className={classes.referral_program__row}>
        <div className={classes.referral_program__main_col}>
          <Paper className={classes.referral_program__panel}>
            <Typography
              variant={K.typographicVariants.heading3}
              className={classes.referral_program__panel__title}
            >
              {intl.formatMessage(locales.referral_program_share_panel_title)}
            </Typography>

            <Typography
              variant={K.typographicVariants.body2}
              className={classes.referral_program__panel__description}
              color={theme.palette.secondary.default}
            >
              {intl.formatMessage(
                locales.referral_program_share_panel_description,
                {
                  guest_voucher_amount: useLocaleCurrency(
                    K.referralVoucherAmounts.guest,
                    {
                      decimalPrecision: 0
                    }
                  ),
                  host_voucher_amount: useLocaleCurrency(
                    K.referralVoucherAmounts.host,
                    {
                      decimalPrecision: 0
                    }
                  ),
                  strong: msg => <strong>{msg}</strong>
                }
              )}
            </Typography>
            <div className={classes.referral_program__panel__actions}>
              <Button
                onClick={requestAndShowSharePopup}
                className={classes.referral_program__panel__actions__button}
              >
                {intl.formatMessage(
                  locales.referral_program_share_panel_button
                )}
              </Button>

              <Button variant="text" onClick={openTermsAndConditionsPopup}>
                <Typography variant={K.typographicVariants.linkS}>
                  {intl.formatMessage(
                    locales.referral_program_share_panel_terms_and_conditions
                  )}
                </Typography>
              </Button>
            </div>
          </Paper>
          <Paper className={classes.referral_program__panel}>
            <Typography
              variant={K.typographicVariants.heading3}
              className={classes.referral_program__panel__title}
            >
              {intl.formatMessage(locales.referral_program_guests_panel_title)}
            </Typography>
            <div>
              <Typography
                variant={K.typographicVariants.body3}
                color={theme.palette.secondary.default}
              >
                {intl.formatMessage(
                  locales.referral_program_guests_panel_description,
                  {
                    host_voucher_amount: useLocaleCurrency(
                      K.referralVoucherAmounts.host,
                      {
                        decimalPrecision: 0
                      }
                    )
                  }
                )}
              </Typography>
            </div>
            <div
              className={classes.referral_program__panel__unicorn_box__wrapper}
            >
              {referralProgramGuests.length === 0 ? (
                Array.from({ length: 5 }, (v, k) => k + 1).map(index => (
                  <div
                    className={classnames(
                      classes.referral_program__panel__unicorn_box,
                      classes.referral_program__panel__unicorn_box__inactive
                    )}
                    key={`referral_program__panel__unicorn_box-${index}`}
                  >
                    <div
                      className={classnames(
                        classes.referral_program__panel__unicorn_box__icon,
                        classes.referral_program__panel__unicorn_box__icon__inactive
                      )}
                    >
                      <SmailyFlatUnicorn color={theme.palette.shade.default} />
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div
                    className={classnames(
                      classes.referral_program__panel__unicorn_box,
                      classes.referral_program__panel__unicorn_box__inactive
                    )}
                    key="referral_program__panel__unicorn_box-empty"
                  >
                    <div
                      className={classnames(
                        classes.referral_program__panel__unicorn_box__icon,
                        classes.referral_program__panel__unicorn_box__icon__inactive
                      )}
                    >
                      <SmailyFlatUnicorn color={theme.palette.shade.default} />
                    </div>
                  </div>
                  {referralProgramGuests.map(
                    ({ identifier, givenNames, pendingDays, voucherAdded }) => (
                      <div
                        className={classnames(
                          classes.referral_program__panel__unicorn_box
                        )}
                        key={`referral_program__panel__unicorn_box-${identifier}`}
                      >
                        {voucherAdded ? (
                          <div
                            className={classnames(
                              classes.referral_program__panel__unicorn_box__icon,
                              classes[
                                `referral_program__panel__unicorn_box__icon__active__${Math.floor(
                                  Math.random() * UNICORN_COLORS.length
                                )}`
                              ]
                            )}
                          >
                            <SmailyColoredUnicorn />
                          </div>
                        ) : (
                          <div
                            className={classnames(
                              classes.referral_program__panel__unicorn_box__icon,
                              classes.referral_program__panel__unicorn_box__icon__inactive
                            )}
                          >
                            <Typography
                              variant={K.typographicVariants.caption2}
                              color={theme.palette.info.default}
                              className={
                                classes.referral_program__panel__unicorn_box__icon__countdown
                              }
                            >
                              {intl.formatMessage(
                                locales.referral_program_countdown_voucher_pending_days,
                                {
                                  pendingDays
                                }
                              )}
                            </Typography>
                            <SmailyFlatUnicorn
                              color={theme.palette.shade.default}
                            />
                          </div>
                        )}

                        <Typography variant={K.typographicVariants.body3}>
                          {givenNames}
                        </Typography>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          </Paper>
        </div>
        <div className={classes.referral_program__secondary_col}>
          <Banner
            className={classes.referral_program__voucher_balance_banner}
            title={intl.formatMessage(locales.referral_program_voucher_balance)}
            subtitle={intl.formatMessage(
              locales.referral_program_voucher_balance_subtitle
            )}
            balance={
              fetching
                ? ""
                : useLocaleCurrency(vouchers.balance, { useCents: true })
            }
          />
          <div
            className={classes.referral_program__voucher_popups_buttons_wrapper}
          >
            <ReferralProgramHowToUseDiscountAction
              process={K.process.onBoarding}
            />
          </div>
          <div
            className={classes.referral_program__voucher_popups_buttons_wrapper}
          >
            <ReferralProgramFAQsAction
              process={K.process.whereToUseTheVoucher}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};
ReferralProgramPage.propTypes = {
  fetching: PropTypes.bool.isRequired,
  referralProgramGuests: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string.isRequired,
      givenNames: PropTypes.string.isRequired
    })
  ),
  vouchers: PropTypes.shape({
    balance: PropTypes.number,
    visible: PropTypes.bool
  }),
  shareReferralProgram: PropTypes.func.isRequired,
  fetchingShareUrl: PropTypes.bool.isRequired,
  shareUrl: PropTypes.string
};

ReferralProgramPage.defaultProps = {
  shareUrl: null,
  vouchers: {},
  referralProgramGuests: []
};

const ReferralProgram = () => {
  const history = useHistory();
  const { shopper } = useShopperContext();

  if (!shopper.referralProgram) {
    history.replace(DASHBOARD_PATH);
  }

  const {
    fetching,
    fetchingError,
    referralProgramGuests,
    vouchers,
    shareReferralProgram,
    fetchingShareUrl,
    fetchingShareUrlError,
    shareUrl
  } = useReferralProgram();

  return (
    <ReferralProgramPage
      fetching={fetching}
      fetchingError={fetchingError}
      referralProgramGuests={referralProgramGuests}
      vouchers={vouchers}
      shareReferralProgram={shareReferralProgram}
      fetchingShareUrl={fetchingShareUrl}
      fetchingShareUrlError={fetchingShareUrlError}
      shareUrl={shareUrl}
    />
  );
};
export default ReferralProgram;
