import { useEffect } from "react";
import { useSetState } from "react-use";
import useProfileDataQuery from "./queries/useProfileData";
import useNotificationContext from "../../../contexts/Notification";
import useToggleAutoPayment from "./mutations/useToggleAutoPayment";
import useSetDefaultCardReference from "./mutations/useSetDefaultCardReference";
import useRemoveSelector from "./mutations/useRemoveSelector";
import useRemoveCardReference from "./mutations/useRemoveCardReference";
import useSetShopperAllowanceForMerchantsPromotions from "./mutations/useSetShopperAllowanceForMerchantsPromotions";
import useAppContext from "../../../contexts/App";
import useTrackingContext from "../../../contexts/Tracking";
import { SHOPPER_QUERY, CARD_REFERENCES_FRAGMENT } from "../../../graphql";

export const initialState = {
  fetching: true,
  error: null,
  renderNextPurchasesPopup: false
};

export default () => {
  const [state, setState] = useSetState(initialState);
  const { setSnackbarLayoutProps } = useNotificationContext();
  const {
    track,
    eventProperties,
    eventPropertyCategories
  } = useTrackingContext();
  const { setSessionModalStatus } = useAppContext();
  const query = SHOPPER_QUERY(CARD_REFERENCES_FRAGMENT);

  const isDefaultCard = cards => cards.some(card => card.isDefault === true);

  const [fetchProfileData, { data, refetch }] = useProfileDataQuery({
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    },
    onCompleted: () => {
      setState({
        error: undefined,
        fetching: false
      });
    }
  });

  const [fetchToggleAutoPayment] = useToggleAutoPayment({
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    },
    onCompleted: dataAutoPayment => {
      const hasAutoPaymentEnabled =
        dataAutoPayment?.toggleAutoPayment.hasAutoPaymentEnabled;
      if (hasAutoPaymentEnabled) {
        track("Enable shopper auto payment", {
          [eventProperties.CATEGORY]: eventPropertyCategories.SHOPPER
        });
      } else {
        track("Disable shopper auto payment", {
          [eventProperties.CATEGORY]: eventPropertyCategories.SHOPPER
        });
      }
    }
  });

  const [setDefaultCard] = useSetDefaultCardReference({
    onCompleted: () => {
      track("Successfully set default card", {
        [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
      });
    },
    onError: () => {
      track("Unsuccessfully set default card", {
        [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
      });
    },
    update: (cache, { data: { setDefaultCardReference } }) => {
      const { shopper } = cache.readQuery({ query });
      const cardReferences = shopper.cardReferences.map(card => {
        if (card.id === setDefaultCardReference.id) {
          return setDefaultCardReference;
        }
        return { ...card, isDefault: false };
      });

      cache.writeQuery({
        query,
        data: { shopper: { ...shopper, cardReferences } }
      });
    }
  });

  const [removeCard] = useRemoveCardReference({
    onCompleted: () => {
      track("Successfully removed card", {
        [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
      });
    },
    onError: () => {
      track("Unsuccessfully removed card", {
        [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
      });
    },
    update: (cache, { data: { removeCardReference } }) => {
      const { shopper } = cache.readQuery({ query });
      const cardReferences = shopper.cardReferences.filter(
        card => card.id !== removeCardReference.id
      );

      cache.writeQuery({
        query,
        data: { shopper: { ...shopper, cardReferences } }
      });
    }
  });

  const [removeSelector] = useRemoveSelector({
    onCompleted: dataRemovedSelector => {
      track("Successfully removed selector from profile page", {
        selectorId: dataRemovedSelector?.removeSelector?.id
      });
      window.location.reload();
    },
    onError: error => {
      track("Unsuccessfully removed selector from profile page");
      if (error.message === "UNAUTHORIZED_ERROR") {
        setSessionModalStatus();
      }
    }
  });

  const [
    setSetShopperAllowanceForMerchantsPromotions
  ] = useSetShopperAllowanceForMerchantsPromotions({
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    },
    onCompleted: dataMerchantsPromotions => {
      const promotionsAllowed =
        dataMerchantsPromotions?.merchantsPromotions?.promotionsAllowed;

      if (promotionsAllowed === "ALLOWED") {
        track("Enable shopper merchants promotions", {
          [eventProperties.CATEGORY]: eventPropertyCategories.SHOPPER
        });
      } else {
        track("Disable shopper merchants promotions", {
          [eventProperties.CATEGORY]: eventPropertyCategories.SHOPPER
        });
      }
    }
  });

  const onChangeAutoPayment = () => {
    fetchToggleAutoPayment();
  };

  const onChangeDataProtection = async value => {
    await setSetShopperAllowanceForMerchantsPromotions({
      variables: {
        value
      }
    });
    refetch();
  };

  const onClickCard = async cardReferenceId => {
    await setDefaultCard(cardReferenceId);
    refetch();
  };

  const onClickRemoveSelector = async (selectorId, selectorType) => {
    await removeSelector({
      variables: {
        selectorId,
        selectorType
      }
    });
    refetch();
  };

  const onClickRemoveCard = async cardReferenceId => {
    await removeCard(cardReferenceId);
    refetch();
  };

  function toggleNextPurchasesPopup() {
    setState({
      renderNextPurchasesPopup: !state.renderNextPurchasesPopup
    });
  }

  function showSnackbar(label) {
    setSnackbarLayoutProps({
      open: true,
      label
    });
  }

  useEffect(() => {
    fetchProfileData();
  }, []);

  const basicInfo = data?.shopper || {};
  const profileSelectorsEmails =
    data?.shopper?.emails?.length > 0 ? data.shopper.emails : [];
  const profileSelectorsPhones =
    data?.shopper?.phones?.length > 0 ? data.shopper.phones : [];
  const hasFavoriteCard = data?.shopper?.cardReferences
    ? isDefaultCard(data?.shopper?.cardReferences)
    : false;
  const profileCards = data?.shopper?.cardReferences || [];
  const hasMerchantsPromotionsEnabled =
    data?.shopper?.merchantsPromotions?.promotionsAllowed === "ALLOWED";

  return {
    basicInfo,
    profileSelectorsEmails,
    profileSelectorsPhones,
    hasFavoriteCard,
    profileCards,
    hasMerchantsPromotionsEnabled,
    ...state,
    onSuccessAddedSelector: label => {
      refetch();
      showSnackbar(label);
    },
    onChangeAutoPayment,
    onChangeDataProtection,
    showSnackBar: label => showSnackbar(label),
    toggleNextPurchasesPopup,
    onClickCard: cardReferenceId => {
      onClickCard(cardReferenceId);
    },
    onClickRemoveCard: cardReferenceId => {
      onClickRemoveCard(cardReferenceId);
    },
    onClickRemoveSelector: (selectorId, selectorType) => {
      onClickRemoveSelector(selectorId, selectorType);
    }
  };
};
