import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { Card, CardBody, CardFooter, Typography, Button } from "../../atoms";
import { NoStore, Event, Bag, ArrowRight } from "../../atoms/Icons";
import { ErrorBoundary } from "../../templates";
import {
  CardExpiration,
  Cancelled,
  Overdue,
  Paid,
  Unshipped,
  Unpaid
} from "./components";
import { useOrder } from "../../hooks";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList,
  paymentSummaryPath,
  goToOrderPath
} from "../../utils";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  order_card: {
    display: "inherit",
    height: "100%",
    maxHeight: "409px",
    minHeight: "320px",
    boxShadow: "0px 6px 26px 0px rgba(0, 0, 0, 0.05)",
    width: "100%",
    borderRadius: "none",
    textDecoration: "none",
    "@media(min-width: 768px)": {
      height: "409px",
      width: "305px",
      borderRadius: "8px"
    }
  },
  order_card__header: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    paddingBottom: "16px",
    boxSizing: "border-box"
  },
  order_card__header__link: {
    position: "absolute",
    right: 0
  },
  order_card__header__logo: {
    width: "72px",
    height: "72px",
    marginBottom: "16px",
    borderRadius: "8px",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: ({ hasLogo }) =>
      hasLogo ? "none" : `2px solid ${theme.palette.secondary.default}`,
    boxSizing: "border-box",
    "& > img": {
      width: "72px",
      height: "72px"
    }
  },
  order_card__header__name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "32px",
    marginBottom: "8px"
  },
  order_card__header__info: {
    display: "flex",
    "& > :first-child": {
      marginRight: "24px"
    },
    "& > div": {
      display: "flex",
      alignItems: "flex-end",
      "& > :first-child": {
        marginRight: "8px"
      }
    }
  },
  order_card__header__separator: {
    position: "absolute",
    right: 0,
    left: 0,
    height: "1px",
    border: 0,
    backgroundColor: theme.palette.background.default,
    margin: 0
  },
  order_card__content: {
    marginTop: "16px",
    padding: "0 24px",
    flex: 1
  },
  order_card__footer: {
    height: "82px",
    boxSizing: "border-box",
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    borderTop: 0
  }
}));

const OrderCard = () => {
  const order = useOrder();
  const classes = useStyles({ hasLogo: Boolean(order.merchant.logo) });
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  let status = null;

  if (order.isCancelled) {
    status = <Cancelled />;
  } else if (order.isPaid) {
    status = <Paid paidOn={order.paidOn} />;
  } else if (order.isOverdue) {
    status = <Overdue overdueAmount={order.overdueAmount} />;
  } else if (
    (order.isUnshipped || order.isPartiallyShipped) &&
    !order.isPartiallyPaid
  ) {
    status = <Unshipped />;
  } else {
    status = <Unpaid />;
  }

  const Footer = () => {
    const onClickButton = e => {
      e.preventDefault();
      goToOrderPath({
        order,
        history,
        pathFunction: paymentSummaryPath
      });
    };
    if (order.hasExpiredCard || order.hasExpiringSoonCard) {
      return (
        <CardExpiration
          currentProductName={order.currentProductName}
          isExpired={order.cardReference.isExpired}
          orderId={order.id}
        />
      );
    }

    if (order.canBePaid) {
      return (
        <Button
          size="small"
          color={order.isOverdue ? "error" : "primary"}
          onClick={onClickButton}
        >
          {order.isOverdue
            ? intl.formatMessage(locales.pay_debt)
            : intl.formatMessage(locales.go_to_payment)}
        </Button>
      );
    }

    return null;
  };

  return (
    <ErrorBoundary component="OrderCard">
      <Link
        to={`/order/${order.id}`}
        className={classNames(classes.order_card, "fadein")}
      >
        <Card>
          <CardBody>
            <div className={classes.order_card__header}>
              <span className={classes.order_card__header__link}>
                <ArrowRight color={theme.palette.secondary.default} />
              </span>
              <div className={classes.order_card__header__logo}>
                {order.merchant.logo ? (
                  <img src={order.merchant.logo} alt={order.merchant.name} />
                ) : (
                  <NoStore width={39} height={34} />
                )}
              </div>
              <Typography
                variant={K.typographicVariants.visual2}
                className={classes.order_card__header__name}
                color={theme.palette.primary.default}
              >
                {order.merchant.name}
              </Typography>
              <div className={classes.order_card__header__info}>
                <div>
                  <Event width={16} height={16} />
                  <Typography
                    variant={K.typographicVariants.caption1}
                    color={K.colors.secondary}
                  >
                    {intl.formatDate(order.confirmedAt, {
                      year: "numeric",
                      month: "short",
                      day: "2-digit"
                    })}
                  </Typography>
                </div>
                {!order.isCancelled && (
                  <div>
                    <Bag width={16} height={16} />
                    <Typography
                      variant={K.typographicVariants.caption1}
                      color={K.colors.secondary}
                    >
                      {intl.formatMessage(locales.items_quantity, {
                        quantity: getTotalCountFromItemsList(
                          getArticlesListFromCart(order.cart)
                        )
                      })}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <hr className={classes.order_card__header__separator} />
          </CardBody>
          <div className={classes.order_card__content}>{status}</div>
          {!order.isCancelled && !order.isPaid && (
            <CardFooter hideIcon className={classes.order_card__footer}>
              <div />
              <Footer />
            </CardFooter>
          )}
        </Card>
      </Link>
    </ErrorBoundary>
  );
};

export default OrderCard;
