import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Typography } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";
import { useLocaleCurrency } from "../../hooks";
import { Gift } from "../../atoms/Icons";

const useStyles = createUseStyles(theme => ({
  discount_applied: {
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "16px 0",
    background: `linear-gradient(269.73deg, ${theme.palette.success.medium} 11.43%, ${theme.palette.info.medium} 93.57%)`,
    borderRadius: "8px"
  },
  discount_applied_icon: {
    marginTop: "2px"
  },
  discount_applied_text: {
    marginLeft: "6px"
  }
}));

const DiscountApplied = ({ amount }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <div className={classes.discount_applied}>
        <span className={classes.discount_applied_icon}>
          <Gift />
        </span>
        <Typography
          variant={K.typographicVariants.body3}
          color={K.colors.primary}
          className={classes.discount_applied_text}
        >
          {intl.formatMessage(locales.order_page_discount_applied, {
            amount: useLocaleCurrency(amount, { useCents: true })
          })}
        </Typography>
      </div>
    </>
  );
};

DiscountApplied.propTypes = {
  amount: PropTypes.number.isRequired
};

export default DiscountApplied;
