import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { useMedia } from "react-use";
import ReactTooltip from "react-tooltip";
import { Chip } from "@sequra/telar";
import { Card, Typography } from "../../atoms";
import { Transport } from "../../atoms/Icons";
import { ErrorBoundary } from "../../templates";
import { OrderContext } from "../../contexts";
import { centsToBasicUnit } from "../../utils";
import { useLocaleCurrency } from "../../hooks";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  order_article_list_wrapper: {
    backgroundColor: theme.palette.white.default,
    borderRadius: "8px",
    padding: "16px",
    width: "100%",
    boxSizing: "border-box",
    "@media (min-width: 768px)": {
      maxWidth: "624px",
      padding: "24px"
    }
  },
  order_article_list__title: {
    marginBottom: "24px"
  },
  order_article_list__header: {
    display: "flex",
    justifyItems: "center",
    padding: "16px 0 16px 24px",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px 8px 0 0",
    fontWeight: "600",
    "& > span": {
      width: "20%",
      textAlign: "center"
    },
    "& > :first-child": {
      width: "350px",
      textAlign: "left"
    },
    "& > :nth-child(3)": {
      textAlign: "right",
      paddingRight: "20px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "612px"
    }
  },
  order_article_list__item: {
    display: "flex",
    textAlign: "center",
    flexWrap: "nowrap",
    padding: "10px 0 10px 24px",
    borderWidth: "0px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: theme.palette.stroke.default,
    "& > span": {
      width: "20%"
    },
    "& > :nth-child(3)": {
      textAlign: "right",
      paddingRight: "20px"
    },
    "& > :first-child": {
      width: "350px",
      textAlign: "left"
    },
    "&:nth-last-child(2)": {
      borderRadius: "0 0 8px 8px"
    }
  },
  order_article_list__item_mobile: {
    display: "flex",
    flexWrap: "wrap",
    padding: "16px 0",
    borderBottom: `solid 1px ${theme.palette.stroke.default}`,
    "& > :first-child, & > :last-child": {
      width: "100%"
    },
    "& > :nth-child(2), & > :nth-child(3)": {
      width: "50%",
      color: theme.palette.tertiary.default,
      margin: "8px 0 16px 0"
    },
    "& > :nth-child(3)": {
      textAlign: "right"
    }
  },
  order_article_list__chip: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "left",
    "& > svg": {
      marginTop: "-3px"
    },
    "&:hover": {
      cursor: "pointer"
    },
    "@media(min-width: 768px)": {
      justifyContent: "center"
    }
  },
  order_article_list__chip_tooltip: {
    opacity: "1 !important",
    padding: "6px 8px !important"
  },
  order_article_list__total: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "13px",
    alignItems: "baseline",
    "& > :first-child": {
      marginRight: "8px"
    }
  }
}));

const OrderArticleList = ({ withShippingIcon }) => {
  const order = React.useContext(OrderContext);
  const intl = useIntl();
  const isRenting = order.currentProductName === "r1";
  const classes = useStyles();
  const theme = useTheme();
  const isWide = useMedia("(min-width: 768px)");
  const isSmall = useMedia("(max-width: 320px)");

  const TitleArticlesList = () => (
    <Typography
      variant={K.typographicVariants.heading3}
      className={classes.order_article_list__title}
    >
      {intl.formatMessage(locales.shopping_basket)}
    </Typography>
  );

  const HeaderArticlesList = () => (
    <>
      <Typography
        color={theme.palette.primary.default}
        variant={K.typographicVariants.body3}
      >
        <div className={classes.order_article_list__header}>
          <span>{intl.formatMessage(locales.name)}</span>
          <span>{intl.formatMessage(locales.number_of_items)}</span>
          {!isRenting && <span>{intl.formatMessage(locales.total)}</span>}
          <span>{intl.formatMessage(locales.status)}</span>
        </div>
      </Typography>
    </>
  );

  const ArticleStatus = ({ shipped }) => {
    const shippedMessage = intl.formatMessage(locales.shipped);
    const pendingMessage = intl.formatMessage(locales.pending);
    const unshippedMessage = intl.formatMessage(locales.unshipped);

    if (shipped) return shippedMessage;
    if (isSmall) return pendingMessage;
    return unshippedMessage;
  };

  const ShippingIconWithTooltip = ({ tooltipId, isShipped }) => (
    <>
      <div data-tip data-for={tooltipId}>
        <Transport
          color={
            isShipped
              ? theme.palette.success.default
              : theme.palette.secondary.default
          }
        />
      </div>
      <ReactTooltip
        id={tooltipId}
        backgroundColor={theme.palette.info.medium}
        effect="solid"
        place="left"
        arrowRadius="8"
        className={classes.order_article_list__chip_tooltip}
      >
        <Typography
          color={theme.palette.primary.default}
          variant={K.typographicVariants.body2}
        >
          <ArticleStatus shipped={isShipped} />
        </Typography>
      </ReactTooltip>
    </>
  );

  ShippingIconWithTooltip.propTypes = {
    tooltipId: PropTypes.string.isRequired,
    isShipped: PropTypes.bool.isRequired
  };

  const ArticleStatusChip = ({ isShipped }) => (
    <Chip type={isShipped === true ? "success" : "informative"} size="small">
      <Transport width={15} height={11} color={theme.palette.primary.default} />
      <ArticleStatus shipped={isShipped} />
    </Chip>
  );

  ArticleStatusChip.propTypes = {
    isShipped: PropTypes.bool.isRequired
  };

  return (
    <ErrorBoundary component="ArticleList">
      <div className={classes.order_article_list_wrapper}>
        {isWide && (
          <>
            <TitleArticlesList />
            <HeaderArticlesList />
          </>
        )}

        <Card fullWidth={isWide}>
          {order.cart.map(item => {
            const uniqueItemKey = Math.random()
              .toString(36)
              .substring(6);
            return (
              <div
                className={
                  isWide
                    ? classes.order_article_list__item
                    : classes.order_article_list__item_mobile
                }
                key={uniqueItemKey}
              >
                <Typography
                  variant={
                    isWide
                      ? K.typographicVariants.body2
                      : K.typographicVariants.body1
                  }
                >
                  {item.name}
                </Typography>

                <Typography variant={K.typographicVariants.body2}>
                  {item.quantity}
                </Typography>

                <Typography variant={K.typographicVariants.body2}>
                  {isRenting
                    ? intl.formatMessage(
                        locales.item_quantity_and_price_with_tax,
                        {
                          quantity: item.quantity,
                          price_with_tax: useLocaleCurrency(
                            centsToBasicUnit(item.priceWithTax)
                          )
                        }
                      )
                    : useLocaleCurrency(centsToBasicUnit(item.totalWithTax))}
                </Typography>

                <span className={classes.order_article_list__chip}>
                  {isWide && withShippingIcon && !isRenting ? (
                    <ShippingIconWithTooltip
                      tooltipId={`article_status_${uniqueItemKey}`}
                      isShipped={item.shipped}
                    />
                  ) : (
                    <ArticleStatusChip isShipped={item.shipped} />
                  )}
                </span>
              </div>
            );
          })}

          {!isRenting && (
            <div className={classes.order_article_list__total}>
              <Typography variant={K.typographicVariants.visual4}>
                {intl.formatMessage(locales.order_shipment_total)}:
              </Typography>
              <Typography variant={K.typographicVariants.visual4}>
                {useLocaleCurrency(centsToBasicUnit(order.currentOrderValue))}
              </Typography>
            </div>
          )}
        </Card>
      </div>
    </ErrorBoundary>
  );
};

OrderArticleList.propTypes = {
  withShippingIcon: PropTypes.bool
};

OrderArticleList.defaultProps = {
  withShippingIcon: true
};

export default OrderArticleList;
