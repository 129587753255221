import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Typography, Button } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  cookies: {
    position: "fixed",
    display: "flex",
    bottom: 0,
    zIndex: 100000,
    boxSizing: "border-box",
    backgroundColor: theme.palette.success.default,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100px",
    padding: "0 16px",
    "@media only screen and (min-width:  667px)": {
      height: "50px",
      padding: "0"
    }
  },
  cookies__message: {
    color: theme.palette.white.default,
    marginRight: "8px"
  },
  cookies__policy_page_link: {
    color: theme.palette.white.default,
    fontWeight: 600
  },
  cookies__button_confirm: {
    width: "103px",
    height: "30px",
    borderRadius: "1px",
    backgroundColor: theme.palette.white.default,
    cursor: "pointer",
    fontWeight: "lighter",
    textDecoration: "underline",
    marginTop: "16px",
    "@media only screen and (min-width:  667px)": {
      marginTop: "0",
      marginLeft: "32px"
    }
  }
}));

const Cookies = ({ onAccept }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.cookies}>
      <Typography
        variant={K.typographicVariants.body3}
        className={classes.cookies__message}
      >
        {intl.formatMessage(locales.general_cookies_banner_message)}
        &nbsp;
        <wbr />
        <a
          href={intl.formatMessage(locales.general_cookies_policy_page_link)}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.cookies__policy_page_link}
        >
          {intl.formatMessage(locales.general_cookies_banner_more_info)}
        </a>
      </Typography>
      <Button variant="outlined" size="small" onClick={onAccept}>
        {intl.formatMessage(locales.general_cookies_banner_confirm)}
      </Button>
    </div>
  );
};

Cookies.propTypes = {
  onAccept: PropTypes.func
};

Cookies.defaultProps = {
  onAccept: () => {}
};

export default Cookies;
