import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Check = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 152 152">
    <g fill="none" fillRule="evenodd">
      <circle stroke={color} strokeWidth={4} cx={76.5} cy={76.5} r={53.5} />
      <path
        d="M105.387 51.769a2 2 0 013.244 2.334l-.096.133-36.056 46a2 2 0 01-2.9.264l-.119-.114-21.902-22.869a2 2 0 012.764-2.886l.125.12 20.306 21.201 34.634-44.183z"
        fill={color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

Check.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Check.defaultProps = {
  width: 152,
  height: 152,
  color: theme.palette.primary.default
};

export default Check;
