import React from "react";
import PropTypes from "prop-types";
import injectSheet from "react-jss";
import { useMutation } from "@apollo/client";
import { useIntl, injectIntl } from "react-intl";
import { withRouter, Redirect } from "react-router-dom";
import IBANInput from "./IBANInput";
import { OrderContext } from "../../../../contexts";
import useTrackingContext from "../../../../contexts/Tracking";
import { centsToBasicUnit } from "../../../../utils";
import { ADD_BANK_ACCOUNT_TO_REFUND_PROCESS } from "../../../../graphql";
import { Paper, Typography } from "../../../../atoms";
import { ScaleLoader } from "../../../../atoms/Loaders";
import K from "../../../../constants";
import locales from "../../../../locales";

const styles = {
  refund_process_wrapper: {
    marginRight: "24px",
    maxWidth: "612px"
  },
  subtitle: {
    margin: "8px 0 16px 0",
    display: "block"
  }
};

const OrderRefundProcess = ({ intl, classes, history }) => {
  const order = React.useContext(OrderContext);
  const { track, buildOrderTrackingPayload } = useTrackingContext();
  const eventProps = buildOrderTrackingPayload(order);
  const intlTrans = useIntl();

  const [addBankAccount, { error, loading }] = useMutation(
    ADD_BANK_ACCOUNT_TO_REFUND_PROCESS,
    {
      onCompleted: () => {
        track("Successfully added refund bank account", eventProps);
        history.push(`/order/${order.id}/bank-account-refund/confirmed`);
      },
      onError: () => {
        track("Unsuccessfully added refund bank account", eventProps);
      }
    }
  );

  React.useEffect(() => {
    track("Start refund bank account", eventProps);
  }, []);

  if (!order.refundProcess) return null;

  return (
    <>
      {loading && <ScaleLoader />}
      <div className={classes.refund_process_wrapper}>
        <Paper>
          <Typography variant={K.typographicVariants.heading4}>
            {intlTrans.formatMessage(locales.bank_account_refund_title)}
          </Typography>
          <Typography
            variant={K.typographicVariants.body2}
            className={classes.subtitle}
          >
            {intlTrans.formatMessage(locales.bank_account_refund_sub_title, {
              amount: intl.formatNumber(
                centsToBasicUnit(order.refundProcess.amount),
                { style: "currency", currency: "EUR" }
              )
            })}
          </Typography>
          {order.refundProcess.state === "WAITING_FOR_BANK_ACCOUNT" ? (
            <IBANInput
              onConfirm={iban => {
                addBankAccount({
                  variables: {
                    iban,
                    refundProcessId: order.refundProcess.id,
                    orderId: order.id
                  }
                });
              }}
              error={Boolean(error)}
            />
          ) : (
            <Redirect to={`/order/${order.id}`} />
          )}
        </Paper>
      </div>
    </>
  );
};

OrderRefundProcess.propTypes = {
  classes: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
    refund_process_wrapper: PropTypes.string.isRequired
  }).isRequired,
  intl: PropTypes.shape({
    formatNumber: PropTypes.func.isRequired
  }).isRequired,
  history: PropTypes.shape().isRequired
};

export default withRouter(injectIntl(injectSheet(styles)(OrderRefundProcess)));
