import React from "react";
import { useOrder } from "../../../hooks";
import { Unshipped } from "./Invoice";
import PartPayment from "./PartPayment";

export default () => {
  const order = useOrder();

  if (/[s|p]?[p|r]\d+/.test(order.currentProductName)) {
    const { count, paidCount } = order.payments;

    return (
      <PartPayment
        isCampaign={order.isCampaign}
        nextInstalment={order.paymentPlan.futureInstalments[0]}
        instalmentsCount={count}
        paidInstalmentsCount={paidCount}
        isOverdue={order.isOverdue}
      />
    );
  }

  return (
    <Unshipped
      currentOrderValue={order.currentOrderValue}
      invoicingCreditDays={order.merchant.invoicingCreditDays}
    />
  );
};
