import React from "react";
import PropTypes from "prop-types";
import store from "store";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Sequra, ES, IT, FR, PT } from "../../atoms/Icons";
import { useDocumentTitle } from "../../hooks";
import { Paper } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  login_template: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "0 8px",
    backgroundColor: theme.palette.background.default,
    "@media (min-width: 768px)": {
      padding: "0 40px"
    }
  },
  login__paper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    padding: "48px 16px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.12)",
    borderRadius: "0px",
    position: "relative",
    "@media (min-width: 768px)": {
      flex: 0,
      maxWidth: "330px",
      padding: "48px",
      boxSizing: "content-box",
      borderRadius: "8px"
    }
  },
  login_icon: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: "74px"
  },
  language_box: {
    width: "24px",
    height: "24px",
    position: "absolute",
    right: "48px",
    border: "1px solid #ccc",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    cursor: "pointer",
    padding: "1px"
  },
  language_box_selected: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      transform: "scale(1.5)"
    }
  },
  language_box_selector: {
    position: "absolute",
    top: "36px",
    width: "103px",
    height: "174px",
    padding: "17px 14px 17px 12px",
    borderRadius: "8px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    backgroundColor: "#fff",
    flexDirection: "column",
    justifyContent: "space-between",
    display: ({ showSelectorLanguage }) =>
      showSelectorLanguage ? "flex" : "none",
    "& li": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      "&:active": {
        backgroundColor: "#eee"
      },
      "& span": {
        fontSize: "14px",
        marginLeft: "0.25rem"
      },
      "& *": {
        pointerEvents: "none"
      }
    }
  }
}));

const LoginTemplate = ({ children, title, hideLanguageSelector }) => {
  const [showSelectorLanguage, toggleSelectorLanguage] = React.useState(false);
  const classes = useStyles({ showSelectorLanguage });
  const intl = useIntl();

  useDocumentTitle(title);

  const selectLocale = e => {
    store.set("locale", e.target.dataset.locale);
    window.location.reload();
  };

  const localeArray = [
    [K.supportedLocales.es, intl.formatMessage(locales.spanish)],
    [K.supportedLocales.pt, intl.formatMessage(locales.portuguese)],
    [K.supportedLocales.fr, intl.formatMessage(locales.french)],
    [K.supportedLocales.it, intl.formatMessage(locales.italian)]
  ];

  const selectedLocale = store.get("locale");

  // eslint-disable-next-line react/prop-types
  const Flag = ({ language }) => {
    switch (language) {
      case K.supportedLocales.fr:
        return <FR />;
      case K.supportedLocales.pt:
        return <PT />;
      case K.supportedLocales.it:
        return <IT />;
      default:
        return <ES />;
    }
  };

  return (
    <div className={classes.login_template}>
      <Paper className={classes.login__paper}>
        <div className={classes.login_icon}>
          <Sequra width={124} height={32} />
          {hideLanguageSelector ? null : (
            <div className={classes.language_box}>
              <div
                className={classes.language_box_selected}
                onClick={() => toggleSelectorLanguage(!showSelectorLanguage)}
                onKeyPress={() => toggleSelectorLanguage(!showSelectorLanguage)}
                role="button"
                tabIndex={0}
              >
                <Flag language={selectedLocale} />
              </div>
              <ul className={classes.language_box_selector}>
                {localeArray.map(([locale, name]) => (
                  <li
                    role="tab"
                    onClick={selectLocale}
                    onKeyPress={selectLocale}
                    key={locale}
                    data-locale={locale}
                  >
                    <div className={classes.language_box_selected}>
                      <Flag language={locale} />
                    </div>
                    <span>{name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {children}
      </Paper>
    </div>
  );
};

LoginTemplate.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  hideLanguageSelector: PropTypes.bool
};

LoginTemplate.defaultProps = {
  children: null,
  title: "",
  hideLanguageSelector: false
};

export default LoginTemplate;
