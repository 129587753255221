import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { useLocaleCurrency, useShopper } from "../../../../../hooks";
import NextActionCard from "../NextActionCard";
import MessageCard from "../MessageCard";
import K from "../../../../../constants";

const useStyles = createUseStyles(theme => ({
  dashboard__message_card: {
    marginTop: 15,
    [`${theme.breakpoints.desktop}`]: {
      marginTop: 0,
      maxWidth: 296,
      height: 251
    }
  },
  next_action_card_message_card_container: {
    display: "flex",
    flexDirection: "column",
    "@media(min-width: 768px)": {
      flexDirection: "row"
    }
  }
}));

const NextActionAndMessageSection = ({
  messageProps,
  nextActionCardProps,
  onClickNextActionCard,
  onClickMessage,
  fetching
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const shopper = useShopper();

  const nextPaymentAmountStr = useLocaleCurrency(
    nextActionCardProps.nextPaymentAmountStr,
    { useCents: true }
  );

  return (
    <>
      <div className={classes.next_action_card_message_card_container}>
        <NextActionCard
          title={nextActionCardProps.title}
          withoutNextAction={nextActionCardProps.withoutNextAction}
          amountColor={nextActionCardProps.amountColor}
          nextPaymentAmountStr={nextPaymentAmountStr}
          nextPaymentDateStr={
            nextActionCardProps.nextPaymentDateStr
              ? intl.formatDate(nextActionCardProps.nextPaymentDateStr, {
                  day: "2-digit",
                  month: "short"
                })
              : null
          }
          orderMerchantName={nextActionCardProps.orderMerchantName}
          orderDateStr={intl.formatDate(nextActionCardProps.orderDateStr, {
            day: "2-digit",
            month: "short"
          })}
          orderItemsTotal={nextActionCardProps.orderItemsTotal}
          onClickLink={onClickNextActionCard}
          fetching={fetching}
        />
        <MessageCard
          className={classes.dashboard__message_card}
          message={
            messageProps.message
              ? intl.formatMessage({ id: messageProps.message })
              : ""
          }
          buttonText={
            messageProps.buttonText && shopper.realm === K.realm.sequra
              ? intl.formatMessage({ id: messageProps.buttonText })
              : ""
          }
          cardColor={messageProps.cardColor}
          textColor={messageProps.textColor}
          linkUrl={onClickMessage}
          fetching={fetching}
        />
      </div>
    </>
  );
};

NextActionAndMessageSection.propTypes = {
  nextActionCardProps: PropTypes.shape({
    title: PropTypes.string,
    withoutNextAction: PropTypes.bool,
    amountColor: PropTypes.string,
    nextPaymentAmountStr: PropTypes.number,
    nextPaymentDateStr: PropTypes.string,
    orderMerchantName: PropTypes.string,
    orderDateStr: PropTypes.string,
    orderItemsTotal: PropTypes.number
  }),
  messageProps: PropTypes.shape({
    message: PropTypes.string,
    cardColor: PropTypes.string,
    textColor: PropTypes.string,
    buttonText: PropTypes.string
  }),
  onClickNextActionCard: PropTypes.func,
  onClickMessage: PropTypes.string,
  fetching: PropTypes.bool
};

NextActionAndMessageSection.defaultProps = {
  nextActionCardProps: {},
  messageProps: {},
  onClickNextActionCard: undefined,
  onClickMessage: undefined,
  fetching: true
};

export default NextActionAndMessageSection;
