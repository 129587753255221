import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { Button, NextModal, Typography } from "../../atoms";
import { NavigationArrowRight, NavigationArrowBack } from "../../atoms/Icons";
import K from "../../constants";
import Step1 from "./images/step1.png";
import Step2 from "./images/step2.png";
import Step3 from "./images/step3.png";
import Step4 from "./images/step4.png";
import useTrackingContext from "../../contexts/Tracking";

const useStyles = createUseStyles(theme => ({
  modal: {
    position: "relative",
    maxHeight: "none",
    maxWidth: 514,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0
  },
  modal__figure: {
    height: "230px",
    margin: "16px 0 26px",
    backgroundImage:
      "linear-gradient(144deg, #00C2A3 -1.8%, #01AED6 59.28%, #7801d6a1 119.04%)",
    textAlign: "center",
    "& > img": {
      marginTop: imageHeight => (imageHeight === "65" ? "84px" : "-34px")
    }
  },
  modal__content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: 20,
    padding: "0 16px",
    minHeight: "195px",
    "@media(min-width: 768px)": {
      flex: 0,
      marginTop: 0,
      padding: "0 48px"
    },
    "& p": {
      marginTop: "24px",
      "& span": {
        lineHeight: "normal"
      }
    }
  },
  modal__content__title: {
    margin: "24px 0",
    "& strong": {
      color: theme.palette.success.default
    }
  },
  modal__content__link: {
    "& > a": {
      color: theme.palette.primary.default
    }
  },
  modal__content__footer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    width: "100%",
    marginTop: 32,
    padding: "0 16px",
    "@media(min-width: 768px)": {
      marginTop: 42,
      padding: "0 48px"
    },
    "& ul": {
      display: "flex",
      alignItems: "center"
    },
    " & li": {
      width: "11px",
      height: "11px",
      marginRight: "10px",
      overflow: "hidden",
      borderRadius: "50%",
      backgroundColor: theme.palette.shade.default,
      textIndent: "-999px",
      "&.active": {
        backgroundColor: theme.palette.primary.default
      }
    }
  },
  modal__content__footer__actions: {
    display: "flex"
  },
  modal__content__footer__action_steps: {
    marginLeft: "16px",
    paddingTop: "6px",
    width: "48px"
  },
  modal__content__footer__action_last_step: {
    paddingTop: "1px!important",
    "& > span": {
      textDecoration: "none"
    }
  }
}));
const ModalContent = ({
  img,
  title,
  paragraph,
  claim,
  dataTestId,
  imageHeight
}) => {
  const theme = useTheme();
  const classes = useStyles(imageHeight);
  const { track, eventNames } = useTrackingContext();

  useEffect(() => {
    track(eventNames.generalVouchers.howToUseItModal.viewed);
  }, []);
  return (
    <div data-testid={dataTestId}>
      <figure className={classes.modal__figure}>
        <img src={img} alt="" height={imageHeight} />
      </figure>
      <div className={classes.modal__content}>
        <Typography
          variant={K.typographicVariants.heading1}
          color={theme.palette.primary.default}
        >
          {title}
        </Typography>
        <p>
          <Typography>
            <span
              className={classes.modal__content__link}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          </Typography>
        </p>
        {claim && (
          <p>
            <Typography>{claim}</Typography>
          </p>
        )}
      </div>
    </div>
  );
};

ModalContent.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  claim: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  imageHeight: PropTypes.string
};
ModalContent.defaultProps = {
  claim: "",
  img: "",
  paragraph: "",
  imageHeight: "340"
};

const renderModalContent = currentStep => {
  switch (currentStep) {
    case 1:
      return (
        <ModalContent
          img={Step1}
          title="Usa tu descuento en compras de mas de 100 €"
          paragraph="Vuelve a comprar en tus tiendas favoritas o descubre muchas más en <a href='https://www.sequra.com/es/tiendas-paga-mas-tarde/todas' target='_blank'>sequra.com</a>"
          dataTestId="step1"
        />
      );
    case 2:
      return (
        <ModalContent
          img={Step2}
          title="Elige Paga a Plazos con seQura"
          paragraph="Podrás aplicar tu descuento tras validar tus datos en nuestro proceso de pago 🎁"
          dataTestId="step2"
        />
      );
    case 3:
      return (
        <ModalContent
          img={Step3}
          title="Paga menos en tu primer plazo"
          paragraph="El descuento se aplicará en el primer pago, lo que no uses en ese momento se acumula para tu siguiente compra."
          dataTestId="step3"
        />
      );
    case 4:
      return (
        <ModalContent
          img={Step4}
          title="Descuento por tiempo limitado"
          paragraph="¿Ya sabes dónde usar tu descuento? Descubre todas las tiendas y sectores donde puedes pagar con seQura."
          dataTestId="step4"
          imageHeight="65"
        />
      );

    default:
      return null;
  }
};

const HowToUseDiscounts = ({ onClose, numberOfSteps }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const theme = useTheme();
  const classes = useStyles();
  const { track, eventNames } = useTrackingContext();

  const onClickNextButton = () => setCurrentStep(currentStep + 1);
  const onClickPrevButton = () => setCurrentStep(currentStep - 1);

  const onClickGotoShops = () => {
    window.open(
      "https://www.sequra.com/es/tiendas-paga-mas-tarde/todas",
      "_blank"
    );
    track(eventNames.generalVouchers.howToUseItModal.clickedGoToShopsButton);
  };

  return (
    <NextModal
      open
      hideBack
      onClose={onClose}
      withIconClose
      modalClassname={classes.modal}
      withFullWidth
    >
      {renderModalContent(currentStep)}
      <div className={classes.modal__content__footer}>
        <ul>
          {Array(numberOfSteps)
            .fill("")
            .map((_, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={index + 1 === currentStep ? "active" : undefined}
              >
                {index + 1}
              </li>
            ))}
        </ul>
        <div className={classes.modal__content__footer__actions}>
          {currentStep > 1 && (
            <Button
              variant="text"
              size="medium"
              onClick={onClickPrevButton}
              dataTestId="prev-step"
            >
              <NavigationArrowBack color={theme.palette.primary.default} />
            </Button>
          )}

          <Button
            size="medium"
            onClick={
              currentStep !== numberOfSteps
                ? onClickNextButton
                : onClickGotoShops
            }
            dataTestId="next-step"
            className={
              currentStep === numberOfSteps
                ? classes.modal__content__footer__action_last_step
                : classes.modal__content__footer__action_steps
            }
          >
            {currentStep !== numberOfSteps ? (
              <NavigationArrowRight color={theme.palette.white.default} />
            ) : (
              <Typography color={theme.palette.white.default}>
                Ir a tiendas
              </Typography>
            )}
          </Button>
        </div>
      </div>
    </NextModal>
  );
};

HowToUseDiscounts.propTypes = {
  onClose: PropTypes.func.isRequired,
  numberOfSteps: PropTypes.number
};
HowToUseDiscounts.defaultProps = {
  numberOfSteps: 4
};

export default HowToUseDiscounts;
