import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Sp1 = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 80 80">
    <path
      d="M32.004 48.405a1.354 1.354 0 0 1 2.035-1.176l18.995 11.018c.419.243.678.69.68 1.175l.09 22.277a1.354 1.354 0 0 1-2.046 1.172l-19.03-11.262a1.365 1.365 0 0 1-.67-1.171zm48.992 0-.054 22.033a1.365 1.365 0 0 1-.67 1.171l-19.03 11.262a1.354 1.354 0 0 1-2.045-1.172l.09-22.277c.001-.484.26-.932.68-1.175L78.96 47.229a1.354 1.354 0 0 1 2.035 1.176zm-46.27 2.36.045 18.898 16.307 9.65-.076-19.106-16.277-9.442zm43.549 0-16.277 9.442-.076 19.106 16.307-9.65.046-18.898zm-22.461-21.58a1.365 1.365 0 0 1 1.368-.003L76.38 40.243c.908.523.906 1.83-.004 2.351l-19.198 10.97c-.421.24-.94.239-1.36-.004l-18.97-10.97a1.354 1.354 0 0 1-.005-2.343zm.691 2.74-16.271 9.487 16.27 9.408 16.466-9.408-16.465-9.487z"
      fill={color}
      transform="translate(-16 -16)"
    />
  </svg>
);

Sp1.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Sp1.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Sp1;
