import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useTheme, createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Typography, Card, CardBody } from "../../atoms";
import OrderPaymentCardInfo from "./OrderPaymentCardInfo";
import OrderPaymentCardErrors from "./OrderPaymentCardErrors";
import locales from "../../locales";
import K from "../../constants";
import { useOrder } from "../../hooks";
import { changeCardPath } from "../../utils";
import { PROFILE_PATH } from "../../routes";

const useStyles = createUseStyles(theme => ({
  order_payment_card_section: {
    display: "flex",
    width: "100%",
    "@media(min-width: 768px)": {
      maxWidth: "309px"
    }
  },
  order_payment_card_section__action: {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "flex-end",
    alignItems: "center",
    "& a": {
      textDecoration: "none"
    }
  },
  error: {
    border: `1px solid ${theme.palette.error.default}`
  }
}));

const OrderPaymentCard = ({
  orderCurrentProductName,
  orderCardIssuer,
  orderCardLastFour,
  orderHasForcedCard,
  orderIsPaid,
  orderHasAutoPaymentEnabled,
  orderHasExpiredCard,
  orderHasExpiringSoonCard,
  withinCard,
  onChange
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const order = useOrder();
  const error = orderHasExpiredCard || orderHasExpiringSoonCard;

  const __changeCardPath = () => {
    if (!/[s|p]?[p|r]\d+/.test(orderCurrentProductName)) {
      return PROFILE_PATH;
    }
    return changeCardPath(order);
  };

  const component = (
    <div className={classes.order_payment_card_section}>
      <OrderPaymentCardInfo
        issuer={orderCardIssuer}
        lastFour={orderCardLastFour}
        hasAutoPaymentEnabled={
          !/[f|s|p]?[p|r]\d+/.test(orderCurrentProductName) &&
          !orderHasForcedCard &&
          !orderIsPaid
        }
      />
      {!orderHasForcedCard && !orderIsPaid && (
        <div className={classes.order_payment_card_section__action}>
          {/[s|p]?[p|r]\d+/.test(orderCurrentProductName) || error ? (
            <>
              {order.canChangeCard ? (
                <Link
                  to={__changeCardPath()}
                  data-testid="molecule_order_payment_card_link"
                >
                  <Typography
                    variant={K.typographicVariants.linkS}
                    color={theme.palette[error ? "error" : "primary"].default}
                  >
                    {intl.formatMessage(locales.change)}
                  </Typography>
                </Link>
              ) : null}
            </>
          ) : (
            <Toggle
              icons={false}
              defaultChecked={orderHasAutoPaymentEnabled}
              onChange={onChange}
            />
          )}
        </div>
      )}
    </div>
  );

  if (withinCard) {
    return (
      <div>
        <Card
          className={classnames({
            [classes.error]: error && !orderIsPaid
          })}
        >
          <CardBody>{component}</CardBody>
        </Card>

        {!orderIsPaid && (
          <OrderPaymentCardErrors
            orderHasExpiredCard={orderHasExpiredCard}
            orderHasExpiringSoonCard={orderHasExpiringSoonCard}
          />
        )}
      </div>
    );
  }

  return component;
};

OrderPaymentCard.propTypes = {
  orderCurrentProductName: PropTypes.string,
  orderCardIssuer: PropTypes.string,
  orderCardLastFour: PropTypes.string,
  orderHasForcedCard: PropTypes.bool,
  orderIsPaid: PropTypes.bool,
  orderHasAutoPaymentEnabled: PropTypes.bool,
  orderHasExpiredCard: PropTypes.bool,
  orderHasExpiringSoonCard: PropTypes.bool,
  withinCard: PropTypes.bool,
  onChange: PropTypes.func
};

OrderPaymentCard.defaultProps = {
  orderCurrentProductName: "",
  orderCardIssuer: "",
  orderCardLastFour: "",
  orderHasForcedCard: false,
  orderIsPaid: false,
  orderHasAutoPaymentEnabled: false,
  orderHasExpiredCard: false,
  orderHasExpiringSoonCard: false,
  withinCard: true,
  onChange: () => {}
};

export default OrderPaymentCard;
