import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Paper, Button, Typography } from "../../../../atoms";
import { Info } from "../../../../atoms/Icons";
import PaymentCard from "../PaymentCard";
import useTrackingContext from "../../../../contexts/Tracking";
import { isMobile } from "../../../../utils";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  shopper_card_list: {
    display: "flex",
    flexDirection: "column"
  },
  shopper_card_list__title: {
    marginBottom: "16px"
  },
  shopper_card_list__cards: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "12px 0",
    borderTop: `1px solid ${theme.palette.shade.default}`,
    "@media (min-width: 768px)": {
      alignItems: "flex-start"
    }
  },
  shopper_card_list__button: {
    width: "auto",
    minWidth: "138px",
    maxWidth: "200px",
    margin: "0 auto"
  },
  shopper_card_list__info: {
    display: "flex",
    alignItems: "center"
  },
  shopper_card_list__info_action: {
    cursor: "pointer",
    marginLeft: "10px"
  }
}));

const CardsList = ({
  profileCards,
  toggleNextPurchasesPopup,
  onClickCard,
  onClickRemoveCard
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const {
    track,
    eventProperties,
    eventPropertyCategories
  } = useTrackingContext();

  return (
    <>
      <Typography
        variant={K.typographicVariants.heading4}
        className={classes.shopper_card_list__title}
      >
        {intl.formatMessage(locales.payment_cards_list_title)}
      </Typography>
      <Paper className={classes.shopper_card_list}>
        <div className={classes.shopper_card_list__info}>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(locales.payment_cards_list_description)}
          </Typography>
          <div
            className={classes.shopper_card_list__info_action}
            onClick={toggleNextPurchasesPopup}
            onKeyDown={toggleNextPurchasesPopup}
            role="button"
            tabIndex={0}
          >
            <Info color={theme.palette.info.default} />
          </div>
        </div>

        <div className={classes.shopper_card_list__cards}>
          {profileCards.map(card => (
            <PaymentCard
              key={card.id}
              card={card}
              isDefault={card.isDefault}
              hideDelete={card.isDefault}
              onSelect={() => {
                track("Start set as default card process", {
                  [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
                });
                onClickCard({
                  variables: {
                    cardReferenceId: card.id
                  }
                });
              }}
              onDelete={() => {
                track("Start remove card process", {
                  [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
                });
                onClickRemoveCard({
                  variables: {
                    cardReferenceId: card.id
                  }
                });
              }}
            />
          ))}
        </div>
        <Button
          className={classes.shopper_card_list__button}
          variant="text"
          color="primary"
          size="small"
          fullWidth={isMobile.phone}
          onClick={() => {
            const search = queryString.stringify({
              process: K.process.addCardToYourAccount,
              gateway: K.mufasaGateways.tokenization
            });
            history.push(`?${search}`);
          }}
        >
          {intl.formatMessage(locales.payment_cards_add_new_card)}
        </Button>
      </Paper>
    </>
  );
};

CardsList.propTypes = {
  toggleNextPurchasesPopup: PropTypes.func.isRequired,
  profileCards: PropTypes.PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickCard: PropTypes.func.isRequired,
  onClickRemoveCard: PropTypes.func.isRequired
};

export default CardsList;
