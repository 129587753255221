import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  new_tab_link: {
    cursor: "pointer",
    color: "inherit",
    outline: "none",
    "&:hover, &:focus, &:active": {
      outline: "none",
      textDecoration: "none"
    },
    "> img": {
      outline: "0"
    },
    "> svg": {
      outline: "0"
    }
  }
});

const NewTabLink = ({ href, className, children, onClick }) => {
  const classes = useStyles();
  const classNames = classnames(className, classes.new_tab_link);

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

NewTabLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func
};

NewTabLink.defaultProps = {
  children: null,
  className: "",
  href: "",
  onClick: () => {}
};

export default NewTabLink;
