import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Papyrus = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M12.702 0a2.53 2.53 0 0 1 2.527 2.527c0 2.111-.522 4.2-1.026 6.22-.448 1.795-.91 3.646-.985 5.47h3.527c.279 0 .505.227.505.506a2.53 2.53 0 0 1-2.527 2.527H4.548a2.53 2.53 0 0 1-2.527-2.527c0-2.111.522-4.2 1.026-6.22.448-1.795.91-3.646.985-5.47H.505A.505.505 0 0 1 0 2.526 2.53 2.53 0 0 1 2.527 0zm0 1.01H4.547c.318.423.507.948.507 1.517 0 2.111-.522 4.2-1.026 6.22-.49 1.961-.996 3.989-.996 5.976 0 .836.68 1.516 1.516 1.516s1.516-.68 1.516-1.516c0-.279.227-.505.506-.505h5.636c.074-1.94.552-3.857 1.016-5.715.49-1.961.996-3.989.996-5.976 0-.836-.68-1.516-1.516-1.516zm3.45 14.219H7.024a2.516 2.516 0 0 1-.456 1.01h8.155c.659 0 1.22-.422 1.43-1.01zm-4.997-5.122a.505.505 0 0 1 0 1.011H5.122a.505.505 0 0 1 0-1.01zm.473-2.021a.505.505 0 0 1 0 1.01H5.622a.505.505 0 0 1 0-1.01zm.5-2.022a.505.505 0 0 1 0 1.011H6.095a.505.505 0 0 1 0-1.01zm.405-2.021a.505.505 0 0 1 0 1.01H6.57a.505.505 0 0 1 0-1.01zM2.527 1.011c-.659 0-1.221.422-1.43 1.01h2.86a1.519 1.519 0 0 0-1.43-1.01z"
      transform="matrix(-1 0 0 1 17.25 0)"
    />
  </svg>
);

Papyrus.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Papyrus.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Papyrus;
