import { createUseStyles } from "react-jss";

export default createUseStyles(theme => ({
  box: {
    display: "flex",
    "& > :first-child": {
      marginBottom: "8px",
      marginRight: "16px"
    }
  },
  box__info: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "& > :first-child": {
      marginBottom: "4px",
      "@media(min-width: 768px)": {
        marginBottom: "8px"
      }
    },
    "& > :last-child": {
      color: theme.palette.secondary.default
    }
  }
}));
