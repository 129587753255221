import { useLazyQuery } from "@apollo/client";
import {
  ORDERS_QUERY,
  ORDER_INFO_FRAGMENT,
  MERCHANT_FRAGMENT,
  INVOICES_FRAGMENT,
  UNSHIPPED_CART_FRAGMENT,
  CARD_REFERENCE_FRAGMENT,
  PAYMENT_PLAN_FRAGMENT,
  RENTING_PLAN_FRAGMENT,
  SHIPPED_CART_FRAGMENT,
  CONVERSION_PLANS_FRAGMENT
} from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError }) =>
  useLazyQuery(
    ORDERS_QUERY(
      ORDER_INFO_FRAGMENT,
      MERCHANT_FRAGMENT,
      INVOICES_FRAGMENT,
      SHIPPED_CART_FRAGMENT,
      UNSHIPPED_CART_FRAGMENT,
      CARD_REFERENCE_FRAGMENT,
      PAYMENT_PLAN_FRAGMENT,
      RENTING_PLAN_FRAGMENT,
      CONVERSION_PLANS_FRAGMENT
    ),
    {
      variables: {
        first: 1,
        filter_by: "ACTIVE"
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: K.fetchPolicy.cacheAndNetwork,
      onCompleted,
      onError
    }
  );
