import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Typography } from "../../atoms";
import K from "../../constants";

const useStyles = createUseStyles({
  order_payment_card_errors: {
    display: "flex",
    alignItems: "center",
    margin: "8px 0",
    "& :first-child": {
      marginRight: "8px"
    }
  }
});

const OrderPaymentCardErrors = ({
  orderHasExpiredCard,
  orderHasExpiringSoonCard
}) => {
  const classes = useStyles();

  return (
    <>
      {orderHasExpiredCard && (
        <Typography
          variant={K.typographicVariants.body3}
          color={K.colors.error}
          className={classes.order_payment_card_errors}
        >
          Tarjeta caducada
        </Typography>
      )}
      {orderHasExpiringSoonCard && (
        <Typography
          variant={K.typographicVariants.body3}
          color={K.colors.error}
          className={classes.order_payment_card_errors}
        >
          Tarjeta a punto de caducar
        </Typography>
      )}
    </>
  );
};

OrderPaymentCardErrors.propTypes = {
  orderHasExpiredCard: PropTypes.bool,
  orderHasExpiringSoonCard: PropTypes.bool
};

OrderPaymentCardErrors.defaultProps = {
  orderHasExpiredCard: false,
  orderHasExpiringSoonCard: false
};

export default OrderPaymentCardErrors;
