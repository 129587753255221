import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { ArrowUnfold, ArrowFold } from "../Icons";
import Typography from "../Typography";
import K from "../../constants";
import theme from "../../theme";

const useStyles = createUseStyles({
  drop_down_list__header: {
    display: "flex",
    justifyContent: "space-between"
  },
  drop_down_list__header__title: {
    fontWeight: "bold"
  },
  drop_down_list__header__right: {
    display: "flex",
    alignItems: "flexStart",
    cursor: ({ hideExpand }) => (hideExpand ? "auto" : "pointer"),
    "& :first-child": {
      marginRight: "8px"
    }
  },
  drop_down_list__header__arrow_space: {
    width: "30px",
    textAlign: "right"
  }
});

const DropDownListHeader = ({
  title,
  subtitle,
  colorSubtitle,
  onToggle,
  hideExpand,
  isOpen
}) => {
  const classes = useStyles({ hideExpand });

  return (
    <div className={classes.drop_down_list__header}>
      <Typography
        variant={K.typographicVariants.body3}
        className={classes.drop_down_list__header__title}
      >
        {title}
      </Typography>
      <div
        className={classes.drop_down_list__header__right}
        onClick={onToggle}
        onKeyPress={onToggle}
        role="button"
        tabIndex={hideExpand ? "" : "0"}
      >
        <Typography variant={K.typographicVariants.body2} color={colorSubtitle}>
          {subtitle}
        </Typography>
        <span className={classes.drop_down_list__header__arrow_space}>
          {hideExpand || (isOpen ? <ArrowFold /> : <ArrowUnfold />)}
        </span>
      </div>
    </div>
  );
};

DropDownListHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  colorSubtitle: PropTypes.string,
  onToggle: PropTypes.func,
  hideExpand: PropTypes.bool,
  isOpen: PropTypes.bool
};

DropDownListHeader.defaultProps = {
  title: "",
  subtitle: "",
  colorSubtitle: theme.palette.primary.default,
  onToggle: () => {},
  hideExpand: false,
  isOpen: false
};

DropDownListHeader.displayName = "DropDownListHeader";

export default DropDownListHeader;
