import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Card = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 76 46" role="img" alt="Card">
    <path
      d="M61.435,0 L6.688,0 C3,0 0,3 0,6.688 L0,15.736 L0,25.183 L0,36.538 C0,40.226 3,43.226 6.688,43.226 L61.434,43.226 C65.122,43.226 68.1220002,40.226 68.1220002,36.538 L68.1220002,25.184 L68.1220002,15.737 L68.1220002,6.689 C68.123,3 65.123,0 61.435,0 Z M6.688,2 L61.434,2 C64.019,2 66.122,4.103 66.122,6.688 L66.122,8.868 L2,8.868 L2,6.688 C2,4.103 4.103,2 6.688,2 Z M66.123,18.184 L2,18.184 L2,10.737 L66.123,10.737 L66.123,18.184 Z M61.435,41.227 L6.688,41.227 C4.103,41.227 2,39.124 2,36.539 L2,20.094748 L66.123,20.094748 L66.123,36.539 C66.123,39.124 64.02,41.227 61.435,41.227 Z M14.395,26.01 C14.395,26.562 13.947,27.01 13.395,27.01 L7.706,27.01 C7.154,27.01 6.706,26.562 6.706,26.01 C6.706,25.458 7.154,25.01 7.706,25.01 L13.394,25.01 C13.947,25.01 14.395,25.458 14.395,26.01 Z M27.288,26.01 C27.288,26.562 26.84,27.01 26.288,27.01 L20.6,27.01 C20.048,27.01 19.6,26.562 19.6,26.01 C19.6,25.458 20.048,25.01 20.6,25.01 L26.288,25.01 C26.84,25.01 27.288,25.458 27.288,26.01 Z"
      fill={color}
    />
  </svg>
);

Card.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Card.defaultProps = {
  width: 152,
  height: 152,
  color: theme.palette.primary.default
};

export default Card;
