import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  input: {
    height: "68px",
    width: "100%",
    border: `1px solid ${theme.palette.shade.default}`,
    boxSizing: "border-box",
    padding: "20px 16px",
    fontSize: "16px",
    transition: theme.transitions.default,
    transitionDuration: 100,
    borderRadius: "8px",
    "&:focus": {
      borderWidth: "2px"
    },
    "&::placeholder": {
      fontSize: 14,
      color: theme.palette.secondary.default,
      fontWeight: 400
    },
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      margin: 0
    },
    "&[type=number]": {
      "-webkit-appearance": "textfield",
      "-moz-appearance": "textfield"
    }
  },
  inputWithLabel: {
    position: "relative",
    "& > label": {
      position: "absolute",
      top: "8px",
      left: "16px",
      fontSize: "14px",
      "& span": {
        fontWeight: "600"
      }
    },
    "& > input": {
      paddingTop: "40px",
      paddingLeft: ({ prefix }) => (prefix ? "48px" : "16px")
    },
    "& > span": {
      position: "absolute",
      top: "35px",
      left: "16px",
      fontSize: "16px",
      color: theme.palette.tertiary.default
    }
  }
}));

const Input = ({
  type,
  className,
  placeholder,
  onChange,
  name,
  label,
  inputRef,
  focus,
  maxLength,
  onKeyDown,
  value,
  onPaste,
  testid,
  prefix
}) => {
  const classes = useStyles({ prefix });

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  const onChangeCallback = e => {
    onChange(e, e.target.value);
  };

  return (
    <>
      {label ? (
        <div className={classes.inputWithLabel}>
          <label htmlFor={name}>
            <Typography
              variant={K.typographicVariants.body2}
              className={classes.promote_merchants_in_recovery__content}
              color={K.colors.success}
            >
              {label}
            </Typography>
          </label>
          {prefix && <span>{prefix}</span>}
          <input
            name={name}
            id={name}
            ref={inputRef}
            type={type}
            onChange={e => onChangeCallback(e)}
            onKeyDown={e => onKeyDown(e)}
            onPaste={e => onPaste(e)}
            className={`${classes.input} ${className}`}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            data-testid={testid}
          />
        </div>
      ) : (
        <input
          name={name}
          ref={inputRef}
          type={type}
          onChange={e => onChangeCallback(e)}
          onKeyDown={e => onKeyDown(e)}
          onPaste={e => onPaste(e)}
          className={`${classes.input} ${className}`}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          data-testid={testid}
        />
      )}
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  focus: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  testid: PropTypes.string,
  prefix: PropTypes.string
};

Input.defaultProps = {
  type: "text",
  className: "",
  placeholder: "",
  onChange: () => {},
  onKeyDown: () => {},
  onPaste: () => {},
  name: "",
  label: "",
  inputRef: () => {},
  focus: false,
  maxLength: null,
  value: "",
  testid: "",
  prefix: ""
};

export default Input;
