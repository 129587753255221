import { useEffect } from "react";
import { useSetState } from "react-use";
import useVouchers from "./queries/useVouchers";

export const initialState = {
  generalVouchers: {},
  fetching: true,
  error: null
};

export default () => {
  const [state, setState] = useSetState(initialState);
  const [fetchVouchers] = useVouchers({
    onCompleted: data => {
      const generalVouchers = data?.shopper?.generalVouchers ?? {};
      setState({
        generalVouchers:
          Object.entries(generalVouchers).length > 0 ? generalVouchers : {},
        fetching: false
      });
    },
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    }
  });

  useEffect(() => {
    fetchVouchers();
  }, []);

  return state;
};
