import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useTheme } from "react-jss";

import { ReferralButton, Typography } from "../../atoms";
import { FaqsBullet } from "../../atoms/Icons";

import K from "../../constants";
import locales from "../../locales";

const ReferralProgramFAQsAction = ({ process, className }) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <ReferralButton
      process={process}
      className={className}
      dataTestId="referral-program-faqs-button"
    >
      <FaqsBullet
        width={20}
        height={20}
        color={theme.palette.success.default}
      />
      <Typography variant={K.typographicVariants.body3}>
        {intl.formatMessage(locales.referral_program_faqs)}
      </Typography>
    </ReferralButton>
  );
};

ReferralProgramFAQsAction.propTypes = {
  className: PropTypes.string,
  process: PropTypes.string
};

ReferralProgramFAQsAction.defaultProps = {
  className: "",
  process: ""
};

export default ReferralProgramFAQsAction;
