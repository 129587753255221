/* eslint-disable global-require, import/no-dynamic-require */
import { createIntl, createIntlCache } from "react-intl";
import getMessagesForLocale from "../../locales/index";

const messages = getMessagesForLocale(window.locale);

// https://formatjs.io/docs/react-intl/api#createintl
// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const intl = createIntl(
  {
    locale: window.locale,
    messages
  },
  cache
);

export default intl;
