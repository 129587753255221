import React, { memo, useRef } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { useLocation, useHistory } from "react-router-dom";
import { Tabs, Tab, TabPanel } from "../../atoms";
import { PageTemplate } from "../../templates";
import { OrderList, EmptyFinished, EmptyActives } from "./components";
import locales from "../../locales";
import { ORDERS_PATH_ACTIVE_TAB, ORDERS_PATH_FINISHED_TAB } from "../../routes";
import useOrders from "./hooks";

const useStyles = createUseStyles({
  orders__tabs: {
    margin: "0 auto",
    padding: "0 8px",
    "@media(min-width: 768px)": {
      width: "auto"
    }
  }
});

export const OrdersPage = memo(
  ({
    activeOrders,
    hasMoreActiveOrders,
    fetchMoreActiveOrders,
    activeOrdersFetching,
    activeOrdersError,
    finishedOrders,
    hasMoreFinishedOrders,
    fetchMoreFinishedOrders,
    finishedOrdersFetching,
    finishedOrdersError
  }) => {
    const intl = useIntl();
    const classes = useStyles();

    const location = useLocation();
    const history = useHistory();

    const indexTabSelected = useRef(0);

    const query = queryString.parse(location.search);
    const selectedTab = query.tab;

    React.useEffect(() => {
      if (selectedTab === ORDERS_PATH_FINISHED_TAB)
        indexTabSelected.current = 1;
      else indexTabSelected.current = 0;
    }, [selectedTab]);

    const onChange = tabIndex => {
      indexTabSelected.current = tabIndex;
      if (tabIndex === 0) history.push(`?tab=${ORDERS_PATH_ACTIVE_TAB}`);
      if (tabIndex === 1) history.push(`?tab=${ORDERS_PATH_FINISHED_TAB}`);
    };

    const loading =
      indexTabSelected.current === 0
        ? activeOrdersFetching
        : finishedOrdersFetching;
    const orders =
      indexTabSelected.current === 0 ? activeOrders : finishedOrders;

    return (
      <PageTemplate
        title={intl.formatMessage(locales.general_page_title_orders)}
        loader={loading && orders.length === 0}
        isOrders
      >
        <Tabs
          value={indexTabSelected.current}
          onChange={onChange}
          className={classes.orders__tabs}
        >
          <Tab>{intl.formatMessage(locales.actives)}</Tab>
          <Tab>{intl.formatMessage(locales.finished)}</Tab>
        </Tabs>
        <TabPanel value={indexTabSelected.current} index={0}>
          <OrderList
            orders={activeOrders}
            hasMoreOrders={hasMoreActiveOrders}
            fetchMoreOrders={fetchMoreActiveOrders}
            fetching={activeOrdersFetching}
            error={activeOrdersError}
            keyPrefix="active-orders"
            emptyOrderListNode={
              <EmptyActives
                onClick={() => {
                  indexTabSelected.current = 1;
                }}
              />
            }
          />
        </TabPanel>
        <TabPanel value={indexTabSelected.current} index={1}>
          <OrderList
            orders={finishedOrders}
            hasMoreOrders={hasMoreFinishedOrders}
            fetchMoreOrders={fetchMoreFinishedOrders}
            fetching={finishedOrdersFetching}
            error={finishedOrdersError}
            keyPrefix="finished-orders"
            emptyOrderListNode={<EmptyFinished />}
          />
        </TabPanel>
      </PageTemplate>
    );
  }
);

OrdersPage.propTypes = {
  activeOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMoreActiveOrders: PropTypes.bool.isRequired,
  fetchMoreActiveOrders: PropTypes.func.isRequired,
  activeOrdersFetching: PropTypes.bool.isRequired,
  activeOrdersError: PropTypes.bool,
  finishedOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMoreFinishedOrders: PropTypes.bool.isRequired,
  fetchMoreFinishedOrders: PropTypes.func.isRequired,
  finishedOrdersFetching: PropTypes.bool.isRequired,
  finishedOrdersError: PropTypes.bool
};

OrdersPage.defaultProps = {
  activeOrdersError: false,
  finishedOrdersError: false
};

const Orders = () => {
  const {
    orders: activeOrders,
    hasMore: hasMoreActiveOrders,
    fetchMoreOrders: fetchMoreActiveOrders,
    fetching: activeOrdersFetching,
    error: activeOrdersError
  } = useOrders({ activeOrders: true });
  const {
    orders: finishedOrders,
    hasMore: hasMoreFinishedOrders,
    fetchMoreOrders: fetchMoreFinishedOrders,
    fetching: finishedOrdersFetching,
    error: finishedOrdersError
  } = useOrders({ activeOrders: false });

  return (
    <OrdersPage
      activeOrders={activeOrders}
      hasMoreActiveOrders={hasMoreActiveOrders}
      fetchMoreActiveOrders={fetchMoreActiveOrders}
      activeOrdersFetching={activeOrdersFetching}
      activeOrdersError={activeOrdersError}
      finishedOrders={finishedOrders}
      hasMoreFinishedOrders={hasMoreFinishedOrders}
      fetchMoreFinishedOrders={fetchMoreFinishedOrders}
      finishedOrdersFetching={finishedOrdersFetching}
      finishedOrdersError={finishedOrdersError}
    />
  );
};

export default memo(Orders);
