import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { ModalDescription } from "../../../ConversionFlow/components";
import AmortizationSummaryTable from "../AmortizationSummaryTable";
import locales from "../../../../locales";

const AmortizationModalDescription = ({ modalTitle }) => {
  const intl = useIntl();

  return (
    <ModalDescription
      title={intl.formatMessage(modalTitle)}
      subtitle={intl.formatMessage(
        locales.amortization_flow_amortize_plan_description
      )}
    >
      <AmortizationSummaryTable />
    </ModalDescription>
  );
};

AmortizationModalDescription.propTypes = {
  modalTitle: PropTypes.shape({
    id: PropTypes.string,
    defaultMessages: PropTypes.string
  }).isRequired
};

export default AmortizationModalDescription;
