import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { useLocation } from "react-router-dom";
import locales from "../../locales";
import { Button, NextModal, Typography } from "../../atoms";
import { Check } from "../../atoms/Illustrations";
import K from "../../constants";

const useStyles = createUseStyles({
  modal: {
    position: "relative",
    maxHeight: 549,
    maxWidth: 514,
    borderRadius: 8,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0,
    "@media(min-width: 768px)": {
      maxHeight: 517
    }
  },
  success_plan_modal: {
    width: "100%",
    textAlign: "center",
    "@media(min-width: 768px)": {
      textAlign: "left"
    }
  },
  success_plan_modal__header: {
    marginTop: -5,
    "@media(min-width: 768px)": {
      marginTop: -30,
      marginBottom: 20
    }
  },
  success_plan_modal__content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 20,
    "@media(min-width: 768px)": {
      flex: 0,
      marginTop: 0
    }
  },
  success_plan_modal__content__subtitle: {
    marginTop: 20,
    lineHeight: "normal"
  },
  success_plan_modal__footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 56
  },
  success_plan_modal__footer__back_btn: {
    marginTop: 16
  }
});

const SuccessPlanModal = ({ onClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const location = useLocation();
  return (
    <NextModal
      open
      hideBack
      onClose={onClose}
      withIconClose
      modalClassname={classes.modal}
    >
      <div className={classes.success_plan_modal}>
        <div className={classes.success_plan_modal__header}>
          <Check width={100} height={100} color={theme.palette.info.default} />
        </div>
        <div className={classes.success_plan_modal__content}>
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(locales.success_plan_modal_title)}
          </Typography>
          <Typography className={classes.success_plan_modal__content__subtitle}>
            {intl.formatMessage(locales.success_plan_modal_first_paragraph)}
          </Typography>
          <Typography className={classes.success_plan_modal__content__subtitle}>
            {intl.formatMessage(locales.success_plan_modal_second_paragraph)}
          </Typography>
        </div>
        <div className={classes.success_plan_modal__footer}>
          <Button
            fullWidth
            variant="text"
            size="large"
            className={classes.success_plan_modal__footer__back_btn}
            onClick={() => window.location.replace(location.pathname)}
          >
            <Typography variant={K.typographicVariants.buttonL}>
              {intl.formatMessage(locales.success_plan_modal_close)}
            </Typography>
          </Button>
        </div>
      </div>
    </NextModal>
  );
};

SuccessPlanModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SuccessPlanModal;
