import React from "react";
import PropTypes from "prop-types";
import Name from "./components/Name";
import IdentificationNumber from "./components/IdentificationNumber";
import Email from "./components/Email";
import Phone from "./components/Phone";
import OtpStep from "./components/OtpStep";
import AccountExisted from "./components/AccountExisted";

const StepForm = ({
  currentStepForm,
  onSubmitStep,
  onClickPrevStep,
  onSubmitStepOtp,
  stepFormInputsValues,
  onChangeInputValue,
  fieldsValidationErrors
}) => {
  const {
    name,
    identificationNumber,
    email,
    phone,
    otp
  } = stepFormInputsValues;
  switch (currentStepForm) {
    case -1:
      return <AccountExisted currentStepForm={currentStepForm} />;
    case 0:
      return (
        <Name
          onSubmitStep={onSubmitStep}
          onClickPrevStep={onClickPrevStep}
          currentStepForm={currentStepForm}
          inputValue={name}
          onChangeInputValue={onChangeInputValue}
        />
      );
    case 1:
      return (
        <IdentificationNumber
          onSubmitStep={onSubmitStep}
          onClickPrevStep={onClickPrevStep}
          currentStepForm={currentStepForm}
          guestName={name}
          inputValue={identificationNumber}
          onChangeInputValue={onChangeInputValue}
          error={fieldsValidationErrors.NIN}
        />
      );
    case 2:
      return (
        <Email
          onSubmitStep={onSubmitStep}
          onClickPrevStep={onClickPrevStep}
          currentStepForm={currentStepForm}
          inputValue={email}
          onChangeInputValue={onChangeInputValue}
          error={fieldsValidationErrors.EMAIL}
        />
      );
    case 3:
      return (
        <Phone
          onSubmitStep={onSubmitStep}
          onClickPrevStep={onClickPrevStep}
          currentStepForm={currentStepForm}
          inputValue={phone}
          onChangeInputValue={onChangeInputValue}
          error={fieldsValidationErrors.PHONE}
        />
      );
    case 4:
      return (
        <OtpStep
          onClickPrevStep={onClickPrevStep}
          onSubmitStep={onSubmitStepOtp}
          currentStepForm={currentStepForm}
          phone={phone}
          onChangeInputValue={onChangeInputValue}
          otpValue={otp}
          error={fieldsValidationErrors.OTP}
        />
      );
    default:
      return null;
  }
};

StepForm.propTypes = {
  currentStepForm: PropTypes.number,
  onSubmitStep: PropTypes.func.isRequired,
  onClickPrevStep: PropTypes.func.isRequired,
  onSubmitStepOtp: PropTypes.func.isRequired,
  stepFormInputsValues: PropTypes.shape({
    name: PropTypes.string,
    identificationNumber: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    otp: PropTypes.string
  }).isRequired,
  onChangeInputValue: PropTypes.func.isRequired,
  fieldsValidationErrors: PropTypes.shape({
    NIN: PropTypes.bool,
    EMAIL: PropTypes.bool,
    PHONE: PropTypes.bool,
    OTP: PropTypes.bool
  }).isRequired
};

StepForm.defaultProps = {
  currentStepForm: undefined
};

export default StepForm;
