import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles(theme => ({
  paper: {
    borderRadius: "8px",
    backgroundColor: theme.palette.white.default,
    boxSizing: "border-box",
    padding: "16px",
    height: ({ large }) => (large ? "100%" : "auto")
  }
}));

const Paper = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    large, // TODO: Remove this
    ...rest
  } = props;

  const classes = useStyles({ large });

  return (
    <div className={classnames(classes.paper, className)} ref={ref} {...rest}>
      {children}
    </div>
  );
});

Paper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  large: PropTypes.bool
};

Paper.defaultProps = {
  children: null,
  className: null,
  large: false
};

export default Paper;
