import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Receipt = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 18 20">
      <path
        fill={color}
        d="M16.5 1.5 15 0l-1.5 1.5L12 0l-1.5 1.5L9 0 7.5 1.5 6 0 4.5 1.5 3 0v14H1c-.55 0-1 .45-1 1v2c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V0l-1.5 1.5ZM12 18H3c-.55 0-1-.45-1-1v-1h10v2Zm4-1c0 .55-.45 1-1 1s-1-.45-1-1v-2c0-.55-.45-1-1-1H5V3h11v14Z"
      />
    </svg>
  );
};

Receipt.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Receipt.defaultProps = {
  width: 18,
  height: 20,
  color: theme.palette.tertiary.default
};

export default Receipt;
