import React, {
  createContext,
  useContext,
  useEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";
import store from "store";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useSetState } from "react-use";
import { shutdown } from "@intercom/messenger-js-sdk";

import useTrackingContext from "../Tracking";
import useMonitoringContext from "../Monitoring";
import {
  LOGOUT_MUTATION,
  GET_SESSION_AUTHORIZATION,
  CREATE_NEW_SESSION
} from "../../graphql";
import { DASHBOARD_PATH, LOGIN_PATH } from "../../routes";

const FULL_SESSION = "FullSession";
const ORDER_SESSION = "OrderSession";
const SELECTOR_SESSION = "SelectorSession";
const NO_SESSION = "NoSession";
const REFERRAL_SESSION = "ReferralSignUpSession";

export const initialState = {
  init: false,
  sessionAuthorization: null,
  fetching: false,
  error: null
};

export const SessionAuthorizationContext = createContext();

export const SessionAuthorizationContextProvider = ({ children }) => {
  const history = useHistory();
  const { track, reset } = useTrackingContext();
  const { monitoringSetContext } = useMonitoringContext();
  const [
    { init, sessionAuthorization, fetching, error },
    setSessionAuthorizationState
  ] = useSetState(initialState);

  const sessionType = sessionAuthorization?.__typename;
  const isAuthorized = [
    FULL_SESSION,
    ORDER_SESSION,
    SELECTOR_SESSION,
    REFERRAL_SESSION
  ].includes(sessionType);
  const isFullSession = sessionType === FULL_SESSION;
  const isPartialSession = isAuthorized && !isFullSession;
  const isReferralPartialSession =
    isAuthorized &&
    !isFullSession &&
    sessionAuthorization?.__typename === REFERRAL_SESSION;
  const currentShopperUserId = sessionAuthorization?.currentShopperUserId;
  const referralHostName = sessionAuthorization?.referralHostName;
  const allowedShopperAccessScope =
    sessionAuthorization?.allowedShopperAccessScope;
  const shopperAccountReadOnly = sessionAuthorization?.shopperAccountReadOnly;

  const sessionDataQuery = useLazyQuery(GET_SESSION_AUTHORIZATION);
  const [fetchSessionQuery] = sessionDataQuery;

  const fetchSession = useCallback(() => {
    setSessionAuthorizationState({ fetching: true });
    fetchSessionQuery();
  });

  const updateSession = newSession =>
    setSessionAuthorizationState({
      sessionAuthorization: newSession,
      init: true
    });

  useEffect(() => {
    const [, { data: sessionData, error: sessionError }] = sessionDataQuery;

    if (sessionError) {
      setSessionAuthorizationState({
        sessionAuthorization: null,
        error,
        fetching: false,
        init: true
      });
    } else if (sessionData?.sessionAuthorization) {
      const isNoSession =
        sessionData?.sessionAuthorization.__typename === NO_SESSION;
      monitoringSetContext({
        user_id: sessionData.sessionAuthorization.currentShopperUserId,
        session_type:
          sessionData.sessionAuthorization.allowedShopperAccessScope,
        shopper_account_read_only:
          sessionData.sessionAuthorization.shopperAccountReadOnly
      });
      setSessionAuthorizationState({
        sessionAuthorization: isNoSession
          ? null
          : sessionData?.sessionAuthorization,
        fetching: false,
        error: null,
        init: true
      });
      if (isNoSession) {
        history.replace(LOGIN_PATH);
      }
    }
  }, [sessionDataQuery]);

  const [
    createNewSessionMutation,
    { loading: createNewSessionLoading }
  ] = useMutation(CREATE_NEW_SESSION);

  const [logoutMutation, { loading: logoutLoading }] = useMutation(
    LOGOUT_MUTATION
  );

  const logout = useCallback(async () => {
    await logoutMutation();
    updateSession(null);
    track("Logout");
    reset();
    // Intercom Messenger shutdown
    shutdown();
    /* Only for app reviewers.

    We are saving the api uri on the localStorage object here: /shopper-app/src/pages/Login/Login.js:127
    in order to have available the reviewers (sandbox) api uri during hard refreshing of the page.

    With this line, we ensure that we remove the api from our localStorage after logout.
    */
    store.remove("api");
    history.replace(LOGIN_PATH);
  }, []);

  const createNewSessionByPhone = useCallback(async code => {
    const response = await createNewSessionMutation({
      variables: {
        sessionIdHash: store.get("otpHash"),
        otp: code
      }
    });
    const { newSession } = response.data;
    const ok = newSession.__typename !== "InvalidOtp";
    const selectorType = "phone";
    if (ok) {
      store.remove("otpHash");
      track("Session Creation Success", {
        selectorType,
        sessionType: newSession.sessionAuthorization?.__typename
      });
      setSessionAuthorizationState({
        sessionAuthorization: newSession.sessionAuthorization,
        fetching: false
      });
      history.push(DASHBOARD_PATH);
    } else {
      track("Session Creation Error", {
        selectorType,
        errorType: "InvalidOtp"
      });
      setSessionAuthorizationState({
        sessionAuthorization: null,
        fetching: false,
        error: response.errors
      });
    }
    return ok;
  }, []);

  const isAuthorizing = fetching || createNewSessionLoading || logoutLoading;

  const contextValue = {
    allowedShopperAccessScope,
    createNewSessionByPhone,
    currentShopperUserId,
    error,
    fetchSession,
    init,
    isAuthorized,
    isAuthorizing,
    isFullSession,
    isPartialSession,
    isReferralPartialSession,
    logout,
    shopperAccountReadOnly,
    updateSession,
    referralHostName
  };
  return (
    <SessionAuthorizationContext.Provider value={contextValue}>
      {children}
    </SessionAuthorizationContext.Provider>
  );
};

SessionAuthorizationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default () => useContext(SessionAuthorizationContext);
