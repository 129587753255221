import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { ORDERS_PATH, ORDERS_PATH_FINISHED_TAB } from "../../../../routes";
import { Typography } from "../../../../atoms";
import { Submarine } from "../../../../atoms/Illustrations";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  empty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "40px",
    "@media(min-width: 768px)": {
      alignSelf: "center",
      marginTop: "80px"
    },
    "& :first-child": {
      marginBottom: "24px"
    },
    "& :nth-child(2)": {
      marginBottom: "16px"
    },
    "& :nth-child(3)": {
      marginBottom: "4px"
    },
    "& :last-child": {
      "& a": {
        color: theme.palette.success.default,
        textDecoration: "none",
        textTransform: "lowercase"
      }
    }
  }
}));

const EmptyActives = ({ onClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <div className={classes.empty}>
      <Submarine color={theme.palette.primary.default} width={85} height={85} />
      <Typography
        variant={K.typographicVariants.heading2}
        color={theme.palette.primary.default}
      >
        {intl.formatMessage(locales.empty_actives_title)}
      </Typography>
      <Typography variant={K.typographicVariants.body2}>
        {intl.formatMessage(locales.empty_actives_subtitle_one)}
      </Typography>
      <Typography variant={K.typographicVariants.body2}>
        {intl.formatMessage(locales.empty_actives_subtitle_two)}{" "}
        <Link
          to={`${ORDERS_PATH}?tab=${ORDERS_PATH_FINISHED_TAB}`}
          onClick={onClick}
        >
          <Typography variant={K.typographicVariants.linkS}>
            {intl.formatMessage(locales.finished)}
          </Typography>
        </Link>
        .
      </Typography>
    </div>
  );
};

EmptyActives.propTypes = {
  onClick: PropTypes.func
};

EmptyActives.defaultProps = {
  onClick: () => {}
};

export default EmptyActives;
