import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Bin = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 14 18">
    <path
      d="M1,16 C1,17.1 1.9,18 3,18 L11,18 C12.1,18 13,17.1 13,16 L13,6 C13,4.9 12.1,4 11,4 L3,4 C1.9,4 1,4.9 1,6 L1,16 Z M13,1 L10.5,1 L9.79,0.29 C9.61,0.11 9.35,0 9.09,0 L4.91,0 C4.65,0 4.39,0.11 4.21,0.29 L3.5,1 L1,1 C0.45,1 0,1.45 0,2 C0,2.55 0.45,3 1,3 L13,3 C13.55,3 14,2.55 14,2 C14,1.45 13.55,1 13,1 Z"
      fill={color}
    />
  </svg>
);

Bin.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Bin.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Bin;
