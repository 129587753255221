import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import useTrackingContext from "../../../../../../contexts/Tracking";
import Layout from "../../Layout";
import { Button, Input, Typography } from "../../../../../../atoms";
import locales from "../../../../../../locales";
import K from "../../../../../../constants";

const Email = ({
  onSubmitStep,
  currentStepForm,
  inputValue,
  onChangeInputValue,
  onClickPrevStep,
  error
}) => {
  const intl = useIntl();
  const { track, eventNames } = useTrackingContext();

  useEffect(() => {
    track(eventNames.signUp.stepForm.email.start);
  }, []);
  return (
    <Layout
      subTitle={intl.formatMessage(locales.sign_up_step_form_email_subtitle)}
      claim={intl.formatMessage(locales.sign_up_step_form_email_claim)}
      currentStep={currentStepForm}
      onSubmitStep={onSubmitStep}
      onClickPrevStep={onClickPrevStep}
    >
      <div>
        <Input
          label={intl.formatMessage(
            locales.sign_up_step_form_email_label_input
          )}
          type="email"
          name="email"
          inputRef={React.createRef()}
          focus
          onChange={inputData => {
            onChangeInputValue(inputData);
          }}
          value={inputValue}
          testid="email"
        />
        {error && (
          <p>
            <Typography
              variant={K.typographicVariants.body2}
              color={K.colors.error}
            >
              {intl.formatMessage(locales.sign_up_step_form_email_error)}
            </Typography>
          </p>
        )}
      </div>

      <div>
        <Button type="submit" fullWidth size="large" disabled={!inputValue}>
          {intl.formatMessage(locales.general_navigation_next)}
        </Button>
      </div>
    </Layout>
  );
};

Email.propTypes = {
  onSubmitStep: PropTypes.func.isRequired,
  currentStepForm: PropTypes.number.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChangeInputValue: PropTypes.func.isRequired,
  onClickPrevStep: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired
};

export default Email;
