/* eslint-disable no-else-return */
import { useEffect } from "react";
import { useSetState } from "react-use";
import queryString from "query-string";
import useNextActionOrderQuery from "./queries/useNextActionOrder";
import { getOrderDetail } from "../../../utils";
import K from "../../../constants";
import { PROFILE_PATH } from "../../../routes";
import { getNextActionCardProps, getDashboardMessageProps } from "../utils";

export const initialState = {
  nextActionOrderDetailed: null,
  nextActionCardProps: {},
  messageProps: {},
  fetching: true,
  error: null
};

export default () => {
  const [state, setState] = useSetState(initialState);
  const [fetchNextAction] = useNextActionOrderQuery({
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    },
    onCompleted: data => {
      const nextActionOrderDetailed =
        data?.shopper.orders.length > 0
          ? getOrderDetail({
              order: data.shopper.orders[0]
            })
          : null;

      setState({
        nextActionOrderDetailed,
        nextActionCardProps: getNextActionCardProps(nextActionOrderDetailed),
        messageProps: getDashboardMessageProps(nextActionOrderDetailed),
        fetching: false
      });
    }
  });

  const onClickMessage = nextActionOrder => {
    const isInvoice = /i\d+/.test(nextActionOrder?.currentProductName);
    if (!nextActionOrder) {
      return "https://www.sequra.com/tiendas-que-usan-sequra?utm_source=sequra_app&utm_medium=dashboard_card&utm_campaign=web_tiendas";
    } else if (
      nextActionOrder?.hasExpiredCard ||
      nextActionOrder?.hasExpiringSoonCard
    ) {
      if (isInvoice) {
        return PROFILE_PATH;
      } else {
        return `/order/${nextActionOrder.id}?${queryString.stringify({
          process: K.process.changePaymentCard,
          order_id: nextActionOrder.id,
          gateway: K.mufasaGateways.assignCard
        })}`;
      }
    } else {
      return `/order/${nextActionOrder.id}?${queryString.stringify({
        process: K.process.payment,
        order_id: nextActionOrder.id,
        step: K.steps.summary,
        amount: isInvoice ? null : nextActionOrder.overdueAmount,
        reference: isInvoice ? null : nextActionOrder.paymentPlan.number
      })}`;
    }
  };

  useEffect(() => {
    fetchNextAction();
  }, []);

  return {
    ...state,
    onClickNextActionCard: () => {
      if (state.nextActionOrderDetailed) {
        return `/order/${state.nextActionOrderDetailed.id}`;
      }
      return false;
    },
    onClickMessage: onClickMessage(state.nextActionOrderDetailed)
  };
};
