import { useEffect } from "react";
import { hotjar } from "react-hotjar";

export default (userId = null, userAttributes = {}) => {
  useEffect(() => {
    try {
      hotjar.identify(userId, userAttributes);
    } catch (error) {
      console.warn("Hotjar identify failed");
    }
  }, []);
};
