import React from "react";
import { Overdue as OverdueInvoices } from "../Invoices";
import { useOrder } from "../../../hooks";
import PartPayments from "../PartPayments";

const Overdue = () => {
  const order = useOrder();

  if (/[s|p]?[p|r]\d+/.test(order.currentProductName)) {
    return <PartPayments />;
  }

  return <OverdueInvoices />;
};

export default Overdue;
