import { useLazyQuery } from "@apollo/client";
import {
  SHOPPER_QUERY,
  PAYMENT_PROTECTION_INSURANCE_FRAGMENT
} from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError }) =>
  useLazyQuery(SHOPPER_QUERY(PAYMENT_PROTECTION_INSURANCE_FRAGMENT), {
    /* Cache is disabled to avoid an issue with WeeCover widget failing and not rendering when
    starting on a different page and then navigating to PPI page. */
    fetchPolicy: K.fetchPolicy.noCache,
    nextFetchPolicy: K.fetchPolicy.noCache,
    onCompleted,
    onError
  });
