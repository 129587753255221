import React from "react";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useTheme, createUseStyles } from "react-jss";
import { useLocation, useHistory } from "react-router-dom";
import { MessageBox } from "../../../../molecules";
import { NextModal, Typography, Button } from "../../../../atoms";
import { CheckBox, CheckBoxOutlineBlank } from "../../../../atoms/Icons";
import { ModalOrderInfo } from "../../../ConversionFlow/components";
import { InvoiceDropDown } from "../../../PaymentFlow/components";
import { useOrder } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../../utils";

const useStyles = createUseStyles(theme => ({
  modal: {
    "@media(min-width: 768px)": {
      height: "715px"
    }
  },
  modal_title: {
    margin: "32px 0",
    "& > :first-child": {
      marginBottom: "8px"
    }
  },
  summary_list_total: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "48px"
  },
  summary_box: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    marginBottom: "16px",
    "& > :last-child": {
      borderBottom: "none",
      marginBottom: 0
    }
  },
  summary_checkbox: {
    marginRight: "8px",
    cursor: "pointer"
  },
  confirmation_button: {
    marginTop: "16px"
  }
}));

const ReturnRequestSummaryModal = () => {
  const order = useOrder();
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [invoiceNumbers, setInvoiceNumbers] = React.useState({});
  const invoices = order.invoices.filter(invoice => invoice.canBeReturned);

  React.useEffect(() => {
    setInvoiceNumbers(
      invoices.reduce(
        (acc, invoice) => ({ [invoice.number]: invoice.number, ...acc }),
        {}
      )
    );
  }, []);

  const onNext = () => {
    const invoiceNumbersValues = Object.values(invoiceNumbers).reverse();
    const search = queryString.stringify(
      {
        ...queryString.parse(location.search),
        step: K.steps.confirm,
        invoice_numbers: invoiceNumbersValues
      },
      { arrayFormat: "comma" }
    );
    history.push(`${location.pathname}?${search}`);
  };

  const checkInvoice = invoiceNumber => {
    const copyInvoiceNumbers = { ...invoiceNumbers };
    delete copyInvoiceNumbers[invoiceNumber];

    setInvoiceNumbers({
      ...copyInvoiceNumbers
    });
  };

  const uncheckInvoice = invoiceNumber => {
    setInvoiceNumbers({
      [invoiceNumber]: invoiceNumber,
      ...invoiceNumbers
    });
  };

  return (
    <NextModal open hideBack modalClassname={classes.modal}>
      <ModalOrderInfo
        merchantLogoURL={order.merchant.logo}
        merchantName={order.merchant.name}
        orderConfirmedAt={order.confirmedAt}
        orderNumberOfArticles={getTotalCountFromItemsList(
          getArticlesListFromCart(order.cart)
        )}
      />

      <div className={classes.modal_title}>
        <Typography
          variant={K.typographicVariants.heading2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(
            locales.order_return_request_select_invoices_title
          )}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(
            locales.order_return_request_select_invoices_subtitle
          )}
        </Typography>
      </div>

      {invoices.map(invoice => (
        <div className={classes.summary_box} key={invoice.number}>
          {invoices.length === 1 ? null : (
            <div className={classes.summary_checkbox}>
              {invoiceNumbers[invoice.number] || invoice.isOverdue ? (
                <div
                  onKeyPress={() => {
                    checkInvoice(invoice.number);
                  }}
                  onClick={() => {
                    checkInvoice(invoice.number);
                  }}
                  tabIndex="0"
                  role="checkbox"
                  aria-checked="true"
                >
                  <CheckBox
                    color={
                      invoice.isOverdue
                        ? theme.palette.secondary.default
                        : theme.palette.success.default
                    }
                  />
                </div>
              ) : (
                <div
                  onKeyPress={() => {
                    uncheckInvoice(invoice.number);
                  }}
                  onClick={() => {
                    uncheckInvoice(invoice.number);
                  }}
                  tabIndex="0"
                  role="checkbox"
                  aria-checked="false"
                >
                  <CheckBoxOutlineBlank color={theme.palette.shade.default} />
                </div>
              )}
            </div>
          )}
          <InvoiceDropDown invoice={invoice} />
        </div>
      ))}

      <div className={classes.summary_list_total}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.total)}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.shipments_with_quantity, {
            quantity: Object.values(invoiceNumbers).length
          })}
        </Typography>
      </div>
      <MessageBox
        text={intl.formatMessage(locales.order_return_request_return_message)}
        type="warning"
      />
      <Button
        className={classes.confirmation_button}
        variant="outlined"
        fullWidth
        onClick={onNext}
        disabled={Object.values(invoiceNumbers).length === 0}
      >
        {intl.formatMessage(locales.general_navigation_next)}
      </Button>
    </NextModal>
  );
};

export default ReturnRequestSummaryModal;
