import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { animated, useSpring } from "react-spring";
import { useCopyToClipboard, useMedia } from "react-use";
import { Typography, Button } from "../../../../atoms";
import { Check } from "../../../../atoms/Icons";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  copy_box: {
    height: "48px",
    padding: "8px",
    borderRadius: "4px",
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.shade.default}`,
    minWidth: "228px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    backgroundColor: "transparent",
    cursor: "pointer"
  },
  copy_box__buton: {
    height: "auto",
    width: "auto",
    marginLeft: "16px"
  },
  copy_box__copied: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const CopyBox = ({ label }) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const [copied, setCopied] = React.useState(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const isSmall = useMedia("(max-width: 320px)");
  const style = useSpring({
    to: async next => {
      if (copied) {
        await next({ display: "flex", opacity: 1 });
      } else {
        await next({ opacity: 0, display: "none" });
      }
    },
    from: { display: "none", opacity: 0 }
  });

  const onCopy = () => {
    copyToClipboard(label);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
    setCopied(true);
  };

  return (
    <div
      onClick={onCopy}
      onKeyDown={onCopy}
      className={classes.copy_box}
      role="button"
      tabIndex={0}
    >
      <animated.div className={classes.copy_box__copied} style={style}>
        <Check color={theme.palette.white.default} />
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.white.default}
        >
          {intl.formatMessage(locales.copied)}
        </Typography>
      </animated.div>
      <Typography
        variant={
          isSmall ? K.typographicVariants.caption1 : K.typographicVariants.body2
        }
      >
        {label}
      </Typography>
      <Button size="small" variant="text" className={classes.copy_box__buton}>
        {intl.formatMessage(locales.copy)}
      </Button>
    </div>
  );
};

CopyBox.propTypes = {
  label: PropTypes.string
};

CopyBox.defaultProps = {
  label: ""
};

export default CopyBox;
