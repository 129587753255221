import React from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { SelectCardModal, MufasaModal } from "./modals";
import { useOrder, useShopperCardsDataQuery } from "../../hooks";
import useTrackingContext from "../../contexts/Tracking";
import K from "../../constants";
import {
  filterReusableCardReferences,
  goToOrderPath,
  paymentSummaryPath
} from "../../utils";

const ChangePaymentCardFlow = () => {
  const order = useOrder();
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const {
    track,
    buildOrderTrackingPayload,
    eventProperties,
    eventPropertyCategories,
    eventNames
  } = useTrackingContext();

  const { isOverdue } = order;

  React.useEffect(() => {
    if (isOverdue) {
      goToOrderPath({
        order,
        history,
        pathFunction: paymentSummaryPath
      });
    }

    switch (query.gateway) {
      case K.mufasaGateways.tokenization:
        track(eventNames.tokenization.start, {
          [eventProperties.CATEGORY]: eventPropertyCategories.CARDS
        });
        break;
      case K.mufasaGateways.assignCard:
        track(
          eventNames.assignCard.start,
          buildOrderTrackingPayload(order, true)
        );
        break;
      default:
        break;
    }
  }, []);

  const cardReferencesResponse = useShopperCardsDataQuery({
    fetchPolicy: K.fetchPolicy.cacheAndNetwork
  });

  const emptyReusableCardReferences =
    filterReusableCardReferences(
      cardReferencesResponse.data?.shopper?.cardReferences
    ).length === 0;

  if (query.step === K.steps.addCard || emptyReusableCardReferences) {
    return <MufasaModal open hideBack={emptyReusableCardReferences} />;
  }

  return (
    <SelectCardModal
      open
      hideBack={query.process === K.process.changePaymentCard}
    />
  );
};

export default ChangePaymentCardFlow;
