import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Cart = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M6.287 15.015c-.95-.049-.932-1.392 0-1.439 2.641.01 9.607.003 12.332.005.745 0 1.383-.51 1.55-1.24l1.548-6.734a1.293 1.293 0 0 0-1.25-1.585c-1.096 0-10.401-.042-15.167-.061l-.756-2.818A1.544 1.544 0 0 0 3.058 0H.638A.64.64 0 0 0 0 .64c0 .355.285.641.638.641h2.42c.12 0 .224.081.255.197l2.902 10.818c-.51.019-.985.228-1.346.595a2.005 2.005 0 0 0 1.418 3.405h.93A2.24 2.24 0 0 0 9.233 19.5a2.24 2.24 0 0 0 2.015-3.204h4.026A2.24 2.24 0 0 0 17.29 19.5a2.24 2.24 0 0 0 2-3.237.64.64 0 0 0-.204-1.247H6.287zm3.902 2.242a.96.96 0 0 1-.956.962.96.96 0 0 1-.957-.962c0-.53.429-.96.956-.96h.001a.96.96 0 0 1 .956.96zm7.101.961a.96.96 0 0 1-.956-.96.96.96 0 0 1 .94-.962h.032a.96.96 0 0 1 .94.961.96.96 0 0 1-.956.961zm3.182-12.91c.004.005.003.007.002.01l-.652 2.838h-2.207l.375-2.863 2.472.01c.004 0 .006 0 .01.005zM13.899 12.3V9.438h2.261l-.376 2.861H13.9zm-3.162 0-.378-2.861h2.265v2.861h-1.887zm-2.935 0H7.8a.346.346 0 0 1-.332-.255l-.7-2.606h2.304l.38 2.861h-1.65zm2.387-4.143L9.806 5.26l2.818.012v2.884h-2.435zm3.71 0v-2.88l2.806.012-.377 2.868h-2.43zm-5.38-2.901.384 2.901H6.425l-.781-2.913 2.875.012zm10.1 7.044H17.07l.375-2.861h2.082l-.601 2.615a.315.315 0 0 1-.308.246z"
    />
  </svg>
);

Cart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Cart.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Cart;
