import { useEffect } from "react";
import { useSetState } from "react-use";
import useRecentMerchants from "./queries/useRecentMerchants";

export const initialState = {
  recentMerchants: [],
  fetching: true,
  error: null
};

export default () => {
  const [state, setState] = useSetState(initialState);
  const [fetchRecentMerchants] = useRecentMerchants({
    onCompleted: data => {
      const recentMerchants = data?.shopper.lastUsedStores;
      setState({
        recentMerchants: recentMerchants.length > 0 ? recentMerchants : [],
        fetching: false
      });
    },
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    }
  });

  useEffect(() => {
    fetchRecentMerchants();
  }, []);

  return state;
};
