import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  list_header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "16px 0 8px 0",
    flex: 1,
    cursor: "pointer"
  }
});

const ListHeader = React.forwardRef((props, ref) => {
  const { children, className, onClick } = props;
  const classes = useStyles();
  const componentRef = ref || React.useRef(null);
  const classNames = classnames(classes.list_header, className);

  return (
    <div
      ref={componentRef}
      className={classNames}
      role="tab"
      tabIndex="0"
      onClick={onClick}
      onKeyPress={onClick}
    >
      {children}
    </div>
  );
});

ListHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func
};

ListHeader.defaultProps = {
  children: null,
  className: "",
  onClick: () => {}
};

export default ListHeader;
