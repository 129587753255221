import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import { useLocaleCurrency } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  conversion_plan_info: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "375px",
    width: "100%",
    marginBottom: "24px",
    "& > div": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "8px",
      "&:last-child": {
        justifyContent: "center",
        marginTop: "8px",
        marginBottom: 0,
        "& a": {
          textDecoration: "none"
        }
      }
    }
  },
  conversion_plan_info__claim: {
    marginBottom: "24px",
    "& span": {
      fontWeight: "bold"
    }
  }
});

const ConversionPlanInfo = ({ conversionPlan }) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  return (
    <>
      <div
        role="table"
        aria-label={intl.formatMessage(locales.conversion_plan_info)}
        className={classes.conversion_plan_info}
      >
        <div role="row">
          <div role="cell">
            <Typography
              variant={K.typographicVariants.body2}
              color={theme.palette.primary.default}
            >
              {intl.formatMessage(
                locales.flexi_conversion_info_order_amount_title
              )}
            </Typography>
          </div>
          <div role="cell">
            <Typography color={theme.palette.primary.default}>
              {useLocaleCurrency(conversionPlan?.totalWithTax, {
                useCents: true
              })}
            </Typography>
          </div>
        </div>
        <div role="row">
          <div role="cell">
            <Typography
              variant={K.typographicVariants.body2}
              color={theme.palette.primary.default}
            >
              {intl.formatMessage(
                locales.order_conversion_info_instalment_amount_title,
                {
                  amount: useLocaleCurrency(conversionPlan?.instalmentFee, {
                    useCents: true
                  })
                }
              )}
            </Typography>
          </div>
          <div role="cell">
            <Typography color={theme.palette.primary.default}>
              {useLocaleCurrency(conversionPlan?.costOfCredit, {
                useCents: true
              })}
            </Typography>
          </div>
        </div>
        <div role="row">
          <div role="cell">
            <Typography
              variant={K.typographicVariants.body2}
              color={theme.palette.primary.default}
            >
              {intl.formatMessage(locales.total)}
            </Typography>
          </div>
          <div role="cell">
            <Typography color={theme.palette.primary.default}>
              {useLocaleCurrency(conversionPlan?.grandTotal, {
                useCents: true
              })}
            </Typography>
          </div>
        </div>
        <div role="row">
          <div role="cell">
            <a
              href={conversionPlan?.secciUrl}
              alt={intl.formatMessage(locales.credit_info)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant={K.typographicVariants.linkS}>
                {intl.formatMessage(locales.credit_info)}
              </Typography>
            </a>
          </div>
        </div>
      </div>
      <p className={classes.conversion_plan_info__claim}>
        <Typography
          color={theme.palette.primary.default}
          variant={K.typographicVariants.body2}
        >
          {intl.formatMessage(
            locales.flexi_conversion_info_order_next_instalment_claim,
            {
              amount: useLocaleCurrency(
                conversionPlan?.futureInstalments[0]?.instalmentTotal,
                {
                  useCents: true
                }
              ),
              date: intl.formatDate(
                conversionPlan?.futureInstalments[0]?.dueOn,
                {
                  month: "short",
                  day: "numeric",
                  timeZone: "Europe/Madrid"
                }
              )
            }
          )}
        </Typography>
      </p>
    </>
  );
};

ConversionPlanInfo.propTypes = {
  conversionPlan: PropTypes.shape()
};

ConversionPlanInfo.defaultProps = {
  conversionPlan: null
};

export default ConversionPlanInfo;
