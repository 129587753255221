import React from "react";
import PropTypes from "prop-types";
import { iconNames, iconsByName } from "./constants";

const Icon = ({ name }) => {
  const IconComponent = iconsByName[name];
  return IconComponent ? <IconComponent /> : null;
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(iconNames)).isRequired
};

Icon.defaultProps = {};

export default Icon;
