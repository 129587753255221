import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const FileCopy = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 19 22">
    <path
      d="M13 0H2C.9 0 0 .9 0 2v13c0 .55.45 1 1 1s1-.45 1-1V3c0-.55.45-1 1-1h10c.55 0 1-.45 1-1s-.45-1-1-1Zm.59 4.59 4.83 4.83c.37.37.58.88.58 1.41V20c0 1.1-.9 2-2 2H5.99C4.89 22 4 21.1 4 20l.01-14c0-1.1.89-2 1.99-2h6.17c.53 0 1.04.21 1.42.59ZM13 11h4.5L12 5.5V10c0 .55.45 1 1 1Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

FileCopy.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

FileCopy.defaultProps = {
  width: 19,
  height: 22,
  color: theme.palette.secondary.default
};

export default FileCopy;
