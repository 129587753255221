import React, { useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useTheme, createUseStyles } from "react-jss";
import { useMedia } from "react-use";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useOrder, useLocaleCurrency } from "../../../../hooks";
import { NextModal, Typography, Button } from "../../../../atoms";
import useTrackingContext from "../../../../contexts/Tracking";
import {
  ModalOrderInfo,
  ConversionPlanSelectorList,
  ConversionPlanInfo
} from "../../components";

import locales from "../../../../locales";
import K from "../../../../constants";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../../utils";

const useStyles = createUseStyles({
  modal: {
    "@media(min-width: 768px)": {
      height: "715px"
    }
  },
  modal__content: {
    padding: "24px 40px"
  },
  conversion_plan_modal__title: {
    marginTop: "40px",
    marginBottom: "32px"
  }
});

const ConversionSelectPlanModal = ({ selectedPlan, setConversionPlan }) => {
  const order = useOrder();
  const theme = useTheme();
  const classes = useStyles();
  const isWideScreen = useMedia("(min-width: 768px)");
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const nextInstalment = order.unpaidInstalments[0];
  const totalAmount = nextInstalment?.instalmentTotal || nextInstalment?.amount;
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  const onNext = () => {
    const search = queryString.stringify({
      ...queryString.parse(location.search),
      step: K.steps.flexiConfirmationPlan,
      instalment_count: selectedPlan.instalmentCount
    });
    history.push(`${location.pathname}?${search}`);
  };

  useEffect(() => {
    track(
      eventNames.flexiConversion.selectPlan,
      buildOrderTrackingPayload(order, true)
    );
  }, []);

  return (
    <NextModal
      open
      modalClassname={classes.modal}
      contentClassname={classes.modal__content}
      hideBack
    >
      <ModalOrderInfo
        merchantLogoURL={order.merchant.logo}
        merchantName={order.merchant.name}
        orderConfirmedAt={order.confirmedAt}
        orderNumberOfArticles={getTotalCountFromItemsList(
          getArticlesListFromCart(order.cart)
        )}
      />
      <Typography
        variant={
          isWideScreen
            ? K.typographicVariants.heading1
            : K.typographicVariants.heading2
        }
        color={theme.palette.primary.default}
        className={classes.conversion_plan_modal__title}
      >
        {intl.formatMessage(locales.flexi_conversion_choose_plan_title, {
          totalAmount: useLocaleCurrency(totalAmount, {
            useCents: true
          })
        })}
      </Typography>
      <ConversionPlanSelectorList
        conversionPlans={order.conversionPlans}
        selectedPlan={selectedPlan}
        onSelectPlan={setConversionPlan}
      />
      <ConversionPlanInfo conversionPlan={selectedPlan} />
      <Button variant="outlined" fullWidth size="large" onClick={onNext}>
        {intl.formatMessage(locales.general_navigation_next)}
      </Button>
    </NextModal>
  );
};

ConversionSelectPlanModal.propTypes = {
  selectedPlan: PropTypes.shape(),
  setConversionPlan: PropTypes.func
};

ConversionSelectPlanModal.defaultProps = {
  selectedPlan: null,
  setConversionPlan: () => {}
};

export default ConversionSelectPlanModal;
