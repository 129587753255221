import React from "react";
import PropTypes from "prop-types";
import { withModal } from "../../../../hocs";
import { MufasaTokenizationGateway } from "../../../../molecules";
import locales from "../../../../locales";
import { getIntl } from "../../../../utils";

const MufasaFormModal = ({
  cardReferenceId,
  onSuccess,
  onError,
  onSubmit,
  onCancel
}) => (
  <MufasaTokenizationGateway
    cardReferenceId={cardReferenceId}
    onSubmit={onSubmit}
    onSuccess={onSuccess}
    onError={onError}
    onCancel={onCancel}
  />
);

MufasaFormModal.propTypes = {
  cardReferenceId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default withModal(MufasaFormModal, {
  title: getIntl.formatMessage(locales.verify_card_flow_mufasa_modal_title),
  open: true,
  hideBack: false,
  withBackdropClose: false
});
