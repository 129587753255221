import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { Typography, ListItem } from "../../atoms";
import { centsToBasicUnit } from "../../utils";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  article: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.shade.default,
    borderRadius: "8px",
    marginTop: "8px",
    padding: "4px"
  },
  article__info: {
    display: "flex",
    "& :first-child": {
      marginRight: "8px"
    },
    "& :last-child": {
      maxWidth: "200px",
      width: "100%"
    }
  }
}));

const Article = ({ article }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <ListItem className={classes.article} noBorderBottom>
      <div className={classes.article__info}>
        <Typography variant={K.typographicVariants.body3}>
          {article.quantity}
        </Typography>
        <Typography variant={K.typographicVariants.body3}>
          {article.name}
        </Typography>
      </div>
      <Typography variant={K.typographicVariants.body3}>
        {intl.formatNumber(centsToBasicUnit(article.totalWithTax), {
          style: "currency",
          currency: "eur"
        })}
      </Typography>
    </ListItem>
  );
};

Article.propTypes = {
  article: PropTypes.shape()
};

Article.defaultProps = {
  article: {}
};

export default Article;
