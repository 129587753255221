import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Paper, Button, Typography } from "../../atoms";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  help_box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "370px",
    boxShadow: "none",
    marginBottom: "16px",
    "@media (min-width: 768px)": {
      width: "320px"
    },
    "@media (min-width: 1280px)": {
      marginBottom: 0
    }
  },
  help_box__icon: {
    marginBottom: "24px"
  },
  help_box__separator: {
    height: "2px",
    width: "264px",
    backgroundColor: theme.palette.background.default,
    marginTop: "16px",
    marginBottom: "24px",
    border: 0
  },
  help_box__description: {
    textAlign: "center",
    flex: 1
  }
}));

const HelpBox = ({ icon, title, description, label, onClick }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.help_box}>
      <i className={classes.help_box__icon}>{icon}</i>
      <Typography variant={K.typographicVariants.heading4}>{title}</Typography>
      <hr className={classes.help_box__separator} />
      <Typography
        variant={K.typographicVariants.body2}
        className={classes.help_box__description}
      >
        {description}
      </Typography>
      <Button onClick={onClick} fullWidth>
        {label}
      </Button>
    </Paper>
  );
};

HelpBox.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

HelpBox.defaultProps = {
  icon: null,
  title: "",
  description: "",
  label: "",
  onClick: () => {}
};

export default HelpBox;
