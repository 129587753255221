import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar } from "react-circular-progressbar";
import { createUseStyles } from "react-jss";
import theme from "../../theme";

const useStyles = createUseStyles({
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "97px",
    height: "97px",
    borderRadius: "50%",
    position: "relative"
  },
  progress__content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "85px",
    height: "85px",
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    boxSizing: "border-box",
    borderRadius: "50%"
  }
});

const Progress = ({
  children,
  maxValue,
  value,
  backgroundColor,
  borderColor
}) => {
  const classes = useStyles({
    backgroundColor
  });

  return (
    <div className={classes.progress}>
      <CircularProgressbar
        maxValue={maxValue}
        value={value}
        strokeWidth={3}
        styles={{
          root: {
            position: "absolute"
          },
          path: {
            stroke: borderColor,
            strokeWidth: 3
          },
          trail: {
            stroke: theme.palette.shade.default,
            strokeWidth: 2
          }
        }}
      />
      <div className={classes.progress__content}>{children}</div>
    </div>
  );
};

Progress.propTypes = {
  children: PropTypes.node,
  maxValue: PropTypes.number,
  value: PropTypes.number,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string
};

Progress.defaultProps = {
  children: null,
  maxValue: 0,
  value: 0,
  backgroundColor: theme.palette.primary.default,
  borderColor: theme.palette.primary.default
};

export default Progress;
