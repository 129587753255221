import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import locales from "../../locales";
import useAppContext from "../../contexts/App";
import useSessionAuthenticationContext from "../../contexts/SessionAuthentication";
import { Button, NextModal, Typography } from "../../atoms";
import { FishBone } from "../../atoms/Illustrations";
import K from "../../constants";

const useStyles = createUseStyles({
  modal: {
    position: "relative",
    maxHeight: 549,
    maxWidth: 514,
    borderRadius: 8,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0
  },
  session_expired_modal: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  session_expired_modal__header: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
    "@media(min-width: 768px)": {
      marginTop: 12
    }
  },
  session_expired_modal__content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 31,
    textAlign: "center",
    "@media(min-width: 768px)": {
      flex: 0,
      marginTop: 20
    }
  },
  session_expired_modal__content__title: {
    marginBottom: "16px"
  },
  session_expired_modal__content__subtitle: {
    marginTop: 16
  },
  session_expired_modal__footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 56
  }
});

const SessionExpiredModal = () => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const { logout } = useSessionAuthenticationContext();
  const {
    isSessionExpiredModalOpened,
    setSessionModalStatus
  } = useAppContext();

  const onClickButton = () => {
    setSessionModalStatus();
    logout();
  };
  return (
    <NextModal
      open={isSessionExpiredModalOpened}
      hideNavigation
      modalClassname={classes.modal}
    >
      <div className={classes.session_expired_modal}>
        <div className={classes.session_expired_modal__header}>
          <FishBone />
        </div>
        <div className={classes.session_expired_modal__content}>
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
            className={classes.session_expired_modal__content__title}
          >
            {intl.formatMessage(locales.session_expired_modal_title)}
          </Typography>
          <Typography
            className={classes.session_expired_modal__content_subtitle}
          >
            {intl.formatMessage(locales.session_expired_modal_subtitle)}
          </Typography>
        </div>
        <div className={classes.session_expired_modal__footer}>
          <Button fullWidth size="medium" onClick={onClickButton}>
            {intl.formatMessage(locales.general_navigation_login)}
          </Button>
        </div>
      </div>
    </NextModal>
  );
};

export default SessionExpiredModal;
