import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { ErrorBoundary } from "../../../../../templates";
import { MerchantLogo, Typography } from "../../../../../atoms";
import locales from "../../../../../locales";
import K from "../../../../../constants";

const useStyles = createUseStyles(theme => ({
  recent_merchants_card: {
    width: "240px",
    height: "251px",
    display: "flex",
    flexDirection: "column",
    padding: "32px 18px 26px 18px",
    boxSizing: "border-box",
    backgroundColor: theme.palette.white.default,
    boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.09)",
    borderRadius: "8px"
  },
  recent_merchants_card__merchant_logos: {
    display: "grid",
    justifyContent: "space-around",
    gridTemplateColumns: "repeat(2, 75px)"
  },
  recent_merchants_card__merchant_logos__logo_container: {
    marginTop: "16px"
  },
  recent_merchants_card__merchant_logos__logo: {
    margin: 0
  },
  recent_merchants_card__merchant_logos__link: {
    margin: 0
  },
  skeleton: {
    backgroundRepeat: "no-repeat"
  }
}));

const RecentMerchantsSection = ({ className, fetching, merchants }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classnames(classes.recent_merchants_card, className)}>
      <Typography variant={K.typographicVariants.heading3}>
        {intl.formatMessage(locales.dashboard_recent_shops)}
      </Typography>
      <div className={classes.recent_merchants_card__merchant_logos}>
        <ErrorBoundary component="RecentMerchantsSection">
          {!fetching &&
            merchants.map(({ logo, name, url }) => (
              <div
                key={`${name}__logo_container`}
                className={
                  classes.recent_merchants_card__merchant_logos__logo_container
                }
              >
                <MerchantLogo
                  logoClassName={
                    classes.recent_merchants_card__merchant_logos__logo
                  }
                  linkClassName={
                    classes.recent_merchants_card__merchant_logos__link
                  }
                  merchantLogo={logo}
                  merchantName={name}
                  merchantUrl={url}
                />
              </div>
            ))}
        </ErrorBoundary>
      </div>
    </div>
  );
};

RecentMerchantsSection.propTypes = {
  className: PropTypes.string,
  fetching: PropTypes.bool,
  merchants: PropTypes.arrayOf(PropTypes.shape())
};

RecentMerchantsSection.defaultProps = {
  className: "",
  fetching: true,
  merchants: []
};

export default RecentMerchantsSection;
