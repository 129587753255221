import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography, Button } from "../../atoms";
import { SmileBag } from "../../atoms/Icons";
import { GenericTemplate } from "../../templates";
import { isMobile } from "../../utils";
import K from "../../constants";
import locales from "../../locales";

const useStyles = createUseStyles({
  no_match_page: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 24px",
    alignItems: "center",
    boxSizing: "border-box",
    "@media only screen and (min-width: 768px)": {
      flexDirection: "row-reverse",
      justifyContent: "center"
    }
  },
  description: {
    "@media only screen and (min-width: 768px)": {
      maxWidth: "420px",
      paddingBottom: "0"
    }
  },
  button: {
    marginTop: "16px"
  }
});

const NoMatchPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <GenericTemplate title="404">
      <div className={classes.no_match_page}>
        <SmileBag
          height={isMobile.phone ? 200 : 400}
          width={isMobile.phone ? 200 : 400}
          color={theme.palette.success.default}
        />
        <div className={classes.description}>
          <Typography variant={K.typographicVariants.heading3}>
            {intl.formatMessage(locales.not_match_page_exclamation)}
          </Typography>
          <Typography variant={K.typographicVariants.heading3}>
            {intl.formatMessage(locales.not_match_page_description)}
          </Typography>
          <Typography variant={K.typographicVariants.body2}>
            {intl.formatMessage(locales.not_match_page_apologize)}
          </Typography>
          <Button
            to="/"
            variant="outlined"
            size="large"
            className={classes.button}
          >
            {intl.formatMessage(locales.not_match_page_back_to_orders)}
          </Button>
        </div>
      </div>
    </GenericTemplate>
  );
};

export default NoMatchPage;
