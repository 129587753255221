import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import locales from "../../../../locales";
import { Button, NextModal, Typography } from "../../../../atoms";
import K from "../../../../constants";
import { Confetti } from "../../../../atoms/Illustrations";

const useStyles = createUseStyles(theme => ({
  modal: {
    position: "relative",
    maxHeight: "none",
    height: "auto",
    maxWidth: 514,
    borderRadius: 8,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0
  },
  modal__content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 20,
    padding: "0 16px",
    "@media(min-width: 768px)": {
      flex: 0,
      marginTop: 0,
      padding: "0 48px"
    },
    "& p": {
      marginBottom: "24px",
      "& span": {
        lineHeight: "normal"
      }
    }
  },
  modal__content__title: {
    margin: "24px 0",
    "& strong": {
      color: theme.palette.success.default
    }
  },
  modal__content__footer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    width: "100%",
    marginTop: 32,
    padding: "0 16px",
    "@media(min-width: 768px)": {
      marginTop: 42,
      padding: "0 48px"
    },
    "& ul": {
      display: "flex",
      alignItems: "center"
    },
    " & li": {
      width: "11px",
      height: "11px",
      marginRight: "10px",
      overflow: "hidden",
      borderRadius: "50%",
      backgroundColor: theme.palette.success.medium,
      textIndent: "-999px"
    }
  },
  next_purchases__footer__back_btn: {
    marginTop: 16
  }
}));

const SuccessfulModal = ({ onClickNext }) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  return (
    <NextModal
      open
      hideBack
      withIconClose
      modalClassname={classes.modal}
      withFullWidth
    >
      <div className={classes.modal__content}>
        <Confetti width={76} height={75} />
        <div data-testid="modal_title">
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
            className={classes.modal__content__title}
          >
            {intl.formatMessage(locales.sign_up_successful_modal_title, {
              amount: "10€",
              strong: msg => <strong>{msg}</strong>,
              br: <br />
            })}
          </Typography>
        </div>

        <p data-testid="modal_first_paragraph">
          <Typography
            variant={K.typographicVariants.body1}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(
              locales.sign_up_successful_modal_first_paragraph
            )}
          </Typography>
        </p>
      </div>
      <div className={classes.modal__content__footer}>
        <ul>
          <li>1</li>
          <li>2</li>
          <li>3</li>
          <li>4</li>
          <li>5</li>
        </ul>
        <Button size="medium" onClick={onClickNext}>
          {intl.formatMessage(locales.general_navigation_next)}
        </Button>
      </div>
    </NextModal>
  );
};

SuccessfulModal.propTypes = {
  onClickNext: PropTypes.func.isRequired
};

export default SuccessfulModal;
