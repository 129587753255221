import { useLazyQuery } from "@apollo/client";
import { SHOPPER_QUERY, GENERAL_VOUCHERS_FRAGMENT } from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError }) =>
  useLazyQuery(SHOPPER_QUERY(GENERAL_VOUCHERS_FRAGMENT), {
    fetchPolicy: K.fetchPolicy.cacheAndNetwork,
    nextFetchPolicy: K.fetchPolicy.cacheFirst,
    onCompleted,
    onError
  });
