import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classnames from "classnames";

const useStyles = createUseStyles({
  panel_section_content: {
    display: "flex",
    flexDirection: "row",
    flexGrow: ({ grow }) => (grow ? 1 : 0)
  }
});

const PanelSectionContent = React.forwardRef((props, ref) => {
  const { children, className, grow } = props;
  const classes = useStyles({ grow });

  return (
    <section
      ref={ref}
      className={classnames(className, classes.panel_section_content)}
    >
      {children}
    </section>
  );
});

PanelSectionContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  grow: PropTypes.bool
};

PanelSectionContent.defaultProps = {
  children: null,
  className: "",
  grow: false
};

export default PanelSectionContent;
