import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import { Alert } from "../../../../atoms/Illustrations";
import { useLocaleCurrency } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  overdue: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  overdue__info: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "16px"
    }
  }
});

const Overdue = ({ overdueAmount }) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.overdue}>
      <div className={classes.overdue__info}>
        <Typography
          variant={K.typographicVariants.visual3}
          color={K.colors.error}
        >
          {intl.formatMessage(locales.order_overdue)}
        </Typography>
        <Typography variant={K.typographicVariants.visual3}>
          {useLocaleCurrency(overdueAmount, { useCents: true })}
        </Typography>
      </div>
      <Alert width={97} height={97} color={theme.palette.error.default} />
    </div>
  );
};

Overdue.propTypes = {
  overdueAmount: PropTypes.number
};

Overdue.defaultProps = {
  overdueAmount: 0
};

export default Overdue;
