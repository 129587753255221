import React from "react";
import store from "store";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { Paper, Typography, Button } from "../../atoms";
import { BarLoader } from "../../atoms/Loaders";
import { GenericTemplate } from "../../templates";
import { NoMatchPage } from "../../components";
import useTrackingContext from "../../contexts/Tracking";
import {
  SELECTOR_BY_ID_AND_TYPE,
  VALIDATE_SELECTOR,
  LOGOUT_MUTATION
} from "../../graphql";
import { isMobile } from "../../utils";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles({
  selector_verification: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (min-width: 768px)": {
      flex: 0,
      maxWidth: "768px"
    }
  },
  selector_verification__title: {
    marginBottom: "4px"
  },
  selector_verification__description: {
    marginBottom: "8px"
  },
  selector_verification__button: {
    marginTop: "16px"
  }
});

const SelectorValidation = () => {
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const classes = useStyles();
  const {
    track,
    eventProperties,
    eventPropertyCategories
  } = useTrackingContext();
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [isValidated, setIsValidated] = React.useState(false);
  const [validationExpired, setValidationExpired] = React.useState(false);

  const { error, loading, data } = useQuery(SELECTOR_BY_ID_AND_TYPE, {
    variables: {
      selectorId: location.state?.selectorId,
      selectorType: location.state?.selectorType
    },
    onCompleted: res => {
      const { selectorByIdAndType } = res.shopper;

      setIsValidated(selectorByIdAndType.isValidated);
      setValidationExpired(selectorByIdAndType.validationExpired);
    }
  });

  const [validateSelector, { loading: loadingValidation }] = useMutation(
    VALIDATE_SELECTOR,
    {
      onCompleted: res => {
        const { __typename } = res.validateSelector;

        track("Validate selector", {
          [eventProperties.SELECTOR_TYPE]: __typename,
          [eventProperties.CATEGORY]: eventPropertyCategories.SHOPPER
        });

        if (__typename === "AlreadyValidatedSelector") setIsValidated(true);
        if (__typename === "SelectorValidationProcessExpired") {
          setValidationExpired(true);
        }

        setIsConfirmed(true);
      }
    }
  );

  const [logout] = useMutation(LOGOUT_MUTATION, {
    onCompleted: () => {
      /* Only for app reviewers.

      We are saving the api uri on the localStorage object here: /shopper-app/src/pages/Login/Login.js:127
      in order to have available the reviewers (sandbox) api uri during hard refreshing of the page.

      With this line, we ensure that we remove the api from our localStorage after logout.
      */
      store.remove("api");
      history.push("/login");
    }
  });

  if (error) return <NoMatchPage />;
  if (loading) return <BarLoader />;

  const { shopper, sessionAuthorization } = data;

  const isPhone = location.state.selectorType === "phone";

  const selectorType = isPhone ? "móvil" : "correo electrónico";

  const selectorPluralType = isPhone ? "móviles" : "correos electrónicos";

  const selectorValue = isPhone
    ? shopper.selectorByIdAndType.phone
    : shopper.selectorByIdAndType.email;

  const renderAction = () => {
    let title = "";
    let variant = "contained";
    let onClick = () => {};

    switch (true) {
      case isValidated:
      case validationExpired:
      case isConfirmed:
        variant = "outlined";

        if (sessionAuthorization.__typename === "FullSession") {
          title = intl.formatMessage(locales.selector_validation_back_button);
          onClick = () => {
            history.replace("/");
          };
          break;
        }

        title = intl.formatMessage(locales.selector_validation_login_button);
        onClick = logout;
        break;
      default:
        title = intl.formatMessage(locales.selector_validation_confirm_button);
        onClick = () => {
          validateSelector({
            variables: {
              selectorId: location.state.selectorId,
              selectorType: location.state.selectorType
            }
          });
        };
        break;
    }

    return (
      <Button
        className={classes.selector_verification__button}
        size="large"
        fullWidth={isMobile.phone}
        variant={variant}
        onClick={onClick}
      >
        {title}
      </Button>
    );
  };

  const renderTitle = () => {
    switch (true) {
      case validationExpired:
        return intl.formatMessage(locales.selector_validation_expired_title);
      case isValidated:
      case isConfirmed:
        return intl.formatMessage(locales.selector_validation_confirmed_title);
      default:
        return intl.formatMessage(locales.selector_validation_confirm_title, {
          selectorType
        });
    }
  };

  const renderDescription = () => {
    const values = {
      selectorType,
      selectorValue: <b style={{ fontWeight: "bold" }}>{selectorValue}</b>,
      selectorPluralType
    };

    switch (true) {
      case validationExpired:
        return intl.formatMessage(
          locales.selector_validation_expired_description,
          values
        );
      case isValidated:
        return intl.formatMessage(
          locales.selector_validation_already_validated_description,
          values
        );
      case isConfirmed:
        return intl.formatMessage(
          locales.selector_validation_confirmed_description,
          values
        );
      default:
        return intl.formatMessage(
          locales.selector_validation_confirm_description,
          values
        );
    }
  };

  return (
    <GenericTemplate title={`Validación de ${selectorType}`}>
      {loadingValidation && <BarLoader />}
      <Paper className={classes.selector_verification}>
        <Typography
          variant={K.typographicVariants.heading2}
          className={classes.selector_verification__title}
        >
          {renderTitle()}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          className={classes.selector_verification__description}
        >
          {renderDescription()}
        </Typography>
        {renderAction()}
      </Paper>
    </GenericTemplate>
  );
};

export default SelectorValidation;
