import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import Modal from "./components/Modal";
import useOrdersDataQuery from "./hooks/queries/useOrdersData";
import useOrderDataQuery from "./hooks/queries/useOrderData";
import useTrackingContext from "../../contexts/Tracking";
import { useShopperCardsDataQuery } from "../../hooks";
import { getOrderDetail } from "../../utils";
import useAssignCard from "./hooks/mutations/useAssignCard";
import useNotificationContext from "../../contexts/Notification";
import K from "../../constants";
import locales from "../../locales";

export const Flow = ({
  currentCardReferenceId,
  currentCardFingerPrint,
  currentOrder,
  ordersToUpdate,
  numberOfOrdersWithOverdue,
  onClickUpdateCard,
  orderCardUpdated
}) => {
  return (
    <Modal
      currentCardReferenceId={currentCardReferenceId}
      currentCardFingerPrint={currentCardFingerPrint}
      currentOrder={currentOrder}
      ordersToUpdate={ordersToUpdate}
      numberOfOrdersWithOverdue={numberOfOrdersWithOverdue}
      onClickUpdateCard={onClickUpdateCard}
      orderCardUpdated={orderCardUpdated}
    />
  );
};

Flow.propTypes = {
  ordersToUpdate: PropTypes.oneOfType([() => null, PropTypes.object]),
  orderCardUpdated: PropTypes.oneOfType([() => null, PropTypes.object]),
  currentOrder: PropTypes.oneOfType([() => null, PropTypes.object]),
  numberOfOrdersWithOverdue: PropTypes.number,
  currentCardReferenceId: PropTypes.string,
  onClickUpdateCard: PropTypes.func.isRequired,
  currentCardFingerPrint: PropTypes.string
};

Flow.defaultProps = {
  ordersToUpdate: [],
  orderCardUpdated: [],
  currentOrder: [],
  numberOfOrdersWithOverdue: 0,
  currentCardReferenceId: "",
  currentCardFingerPrint: ""
};

const ORDER_BATCH_SIZE = 6;

function filterOrders(orders, currentCardReference) {
  if (currentCardReference) {
    const ordersFilterByCardFingerprint = orders.filter(
      order =>
        order.cardReference &&
        order.cardReference?.cardFingerprint !==
          currentCardReference?.cardFingerprint &&
        !order.isOverdue
    );
    return ordersFilterByCardFingerprint;
  }

  return [];
}

function cookUpdatedOrder(order) {
  return getOrderDetail({ order });
}

const UpdateCardsFlow = () => {
  const [orderCardUpdated, setOrderCardUpdated] = useState([]);
  const [isInitTrack, setIsInitTrack] = useState(true);
  const { setSnackbarLayoutProps } = useNotificationContext();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();
  const intl = useIntl();
  const { id } = useParams();
  const [fetchOrderData, { data: orderData }] = useOrderDataQuery({
    onError: () => {},
    onCompleted: () => {},
    orderId: id
  });

  const [fetchOrdersData, { data }] = useOrdersDataQuery({
    onError: () => {},
    onCompleted: () => {},
    batchSize: ORDER_BATCH_SIZE,
    filterBy: "ACTIVE"
  });

  const [assignCard] = useAssignCard({
    onCompleted: ({ assignCard: { order: updatedOrder } }) => {
      setOrderCardUpdated([
        ...orderCardUpdated,
        cookUpdatedOrder(updatedOrder)
      ]);
    },
    onError: () => {
      setSnackbarLayoutProps({
        open: true,
        label: intl.formatMessage(locales.update_cards_assing_card_error),
        variant: "error"
      });
    }
  });

  function onClickUpdateCard(orderId, cardReferenceId, currentOrder) {
    const variables = {
      orderId,
      cardReferenceId
    };
    assignCard({ variables });
    track(
      eventNames.updatedCard.success,
      buildOrderTrackingPayload(currentOrder, true)
    );
  }

  useEffect(() => {
    fetchOrdersData();
    fetchOrderData();
  }, []);

  const currentOrder = orderData?.shopper?.orderById;
  const cardReferencesResponse = useShopperCardsDataQuery({
    fetchPolicy: K.fetchPolicy.networkOnly
  });

  const currentCardReference = cardReferencesResponse?.data?.shopper?.cardReferences.find(
    carReference =>
      carReference.cardFingerprint ===
      currentOrder?.cardReference?.cardFingerprint
  );

  const orders = data?.shopper?.orders || [];
  const ordersDetailed = orders.map(order => getOrderDetail({ order }));
  const ordersToUpdate =
    ordersDetailed.length > 0
      ? filterOrders(ordersDetailed, currentCardReference)
      : [];

  if (ordersDetailed.length > 0 && isInitTrack) {
    const eventName =
      ordersToUpdate.length > 0
        ? eventNames.updatedCard.start.withSuggestion
        : eventNames.updatedCard.start.withoutSuggestion;
    track(eventName, buildOrderTrackingPayload(currentOrder, true));
    setIsInitTrack(false);
  }
  return (
    <Flow
      currentCardReferenceId={currentCardReference?.id}
      currentCardFingerPrint={currentCardReference?.cardFingerprint}
      currentOrder={currentOrder}
      ordersToUpdate={ordersToUpdate}
      numberOfOrdersWithOverdue={
        ordersDetailed.filter(orderDetailed => orderDetailed.isOverdue).length
      }
      onClickUpdateCard={onClickUpdateCard}
      orderCardUpdated={orderCardUpdated}
    />
  );
};

export default UpdateCardsFlow;
