import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import SelectCardModal from "../SelectCardModal";
import { PaymentModalDescription } from "../../components";
import K from "../../../../constants";
import locales from "../../../../locales";
import { useOrder } from "../../../../hooks";

const PaymentSelectCardModal = () => {
  const location = useLocation();
  const intl = useIntl();
  const order = useOrder();
  const query = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: "comma"
  });

  const overdueAmount = order.isOverdue
    ? order.overdueAmount
    : order.unpaidAmount;
  const maxAmount =
    (order.isOverdue ? order.unpaidPlanAmount : order.unpaidAmount) +
    order.overdueAmount;
  const amountCents = Math.min(query.amount || overdueAmount, maxAmount);

  return (
    <SelectCardModal
      open
      title={intl.formatMessage(locales.order_payment_with_card)}
      paymentAmountCents={amountCents}
      withDescription
      hideBack={query.step === K.steps.summary}
      invoiceNumbers={
        typeof query.invoice_numbers === "string"
          ? [query.invoice_numbers]
          : query.invoice_numbers
      }
      contentDescription={
        <PaymentModalDescription
          invoiceNumbers={query.invoice_numbers}
          amountCents={amountCents}
        />
      }
    />
  );
};

export default PaymentSelectCardModal;
