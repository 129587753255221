import React from "react";
import PropTypes from "prop-types";
import { useMedia } from "react-use";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";
import MerchantLogo from "../../atoms/MerchantLogo";

const useStyles = createUseStyles({
  order_header: {
    display: "flex",
    overflow: "hidden",
    flex: 1
  },
  order_header__info: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "hidden",
    "@media(min-width: 768px)": {
      marginRight: "16px"
    }
  },
  order_header__info__merchant_name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media(min-width: 768px)": {
      lineHeight: "normal"
    }
  }
});

const OrderHeader = ({
  merchantLogo,
  merchantName,
  merchantUrl,
  orderReference,
  orderConfirmedAt
}) => {
  const intl = useIntl();
  const isWide = useMedia("(min-width: 768px)");
  const classes = useStyles();

  return (
    <div className={classes.order_header}>
      <MerchantLogo
        merchantName={merchantName}
        merchantLogo={merchantLogo}
        merchantUrl={merchantUrl}
      />
      <div className={classes.order_header__info}>
        <Typography
          variant={
            isWide
              ? K.typographicVariants.heading5
              : K.typographicVariants.body1
          }
          color={K.colors.secondary}
        >
          {intl.formatDate(orderConfirmedAt, {
            day: "numeric",
            month: "short",
            year: "numeric"
          })}
        </Typography>
        <Typography
          className={classes.order_header__info__merchant_name}
          variant={
            isWide
              ? K.typographicVariants.visual1
              : K.typographicVariants.visual2
          }
        >
          {merchantName}
        </Typography>
        <Typography variant={K.typographicVariants.body2}>
          {intl.formatMessage(locales.order_reference, {
            reference: orderReference
          })}
        </Typography>
      </div>
    </div>
  );
};

OrderHeader.propTypes = {
  merchantLogo: PropTypes.string,
  merchantName: PropTypes.string.isRequired,
  merchantUrl: PropTypes.string,
  orderReference: PropTypes.string.isRequired,
  orderConfirmedAt: PropTypes.string.isRequired
};

OrderHeader.defaultProps = {
  merchantLogo: "",
  merchantUrl: ""
};

export default OrderHeader;
