import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Event = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 16 16">
    <path
      d="M10.667 9.15h-2A.66.66 0 008 9.8v1.95c0 .357.3.65.667.65h2a.66.66 0 00.666-.65V9.8a.66.66 0 00-.666-.65zm0-6.5v.65H5.333v-.65A.66.66 0 004.667 2 .66.66 0 004 2.65v.65h-.667a1.31 1.31 0 00-1.326 1.3L2 13.7c0 .715.593 1.3 1.333 1.3h9.334C13.4 15 14 14.415 14 13.7V4.6c0-.715-.6-1.3-1.333-1.3H12v-.65a.66.66 0 00-.667-.65.66.66 0 00-.666.65zM12 13.7H4a.66.66 0 01-.667-.65v-6.5h9.334v6.5c0 .357-.3.65-.667.65z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

Event.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Event.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Event;
