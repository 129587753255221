export const MUFASA_IFRAME_ID = "mufasa-iframe";
export const AUTH_3DS_POPUP_ID = "mufasa-3ds-popup-backdrop";

export const MUFASA_RESPONSE_3DS_AUTHENTICATION = "Sequra.3ds_authentication";
export const MUFASA_RESPONSE_3DS_AUTHENTICATION_LOADED =
  "Sequra.3ds_authentication_loaded";
export const MUFASA_RESPONSE_CARD_DATA_FULFILLED = "Sequra.card_data_fulfilled";
export const MUFASA_RESPONSE_INVALID_REQUEST = "Sequra.invalid_request";
export const MUFASA_RESPONSE_PAYMENT_FAILED = "Sequra.payment_failed";
export const MUFASA_RESPONSE_PAYMENT_SUCCESSFUL = "Sequra.payment_successful";
export const MUFASA_RESPONSE_RESIZED = "Sequra.mufasa_resized";
export const MUFASA_RESPONSE_SUBMITTED = "Sequra.mufasa_submitted";
export const MUFASA_RESPONSE_NEW_FORM_FIELDS = "Sequra.new_form_fields";
export const MUFASA_RESPONSE_3DS_AUTHENTICATION_CLOSED =
  "Sequra.3ds_authentication_closed";
export const MUFASA_RESPONSE_START_SYNCHRONIZATION_POLLING =
  "Sequra.start_synchronization_polling";

export const MUFASA_REQUEST_IFRAME_SUBMIT = "Sequra.iframe_submit";
export const MUFASA_REQUEST_SET_PERMISSION_VALUE =
  "Sequra.set_permission_value";
export const MUFASA_REQUEST_NEW_FORM_FIELDS = "Sequra.new_form_fields";
export const MUFASA_REQUEST_3DS_AUTHENTICATION_CLOSED =
  "Sequra.3ds_authentication_closed";
export const MUFASA_REQUEST_START_SYNCHRONIZATION_POLLING =
  "Sequra.start_synchronization_polling";

export const MufasaResponseMessages = Object.freeze({
  authentication_3ds: "3ds_authentication",
  authentication_3ds_loaded: "3ds_authentication_loaded",
  card_data_fulfilled: "card_data_fulfilled",
  invalid_request: "invalid_request",
  mufasa_resized: "mufasa_resized",
  mufasa_submitted: "mufasa_submitted",
  payment_failed: "payment_failed",
  payment_successful: "payment_successful",
  new_form_fields: "new_form_fields",
  authentication_3ds_closed: "3ds_authentication_closed",
  start_synchronization_polling: "start_synchronization_polling"
});

export const MufasaRequestMessages = Object.freeze({
  iframe_submit: MUFASA_REQUEST_IFRAME_SUBMIT,
  new_form_fields: MUFASA_REQUEST_NEW_FORM_FIELDS,
  set_permission_value: MUFASA_REQUEST_SET_PERMISSION_VALUE,
  authentication_3ds_closed: MUFASA_REQUEST_3DS_AUTHENTICATION_CLOSED,
  start_synchronization_polling: MUFASA_REQUEST_START_SYNCHRONIZATION_POLLING
});

export const MufasaTrackEvents = {
  mufasa_3ds_authentication_viewed: "mufasa_3ds_authentication_viewed",
  mufasa_3ds_authentication_closed: "mufasa_3ds_authentication_closed",
  mufasa_payment_successful: "mufasa_payment_successful",
  mufasa_payment_failed: "mufasa_payment_failed",
  mufasa_form_viewed: "mufasa_form_viewed"
};

// Mufasa Iframe Response Objects
export const mufasaResponse3DSAuthentication = src => ({
  mufasaResponseMessage: MufasaResponseMessages.authentication_3ds,
  payload: {
    src
  }
});
export const mufasaResponseCardDataFulfilled = () => ({
  mufasaResponseMessage: MufasaResponseMessages.card_data_fulfilled
});
export const mufasaResponsePaymentFailed = () => ({
  mufasaResponseMessage: MufasaResponseMessages.payment_failed
});
export const mufasaResponsePaymentSuccessful = () => ({
  mufasaResponseMessage: MufasaResponseMessages.payment_successful
});
export const mufasaResponseInvalidRequest = () => ({
  mufasaResponseMessage: MufasaResponseMessages.invalid_request
});
export const mufasaResponseResize = height => ({
  mufasaResponseMessage: MufasaResponseMessages.mufasa_resized,
  payload: {
    height
  }
});
export const mufasaResponseSubmitted = () => ({
  mufasaResponseMessage: MufasaResponseMessages.mufasa_submitted
});
export const mufasaResponseNewFormFields = message => ({
  mufasaResponseMessage: MufasaResponseMessages.new_form_fields,
  payload: {
    action: MufasaRequestMessages.new_form_fields,
    message
  }
});
export const mufasaResponse3DSAuthenticationLoaded = () => ({
  mufasaResponseMessage: MufasaResponseMessages.authentication_3ds_loaded
});

export const mufasaResponse3DSAuthenticationClosed = () => ({
  mufasaResponseMessage: MufasaResponseMessages.authentication_3ds_closed
});

export const mufasaResponseStartSynchronizationPolling = message => ({
  mufasaResponseMessage: MufasaResponseMessages.start_synchronization_polling,
  payload: {
    action: MufasaRequestMessages.start_synchronization_polling,
    message
  }
});

// Mufasa Iframe Request Objects
export const mufasaRequestIframeSubmit = () => ({
  action: MufasaRequestMessages.iframe_submit
});
export const mufasaRequestNewFormFields = message => ({
  action: MufasaRequestMessages.new_form_fields,
  message
});
export const mufasaRequestSetPermissionValue = permissionValue => ({
  action: MufasaRequestMessages.set_permission_value,
  permission_value: permissionValue
});
export const mufasaRequest3DSAuthenticationClosed = () => ({
  action: MufasaRequestMessages.authentication_3ds_closed
});
export const mufasaRequestStartSynchronizationPolling = message => ({
  action: MufasaRequestMessages.start_synchronization_polling,
  message
});

// Popup reducer
export const PopupActions = {
  open: "open",
  close: "close",
  iframeLoaded: "iframeLoaded",
  accept3ds: "accept3ds"
};
