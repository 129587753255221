import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useTheme } from "react-jss";

import { ReferralButton, Typography } from "../../atoms";
import { Cart } from "../../atoms/Icons";

import K from "../../constants";
import locales from "../../locales";

const ReferralProgramHowToUseDiscountAction = ({ process, className }) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <ReferralButton
      process={process}
      className={className}
      dataTestId="how-to-use-voucher-button"
    >
      <Cart width={20} height={20} color={theme.palette.success.default} />
      <Typography variant={K.typographicVariants.body3}>
        {intl.formatMessage(
          locales.referral_program_how_to_use_discount_action
        )}
      </Typography>
    </ReferralButton>
  );
};

ReferralProgramHowToUseDiscountAction.propTypes = {
  className: PropTypes.string,
  process: PropTypes.string
};

ReferralProgramHowToUseDiscountAction.defaultProps = {
  className: "",
  process: ""
};

export default ReferralProgramHowToUseDiscountAction;
