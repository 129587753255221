import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Alert = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 82 73">
    <g fill="none" fillRule="evenodd">
      <path
        d="M79.743 64.026L45.345 3.629a5 5 0 00-8.69 0L2.257 64.026A5 5 0 006.602 71.5h68.796a5 5 0 004.345-7.474z"
        stroke={color}
        strokeWidth={2}
      />
      <text
        fontFamily="OpenSans-Regular, Open Sans"
        fontSize={40}
        fill={color}
        transform="translate(-2 -4)"
      >
        <tspan x={38} y={66}>
          !
        </tspan>
      </text>
    </g>
  </svg>
);

Alert.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Alert.defaultProps = {
  width: 152,
  height: 152,
  color: theme.palette.primary.default
};

export default Alert;
