import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const ArrowUnfold = ({ width, height, color }) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 12 8">
    <path
      fill={color}
      d="M2.12 1.29 6 5.17l3.88-3.88a.996.996 0 1 1 1.41 1.41L6.7 7.29a.996.996 0 0 1-1.41 0L.7 2.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0Z"
    />
  </svg>
);

ArrowUnfold.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

ArrowUnfold.defaultProps = {
  width: 12,
  height: 8,
  color: theme.palette.primary.default
};

export default ArrowUnfold;
