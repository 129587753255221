import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { ConversionModalDescription } from "../../components";
import SelectCardModal from "../SelectCardModal";
import { useOrder } from "../../../../hooks";

const ConversionSelectCardModal = () => {
  const order = useOrder();
  const location = useLocation();
  const query = queryString.parse(location.search, { parseNumbers: true });
  const conversionPlan = order.conversionPlans.find(
    plan => plan.instalmentCount === query.instalment_count
  );

  if (!conversionPlan) return null;

  return (
    <SelectCardModal
      open
      withDescription
      contentDescription={
        <ConversionModalDescription conversionPlan={conversionPlan} />
      }
      instalmentCount={query.instalment_count}
      paymentAmountCents={conversionPlan.downPaymentTotal}
    />
  );
};

export default ConversionSelectCardModal;
