import React from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useOrder, useShopperCardsDataQuery } from "../../hooks";
import {
  ConversionSelectPlanModal,
  ConversionSelectCardModal,
  ConversionMufasaModal
} from "./modals";
import useTrackingContext from "../../contexts/Tracking";
import K from "../../constants";
import { filterReusableCardReferences } from "../../utils";

const ConversionFlow = () => {
  const order = useOrder();
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  React.useEffect(() => {
    /*
    This avoid to send to mixpanel data when a user uses
    the conversion shortened link to access to the app
    and redirect to the location.pathname
    */
    if (order.isConvertible) {
      track(
        eventNames.conversion.start,
        buildOrderTrackingPayload(order, true)
      );
    } else {
      history.push(location.pathname);
    }
  }, []);

  const cardReferencesResponse = useShopperCardsDataQuery({
    fetchPolicy: K.fetchPolicy.cacheAndNetwork
  });

  if (!order.isConvertible) {
    return null;
  }

  switch (query.step) {
    case K.steps.selectPlan:
      return <ConversionSelectPlanModal />;
    case K.steps.selectCard: {
      const emptyReusableCardReferences =
        filterReusableCardReferences(
          cardReferencesResponse.data?.shopper?.cardReferences
        ).length === 0;

      if (emptyReusableCardReferences) {
        return <ConversionMufasaModal />;
      }
      return <ConversionSelectCardModal />;
    }
    case K.steps.addCard:
      return <ConversionMufasaModal />;
    default:
      return null;
  }
};

export default ConversionFlow;
