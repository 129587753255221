import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";

import { Typography, Button } from "../../../../../atoms";
import locales from "../../../../../locales";
import K from "../../../../../constants";

const useStyles = createUseStyles({
  dashboard__shops__header: {
    marginBottom: "16px",
    "& > button": {
      marginTop: "8px",
      marginRight: "15px",
      paddingLeft: "0",
      width: "auto",
      height: "auto"
    },
    "& > button span": {
      fontSize: "12px"
    },
    "@media (min-width: 768px)": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      "& > button": {
        marginTop: "0"
      }
    }
  },
  dashboard__shops__title: {
    marginTop: 32
  }
});

const PromotedMerchantsSection = ({
  promotionComponentToRender,
  onClickDeniedButton,
  shouldRenderDeniedPromotionsAction
}) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div>
      <>
        <div className={classes.dashboard__shops__header}>
          <Typography
            variant={K.typographicVariants.heading2}
            className={classes.dashboard__shops__title}
          >
            {intl.formatMessage(locales.dashboard_shops_news_title)}
          </Typography>
          {shouldRenderDeniedPromotionsAction && (
            <Button variant="text" onClick={onClickDeniedButton}>
              {intl.formatMessage(
                locales.dashboard_promote_merchants_denied_action_link
              )}
            </Button>
          )}
        </div>
      </>
      {promotionComponentToRender}
    </div>
  );
};

PromotedMerchantsSection.propTypes = {
  promotionComponentToRender: PropTypes.node.isRequired,
  onClickDeniedButton: PropTypes.func.isRequired,
  shouldRenderDeniedPromotionsAction: PropTypes.bool.isRequired
};

export default PromotedMerchantsSection;
