const products = {
  i1: "i1",
  fp1: "fp1",
  sp1: "sp1",
  pp1: "pp1",
  pp2: "pp2",
  pp3: "pp3",
  pp4: "pp4",
  pp5: "pp5",
  pp6: "pp6",
  pp7: "pp7",
  pp9: "pp9"
};

const selectors = {
  phone: "phone",
  email: "email"
};

const process = {
  payment: "payment",
  conversion: "conversion",
  returnRequest: "return-request",
  amortization: "amortization",
  changePaymentCard: "change-payment-card",
  addPaymentCard: "add-payment-card",
  updateCards: "update-cards",
  addCardToYourAccount: "add-card-to-your-account",
  verifyCard: "verify-card",
  referral: "referral",
  onBoarding: "on-boarding",
  whereToUseTheVoucher: "using-the-voucher",
  referralTermsAndConditions: "referral-terms-and-conditions",
  merchants: "merchants",
  flexiConversion: "flexi-conversion",
  howToUseDiscounts: "how-to-use-discounts"
};

const mufasaGateways = {
  payment: "payment",
  conversion: "conversion",
  tokenization: "tokenization",
  assignCard: "assign-card",
  amortization: "amortization"
};

const steps = {
  selectCard: "select-card",
  addCard: "add-card",
  selectPlan: "select-plan",
  selectBankAccount: "select-bank-account",
  summary: "summary",
  confirm: "confirm",
  success: "success",
  flexiSelectPlan: "flexi-select-plan",
  flexiConfirmationPlan: "flexi-confirmation-plan",
  flexiSuccess: "success",
  flexiError: "error"
};

const fetchPolicy = {
  cacheFirst: "cache-first",
  networkOnly: "network-only",
  cacheOnly: "cache-only",
  noCache: "no-cache",
  standBy: "standby",
  cacheAndNetwork: "cache-and-network"
};

const colors = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  shade: "shade",
  background: "background",
  success: "success",
  info: "info",
  warning: "warning",
  error: "error",
  white: "white",
  black: "black"
};

const typographicElements = ["span", "p", "h1", "h2", "h3", "h4", "h5", "h6"];

const typographicVariants = {
  heading1: "heading1",
  heading2: "heading2",
  heading3: "heading3",
  heading4: "heading4",
  heading5: "heading5",
  body1: "body1",
  body2: "body2",
  body3: "body3",
  caption1: "caption1",
  caption2: "caption2",
  buttonL: "buttonL",
  buttonS: "buttonS",
  linkXL: "linkXL",
  linkL: "linkL",
  linkM: "linkM",
  linkS: "linkS",
  visual1: "visual1",
  visual2: "visual2",
  visual3: "visual3",
  visual4: "visual4"
};

const supportedLocales = {
  es: "es-ES",
  "es-ES": "es-ES",
  "es-CO": "es-ES",
  "es-PE": "es-ES",
  fr: "fr-FR",
  "fr-FR": "fr-FR",
  it: "it-IT",
  "it-IT": "it-IT",
  pt: "pt-PT",
  "pt-PT": "pt-PT"
};

const supportedLanguagesFromLocale = {
  "es-ES": "es",
  "fr-FR": "fr",
  "it-IT": "it",
  "pt-PT": "pt"
};

const shortenedLinkActions = {
  createSession: "CreateSession",
  showSelector: "ShowSelector",
  showOrder: "ShowOrder",
  showOrderCards: "ShowOrderCards",
  showInvoicesPaymentProcess: "ShowInvoicesPaymentProcess",
  showPaymentPlanPaymentProcess: "ShowPaymentPlanPaymentProcess",
  showConversionProcess: "ShowConversionProcess",
  renderService: "RenderService",
  addBankAccountToRefundProcess: "AddBankAccountToRefundProcess",
  verifyCard: "VerifyCard",
  showReferralSignUp: "ShowReferralSignUp",
  flexiConversionProcess: "FlexiConversionProcess"
};

const referralVoucherAmounts = {
  host: 10.0,
  guest: 10.0
};

const realm = {
  sequra: "sequra",
  svea: "svea"
};

const cookies = {
  locale: "locale"
};

const links = {
  cookies_policy: "https://legal.sequra.es/politica-cookies"
};

const externalUrls = {
  sequraWeb: {
    merchantsList: "https://www.sequra.es/tiendas-que-usan-sequra",
    faq: "https://www.sequra.es/preguntas-frecuentes",
    referralTermsAndConditions:
      "https://legal.sequra.com/condiciones-programa-de-recomendaciones",
    privacyPolicy: "https://legal.sequra.com/politica-privacidad",
    legalTerms: "https://www.sequra.com/es/terminos-legales"
  }
};

const merchantsSector = [
  "fashion",
  "sports",
  "home",
  "baby&kids",
  "vehicles",
  "music&sound",
  "electronics",
  "hobby",
  "health&beauty",
  "pets",
  "optics",
  "handiwork",
  "bazaar",
  "food&drinks",
  "jewelry"
];

export default {
  products,
  process,
  steps,
  fetchPolicy,
  mufasaGateways,
  colors,
  typographicElements,
  typographicVariants,
  supportedLocales,
  shortenedLinkActions,
  selectors,
  referralVoucherAmounts,
  realm,
  cookies,
  links,
  supportedLanguagesFromLocale,
  externalUrls,
  merchantsSector
};
