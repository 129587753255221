import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Pen = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path
      d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
      fill={color}
    />
  </svg>
);

Pen.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Pen.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Pen;
