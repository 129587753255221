import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Unicorn } from "../../../../../atoms/Illustrations";
import locales from "../../../../../locales";

const useStyles = createUseStyles(theme => ({
  promote_merchants_empty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "60px",
    fontFamily: theme.fontFamily
  },
  promote_merchants_empty__info: {
    margin: "10px 0",
    color: theme.palette.secondary.default,
    fontSize: "12px"
  }
}));

const PromotedMerchantsEmpty = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <div className={classes.promote_merchants_empty}>
      <Unicorn width={80} height={105} color={theme.palette.shade.default} />
      <p className={classes.promote_merchants_empty__info}>
        {intl.formatMessage(locales.dashboard_promote_merchants_empty)}
      </p>
    </div>
  );
};

export default PromotedMerchantsEmpty;
