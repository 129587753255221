import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { animated, useSpring } from "react-spring";
import { useMedia, useWindowSize } from "react-use";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import classnames from "classnames";
import Button from "../Button/Button";
import { Close } from "../Icons";
import locales from "../../locales";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  modal_container: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 100001,
    display: "flex",
    justifyContent: "center",
    "@media(min-width: 768px)": {
      maxWidth: "100%!important",
      alignItems: "center"
    }
  },
  backdrop: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: theme.palette.black.default,
    zIndex: 100001
  },
  modal_wrapper: {
    zIndex: 100002,
    display: "flex",
    justifyContent: "center",
    paddingTop: "40px",
    "@media(min-width: 768px)": {
      height: "auto",
      maxHeight: "90vh",
      inset: "auto",
      paddingTop: "0",
      width: ({ withDescription, withSpecificWidth }) =>
        withDescription ? "calc(470px * 2)" : withSpecificWidth || "32rem"
    }
  },
  modal: {
    width: "100vw",
    height: "calc(100vh - 40px)",
    backgroundColor: theme.palette.white.default,
    borderRadius: "8px 8px 0px 0px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    "@media(min-width: 768px)": {
      maxHeight: "100%",
      boxShadow: ({ withDescription }) =>
        withDescription
          ? "-1px 0px 46px 0px rgba(0,0,0,0.15)"
          : "-1px 0 37px -12px rgba(0,0,0,0.15)",
      backgroundColor: theme.palette.white.default,
      borderRadius: ({ withDescription }) =>
        withDescription ? "0 8px 8px 0" : "8px",
      zIndex: ({ withDescription }) => (withDescription ? "1" : "inherit"),
      paddingBottom: "0",
      height: "686px",
      width: "470px"
    }
  },
  modal__navigation: {
    height: "56px",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    "& > :first-child": {
      justifyContent: "flex-start"
    },
    "& > :nth-child(2)": {
      textAlign: "center"
    },
    "& > :last-child": {
      justifyContent: "flex-end"
    },
    "& > button": {
      width: "auto",
      color: theme.palette.secondary.default,
      padding: "16px 16px 0",
      margin: "0 2px"
    },
    "@media(min-width: 768px)": {
      height: "auto",
      padding: "16px 16px 0",
      "& > button": {
        padding: "0px",
        "& > span": {
          display: "flex",
          alignItems: "center"
        }
      }
    }
  },
  modal__content: {
    padding: ({ withFullWidth }) => (withFullWidth ? "0" : "24px 16px 0"),
    marginBottom: "16px",
    position: "relative",
    "@media(min-width: 768px)": {
      padding: ({ withFullWidth }) => (withFullWidth ? "0" : "48px 48px 0"),
      marginBottom: "40px"
    },
    overflow: "auto"
  },
  modal__content__title: {},
  modal_description: {
    display: "flex",
    height: "686px",
    width: "470px",
    backgroundColor: theme.palette.white.default,
    flexDirection: "column",
    boxSizing: "border-box",
    borderRadius: "8px 0 0 8px",
    padding: "32px 0 48px",
    position: "relative"
  }
}));

const Modal = ({
  children,
  open,
  hideBack,
  hideNavigation,
  hideClose,
  wrapperClassname,
  modalClassname,
  contentClassname,
  backdropClassname,
  onClose,
  onBack,
  title,
  withDescription,
  contentDescription,
  withIconClose,
  withFullWidth,
  withBackdropClose,
  withSpecificWidth
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMedia("(max-width: 768px)");
  const classes = useStyles({
    withDescription,
    withFullWidth,
    withSpecificWidth
  });
  const { height } = useWindowSize();

  const containerStyle = useSpring({
    opacity: open ? 1 : 0,
    display: open ? "flex" : "none"
  });

  const backdropStyle = useSpring({
    opacity: open ? 0.75 : 0
  });

  const mobileStyle = useSpring({
    bottom: open ? "auto" : height
  });

  const desktopStyle = useSpring({
    opacity: open ? 1 : 0
  });

  const close = () => {
    if (onClose) onClose();
    else history.push(location.pathname);
  };

  const back = () => {
    if (onBack) onBack();
    else history.go(-1);
  };

  React.useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <animated.div className={classes.modal_container} style={containerStyle}>
      <animated.div
        className={classnames(classes.backdrop, backdropClassname)}
        onClick={() => withBackdropClose && close()}
        onKeyPress={() => withBackdropClose && close()}
        tabIndex="0"
        role="button"
        label="backdrop"
        style={backdropStyle}
      />
      <animated.div
        style={isMobile ? mobileStyle : desktopStyle}
        className={classnames(classes.modal_wrapper, wrapperClassname)}
      >
        {withDescription && !isMobile ? (
          <div className={classes.modal_description}>{contentDescription}</div>
        ) : null}
        <div className={classnames(classes.modal, modalClassname)}>
          {!hideNavigation && (
            <div className={classes.modal__navigation}>
              {hideBack ? (
                <div />
              ) : (
                <Button variant="text" size="small" onClick={back}>
                  {intl.formatMessage(locales.back)}
                </Button>
              )}
              {title && isMobile ? (
                <Typography color={theme.palette.primary.default}>
                  {title}
                </Typography>
              ) : (
                <div />
              )}
              {hideClose ? null : (
                <Button variant="text" size="small" onClick={close}>
                  {withIconClose ? (
                    <Close color={theme.palette.primary.default} />
                  ) : (
                    intl.formatMessage(locales.cancel)
                  )}
                </Button>
              )}
            </div>
          )}
          <div className={classnames(classes.modal__content, contentClassname)}>
            {title && !isMobile ? (
              <Typography
                className={classes.modal__content__title}
                variant={K.typographicVariants.heading1}
                color={theme.palette.primary.default}
              >
                {title}
              </Typography>
            ) : null}
            {children}
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  hideBack: PropTypes.bool,
  hideNavigation: PropTypes.bool,
  hideClose: PropTypes.bool,
  wrapperClassname: PropTypes.string,
  modalClassname: PropTypes.string,
  contentClassname: PropTypes.string,
  backdropClassname: PropTypes.string,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  title: PropTypes.string,
  withDescription: PropTypes.bool,
  contentDescription: PropTypes.node,
  withIconClose: PropTypes.bool,
  withFullWidth: PropTypes.bool,
  withBackdropClose: PropTypes.bool,
  withSpecificWidth: PropTypes.string
};

Modal.defaultProps = {
  children: null,
  open: false,
  hideBack: false,
  hideNavigation: false,
  hideClose: false,
  wrapperClassname: "",
  modalClassname: "",
  contentClassname: "",
  backdropClassname: "",
  onClose: null,
  onBack: null,
  title: "",
  withDescription: false,
  contentDescription: null,
  withIconClose: false,
  withFullWidth: false,
  withBackdropClose: true,
  withSpecificWidth: ""
};

export default Modal;
