import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import classnames from "classnames";
import { Button } from "@sequra/telar";
import Typography from "../Typography";
import K from "../../constants";
import useTrackingContext from "../../contexts/Tracking";

const useStyles = createUseStyles(theme => ({
  banner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "24px 24px 16px 24px",
    marginTop: "15px",
    width: "100%",
    boxSizing: "border-box",
    borderRadius: "8px",
    backgroundImage: `linear-gradient(98deg, #99EBDE 0%, #80DEF4  54.1%, #DBAFF3 99.88%)`,
    textDecoration: "none",
    textAlign: "center",
    "& > *": {
      color: theme.palette.primary.default
    },
    "@media(min-width: 768px)": {
      width: "240px",
      marginTop: 0
    }
  },
  banner__balance: {
    margin: "12px 0",
    padding: "20px 12px",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "700",
    fontFamily: theme.fontFamily,
    borderRadius: "8px",
    backgroundColor: "rgba(255, 255, 255, 0.90)"
  },
  banner__balance__amount: {
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "36px"
  },
  banner__link: {
    display: "block",
    marginTop: "8px",
    color: theme.palette.primary.default
  }
}));

const BannerDiscount = ({ balance, onClickButton, className }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { track, eventNames } = useTrackingContext();

  useEffect(() => {
    track(eventNames.generalVouchers.bannerDiscountViewed);
  }, []);
  return (
    <>
      <div className={classnames(classes.banner, className)}>
        <Typography variant={K.typographicVariants.heading3}>
          <span data-testid="title">🎁 Descuentos</span>
        </Typography>
        <div className={classes.banner__balance} data-testid="balance">
          <Typography
            variant={K.typographicVariants.heading2}
            className={classes.banner__balance__amount}
          >
            {balance}
          </Typography>
        </div>
        <Button size="small" onClick={onClickButton}>
          <Typography
            variant={K.typographicVariants.buttonS}
            color={theme.palette.white.default}
          >
            Usar descuento
          </Typography>
        </Button>
      </div>
    </>
  );
};

BannerDiscount.propTypes = {
  className: PropTypes.string,
  balance: PropTypes.string,
  onClickButton: PropTypes.func
};

BannerDiscount.defaultProps = {
  className: "",
  balance: "",
  onClickButton: () => {}
};

export default BannerDiscount;
