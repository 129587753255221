import React from "react";
import PropTypes from "prop-types";

const AmericanExpress = ({ width, height }) => (
  <svg width={width} height={height} viewBox="0 0 32 21">
    <path
      d="M2.13 0h25.376c1 0 1.812.818 1.812 1.827v17.347A1.82 1.82 0 0127.506 21H2.13a1.82 1.82 0 01-1.813-1.826V1.827A1.82 1.82 0 012.131 0z"
      fill="#26A6D1"
    />
    <path
      d="M5.14 7l-2.822 6.991h3.378l.419-1.115h.957l.418 1.115h3.718v-.85l.332.85h1.923l.331-.869v.869h7.732l.94-1.086.88 1.086 3.972.009-2.83-3.485L27.318 7h-3.91l-.915 1.066L21.641 7h-8.412l-.722 1.804L11.767 7h-3.37v.822L8.022 7H5.14zm.653.993H7.44l1.871 4.74v-4.74h1.804l1.445 3.399 1.332-3.4h1.795v5.017h-1.092l-.009-3.93-1.592 3.93h-.977l-1.6-3.93v3.93H8.17l-.426-1.124h-2.3l-.426 1.123H3.815l1.978-5.015zm10.893 0h4.44l1.358 1.642 1.402-1.642h1.358l-2.063 2.521 2.063 2.492h-1.42l-1.358-1.661-1.409 1.661h-4.371V7.993zM6.594 8.84l-.758 2.004h1.515L6.594 8.84zm11.189.19v.916h2.422v1.02h-2.422v1h2.716l1.263-1.472-1.209-1.464h-2.77z"
      fill="#FFF"
    />
  </svg>
);

AmericanExpress.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

AmericanExpress.defaultProps = {
  width: 29,
  height: 21
};

export default AmericanExpress;
