import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";
import { useSpring, animated } from "react-spring";
import { ScaleLoader } from "../Loaders";
import Backdrop from "../Backdrop";

const useStyles = createUseStyles(theme => ({
  modal: {
    display: "flex",
    height: ({ group }) => (group ? "593px" : "640px"),
    width: ({ group }) => (group ? "802px" : "472px"),
    borderRadius: "8px",
    boxSizing: "border-box",
    backgroundColor: ({ primaryBackground }) =>
      primaryBackground
        ? theme.palette.background.default
        : theme.palette.white.default,
    position: "fixed",
    zIndex: 100001,
    left: "50%",
    top: "50%",
    marginTop: "calc(640px / -2)",
    overflow: "hidden"
  },
  modal__content: {
    position: "relative",
    flex: 1,
    display: "flex"
  },
  modal__content__children: {
    flex: 1,
    display: "flex",
    flexDirection: ({ group }) => (group ? "row" : "column")
  }
}));

const Modal = React.forwardRef((props, ref) => {
  const {
    children,
    open,
    loading,
    className,
    primaryBackground,
    hasFooter,
    group
  } = props;
  const classes = useStyles({ primaryBackground, hasFooter, group });
  const componentRef = ref || React.useRef(null);
  const classNames = classnames(classes.modal, className);

  React.useEffect(() => {
    componentRef.current.style.marginLeft = `${componentRef.current
      .offsetWidth / -2}px`;
  }, []);

  const spring = useSpring({
    to: {
      transform: open ? "scale(1) translate(0, 0)" : "scale(0) translate(0, 0)"
    }
  });

  const springChildren = useSpring({
    to: { opacity: loading && open ? 0 : 1 }
  });

  return (
    <>
      <Backdrop open={open} />
      <animated.div ref={componentRef} className={classNames} style={spring}>
        <div className={classes.modal__content}>
          {loading ? (
            <ScaleLoader />
          ) : (
            <animated.div
              className={classes.modal__content__children}
              style={springChildren}
            >
              {children}
            </animated.div>
          )}
        </div>
      </animated.div>
    </>
  );
});

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  primaryBackground: PropTypes.bool,
  hasFooter: PropTypes.bool,
  group: PropTypes.bool
};

Modal.defaultProps = {
  children: null,
  open: false,
  loading: false,
  className: "",
  primaryBackground: false,
  hasFooter: false,
  group: false
};

export default Modal;
