import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Button, NextModal, Typography } from "../../atoms";
import { Cookie } from "../../atoms/Icons";
import hexToRGBA from "../../theme/hexToRGBA";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  wrapper: {
    width: "100%",
    "@media(max-width: 320px)": {
      top: "0 !important"
    }
  },
  modal: {
    width: "343px",
    height: "606px",
    padding: "29px 16px 48px",
    borderRadius: "8px",
    boxShadow: `-1px 0 37px -12px ${hexToRGBA(
      theme.palette.black.default,
      16
    )}`,
    boxSizing: "border-box",
    "@media(min-width: 768px)": {
      padding: "47px 42px 66px 48px",
      width: "514px",
      height: "618px"
    },
    "@media(max-width: 320px)": {
      borderRadius: 0,
      height: "auto",
      "padding-top": "16px",
      "padding-bottom": "16px"
    }
  },
  modal__content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginBottom: 0
  },
  content: {
    flex: 1
  },
  content__megaphone: {
    "@media(max-width: 768px)": {
      width: "85px",
      height: "85px",
      padding: "3px 5px 2px 4px",
      boxSizing: "border-box"
    }
  },
  content__buttons: {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",
    "& > :first-child": {
      color: theme.palette.primary.default,
      borderColor: theme.palette.primary.default
    },
    "@media(min-width: 768px)": {
      justifyContent: "flex-end",
      "& > :first-child": {
        marginRight: "16px"
      }
    }
  },
  content__title: {
    margin: "16px 0 24px 0",
    "& > h1": {
      lineHeight: 1.33
    },
    "@media(max-width: 320px)": {
      margin: "8px 0 24px 0"
    }
  },
  content_check: {
    display: "flex",
    justifyContent: "space-between",
    "& > p": {
      lineHeight: 1.25,
      "@media(max-width: 320px)": {
        lineHeight: "normal",
        marginRight: "6px"
      },
      "& > :nth-child(2)": {
        display: "block",
        "@media(max-width: 320px)": {
          lineHeight: "normal"
        }
      },
      "& > span > strong": {
        fontWeight: "bold"
      }
    },
    "&:last-child": {
      marginTop: "16px"
    }
  },
  footer__message: {
    "& > p > span > a": {
      color: theme.palette.primary.default,
      textDecoration: "underline"
    }
  },
  footer__button: {
    marginTop: "16px",
    "&:last-child": {
      color: theme.palette.primary.default,
      borderColor: theme.palette.primary.default
    }
  }
}));

const CookiesConfigurationModal = ({
  cookiesPolicyLink,
  onConfirm,
  onAccept
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  const [checked, onChange] = React.useState(false);

  return (
    <NextModal
      open
      hideBack
      hideClose
      withFullWidth
      hideNavigation
      wrapperClassname={classes.wrapper}
      modalClassname={classes.modal}
      contentClassname={classes.modal__content}
    >
      <div className={classes.content}>
        <div className={classes.content__megaphone}>
          <Cookie width={72} height={75} />
        </div>

        <div data-testid="content_title" className={classes.content__title}>
          <Typography variant="heading1" color={theme.palette.primary.default}>
            {intl.formatMessage(locales.cookies_configuration_modal_title)}
          </Typography>
        </div>

        <div className={classes.content_check}>
          <p data-testid="content_first_check">
            <Typography variant="body1" color={theme.palette.primary.default}>
              {intl.formatMessage(
                locales.cookies_configuration_modal_strict_cookies_title
              )}
            </Typography>
            <span data-testid="content_first_check_info">
              <Typography variant="body3" color={theme.palette.primary.default}>
                {intl.formatMessage(
                  locales.cookies_configuration_modal_strict_cookies_info
                )}
              </Typography>
            </span>
          </p>
          <Toggle defaultChecked disabled icons={false} />
        </div>

        <div className={classes.content_check}>
          <p data-testid="content_second_check">
            <Typography variant="body1" color={theme.palette.primary.default}>
              {intl.formatMessage(
                locales.cookies_configuration_modal_analysis_cookies_title
              )}
            </Typography>
            <span data-testid="content_second_check_info">
              <Typography variant="body3" color={theme.palette.primary.default}>
                {intl.formatMessage(
                  locales.cookies_configuration_modal_analysis_cookies_info
                )}
              </Typography>
            </span>
          </p>
          <Toggle
            onChange={e => {
              onChange(e.target.checked);
            }}
            icons={false}
            checked={checked}
          />
        </div>
      </div>

      <div className={classes.footer__message}>
        <p data-testid="footer__message">
          <Typography variant="caption1" color={theme.palette.primary.default}>
            <a href={cookiesPolicyLink} target="_blank" rel="noreferrer">
              {intl.formatMessage(
                locales.cookies_configuration_modal_cookies_link
              )}
            </a>
          </Typography>
        </p>
      </div>

      <Button
        onClick={() => {
          onConfirm(checked);
        }}
        className={classes.footer__button}
        fullWidth
      >
        {intl.formatMessage(locales.confirm_selection)}
      </Button>

      <Button
        onClick={onAccept}
        className={classes.footer__button}
        fullWidth
        variant="outlined"
      >
        {intl.formatMessage(
          locales.cookies_configuration_modal_cookies_accept_button
        )}
      </Button>
    </NextModal>
  );
};

CookiesConfigurationModal.propTypes = {
  cookiesPolicyLink: PropTypes.string,
  onConfirm: PropTypes.func,
  onAccept: PropTypes.func
};

CookiesConfigurationModal.defaultProps = {
  cookiesPolicyLink: K.links.cookies_policy,
  onConfirm: () => {},
  onAccept: () => {}
};

export default CookiesConfigurationModal;
