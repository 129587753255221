import { useEffect, useMemo } from "react";
import { useSetState } from "react-use";
import useOrdersDataQuery from "./queries/useOrdersData";

const ORDER_BATCH_SIZE = 6;

export const initialState = {
  fetching: true,
  error: undefined,
  offset: undefined,
  nextOffset: undefined,
  orders: [],
  previousOrders: []
};

export default ({ activeOrders }) => {
  const [state, setState] = useSetState(initialState);

  const [fetchOrdersData, { data, fetchMore }] = useOrdersDataQuery({
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    },
    onCompleted: () => {
      setState({
        error: undefined,
        fetching: false
      });
    },
    limit: ORDER_BATCH_SIZE,
    offset: 0,
    state: activeOrders ? "ACTIVE" : "FINISHED"
  });

  useEffect(() => {
    fetchOrdersData();
  }, []);

  useEffect(() => {
    setState({
      orders: data?.orders?.nodes || [],
      hasMore: data?.orders?.pageInfo.hasNextPage
    });
  }, [data?.orders.nodes]);

  return useMemo(
    () => ({
      ...state,
      hasMore: state.hasMore,
      fetchMoreOrders: () => {
        return fetchMore({
          variables: {
            offset: state.orders.length
          }
        });
      }
    }),
    [state]
  );
};
