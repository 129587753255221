import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import useTrackingContext from "../../../../contexts/Tracking";
import { Paper, Button, Typography } from "../../../../atoms";
import { Sequra } from "../../../../atoms/Icons";
import K from "../../../../constants";
import locales from "../../../../locales";
import section1 from "../../../../../public/images/signup-landing/section1.png";
import section2 from "../../../../../public/images/signup-landing/section2.png";
import section3 from "../../../../../public/images/signup-landing/section3.png";
import discountShopper from "../../../../../public/images/signup-landing/discountShopper.png";
import trustpilot from "../../../../../public/images/signup-landing/trustpilot.png";
import trustpilotReviews from "../../../../../public/images/signup-landing/trustpilot-reviews.png";

const useStyles = createUseStyles(theme => ({
  landing: {
    width: "432px",
    padding: "0",
    position: "relative",
    zIndex: "0",
    "@media(min-width: 528px)": {
      width: "528px",
      padding: "32px"
    },
    "&::before, &:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      borderRadius: "7px 7px 0 0"
    },
    "&::before": {
      background: `linear-gradient(269.73deg, ${theme.palette.success.medium} 11.43%, ${theme.palette.info.medium} 93.57%)`,
      height: "600px",
      zIndex: "-1"
    },
    "&::after": {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.39) 6.27%, rgba(255, 255, 255, 0) 45.3%, #FFFFFF 71.47%)",
      height: "700px",
      zIndex: "-1"
    }
  },
  landing__content__wrapper: {
    width: "100%",
    margin: "auto",
    "@media(min-width: 528px)": {
      width: "358px"
    }
  },
  landing__top: {
    padding: "16px",
    "@media(min-width: 528px)": {
      padding: "0"
    }
  },
  landing__logo__wrapper: {
    textAlign: "center"
  },
  landing__illustration: {
    display: "flex",
    margin: "35px auto 18px",
    justifyContent: "center"
  },
  landing__title: {
    "& h1": {
      lineHeight: "42px"
    },
    "& h1 strong": {
      color: theme.palette.success.default,
      whiteSpace: "nowrap"
    }
  },
  landing__claim: {
    margin: "16px 0 40px 0"
  },
  landing__reviews: {
    textAlign: "center",
    marginBottom: "50px",
    "& img": {
      marginRight: "6px",
      marginBottom: "12px"
    }
  },
  landing__terms__link: {
    display: "block",
    textAlign: "center",
    color: theme.palette.primary.default,
    fontWeight: "700",
    marginTop: "24px"
  },
  landing__section__imgs__temp__design: {
    width: "100%",
    textAlign: "center"
  },
  landing__button: {
    border: "none",
    background: "none",
    boxShadow: "none !important",
    padding: "0",
    "& img": {
      marginTop: "40px",
      width: "100%",
      boxSizing: "border-box"
    },
    "&:first-child": {
      padding: "16px",
      "@media(min-width: 528px)": {
        padding: "0"
      }
    }
  },
  landing__button__description: {
    padding: "16px"
  },
  landing__footer: {
    backgroundColor: theme.palette.secondary.default,
    padding: "34px 16px",
    marginTop: "36px"
  },
  landing__footer__logo: {
    marginBottom: "18px"
  },
  landing__footer__link: {
    marginTop: "16px",
    display: "block",
    color: theme.palette.white.default,
    textAlign: "left"
  },
  landing__footer__copyright: {
    marginTop: "62px",
    display: "block",
    textAlign: "right"
  }
}));

const Landing = ({ onClickActionLanding, invitedBy }) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const { track, eventNames } = useTrackingContext();

  useEffect(() => {
    track(eventNames.signUp.landing.start);
  }, []);

  return (
    <>
      <Paper className={classes.landing}>
        <div className={classes.landing__gradient__top} />
        <div className={classes.landing__gradient__bottom} />

        <div className={classes.landing__content__wrapper}>
          <div className={classes.landing__top}>
            <div className={classes.landing__logo__wrapper}>
              <Sequra width={145} height={30} />
            </div>

            <div className={classes.landing__illustration}>
              <img
                src={discountShopper}
                alt="Referral landing page illustration"
              />
            </div>

            <div className={classes.landing__title}>
              <Typography
                variant={K.typographicVariants.heading1}
                color={theme.palette.primary.default}
              >
                {intl.formatMessage(locales.sign_up_landing_page_title, {
                  amount: "10 €",
                  invitedBy,
                  strong: msg => <strong>{msg}</strong>
                })}
              </Typography>
            </div>

            <p className={classes.landing__claim}>
              <Typography
                variant={K.typographicVariants.body1}
                color={theme.palette.primary.default}
              >
                {intl.formatMessage(locales.sign_up_landing_paragraph)}
              </Typography>
            </p>

            <div className={classes.landing__reviews}>
              <Typography
                variant={K.typographicVariants.body2}
                color={theme.palette.primary.default}
              >
                <img src={trustpilot} alt="Trustpilot seQura" />
                <img src={trustpilotReviews} alt="Trustpilot shopper reviews" />
              </Typography>

              <Typography
                variant={K.typographicVariants.heading5}
                color={theme.palette.primary.default}
              >
                {intl.formatMessage(locales.sign_up_landing_trustpilot_subline)}
              </Typography>
            </div>

            <Button
              fullWidth
              onClick={() => {
                onClickActionLanding(
                  eventNames.signUp.landing.clickedSection1Intro
                );
              }}
              size="large"
            >
              {intl.formatMessage(locales.sign_up_landing_button_action)}
            </Button>

            <Typography
              variant={K.typographicVariants.body2}
              color={theme.palette.primary.default}
            >
              <a
                target="_blank"
                className={classes.landing__terms__link}
                href={K.externalUrls.sequraWeb.referralTermsAndConditions}
                rel="noreferrer"
                onClick={() =>
                  track(
                    eventNames.referralProgram
                      .openTermsAndConditionsExternalLinkFromLandingPage
                  )
                }
              >
                {intl.formatMessage(locales.sign_up_landing_terms_terms)}
              </a>
            </Typography>
          </div>

          <div className={classes.landing__section__imgs__temp__design}>
            <button
              type="button"
              className={classes.landing__button}
              data-testid="landing-button-brands"
              onClick={() =>
                track(eventNames.signUp.landing.clickedSection2Brands)
              }
            >
              <img src={section1} alt="Sign up landing page section shops" />
            </button>
            <button
              type="button"
              className={classes.landing__button}
              data-testid="landing-button-instructions"
              onClick={() => {
                onClickActionLanding(
                  eventNames.signUp.landing.clickedSection3Instructions
                );
              }}
            >
              <img src={section2} alt="Sign up landing page section 2" />
            </button>
            <button
              type="button"
              className={`${classes.landing__button} ${classes.landing__button__description}`}
              data-testid="landing-button-description"
              onClick={() => {
                onClickActionLanding(
                  eventNames.signUp.landing.clickedSection4Description
                );
              }}
            >
              <img src={section3} alt="Sign up landing page section 3" />
            </button>
          </div>
          <footer className={classes.landing__footer}>
            <div className={classes.landing__footer__logo}>
              <Sequra
                width={92}
                height={28}
                color={theme.palette.white.default}
              />
            </div>

            <Typography
              variant={K.typographicVariants.body3}
              color={theme.palette.white.default}
            >
              {intl.formatMessage(locales.sign_up_landing_footer_text)}
            </Typography>

            <Typography variant={K.typographicVariants.linkS}>
              <a
                target="_blank"
                className={classes.landing__footer__link}
                href={K.externalUrls.sequraWeb.faq}
                rel="noreferrer"
                onClick={() =>
                  track(
                    eventNames.referralProgram.referralLandingPageClickFAQLink
                  )
                }
              >
                {intl.formatMessage(locales.sign_up_landing_footer_link_faq)}
              </a>
            </Typography>

            <Typography variant={K.typographicVariants.linkS}>
              <a
                target="_blank"
                className={classes.landing__footer__link}
                href={K.externalUrls.sequraWeb.legalTerms}
                rel="noreferrer"
                onClick={() =>
                  track(
                    eventNames.referralProgram
                      .referralLandingPageClickLegalTerms
                  )
                }
              >
                {intl.formatMessage(locales.sign_up_landing_footer_link_legal)}
              </a>
            </Typography>

            <Typography
              variant={K.typographicVariants.body3}
              color={theme.palette.shade.default}
              className={classes.landing__footer__copyright}
            >
              {intl.formatMessage(locales.sign_up_landing_footer_copyright)}
            </Typography>
          </footer>
        </div>
      </Paper>
    </>
  );
};

Landing.propTypes = {
  onClickActionLanding: PropTypes.func.isRequired,
  invitedBy: PropTypes.string.isRequired
};

export default Landing;
