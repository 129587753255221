import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Typography } from "../../../../atoms";
import { Articles as ArticlesIcon } from "../../../../atoms/Icons";
import useStyles from "../styles";
import locales from "../../../../locales";
import K from "../../../../constants";

const Articles = ({ orderId, totalArticles }) => {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <Link to={`/order/${orderId}/articles`}>
        <ArticlesIcon
          width={18}
          height={21}
          color={theme.palette.tertiary.default}
        />
      </Link>
      <div className={classes.box__info}>
        <Typography variant={K.typographicVariants.visual3}>
          {totalArticles}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          color={K.colors.secondary}
        >
          {intl.formatMessage(locales.articles).toLowerCase()}
        </Typography>
      </div>
    </div>
  );
};

Articles.propTypes = {
  orderId: PropTypes.string,
  totalArticles: PropTypes.number
};

Articles.defaultProps = {
  orderId: "",
  totalArticles: 0
};

export default Articles;
