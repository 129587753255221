import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const RadioButtonUnchecked = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        fill={color}
      />
    </g>
  </svg>
);

RadioButtonUnchecked.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

RadioButtonUnchecked.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default RadioButtonUnchecked;
