import {
  MUFASA_IFRAME_ID,
  MUFASA_RESPONSE_3DS_AUTHENTICATION_CLOSED,
  MUFASA_RESPONSE_3DS_AUTHENTICATION_LOADED,
  MUFASA_RESPONSE_3DS_AUTHENTICATION,
  MUFASA_RESPONSE_CARD_DATA_FULFILLED,
  MUFASA_RESPONSE_INVALID_REQUEST,
  MUFASA_RESPONSE_NEW_FORM_FIELDS,
  MUFASA_RESPONSE_PAYMENT_FAILED,
  MUFASA_RESPONSE_PAYMENT_SUCCESSFUL,
  MUFASA_RESPONSE_RESIZED,
  MUFASA_RESPONSE_SUBMITTED,
  MUFASA_RESPONSE_START_SYNCHRONIZATION_POLLING,
  mufasaRequest3DSAuthenticationClosed,
  mufasaRequestIframeSubmit,
  MufasaRequestMessages,
  mufasaRequestNewFormFields,
  mufasaRequestSetPermissionValue,
  mufasaResponse3DSAuthentication,
  mufasaResponse3DSAuthenticationClosed,
  mufasaResponse3DSAuthenticationLoaded,
  mufasaResponseCardDataFulfilled,
  mufasaResponseInvalidRequest,
  mufasaResponseNewFormFields,
  mufasaResponsePaymentFailed,
  mufasaResponsePaymentSuccessful,
  mufasaResponseResize,
  mufasaResponseSubmitted,
  mufasaResponseStartSynchronizationPolling,
  mufasaRequestStartSynchronizationPolling
} from "./types";

export const getMufasaActionFromEvent = event => {
  try {
    const data = JSON.parse(event.data);
    const actionName = data.action;
    switch (actionName) {
      case MUFASA_RESPONSE_INVALID_REQUEST:
        return mufasaResponseInvalidRequest();
      case MUFASA_RESPONSE_RESIZED:
        return mufasaResponseResize(data.height);
      case MUFASA_RESPONSE_SUBMITTED:
        return mufasaResponseSubmitted();
      case MUFASA_RESPONSE_PAYMENT_FAILED:
        return mufasaResponsePaymentFailed();
      case MUFASA_RESPONSE_PAYMENT_SUCCESSFUL:
        return mufasaResponsePaymentSuccessful();
      case MUFASA_RESPONSE_3DS_AUTHENTICATION:
        return mufasaResponse3DSAuthentication(data.src);
      case MUFASA_RESPONSE_CARD_DATA_FULFILLED:
        return mufasaResponseCardDataFulfilled();
      case MUFASA_RESPONSE_NEW_FORM_FIELDS:
        return mufasaResponseNewFormFields(data.message);
      case MUFASA_RESPONSE_3DS_AUTHENTICATION_LOADED:
        return mufasaResponse3DSAuthenticationLoaded();
      case MUFASA_RESPONSE_3DS_AUTHENTICATION_CLOSED:
        return mufasaResponse3DSAuthenticationClosed();
      case MUFASA_RESPONSE_START_SYNCHRONIZATION_POLLING:
        return mufasaResponseStartSynchronizationPolling(data.message);
      default:
        return undefined;
    }
  } catch (err) {
    return undefined;
  }
};

export const getMufasaRequest = (mufasaRequestMessage, payload) => {
  switch (mufasaRequestMessage) {
    case MufasaRequestMessages.iframe_submit:
      return mufasaRequestIframeSubmit();
    case MufasaRequestMessages.set_permission_value:
      return mufasaRequestSetPermissionValue(payload);
    case MufasaRequestMessages.new_form_fields:
      return mufasaRequestNewFormFields(payload);
    case MufasaRequestMessages.authentication_3ds_closed:
      return mufasaRequest3DSAuthenticationClosed();
    case MufasaRequestMessages.start_synchronization_polling:
      return mufasaRequestStartSynchronizationPolling(payload);
    default:
      return null;
  }
};

export const iframePostMessage = msg => {
  const iframe = document.getElementById(MUFASA_IFRAME_ID);
  if (iframe) {
    iframe.contentWindow.postMessage(JSON.stringify(msg), iframe.src);
  }
};
