import React from "react";
import PropTypes from "prop-types";

const Auth3DPopup = ({ iframeSrc }) => {
  const containerStyle = {
    display: "block",
    position: "fixed",
    zIndex: "2147483647",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0"
  };

  const iframeStyle = {
    position: "absolute",
    left: "0px",
    top: "0px",
    height: "100%",
    width: "100%"
  };

  return (
    <div style={containerStyle}>
      <iframe
        id="3ds-auth-iframe"
        title="3DS-iframe"
        style={iframeStyle}
        src={iframeSrc}
        frameBorder="0"
        scrolling="no"
        allowtransparency="true"
      />
    </div>
  );
};

Auth3DPopup.propTypes = {
  iframeSrc: PropTypes.string
};

Auth3DPopup.defaultProps = {
  iframeSrc: ""
};

export default Auth3DPopup;
