import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  list: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "8px 0",
    padding: 0
  }
});

const List = React.forwardRef((props, ref) => {
  const { children, className } = props;
  const classes = useStyles();
  const componentRef = ref || React.useRef(null);
  const classNames = classnames(classes.list, className);

  return (
    <div ref={componentRef} className={classNames} role="list">
      {children}
    </div>
  );
});

List.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

List.defaultProps = {
  children: null,
  className: ""
};

export default List;
