import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme, createUseStyles } from "react-jss";
import { useMedia } from "react-use";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useOrder } from "../../../../hooks";
import useTrackingContext from "../../../../contexts/Tracking";
import { NextModal, Typography, Button } from "../../../../atoms";
import { Cross } from "../../../../atoms/Illustrations";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles({
  modal: {
    "@media(min-width: 768px)": {
      height: "715px"
    }
  },
  modal__content: {
    padding: "24px 40px"
  },
  success_modal__title: {
    marginTop: "24px",
    marginBottom: "32px"
  },
  success_modal__claim: {
    marginBottom: "24px"
  }
});

const ErrorModal = ({ onRestartConversion }) => {
  const order = useOrder();
  const theme = useTheme();
  const classes = useStyles();
  const isWideScreen = useMedia("(min-width: 768px)");
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  const onClickAction = () => {
    onRestartConversion();
    history.push(
      `${location.pathname}?process=${K.process.flexiConversion}&step=${K.steps.flexiSelectPlan}`
    );
  };

  useEffect(() => {
    track(
      eventNames.flexiConversion.fail,
      buildOrderTrackingPayload(order, true)
    );
  }, []);

  return (
    <NextModal
      open
      modal
      modalClassname={classes.modal}
      contentClassname={classes.modal__content}
      hideBack
    >
      <Cross width={97} height={97} />
      <Typography
        variant={
          isWideScreen
            ? K.typographicVariants.heading1
            : K.typographicVariants.heading2
        }
        color={theme.palette.primary.default}
        className={classes.success_modal__title}
      >
        {intl.formatMessage(locales.flexi_conversion_error_modal_title)}
      </Typography>
      <p className={classes.success_modal__claim}>
        <Typography
          variant={K.typographicVariants.body1}
          color={theme.palette.secondary.default}
          className={classes.confirmation_plan_modal__title}
        >
          {intl.formatMessage(locales.flexi_conversion_error_modal_first_claim)}
        </Typography>
      </p>

      <p className={classes.success_modal__claim}>
        <Typography
          variant={K.typographicVariants.body1}
          color={theme.palette.secondary.default}
          className={classes.confirmation_plan_modal__title}
        >
          {intl.formatMessage(
            locales.flexi_conversion_error_modal_second_claim
          )}
        </Typography>
      </p>

      <Button fullWidth size="large" onClick={onClickAction}>
        {intl.formatMessage(locales.flexi_conversion_error_modal_action)}
      </Button>
    </NextModal>
  );
};

ErrorModal.propTypes = {
  onRestartConversion: PropTypes.func
};

ErrorModal.defaultProps = {
  onRestartConversion: () => {}
};

export default ErrorModal;
