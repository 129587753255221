import React, { createContext, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useSetState } from "react-use";
import useSessionAuthenticationContext from "../SessionAuthentication";
import useTrackingContext from "../Tracking";
import {
  SHOPPER_QUERY,
  SHOPPER_INFO_FRAGMENT,
  EMAILS_FRAGMENT
} from "../../graphql";
import K from "../../constants";

export const ShopperContext = createContext();

const initialState = {
  init: false,
  shopper: null,
  fetching: false,
  error: null
};

export const ShopperContextProvider = ({ children }) => {
  const {
    isAuthorized,
    isPartialSession,
    currentShopperUserId
  } = useSessionAuthenticationContext();
  const {
    setMixpanelShopperProps,
    buildShopperTrackingData
  } = useTrackingContext();

  const [{ init, shopper, fetching, error }, setShopperState] = useSetState(
    initialState
  );
  const shopperDataQuery = useLazyQuery(
    isPartialSession
      ? SHOPPER_QUERY(SHOPPER_INFO_FRAGMENT)
      : SHOPPER_QUERY(SHOPPER_INFO_FRAGMENT, EMAILS_FRAGMENT),
    {
      fetchPolicy: K.fetchPolicy.cacheAndNetwork,
      nextFetchPolicy: K.fetchPolicy.cacheFirst
    }
  );

  const [fetchShopper] = shopperDataQuery;

  useEffect(() => {
    if (isAuthorized && currentShopperUserId !== shopper?.id) {
      setShopperState({ fetching: true });
      fetchShopper();
    }
  }, [isAuthorized]);

  useEffect(() => {
    const [, { data: shopperData, error: shopperError }] = shopperDataQuery;
    if (shopperError) {
      setShopperState({
        shopper: null,
        error: shopperError,
        fetching: false,
        init: true
      });
    } else if (shopperData?.shopper) {
      setMixpanelShopperProps(buildShopperTrackingData(shopperData.shopper));
      setShopperState({
        shopper: shopperData.shopper,
        fetching: false,
        error: null,
        init: true
      });
    }
  }, [shopperDataQuery]);

  const contextValue = {
    init,
    shopper,
    fetching,
    error
  };

  return (
    <ShopperContext.Provider value={contextValue}>
      {children}
    </ShopperContext.Provider>
  );
};

ShopperContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default () => useContext(ShopperContext);
