import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }) => {
  return (
    <FeatureFlagsContext.Provider
      value={{ featureFlags: window.env.featureFlags }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

FeatureFlagsProvider.propTypes = {
  children: PropTypes.element.isRequired
};
