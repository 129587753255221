import React from "react";
import PropTypes from "prop-types";
import store from "store";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import Toggle from "react-toggle";
import { Button, Link } from "@sequra/telar";
import { Typography, Line } from "../../atoms";
import locales from "../../locales";
import K from "../../constants";
import { User } from "../../atoms/Icons";
import useLogin from "./hooks";
import LoginTemplate from "../../templates/LoginTemplate";

const useStyles = createUseStyles(theme => ({
  login: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%"
  },
  login__form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "2.5em",
    "& input:first-child": {
      borderBottom: "0px",
      borderRadius: "8px 8px 0px 0px"
    },
    "& input:nth-child(2)": {
      marginBottom: "24px",
      borderRadius: "0px 0px 8px 8px"
    }
  },
  login__form__input: {
    height: "56px",
    width: "100%",
    border: `1px solid ${theme.palette.shade.default}`,
    boxSizing: "border-box",
    padding: "20px 16px",
    fontSize: "14px",
    transition: theme.transitions.default,
    transitionDuration: 100,
    borderRadius: "2px",
    "&:focus": {
      borderWidth: "2px"
    },
    "&::placeholder": {
      fontSize: 14,
      color: theme.palette.secondary.default,
      fontWeight: 400
    }
  },
  login__form__checkbox_container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },
  login__form__checkbox_label: {
    marginLeft: 20
  },
  login__form__error: {
    width: "100%",
    color: theme.palette.error.default,
    marginBottom: "16px"
  },
  login__form__no_account: {
    display: "flex",
    marginTop: "1.25em"
  },
  login__form__no_account__info: {
    display: "flex",
    flex: "1 1 0",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "1em",
    gap: "0.5em"
  },
  login__form__no_account__info__link: {
    marginTop: "1em",
    textDecoration: "none",
    "& :hover": {
      textDecoration: "underline",
      color: theme.palette.success.default
    }
  },
  login__form__submit: {
    display: "flex",
    width: "100%",
    height: 50,
    alignItems: "center",
    justifyContent: "center"
  }
}));

export const LoginPage = ({ error, handleSubmitForm, isCreatingOtp }) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const [nin, setNin] = React.useState("");
  const [emailOrPhone, setEmailOrPhone] = React.useState("");
  const [keepSessionAuthorized, setKeepSessionAuthorized] = React.useState(
    false
  );
  const selectorInputRef = React.useRef();
  const ninInputRef = React.useRef();

  React.useEffect(() => {
    ninInputRef.current.focus();
  }, []);

  const handleCheckKeepSessionAuthorized = e => {
    e.preventDefault();
    setKeepSessionAuthorized(!keepSessionAuthorized);
  };

  const onSubmitForm = e => {
    /* Only for app reviewers.

    We should redirect this user to the sandbox environment
    in order to allow the reviewers use the app without
    compromise the accounting
    */
    if (
      nin === window.env.reviewersNin &&
      emailOrPhone === window.env.reviewersPhone
    ) {
      store.set("api", window.env.reviewersApi);
    }
    e.preventDefault();
    handleSubmitForm(nin, emailOrPhone, keepSessionAuthorized);
  };

  return (
    <LoginTemplate
      title={intl.formatMessage(locales.login_welcome_title)}
      loader={false}
      padded={false}
    >
      <div className={classes.login}>
        <Typography
          variant={K.typographicVariants.heading2}
          color={theme.palette.primary.default}
          className={classes.login__paper__title}
        >
          {intl.formatMessage(locales.login_welcome_title)}
        </Typography>
        <form onSubmit={onSubmitForm} className={classes.login__form}>
          <input
            ref={ninInputRef}
            type="text"
            value={nin}
            onChange={e => {
              setNin(e.target.value);
            }}
            className={classes.login__form__input}
            placeholder={intl.formatMessage(locales.login_form_nin)}
          />
          <input
            ref={selectorInputRef}
            type="text"
            value={emailOrPhone}
            onChange={e => {
              setEmailOrPhone(e.target.value);
            }}
            className={classes.login__form__input}
            placeholder={intl.formatMessage(locales.login_form_email_or_phone)}
          />
          {error && (
            <Typography
              variant={K.typographicVariants.body3}
              className={classes.login__form__error}
            >
              {intl.formatMessage(error)}
              &nbsp;
            </Typography>
          )}
          <div className={classes.login__form__checkbox_container}>
            <Toggle
              checked={keepSessionAuthorized}
              icons={false}
              onChange={handleCheckKeepSessionAuthorized}
            />
            <Typography
              variant={K.typographicVariants.body1}
              className={classes.login__form__checkbox_label}
            >
              {intl.formatMessage(locales.login_form_remember_sesion)}
            </Typography>
          </div>
          <div className={classes.login__form__submit}>
            <Button submit fullWidth loading={isCreatingOtp}>
              {intl.formatMessage(locales.login_send_access)}
            </Button>
          </div>
        </form>
        {store.get(K.cookies.locale) === K.supportedLocales.es ? (
          <>
            <Line variant="section" />
            <div className={classes.login__form__no_account}>
              <User />
              <div className={classes.login__form__no_account__info}>
                <Typography variant={K.typographicVariants.heading5}>
                  {intl.formatMessage(locales.login_form_no_account_title)}
                </Typography>
                <Typography
                  variant={K.typographicVariants.body2}
                  color={K.colors.secondary}
                >
                  {intl.formatMessage(
                    locales.login_form_no_account_description
                  )}
                </Typography>
                <Link
                  href="https://www.sequra.com/tiendas-que-usan-sequra?utm_source=sequra_app&utm_medium=login_link&utm_campaign=web_tiendas"
                  label={intl.formatMessage(locales.discover_our_shops)}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </LoginTemplate>
  );
};

LoginPage.propTypes = {
  error: PropTypes.shape(),
  handleSubmitForm: PropTypes.func,
  isCreatingOtp: PropTypes.bool
};

LoginPage.defaultProps = {
  error: undefined,
  handleSubmitForm: () => {},
  isCreatingOtp: false
};

const Login = () => {
  const { handleSubmitLoginForm, error, creatingOtp } = useLogin();
  return (
    <LoginPage
      error={error}
      handleSubmitForm={handleSubmitLoginForm}
      isCreatingOtp={creatingOtp}
    />
  );
};

export default Login;
