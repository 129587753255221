import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { Button, NextModal, Typography } from "../../atoms";
import { Megaphone } from "../../atoms/Illustrations";
import hexToRGBA from "../../theme/hexToRGBA";
import locales from "../../locales";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  backdrop: {
    opacity: "0.3 !important",
    // This color is not on the palette
    backgroundColor: "rgb(57, 49, 73) !important"
  },
  wrapper: {
    width: "100%",
    "@media(max-width: 320px)": {
      top: "0 !important"
    }
  },
  modal: {
    width: "343px",
    height: "606px",
    padding: "29px 16px 48px",
    borderRadius: "8px",
    boxShadow: `-1px 0 37px -12px ${hexToRGBA(
      theme.palette.black.default,
      16
    )}`,
    boxSizing: "border-box",
    "@media(min-width: 768px)": {
      padding: "47px 42px 66px 48px",
      width: "514px",
      height: "618px"
    },
    "@media(max-width: 320px)": {
      borderRadius: 0,
      height: "auto",
      "padding-top": "16px",
      "padding-bottom": "16px"
    }
  },
  modal__content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginBottom: 0
  },
  content: {
    flex: 1
  },
  content__megaphone: {
    "@media(max-width: 768px)": {
      width: "85px",
      height: "85px",
      padding: "3px 5px 2px 4px",
      boxSizing: "border-box"
    }
  },
  content__title: {
    margin: "16px 0 24px 0",
    "& > h1": {
      lineHeight: 1.33
    },
    "@media(max-width: 320px)": {
      margin: "8px 0 24px 0"
    }
  },
  content_check: {
    display: "flex",
    "& > p": {
      lineHeight: 1.25,
      "@media(max-width: 320px)": {
        lineHeight: "normal",
        marginRight: "6px"
      },
      "& > :nth-child(2)": {
        display: "block",
        lineHeight: 1.67,
        "@media(max-width: 320px)": {
          lineHeight: "normal"
        }
      },
      "& > span > strong": {
        fontWeight: "bold"
      }
    },
    "&:last-child": {
      marginTop: "16px",
      "& > p > span > a": {
        textDecoration: "none"
      }
    }
  },
  footer__message: {
    "& > p > span": {
      lineHeight: "normal"
    }
  },
  footer__button: {
    marginTop: "48px"
  }
}));

const DataProtectionModal = ({
  hasMerchantsPromotionsEnabled,
  onChangeDataProtection
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  const [checked, onChange] = React.useState(hasMerchantsPromotionsEnabled);

  return (
    <NextModal
      open
      hideBack
      hideClose
      withFullWidth
      hideNavigation
      wrapperClassname={classes.wrapper}
      backdropClassname={classes.backdrop}
      modalClassname={classes.modal}
      contentClassname={classes.modal__content}
    >
      <div className={classes.content}>
        <div className={classes.content__megaphone}>
          <Megaphone width={72} height={75} />
        </div>

        <div data-testid="content_title" className={classes.content__title}>
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(locales.data_protection_modal_title)}
          </Typography>
        </div>

        <div className={classes.content_check}>
          <p data-testid="content_first_check">
            <Typography
              variant={K.typographicVariants.body1}
              color={theme.palette.primary.default}
            >
              {intl.formatMessage(locales.data_protection_modal_first_check)}
            </Typography>
            <span data-testid="content_first_check_info">
              <Typography
                variant={K.typographicVariants.body3}
                color={theme.palette.primary.default}
              >
                {intl.formatMessage(
                  locales.data_protection_modal_first_check_small_text
                )}
              </Typography>
            </span>
          </p>
          <Toggle defaultChecked disabled icons={false} />
        </div>

        <div className={classes.content_check}>
          <p data-testid="content_second_check">
            <Typography
              variant={K.typographicVariants.body1}
              color={theme.palette.primary.default}
            >
              {intl.formatMessage(locales.data_protection_modal_second_check, {
                b: msg => <strong>{msg}</strong>
              })}
            </Typography>
            <span data-testid="content_second_check_info">
              <a
                href={intl.formatMessage(locales.personal_data_consent_link)}
                target="_blank"
                rel="noreferrer"
              >
                <Typography variant={K.typographicVariants.linkS}>
                  {intl.formatMessage(locales.general_cookies_banner_more_info)}
                </Typography>
              </a>
            </span>
          </p>
          <Toggle
            onChange={e => {
              onChange(e.target.checked);
            }}
            icons={false}
            checked={checked}
          />
        </div>
      </div>

      <div className={classes.footer__message}>
        <p data-testid="footer__message">
          <Typography
            variant={K.typographicVariants.body1}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(locales.data_protection_modal_footer_message)}
          </Typography>
        </p>
      </div>

      <Button
        onClick={() => {
          onChangeDataProtection(checked);
        }}
        className={classes.footer__button}
        fullWidth
      >
        {intl.formatMessage(locales.confirm_selection)}
      </Button>
    </NextModal>
  );
};

DataProtectionModal.defaultProps = {
  hasMerchantsPromotionsEnabled: false,
  onChangeDataProtection: () => {}
};

DataProtectionModal.propTypes = {
  hasMerchantsPromotionsEnabled: PropTypes.bool,
  onChangeDataProtection: PropTypes.func
};

export default DataProtectionModal;
