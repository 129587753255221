import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import locales from "../../../../../locales";
import K from "../../../../../constants";
import { Typography } from "../../../../../atoms";
import Baby from "./images/baby.png";
import Electronics from "./images/electronics.png";
import Fashion from "./images/fashion.png";
import Health from "./images/health.png";
import Hobbies from "./images/hobbies.png";
import Home from "./images/home.png";
import Music from "./images/music.png";
import Sports from "./images/sports.png";
import Vehicles from "./images/vehicles.png";

const useStyles = createUseStyles({
  merchants: {
    marginBottom: "24px"
  },
  merchants_title: {
    marginBottom: "16px"
  },
  merchants_header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px",
    justifyContent: "space-between",
    "@media(min-width: 768px)": {
      marginBottom: "0",
      flexDirection: "row"
    }
  },
  merchants_header__action: {
    cursor: "pointer"
  },
  merchants_list: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& :nth-child(5)": {
      marginLeft: "0"
    },
    "& li": {
      flexShrink: "0",
      marginBottom: "16px",
      marginLeft: "0",
      marginRight: "20px",
      "&:first-child": {
        marginLeft: "0"
      },
      "& a": {
        display: "block",

        "&:focus": {
          outline: "none"
        }
      },
      "&:hover": {
        cursor: "pointer"
      },
      "@media(min-width: 768px)": {
        flexShrink: "0",
        marginLeft: "20px",
        marginRight: "0"
      }
    }
  }
});

const Merchants = ({ onClick, onClickSeeAll }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.merchants}>
      <div className={classes.merchants_header}>
        <Typography
          variant={K.typographicVariants.heading2}
          className={classes.merchants_title}
        >
          {intl.formatMessage(locales.dashboard_without_orders_merchants_title)}
        </Typography>
        <span
          className={classes.merchants_header__action}
          onClick={() => onClickSeeAll()}
          onKeyPress={() => onClickSeeAll()}
          role="tab"
          tabIndex="0"
          data-testid="see-all"
        >
          <Typography
            variant={K.typographicVariants.linkL}
            className={classes.merchants_title}
          >
            {intl.formatMessage(
              locales.dashboard_without_orders_merchants_title_action
            )}
          </Typography>
        </span>
      </div>

      <ul className={classes.merchants_list}>
        <li role="tab" onClick={() => onClick("fashion")} onKeyPress={onClick}>
          <img src={Fashion} alt="" width="301" />
        </li>
        <li role="tab" onClick={() => onClick("sports")} onKeyPress={onClick}>
          <img src={Sports} alt="" width="140" />
        </li>
        <li
          role="tab"
          onClick={() => onClick("baby&kids")}
          onKeyPress={onClick}
        >
          <img src={Baby} alt="" width="140" />
        </li>
        <li role="tab" onClick={() => onClick("home")} onKeyPress={onClick}>
          <img src={Home} alt="" width="301" />
        </li>
        <li role="tab" onClick={() => onClick("vehicles")} onKeyPress={onClick}>
          <img src={Vehicles} alt="" width="140" />
        </li>
        <li
          role="tab"
          onClick={() => onClick("music&sound")}
          onKeyPress={onClick}
        >
          <img src={Music} alt="" width="140" />
        </li>
        <li
          role="tab"
          onClick={() => onClick("electronics")}
          onKeyPress={onClick}
        >
          <img src={Electronics} alt="" width="301" />
        </li>
        <li role="tab" onClick={() => onClick("hobby")} onKeyPress={onClick}>
          <img src={Hobbies} alt="" width="140" />
        </li>
        <li
          role="tab"
          onClick={() => onClick("health&beauty")}
          onKeyPress={onClick}
        >
          <img src={Health} alt="" width="140" />
        </li>
      </ul>
    </div>
  );
};

Merchants.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClickSeeAll: PropTypes.func.isRequired
};

export default Merchants;
