import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import ContentModal from "./ContentModal";
import K from "../../../../constants";
import { PaymentModalDescription } from "../../components";
import { useOrder } from "../../../../hooks";

const PaymentMufasaModal = () => {
  const location = useLocation();
  const order = useOrder();
  const query = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: "comma"
  });
  const overdueAmount = order.isOverdue
    ? order.overdueAmount
    : order.unpaidAmount;
  const maxAmount =
    (order.isOverdue ? order.unpaidPlanAmount : order.unpaidAmount) +
    order.overdueAmount;
  const amountCents = Math.min(query.amount || overdueAmount, maxAmount);

  return (
    <ContentModal
      open
      gateway={K.mufasaGateways.payment}
      invoiceNumbers={
        typeof query.invoice_numbers === "string"
          ? [query.invoice_numbers]
          : query.invoice_numbers
      }
      hideBack={query.step === K.steps.summary}
      amountCents={amountCents}
      withDescription
      contentDescription={
        <PaymentModalDescription
          invoiceNumbers={query.invoice_numbers}
          amountCents={amountCents}
        />
      }
    />
  );
};

export default PaymentMufasaModal;
