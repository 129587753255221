import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
  const [snackbarLayoutProps, setSnackbarLayoutProps] = useState({
    open: false,
    label: "",
    variant: "accent"
  });

  const contextValue = {
    snackbarLayoutProps,
    setSnackbarLayoutProps
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default () => useContext(NotificationContext);
