import { useReducer } from "react";
import { PopupActions } from "../types";

export const initialState = {
  isWaiting3DS: false,
  isLoading3DS: false,
  iframe3DSUrl: null
};

export const popupReducer = (state, action) => {
  switch (action.type) {
    case PopupActions.close:
      return { ...state, isWaiting3DS: false };
    case PopupActions.accept3ds:
      return { ...state, isWaiting3DS: false };
    case PopupActions.open:
      return {
        isWaiting3DS: true,
        isLoading3DS: true,
        iframe3DSUrl: action.payload.iframeUrl
      };
    case PopupActions.iframeLoaded:
      return {
        ...state,
        isLoading3DS: false
      };
    default:
      return state;
  }
};

export default () => useReducer(popupReducer, initialState);
