import { matchPath } from "react-router-dom";

import {
  ORDER_PATH,
  ORDER_ARTICLES_PATH,
  ORDER_INVOICES_PATH,
  ORDER_INSTALMENTS_PATH
} from "../routes";

export default () => {
  const match = [
    ORDER_PATH,
    ORDER_ARTICLES_PATH,
    ORDER_INVOICES_PATH,
    ORDER_INSTALMENTS_PATH
  ].find(route =>
    matchPath(window.location.pathname, { path: route, exact: true })
  );

  return match;
};
