import React from "react";
import PropTypes from "prop-types";
import { ScaleLoader } from "react-spinners";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import locales from "../../../locales";
import K from "../../../constants";
import { Typography, Button } from "../../../atoms";

const useStyles = createUseStyles(theme => ({
  login_by_phone: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%"
  },
  login_by_phone__form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "16px 0 8px 0"
  },
  login_by_phone__form__input: {
    height: "56px",
    width: "100%",
    border: `1px solid ${theme.palette.shade.default}`,
    boxSizing: "border-box",
    marginBottom: "16px",
    padding: "16px 8px",
    fontSize: "24px",
    textAlign: "center",
    borderRadius: "8px",
    "&::-webkit-inner-spin-button, &::-webkit-inner-spin-button": {
      appearance: "none",
      margin: 0
    }
  },
  login_by_phone__form__error: {
    display: "flex",
    alignSelf: "flex-start",
    color: theme.palette.error.default,
    marginBottom: "16px"
  },
  login_by_phone__submit: {
    display: "flex",
    width: "100%",
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 42
  },
  login_by_phone__button: {
    boxShadow: "0px 2px 4px rgba(0,0,0,0.12)",
    "> span": { fontSize: 14 }
  },
  login_by_phone__footer__button: {
    color: theme.palette.primary.default,
    textDecoration: "underline"
  }
}));

const SMSCodeForm = ({ error, handleSubmitSMSCode, loading }) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const [code, setCode] = React.useState("");
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className={classes.login_by_phone}>
      <Typography variant={K.typographicVariants.heading2}>
        {intl.formatMessage(locales.login_by_phone_description)}
      </Typography>

      <form
        onSubmit={async e => {
          e.preventDefault();
          handleSubmitSMSCode(code);
        }}
        className={classes.login_by_phone__form}
      >
        <input
          type="number"
          pattern="\d*"
          value={code}
          onChange={e => {
            setCode(e.target.value);
          }}
          className={classes.login_by_phone__form__input}
          ref={inputRef}
        />
        {error && (
          <Typography
            variant={K.typographicVariants.body3}
            className={classes.login_by_phone__form__error}
          >
            {intl.formatMessage(locales.login_by_phone_form_error)}
          </Typography>
        )}
        <div className={classes.login_by_phone__submit}>
          {loading ? (
            <ScaleLoader color={theme.palette.success.default} />
          ) : (
            <Button
              type="submit"
              fullWidth
              className={classes.login_by_phone__button}
            >
              {intl.formatMessage(locales.login_enter)}
            </Button>
          )}
        </div>
      </form>
      <Typography
        variant={K.typographicVariants.body2}
        color={K.colors.secondary}
      >
        {intl.formatMessage(locales.login_by_phone_footer)}
        &nbsp;
        <a href="/login" className={classes.login_by_phone__footer__button}>
          {intl.formatMessage(locales.login_by_phone_link)}
        </a>
      </Typography>
    </div>
  );
};

SMSCodeForm.propTypes = {
  error: PropTypes.bool,
  handleSubmitSMSCode: PropTypes.func,
  loading: PropTypes.bool
};

SMSCodeForm.defaultProps = {
  error: false,
  handleSubmitSMSCode: () => {},
  loading: false
};

export default SMSCodeForm;
