import React from "react";
import PropTypes from "prop-types";
import { NextModal } from "../../atoms";

const withModal = (WrappedComponent, { ...props }) => {
  const HOC = ({
    open,
    hideBack,
    hideNavigation,
    modalClassname,
    contentClassname,
    onClose,
    onBack,
    title,
    withDescription,
    contentDescription,
    withIconClose,
    withBackdropClose,
    ...childrenProps
  }) => {
    const modalProps = {
      open,
      hideBack,
      hideNavigation,
      modalClassname,
      contentClassname,
      onClose,
      onBack,
      title,
      withDescription,
      contentDescription,
      withIconClose,
      withBackdropClose,
      ...props
    };

    return (
      <NextModal {...modalProps}>
        <WrappedComponent {...childrenProps} />
      </NextModal>
    );
  };

  HOC.propTypes = {
    open: PropTypes.bool,
    hideBack: PropTypes.bool,
    hideNavigation: PropTypes.bool,
    modalClassname: PropTypes.string,
    contentClassname: PropTypes.string,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    title: PropTypes.string,
    withDescription: PropTypes.bool,
    contentDescription: PropTypes.node,
    withIconClose: PropTypes.bool,
    withBackdropClose: PropTypes.bool
  };

  HOC.defaultProps = {
    open: false,
    hideBack: false,
    hideNavigation: false,
    modalClassname: "",
    contentClassname: "",
    onClose: null,
    onBack: null,
    title: "",
    withDescription: false,
    contentDescription: null,
    withIconClose: false,
    withBackdropClose: true
  };

  return HOC;
};

export default withModal;
