import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useDocumentTitle } from "../../hooks";
import LayoutTemplate from "../LayoutTemplate";
import { Typography } from "../../atoms";
import K from "../../constants";

const useStyles = createUseStyles(theme => ({
  page_template: {
    boxSizing: "border-box",
    "@media (min-width: 1024px)": {
      paddingTop: "0"
    },
    "@media (min-width: 1280px)": {
      margin: "0 auto"
    }
  },
  page_template__header: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: "16px",
    paddingTop: "20px",
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (min-width: 768px)": {
      padding: "16px",
      margin: "0 auto",
      maxWidth: "947px"
    },
    "@media (min-width: 1024px)": {
      padding: "32px 2vw 16px 2vw"
    }
  },
  page_template__header__title: {
    paddingLeft: "8px",
    "@media (min-width: 768px)": {
      paddingLeft: 0
    }
  },
  page_template__header__subtitle: {
    display: "none",
    "@media (min-width: 768px)": {
      display: "flex"
    }
  },
  page_template__content: {
    backgroundColor: theme.palette.background.default,
    boxSizing: "border-box",
    paddingBottom: "200px",

    "@media (min-width: 768px)": {
      height: "100vh",
      overflow: "auto",
      "& > div": {
        margin: "0 auto",
        maxWidth: "947px"
      }
    },
    "&::-webkit-scrollbar": {
      width: 0,
      background: "transparent"
    },
    padding: "0 2vw"
  }
}));

const PageTemplate = ({ children, title, subtitle, loader, isOrders }) => {
  const classes = useStyles({ isOrders });
  const header = React.useRef(null);
  const content = React.useRef(null);
  const paddingTop = window.outerWidth < 768 ? 0 : 16;
  const [mounted, setMounted] = React.useState(false);

  useDocumentTitle(title);

  React.useLayoutEffect(() => {
    setMounted(true);
  }, []);

  React.useLayoutEffect(() => {
    content.current.style.paddingTop = `${header.current.offsetHeight +
      paddingTop}px`;
  }, []);

  return (
    <LayoutTemplate
      className={classes.page_template}
      loader={!mounted || loader}
    >
      <div className={classes.page_template__header} ref={header}>
        {title && (
          <Typography
            variant={K.typographicVariants.heading1}
            className={classes.page_template__header__title}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant={K.typographicVariants.heading5}
            className={classes.page_template__header__subtitle}
            color={K.colors.secondary}
          >
            {subtitle}
          </Typography>
        )}
      </div>
      <div className={classes.page_template__content} ref={content}>
        {children}
      </div>
    </LayoutTemplate>
  );
};

PageTemplate.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loader: PropTypes.bool,
  isOrders: PropTypes.bool
};

PageTemplate.defaultProps = {
  children: null,
  title: "",
  subtitle: "",
  loader: false,
  isOrders: false
};

export default PageTemplate;
