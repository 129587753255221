import { useLazyQuery } from "@apollo/client";
import { MERCHANTS_PROMOTIONS_QUERY } from "../../../../graphql";
import K from "../../../../constants";

export default ({ onCompleted, onError }) =>
  useLazyQuery(MERCHANTS_PROMOTIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: K.fetchPolicy.cacheAndNetwork,
    onCompleted,
    onError
  });
