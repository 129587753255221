import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSpring, animated } from "react-spring";
import { createUseStyles, useTheme } from "react-jss";
import Typography from "../Typography";

const useStyles = createUseStyles(theme => ({
  snackbar: {
    backgroundColor: "var(--backgroundColor)",
    height: "56px",
    maxWidth: "472px",
    position: "fixed",
    zIndex: 110000,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    padding: "0 8px"
  },
  accent: {
    "--backgroundColor": theme.palette.success.default
  },
  success: {
    "--backgroundColor": theme.palette.info.default
  },
  error: {
    "--backgroundColor": theme.palette.error.default
  }
}));

const Snackbar = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const classes = useStyles();
  const componentRef = ref || React.useRef(null);
  const { label, open, className, variant, onLeave } = props;
  const [show, setShow] = React.useState(false);
  const classNames = classnames(className, classes.snackbar, classes[variant]);

  React.useEffect(() => {
    setShow(open);
    setTimeout(() => {
      if (open) {
        setShow(false);
        onLeave();
      }
    }, 3000);
  }, [open]);

  const style = useSpring({
    from: { top: "-56px" },
    to: { top: show ? "0" : "-56px" }
  });

  return (
    <animated.div style={style} className={classNames} ref={componentRef}>
      <Typography color={theme.palette.white.default}>{label}</Typography>
    </animated.div>
  );
});

Snackbar.propTypes = {
  label: PropTypes.string,
  open: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["accent", "success", "error"]),
  onLeave: PropTypes.func
};

Snackbar.defaultProps = {
  label: "",
  open: false,
  className: "",
  variant: "accent",
  onLeave: () => {}
};

export default Snackbar;
