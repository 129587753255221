import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { List } from "../../atoms";
import Card from "../Card";

const useStyles = createUseStyles(theme => ({
  card_list: {
    "@media (max-width: 768px)": {
      "& > *": {
        margin: 0,
        borderTop: 0
      },
      "& > :first-child": {
        marginTop: "8px",
        borderTop: `1px solid ${theme.palette.shade.default}`
      },
      "& > :last-child": {
        marginBottom: "8px",
        borderBottom: `1px solid ${theme.palette.shade.default}`
      }
    },
    "@media (min-width: 768px)": {
      "& > *": {
        margin: "4px 0"
      }
    }
  }
}));

const CardList = ({ cards, defaultCardId, onClick }) => {
  const classes = useStyles();

  return (
    <List className={classes.card_list}>
      {cards.map(card => (
        <Card
          key={card.id}
          card={card}
          isDefault={defaultCardId === card.id}
          onClick={() => {
            onClick(card);
          }}
        />
      ))}
    </List>
  );
};

CardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape()),
  defaultCardId: PropTypes.string,
  onClick: PropTypes.func
};

CardList.defaultProps = {
  cards: [],
  defaultCardId: "",
  onClick: () => {}
};

export default CardList;
