import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import ConversionPlanSelector from "../ConversionPlanSelector";
import locales from "../../../../locales";

const useStyles = createUseStyles({
  conversion_plan_selector_list: {
    display: "flex",
    marginBottom: "24px",
    justifyContent: "space-between",
    flexDirection: "column",
    "& > div": {
      marginBottom: "16px"
    },
    "& > :last-child": {
      marginBottom: 0
    },
    "@media(min-width: 768px)": {
      marginBottom: "48px",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      minWidth: "374px",
      "& > div": {
        marginBottom: 0
      }
    }
  }
});

const ConversionPlanSelectorList = ({
  conversionPlans,
  selectedPlan,
  onSelectPlan
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      role="table"
      aria-label={intl.formatMessage(locales.payment_plan)}
      className={classes.conversion_plan_selector_list}
    >
      {conversionPlans.map(conversionPlan => (
        <ConversionPlanSelector
          key={conversionPlan.instalmentTotal}
          conversionPlan={conversionPlan}
          onClick={() => {
            onSelectPlan(conversionPlan);
          }}
          isSelected={
            selectedPlan?.instalmentCount === conversionPlan.instalmentCount
          }
        />
      ))}
    </div>
  );
};

ConversionPlanSelectorList.propTypes = {
  conversionPlans: PropTypes.arrayOf(PropTypes.shape()),
  selectedPlan: PropTypes.shape(),
  onSelectPlan: PropTypes.func
};

ConversionPlanSelectorList.defaultProps = {
  conversionPlans: [],
  selectedPlan: null,
  onSelectPlan: () => {}
};

export default ConversionPlanSelectorList;
