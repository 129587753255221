import React from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useMedia } from "react-use";
import { useOrder, useShopperCardsDataQuery } from "../../hooks";
import {
  PaymentInvoiceSummaryModal,
  PaymentPartPaymentSummaryModal,
  PaymentSelectCardModal,
  PaymentMufasaModal,
  PaymentBankAccountsModal
} from "./modals";
import useTrackingContext from "../../contexts/Tracking";
import K from "../../constants";
import { filterReusableCardReferences } from "../../utils";

const PaymentFlow = () => {
  const order = useOrder();
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();
  const isWideScreen = useMedia("(min-width: 768px)");

  React.useEffect(() => {
    /*
    This avoid to send to mixpanel data when a user uses
    the payment shortened link to access to the app
    and redirect to the location.pathname
    */
    if (order.canBePaid) {
      track(eventNames.payment.start, buildOrderTrackingPayload(order, true));
    } else {
      history.push(location.pathname);
    }
  }, []);

  const cardReferencesResponse = useShopperCardsDataQuery({
    fetchPolicy: K.fetchPolicy.cacheAndNetwork
  });

  if (!order.canBePaid) {
    return null;
  }

  const emptyReusableCardReferences =
    filterReusableCardReferences(
      cardReferencesResponse.data?.shopper?.cardReferences
    ).length === 0;

  switch (query.step) {
    case K.steps.summary:
      if (/i\d+/.test(order.currentProductName)) {
        return <PaymentInvoiceSummaryModal />;
      }

      if (isWideScreen) {
        if (emptyReusableCardReferences) {
          return <PaymentMufasaModal />;
        }

        return <PaymentSelectCardModal />;
      }

      return <PaymentPartPaymentSummaryModal />;
    case K.steps.selectCard: {
      if (emptyReusableCardReferences) {
        return <PaymentMufasaModal />;
      }
      return <PaymentSelectCardModal />;
    }
    case K.steps.addCard:
      return <PaymentMufasaModal />;
    case K.steps.selectBankAccount:
      return <PaymentBankAccountsModal />;
    default:
      return null;
  }
};

export default PaymentFlow;
