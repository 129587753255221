import React from "react";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import locales from "../../locales";
import useAppContext from "../../contexts/App";
import { Button, NextModal, Typography } from "../../atoms";
import { Lock } from "../../atoms/Illustrations";
import K from "../../constants";

const useStyles = createUseStyles({
  modal: {
    position: "relative",
    maxHeight: 549,
    maxWidth: 514,
    borderRadius: 8,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0
  },
  modal_content: {},
  request_login_modal: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  request_login_modal__header: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
    "@media(min-width: 768px)": {
      marginTop: 12
    }
  },
  request_login_modal__content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 31,
    textAlign: "center",
    "@media(min-width: 768px)": {
      flex: 0,
      marginTop: 38
    }
  },
  request_login_modal__content__subtitle: {
    marginTop: 16
  },
  request_login_modal__footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 56
  },
  request_login_modal__footer__back_btn: {
    marginTop: 16
  }
});

const RequestLoginModal = () => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const {
    isRequestLoginModalOpen,
    closeRequestLoginModal,
    redirectToLoginFromPartialSession
  } = useAppContext();
  return (
    <NextModal
      open={isRequestLoginModalOpen}
      hideNavigation
      modalClassname={classes.modal}
    >
      <div className={classes.request_login_modal}>
        <div className={classes.request_login_modal__header}>
          <Lock />
        </div>
        <div className={classes.request_login_modal__content}>
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
          >
            {intl.formatMessage(locales.request_login_modal_title)}
          </Typography>
          <Typography
            className={classes.request_login_modal__content__subtitle}
          >
            {intl.formatMessage(locales.request_login_modal_subtitle)}
          </Typography>
        </div>
        <div className={classes.request_login_modal__footer}>
          <Button
            fullWidth
            size="medium"
            onClick={() => redirectToLoginFromPartialSession("modal")}
          >
            {intl.formatMessage(locales.general_navigation_login)}
          </Button>
          <Button
            fullWidth
            variant="text"
            size="large"
            className={classes.request_login_modal__footer__back_btn}
            onClick={closeRequestLoginModal}
          >
            <Typography variant={K.typographicVariants.buttonL}>
              {intl.formatMessage(locales.general_navigation_back_to_order)}
            </Typography>
          </Button>
        </div>
      </div>
    </NextModal>
  );
};

export default RequestLoginModal;
