import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { ScaleLoader as Loader } from "react-spinners";

const useStyles = createUseStyles({
  loader: {
    position: ({ fixed }) => (fixed ? "fixed" : "absolute"),
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100009,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const ScaleLoader = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const { fixed } = props;
  const classes = useStyles({ fixed });

  return (
    <div className={classes.loader} ref={ref}>
      <Loader color={theme.palette.success.default} />
    </div>
  );
});

ScaleLoader.propTypes = {
  fixed: PropTypes.bool
};

ScaleLoader.defaultProps = {
  fixed: false
};

export default ScaleLoader;
