import React from "react";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useMedia } from "react-use";
import { useTheme, createUseStyles } from "react-jss";
import { useLocation, useHistory } from "react-router-dom";
import { NextModal, Typography, Button } from "../../../../atoms";
import { CheckBox, CheckBoxOutlineBlank } from "../../../../atoms/Icons";
import { useOrder, useLocaleCurrency } from "../../../../hooks";
import { ModalOrderInfo } from "../../../ConversionFlow/components";
import { InvoiceDropDown } from "../../components";
import locales from "../../../../locales";
import K from "../../../../constants";
import {
  getArticlesListFromCart,
  getTotalCountFromItemsList
} from "../../../../utils";

const useStyles = createUseStyles(theme => ({
  modal__title: {
    marginTop: "40px",
    marginBottom: "32px"
  },
  summary_list_total: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "48px"
  },
  summary_box: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    marginBottom: "16px",
    "& > :last-child": {
      borderBottom: "none",
      marginBottom: 0
    }
  },
  summary_checkbox: {
    marginRight: "8px",
    cursor: "pointer"
  }
}));

const PaymentInvoiceSummaryModal = () => {
  const order = useOrder();
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const isWideScreen = useMedia("(min-width: 768px)");
  const [invoiceNumbers, setInvoiceNumbers] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const query = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: "comma"
  });

  const performTotal = items => {
    setTotal(items.reduce((acc, invoice) => acc + invoice.unpaidAmount, 0));
  };

  let invoices;
  if (query.invoice_numbers) {
    const queryInvoiceNumbers = query.invoice_numbers.split(",");
    invoices = order.invoices.filter(
      invoice =>
        invoice.unpaidAmount > 0 &&
        queryInvoiceNumbers.indexOf(invoice.number) >= 0
    );
  } else {
    invoices = order.invoices.filter(invoice => invoice.unpaidAmount > 0);
  }

  const totalUnpaidAmount = invoices.reduce(
    (acc, invoice) => acc + invoice.unpaidAmount,
    0
  );
  const amountCents = Math.min(
    query.amount || totalUnpaidAmount,
    totalUnpaidAmount
  );
  const isPartialPayment = query.amount === amountCents;

  React.useEffect(() => {
    setInvoiceNumbers(
      invoices.reduce(
        (acc, invoice) => ({ [invoice.number]: invoice.number, ...acc }),
        {}
      )
    );

    performTotal(invoices);
  }, []);

  React.useEffect(() => {
    const invoicesSelected = Object.values(invoiceNumbers).map(invoiceNumber =>
      invoices.find(invoice => invoice.number === invoiceNumber)
    );

    performTotal(invoicesSelected);
  }, [invoiceNumbers]);

  const onNext = () => {
    const invoiceNumbersValues = Object.values(invoiceNumbers).reverse();
    const search = queryString.stringify(
      {
        ...queryString.parse(location.search),
        step: K.steps.selectCard,
        invoice_numbers: invoiceNumbersValues,
        amount: query.amount,
        reference: invoiceNumbersValues[0]
      },
      { arrayFormat: "comma" }
    );
    history.push(`${location.pathname}?${search}`);
  };

  const checkInvoice = invoice => {
    if (invoice.isOverdue || isPartialPayment) return;
    const copyInvoiceNumbers = { ...invoiceNumbers };
    delete copyInvoiceNumbers[invoice.number];

    setInvoiceNumbers({
      ...copyInvoiceNumbers
    });
  };

  const uncheckInvoice = invoice => {
    if (invoice.isOverdue || isPartialPayment) return;
    setInvoiceNumbers({
      [invoice.number]: invoice.number,
      ...invoiceNumbers
    });
  };

  return (
    <NextModal open hideBack>
      <ModalOrderInfo
        merchantLogoURL={order.merchant.logo}
        merchantName={order.merchant.name}
        orderConfirmedAt={order.confirmedAt}
        orderNumberOfArticles={getTotalCountFromItemsList(
          getArticlesListFromCart(order.cart)
        )}
      />
      <Typography
        variant={
          isWideScreen
            ? K.typographicVariants.heading1
            : K.typographicVariants.heading2
        }
        color={theme.palette.primary.default}
        className={classes.modal__title}
      >
        {intl.formatMessage(locales.order_payment_select_invoices_title)}
      </Typography>

      {invoices.map(invoice => (
        <div className={classes.summary_box} key={invoice.number}>
          {invoices.length === 1 ||
          invoice.isOverdue ||
          isPartialPayment ? null : (
            <div className={classes.summary_checkbox}>
              {invoiceNumbers[invoice.number] ? (
                <div
                  onKeyPress={() => checkInvoice(invoice)}
                  onClick={() => checkInvoice(invoice)}
                  tabIndex="0"
                  role="checkbox"
                  aria-checked="true"
                >
                  <CheckBox
                    color={
                      invoice.isOverdue
                        ? theme.palette.secondary.default
                        : theme.palette.success.default
                    }
                  />
                </div>
              ) : (
                <div
                  onKeyPress={() => uncheckInvoice(invoice)}
                  onClick={() => uncheckInvoice(invoice)}
                  tabIndex="0"
                  role="checkbox"
                  aria-checked="false"
                >
                  <CheckBoxOutlineBlank color={theme.palette.shade.default} />
                </div>
              )}
            </div>
          )}
          <InvoiceDropDown invoice={invoice} />
        </div>
      ))}

      <div className={classes.summary_list_total}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.total)}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {useLocaleCurrency(isPartialPayment ? amountCents : total, {
            useCents: true
          })}
        </Typography>
      </div>
      <Button
        variant="outlined"
        fullWidth
        onClick={onNext}
        disabled={Object.values(invoiceNumbers).length === 0}
      >
        {intl.formatMessage(locales.general_navigation_next)}
      </Button>
    </NextModal>
  );
};

export default PaymentInvoiceSummaryModal;
