import queryString from "query-string";
import K from "../../constants";

const goToStep = ({ step, history, location }) => {
  const query = queryString.parse(location.search, { arrayFormat: "comma" });
  const search = queryString.stringify(
    { ...query, step },
    { arrayFormat: "comma" }
  );
  history.push(`${location.pathname}?${search}`);
};

const paymentSummaryPath = order => {
  const query = {
    process: K.process.payment,
    step: K.steps.summary
  };

  return `/order/${order.id}?${queryString.stringify(query)}`;
};

const changeCardPath = order => {
  if (order.isOverdue) {
    return paymentSummaryPath(order);
  }
  const search = queryString.stringify({
    process: K.process.changePaymentCard,
    gateway: K.mufasaGateways.assignCard
  });
  return `/order/${order.id}?${search}`;
};

const goToOrderPath = ({ order, history, pathFunction }) =>
  history.push(pathFunction(order));

export { goToStep, goToOrderPath, paymentSummaryPath, changeCardPath };
