import React from "react";
import queryString from "query-string";
import { useIntl } from "react-intl";
import { useTheme, createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { NextModal, Typography, Button } from "../../../../atoms";
import { MessageBox } from "../../../../molecules";
import { ModalOrderInfo } from "../../../ConversionFlow/components";
import { InvoiceDropDown } from "../../../PaymentFlow/components";
import { useOrder, useShopper } from "../../../../hooks";
import { ScaleLoader } from "../../../../atoms/Loaders";
import {
  RETURN_REQUEST,
  ORDER_QUERY,
  ORDER_INFO_FRAGMENT
} from "../../../../graphql";
import useNotificationContext from "../../../../contexts/Notification";
import useTrackingContext from "../../../../contexts/Tracking";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  modal: {
    "@media(min-width: 768px)": {
      height: "715px"
    }
  },
  modal_title: {
    margin: "32px 0",
    "& > :first-child": {
      marginBottom: "8px"
    }
  },
  summary_list_total: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "48px"
  },
  summary_box: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    marginBottom: "16px",
    "& > :last-child": {
      borderBottom: "none",
      marginBottom: 0
    }
  },
  message_box: {
    marginBottom: "8px",
    marginTop: "8px"
  },
  confirmation_button: {
    marginTop: "16px"
  }
}));

const useReturnRequestMutation = () => {
  const order = useOrder();
  const shopper = useShopper();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { setSnackbarLayoutProps } = useNotificationContext();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();

  return useMutation(RETURN_REQUEST, {
    update: (cache, response) => {
      cache.writeQuery({
        query: ORDER_QUERY(ORDER_INFO_FRAGMENT),
        variables: { orderId: order.id },
        data: {
          shopper: {
            __typename: "Shopper",
            id: shopper.id,
            orderById: response.data.returnRequest
          }
        }
      });
    },
    onCompleted: () => {
      const eventProps = buildOrderTrackingPayload(order, true, true);
      track(eventNames.returnRequest.success, eventProps);
      history.push(location.pathname);
      setSnackbarLayoutProps({
        open: true,
        variant: "accent",
        label: intl.formatMessage(
          locales.order_return_request_notification_success
        )
      });
    },
    onError: () => {
      const eventProps = buildOrderTrackingPayload(order, true, true);
      track(eventNames.returnRequest.fail, eventProps);
      setSnackbarLayoutProps({
        open: true,
        variant: "error",
        label: intl.formatMessage(
          locales.order_return_request_notification_error
        )
      });
    }
  });
};

const ReturnRequestConfirmModal = () => {
  const order = useOrder();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const [invoices, setInvoices] = React.useState([]);
  const [mutation, response] = useReturnRequestMutation();
  const query = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: "comma"
  });

  React.useEffect(() => {
    const invoiceNumbers =
      typeof query.invoice_numbers === "string"
        ? [query.invoice_numbers]
        : query.invoice_numbers;

    setInvoices(
      invoiceNumbers.map(invoiceNumber =>
        order.invoices.find(invoice => invoice.number === invoiceNumber)
      )
    );
  }, []);

  return (
    <NextModal open modalClassname={classes.modal}>
      {response?.loading ? <ScaleLoader /> : null}
      <ModalOrderInfo
        merchantLogoURL={order.merchant.logo}
        merchantName={order.merchant.name}
        orderConfirmedAt={order.confirmedAt}
        orderNumberOfArticles={order.cart.length}
      />

      <div className={classes.modal_title}>
        <Typography
          variant={K.typographicVariants.heading2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(
            locales.order_return_request_invoices_summary_title
          )}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(
            locales.order_return_request_invoices_summary_subtitle
          )}
        </Typography>
      </div>

      {invoices.map(invoice => (
        <div className={classes.summary_box} key={invoice.number}>
          <InvoiceDropDown invoice={invoice} />
        </div>
      ))}

      <div className={classes.summary_list_total}>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.total)}
        </Typography>
        <Typography
          variant={K.typographicVariants.visual2}
          color={theme.palette.primary.default}
        >
          {intl.formatMessage(locales.shipments_with_quantity, {
            quantity: invoices.length
          })}
        </Typography>
      </div>
      <MessageBox
        className={classes.message_box}
        text={intl.formatMessage(
          locales.order_return_request_tag_price_message
        )}
        type="warning"
      />
      <MessageBox
        className={classes.message_box}
        text={intl.formatMessage(locales.order_return_request_deliver_message)}
        type="info"
      />
      <Button
        variant="contained"
        fullWidth
        className={classes.confirmation_button}
        onClick={() => {
          mutation({
            variables: {
              orderId: order.id,
              invoiceNumbers: invoices.map(invoice => invoice.number)
            }
          });
        }}
      >
        {intl.formatMessage(locales.order_return_request_navigation_inform)}
      </Button>
    </NextModal>
  );
};

export default ReturnRequestConfirmModal;
