import { useEffect } from "react";
import { useSetState } from "react-use";
import useReferralProgramDataQuery from "./queries/useReferralProgramData";
import useShareReferralProgram from "./mutations/useShareReferralProgram";

export const initialState = {
  fetching: true,
  fetchingError: false,
  fetchingShareUrl: true,
  fetchingShareUrlError: false,
  shareUrl: null
};

export default () => {
  const [state, setState] = useSetState(initialState);

  const [
    fetchReferralProgramData,
    { data, refetch }
  ] = useReferralProgramDataQuery({
    onError: () => {
      setState({
        fetchingError: true,
        fetching: false
      });
    },
    onCompleted: () => {
      setState({
        fetchingError: false,
        fetching: false
      });
    }
  });

  const [shareReferralProgram] = useShareReferralProgram({
    onCompleted: ({ shareReferralProgram: foo }) => {
      const { __typename, shareUrl: newShareUrl } = foo;
      switch (__typename) {
        case "ShareURLCreated":
          setState({
            shareUrl: newShareUrl,
            fetchingShareUrl: false,
            fetchingShareUrlError: false
          });
          break;
        default:
          setState({
            fetchingShareUrlError: true,
            fetchingShareUrl: false
          });
          break;
      }
    },
    onError: () => {
      setState({
        fetchingShareUrlError: true,
        fetchingShareUrl: false
      });
    }
  });

  useEffect(() => {
    fetchReferralProgramData();
  }, []);

  const shopperInfo = data?.shopper || {};
  const referralProgramGuests = shopperInfo?.referralProgram?.guests || [];
  const vouchers = shopperInfo?.vouchers || {};

  return {
    ...state,
    referralProgramGuests,
    vouchers,
    refetch,
    shareReferralProgram
  };
};
