import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useTrackingContext from "../Tracking";
import { DASHBOARD_PATH, LOGIN_PATH } from "../../routes";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const { track, eventNames } = useTrackingContext();
  const history = useHistory();
  const [
    { isSidebarOpen, isRequestLoginModalOpen },
    setAppState
  ] = React.useState({ isSidebarOpen: false, isRequestLoginModalOpen: false });
  const [
    isSessionExpiredModalOpened,
    setSessionExpiredModalStatus
  ] = React.useState(false);

  const toggleSidebar = () => setAppState({ isSidebarOpen: !isSidebarOpen });

  const closeSidebar = () => setAppState({ isSidebarOpen: false });

  const openRequestLoginModal = () => {
    if (isSidebarOpen) {
      setAppState({ isRequestLoginModalOpen: true, isSidebarOpen: false });
    } else {
      setAppState({ isRequestLoginModalOpen: true });
    }
  };

  const closeRequestLoginModal = () => {
    setAppState({ isRequestLoginModalOpen: false, isSidebarOpen: false });
  };

  const setSessionModalStatus = () => {
    setSessionExpiredModalStatus(!isSessionExpiredModalOpened);
  };

  const redirectToLoginFromPartialSession = from => {
    track("Start log in process from Partial Session", { from });
    setAppState({ isRequestLoginModalOpen: false, isSidebarOpen: false });
    history.replace(LOGIN_PATH);
  };

  const goToDashboard = () => {
    track(eventNames.dashboard.clickDashboardNavBarIcon);
    history.push(DASHBOARD_PATH);
  };

  const contextValue = {
    isRequestLoginModalOpen,
    isSidebarOpen,
    isSessionExpiredModalOpened,
    closeSidebar,
    closeRequestLoginModal,
    openRequestLoginModal,
    redirectToLoginFromPartialSession,
    toggleSidebar,
    goToDashboard,
    setSessionModalStatus
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default () => useContext(AppContext);
