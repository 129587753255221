import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  backdrop: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 100000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    overflow: "hidden",
    visibility: "hidden",
    opacity: 0,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  open: {
    visibility: "visible",
    opacity: 1,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  }
});

const Backdrop = React.forwardRef((props, ref) => {
  const { children, onClick, open, className } = props;

  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={classnames(classes.backdrop, className, {
        [classes.open]: open
      })}
      onClick={onClick}
      onKeyPress={onClick}
      role="tab"
      tabIndex="0"
    >
      {children}
    </div>
  );
});

Backdrop.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  className: PropTypes.string
};

Backdrop.defaultProps = {
  children: null,
  onClick: () => {},
  open: false,
  className: ""
};

export default Backdrop;
