import gql from "graphql-tag";
import {
  SESSION_AUTHORIZATION_INLINE_FRAGMENT,
  SHOPPER_SELECTOR_INLINE_FRAGMENT,
  MERCHANTS_PROMOTIONS_INLINE_FRAGMENT
} from "../fragments";

export const SHOPPER_QUERY = (...fragments) => {
  const query = gql`
    query {
      shopper {
        id
        ${fragments.map(({ name }) => `...${name}`)}
      }
    }
    ${fragments.map(({ entry }) => entry)}`;

  return query;
};

export const ORDERS_QUERY = (...fragments) => {
  const query = gql`
  query Orders($first: Int!, $after: ID, $filter_by: OrderFilterState) {
    shopper {
        id
        orders(first: $first, after: $after, filter_by: $filter_by) {
          ${fragments.map(({ name }) => `...${name}`)}
        }
      }
    }
    ${fragments.map(({ entry }) => entry)}`;

  return query;
};

export const ORDERS_PAGINATED_QUERY = (...fragments) => {
  const query = gql`
  query OrdersPaginated($limit: Int!, $offset: Int!, $state: OrderFilterState) {
      orders(limit: $limit, offset: $offset, filters: {state: $state} ) {
        nodes {
          ${fragments.map(({ name }) => `...${name}`)}
        }
        pageInfo {
          nodesCount
          limit
          offset
          hasPreviousPage
          hasNextPage
        }
      }
    }
    ${fragments.map(({ entry }) => entry)}`;

  return query;
};

export const MERCHANTS_PROMOTIONS_QUERY = gql`
  query MerchantsPromotions {
    shopper {
      id
      merchantsPromotions {
        ${MERCHANTS_PROMOTIONS_INLINE_FRAGMENT}
      }
    }
  }
`;

export const ORDER_QUERY = (...fragments) => {
  const query = gql`
    query Order($orderId: ID!) {
      shopper {
        id
        orderById(orderId: $orderId) {
          ${fragments.map(({ name }) => `...${name}`)}
        }
      }
    }
    ${fragments.map(({ entry }) => entry)}`;

  return query;
};

export const GET_SESSION_AUTHORIZATION = gql`
  query SessionAuthorization {
    sessionAuthorization {
      ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
    }
  }
`;

export const SELECTOR_BY_ID_AND_TYPE = gql`
  query SelectorByIdAndType($selectorId: ID!, $selectorType: Selector!) {
    shopper {
      id
      selectorByIdAndType(selectorId: $selectorId, selectorType: $selectorType) {
        ${SHOPPER_SELECTOR_INLINE_FRAGMENT}
      }
    }
    sessionAuthorization {
      __typename
    }
  }
`;

export const REFERRAL_SIGNUP_FIELD_VALIDATION = gql`
  query ReferralSignUpFieldValidation(
    $fieldType: ReferralSignUpField!
    $fieldValue: String!
  ) {
    referralSignUpFieldValidation(
      fieldType: $fieldType
      fieldValue: $fieldValue
    ) {
      fieldType
      valid
    }
  }
`;
