import getIconColor from "./getIconColor";
import getTypographyColorName from "./getTypographyColorName";
import typographicVariants, {
  createTypographicVariant
} from "./typographicVariants";
import colorPalette from "./colorPalette";

export {
  getIconColor,
  getTypographyColorName,
  typographicVariants,
  createTypographicVariant
};

export default {
  fontFamily: "Open Sans, sans-serif",
  palette: colorPalette,
  transitions: {
    default: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  breakpoints: {
    desktop: "@media(min-width:768px)",
    dashboard: "@media(min-width:1147px)"
  }
};
