import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import useTracking from "../../contexts/Tracking";
import { NoStore } from "../Icons";
import NewTabLink from "../NewTabLink";

const useStyles = createUseStyles(theme => ({
  merchant_logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "72px",
    height: "72px",
    borderRadius: "8px",
    marginRight: "16px",
    float: "left",
    border: ({ hasLogo }) =>
      hasLogo ? "none" : `2px solid ${theme.palette.secondary.default}`,
    "@media(min-width: 768px)": {
      marginRight: "24px"
    },
    "& img": {
      width: "100%",
      height: "100%"
    }
  },
  merchant_logo__link: {
    width: "72px",
    height: "72px",
    marginRight: "16px",
    "@media(min-width: 768px)": {
      marginRight: "24px"
    }
  }
}));

const MerchantLogo = ({
  logoClassName,
  linkClassName,
  merchantLogo,
  merchantName,
  merchantUrl
}) => {
  const classes = useStyles({ hasLogo: merchantLogo });
  const { track, eventNames } = useTracking();
  const merchantImage = (
    <div className={classnames(classes.merchant_logo, logoClassName)}>
      {merchantLogo ? (
        <img src={merchantLogo} alt={merchantName} />
      ) : (
        <NoStore width={39} height={34} />
      )}
    </div>
  );
  return merchantUrl !== "" ? (
    <NewTabLink
      className={classnames(classes.merchant_logo__link, linkClassName)}
      href={merchantUrl}
      onClick={() => {
        track(eventNames.orderDetail.clickMerchantLogo, {
          merchantName,
          merchantUrl
        });
      }}
    >
      {merchantImage}
    </NewTabLink>
  ) : (
    merchantImage
  );
};

MerchantLogo.propTypes = {
  logoClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  merchantLogo: PropTypes.string,
  merchantName: PropTypes.string,
  merchantUrl: PropTypes.string
};

MerchantLogo.defaultProps = {
  logoClassName: "",
  linkClassName: "",
  merchantLogo: "",
  merchantName: "",
  merchantUrl: ""
};

export default MerchantLogo;
