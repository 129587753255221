import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Sequra, SveaPoweredBySequra } from "../../atoms/Icons";
import { BarLoader } from "../../atoms/Loaders";
import { useDocumentTitle } from "../../hooks";
import { isMobile } from "../../utils";
import useShopperContext from "../../contexts/Shopper";

const useStyles = createUseStyles(theme => ({
  generic_template: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: ({ padded }) => (padded ? "0 8px" : "0px"),
    backgroundColor: theme.palette.background.default,
    "@media (min-width: 768px)": {
      padding: ({ padded }) => (padded ? "0 40px" : "0px")
    }
  },
  generic_template__icon: {
    padding: "16px 0",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    backgroundColor: theme.palette.white.default,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    "@media (min-width: 768px)": {}
  }
}));

const GenericTemplate = ({ children, title, loader, padded }) => {
  const classes = useStyles({ padded });
  const { shopper } = useShopperContext();

  useDocumentTitle(title);

  return (
    <div className={classes.generic_template}>
      {loader && <BarLoader />}
      <div className={classes.generic_template__icon}>
        {shopper.realm === "svea" ? (
          <SveaPoweredBySequra
            width={isMobile.phone ? 140 : 180}
            height={isMobile.phone ? 30 : 40}
          />
        ) : (
          <Sequra width={120} height={40} />
        )}
      </div>
      {children}
    </div>
  );
};

GenericTemplate.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  loader: PropTypes.bool,
  padded: PropTypes.bool
};

GenericTemplate.defaultProps = {
  children: null,
  title: "",
  loader: false,
  padded: true
};

export default GenericTemplate;
