import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import useTrackingContext from "../../contexts/Tracking";
import { usePaymentPlanTokenizationGateway } from "../../hooks";
import {
  getMufasaActionFromEvent,
  Mufasa3DSIframe,
  MufasaResponseMessages
} from "../Mufasa3DS";
import { ScaleLoader } from "../../atoms/Loaders";
import useMufasaReducer, {
  MufasaActions
} from "../../hooks/reducers/useMufasaReducer";
import ReadOnlySessionMufasaIframeMessage from "../ReadOnlySessionMufasaIframeMessage";

const useStyles = createUseStyles({
  mufasa: {
    display: "flex",
    alignItems: ({ error, loading }) => (error || loading ? "center" : "unset"),
    justifyContent: ({ error, loading }) =>
      error || loading ? "center" : "unset",
    position: "relative",
    margin: ({ loading }) => (loading ? "40px 0" : "20px 0"),
    minHeight: "auto",
    "& > iframe": {
      flex: 1
    }
  }
});

const MufasaPaymentPlanTokenizationGateway = ({
  orderId,
  onSuccess,
  onSubmit,
  onError,
  onClose
}) => {
  const iframeRef = React.useRef(null);
  const [{ loading, error, height }, dispatch] = useMufasaReducer();
  const classes = useStyles({ loading, error });
  const { track } = useTrackingContext();
  const { iframeUrl, error: queryError } = usePaymentPlanTokenizationGateway(
    orderId
  );

  React.useEffect(() => {
    const { current } = iframeRef;
    if (current) current.style.height = `${height}px`;
  }, [height]);

  React.useEffect(() => {
    if (queryError) {
      onError();
    }
  }, [queryError]);

  const mufasaEventHandler = event => {
    const action = getMufasaActionFromEvent(event);
    switch (action.mufasaResponseMessage) {
      case MufasaResponseMessages.mufasa_resized:
        dispatch({
          type: MufasaActions.resize,
          payload: { height: action.payload.height }
        });
        break;
      case MufasaResponseMessages.payment_successful:
        dispatch({
          type: MufasaActions.loaded
        });
        onSuccess();
        break;
      case MufasaResponseMessages.payment_failed:
        dispatch({
          type: MufasaActions.error
        });
        onError();
        break;
      case MufasaResponseMessages.mufasa_submitted:
        dispatch({
          type: MufasaActions.submit
        });
        onSubmit();
        break;
      case MufasaResponseMessages.authentication_3ds_closed:
        onClose();
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.mufasa}>
      {loading && <ScaleLoader />}
      <ReadOnlySessionMufasaIframeMessage />
      {iframeUrl && (
        <Mufasa3DSIframe
          iframeRef={iframeRef}
          mufasaUrl={iframeUrl}
          handleIframeEvent={mufasaEventHandler}
          handleTrackEvent={track}
        />
      )}
    </div>
  );
};

MufasaPaymentPlanTokenizationGateway.propTypes = {
  orderId: PropTypes.string,
  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func
};

MufasaPaymentPlanTokenizationGateway.defaultProps = {
  orderId: "",
  onSuccess: () => {},
  onSubmit: () => {},
  onError: () => {},
  onClose: () => {}
};

export default MufasaPaymentPlanTokenizationGateway;
