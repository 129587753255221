import { useIntl } from "react-intl";
import locales from "../../locales";

export default ({ paymentStatus, isOverdue, isInvoice } = {}) => {
  const intl = useIntl();

  const status = {
    text: isInvoice
      ? intl.formatMessage(locales.pendingPayment)
      : intl.formatMessage(locales.scheduled),
    chipType: "primary"
  };

  if (paymentStatus === "PAID") {
    status.text = intl.formatMessage(locales.paid);
    status.chipType = "success";
  } else if (isOverdue) {
    status.text = intl.formatMessage(locales.delayed);
    status.chipType = "error";
  }

  return status;
};
