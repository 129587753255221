import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const OkPurchase = ({ width, height, color }) => (
  <svg width={width} height={height}>
    <path
      fill="#fff"
      d="M126.755 22.407s5.58-8.494 5.163-11.492c-.416-2.998-2.165-1.333-2.165-1.333"
    />
    <path
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M126.755 22.407s5.58-8.494 5.163-11.492c-.416-2.998-2.165-1.333-2.165-1.333"
    />
    <path
      fill="#fff"
      d="M131.751 12.997s1.166-.666 1.582 1.082c.416 1.75-1.915 8.911-6.662 14.325-1.583 1.832-11.41 17.155-20.987 21.902-9.66 4.664-8.411 3.998-8.411 3.998s-1.416-12.576-3.664-14.99c0 0 15.156-7.413 22.318-14.741 0 0 .833-.75 1.5-2.499.666-1.749 1.832-7.578 2.331-7.911.5-.333 1.499-1 1.916.333.416 1.332-.25 5.746-.25 5.746s4.913-9.66 6.163-10.66c1.249-1 2.082-1 2.248 0 .167 1-4.33 9.66-5.496 11.493"
    />
    <path
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M131.751 12.997s1.166-.666 1.582 1.082c.416 1.75-1.915 8.911-6.662 14.325-1.583 1.832-11.41 17.155-20.987 21.902-9.66 4.664-8.411 3.998-8.411 3.998s-1.416-12.576-3.664-14.99c0 0 15.156-7.413 22.318-14.741 0 0 .833-.75 1.5-2.499.666-1.749 1.832-7.578 2.331-7.911.5-.333 1.499-1 1.916.333.416 1.332-.25 5.746-.25 5.746s4.913-9.66 6.163-10.66c1.249-1 2.082-1 2.248 0 .167 1-4.33 9.66-5.496 11.493"
    />
    <path
      fill="#fff"
      d="M5.25 22.407c.083.417.416 6.163 1.416 8.745 1 2.498 3.081 6.329 3.58 7.412.5 1.082 3.915 11.576 7.496 17.405 3.581 5.83 11.493 11.41 17.489 6.163s8.411-5.996 8.91-13.658c.5-7.662.917-17.988.917-17.988S34.065 50.14 29.65 48.64c-4.414-1.5-8.161-8.745-8.411-13.075-.167-1.5-.666-7.496-.5-8.412.167-.916 1.083-2.748 2.832-3.997 1.748-1.25 1.166-1.916.333-2.332-1.916-.833-3.831 1.582-4.33 2.082-.5.5-1.916 1.666-1.916.75 0-.916-1-5.58-1.416-5.913"
    />
    <path
      stroke="#1C1C1C"
      fill="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.25 22.407c.083.417.416 6.163 1.416 8.745 1 2.498 3.081 6.329 3.58 7.412.5 1.082 3.915 11.576 7.496 17.405 3.581 5.83 11.493 11.41 17.489 6.163s8.411-5.996 8.91-13.658c.5-7.662.917-17.988.917-17.988S34.065 50.14 29.65 48.64c-4.414-1.5-8.161-8.745-8.411-13.075-.167-1.5-.666-7.496-.5-8.412.167-.916 1.083-2.748 2.832-3.997 1.748-1.25 1.166-1.916.333-2.332-1.916-.833-3.831 1.582-4.33 2.082-.5.5-1.916 1.666-1.916.75 0-.916-1-5.58-1.416-5.913"
    />
    <path
      fill="#fff"
      d="M9.833 30.235c-3.665 1.583-4.414-7.744-4.414-7.744s.916-1.416 2.332-1c0 0 1.665-2.332 2.998-1.499 0 0 1.249-1.582 2.581-1.332 0 0 1.75-1.666 2.915-.75l.333.833 1.083 6.412c.166.75.166 1.75-.417 2.249-.582.5-1.332.75-1.832.416"
    />
    <path
      stroke="#1C1C1C"
      fill="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9.833 30.235c-3.665 1.583-4.414-7.744-4.414-7.744s.916-1.416 2.332-1c0 0 1.665-2.332 2.998-1.499 0 0 1.249-1.582 2.581-1.332 0 0 1.75-1.666 2.915-.75l.333.833 1.083 6.412c.166.75.166 1.75-.417 2.249-.582.5-1.332.75-1.832.416M126.755 22.407s5.58-8.494 5.163-11.492c-.416-2.998-2.165-1.333-2.165-1.333"
    />
    <path
      stroke="#1C1C1C"
      fill="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M131.751 12.997s1.166-.666 1.582 1.082c.416 1.75-1.915 8.911-6.662 14.325-1.583 1.832-11.41 17.155-20.987 21.902-9.66 4.664-8.411 3.998-8.411 3.998s-1.416-12.576-3.664-14.99c0 0 15.156-7.413 22.318-14.741 0 0 .833-.75 1.5-2.499.666-1.749 1.832-7.578 2.331-7.911.5-.333 1.499-1 1.916.333.416 1.332-.25 5.746-.25 5.746s4.913-9.66 6.163-10.66c1.249-1 2.082-1 2.248 0 .167 1-4.33 9.66-5.496 11.493M8.159 21.991s1.083 7.745 1.915 8.661c.833.916 2.416.833 2.665-.5.25-1.332-.666-4.497-.832-5.996a20.17 20.17 0 0 0-.75-3.58"
    />
    <path
      stroke="#1C1C1C"
      fill="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m13.915 19.492 1.499 8.328s.166 1.666-.75 1.916-1.499-.333-1.915-.666"
    />
    <path
      fill="#fff"
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M64.213 107.52s12.242 1.665 16.323-11.077c4.08-12.742 1.166-21.819 1.166-21.819l19.404-.583s.25 30.98-7.828 37.809c-8.079 6.829-26.234 9.577-26.234 9.577l-2.831-13.907Z"
    />
    <path
      fill="#1C1C1C"
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m64.215 107.519-9.244.916s-4.081.833-3.498 4.248c.583 3.414 6.33 20.82 6.33 20.82s3.414 4.913 4.663-.667c1.25-5.579-.916-9.91 1.416-10.576 2.331-.666 3.081-.916 3.081-.916l-2.831-13.908.083.083Z"
    />
    <path
      fill="#fff"
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M27.989 110.268s12.242 1.665 16.323-11.077c4.08-12.742 1.166-21.819 1.166-21.819l19.404-.583s.25 30.98-7.829 37.809c-8.078 6.829-26.233 9.577-26.233 9.577l-2.831-13.907Z"
    />
    <path
      fill="#1C1C1C"
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m27.99 110.268-9.243.916s-4.081.832-3.498 4.247c.583 3.414 6.33 20.82 6.33 20.82s3.414 4.913 4.663-.666c1.25-5.58-.916-9.911 1.416-10.577 2.331-.666 3.081-.916 3.081-.916l-2.832-13.908.084.084Z"
    />
    <path
      fill={color}
      stroke="#1C1C1C"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M48.474 18.41h47.969s3.248-.666 3.581 5.58c.416 6.245 5.496 50.884 5.496 50.884s1.416 3.747-2.332 3.83c-3.747.084-61.294 0-61.294 0s-2.914.5-2.415-3.997c.5-4.497 5.33-54.049 5.33-54.049s0-2.332 3.665-2.332v.084Z"
    />
    <path
      stroke="#1C1C1C"
      fill="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M58.886 19.742S57.72 3.17 71.045 1.837C84.286.587 85.702 13.08 86.368 19.742"
    />
    <path
      fill="#fff"
      stroke="#1C1C1C"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M89.448 36.815c-.416-1-1.332-1.666-2.332-1.666-1 0-1.166.25-1.665.666-1.5 1.333-2.998 2.665-4.498 3.915-5.496 4.83-11.16 9.743-16.073 15.406-1.082-1.249-2.665-2.831-5.08-4.58-.5-.333-.999-.5-1.582-.5-.833 0-1.666.417-2.249 1.166-.832 1.25-.583 2.915.583 3.831 4.081 2.915 4.914 4.747 4.997 4.997.25 1 1 1.666 1.916 1.999.25.083.583.166.916.166.832 0 1.499-.333 2.082-1C71.709 54.72 78.039 49.14 84.2 43.81c1.5-1.249 2.915-2.581 4.414-3.914.916-.75 1.166-1.915.75-2.998l.083-.083Z"
    />
  </svg>
);

OkPurchase.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

OkPurchase.defaultProps = {
  width: 140,
  height: 140,
  color: theme.palette.brand.default
};

export default OkPurchase;
