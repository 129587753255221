import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import locales from "../../../../../locales";
import { Button, NextModal, Typography } from "../../../../../atoms";
import MerchantCard from "../MerchantCard";
import K from "../../../../../constants";
import { Like, Telescope } from "../../../../../atoms/Illustrations";

const useStyles = createUseStyles(theme => ({
  modal: {
    position: "relative",
    maxHeight: "none",
    height: "auto",
    maxWidth: 514,
    borderRadius: 8,
    margin: "0 16px",
    top: "0 !important",
    paddingBottom: 0,
    "@media(min-width: 768px)": {
      maxWidth: "none",
      width: "994px"
    }
  },
  modal__content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 20,
    padding: "0 16px",
    "@media(min-width: 768px)": {
      flex: 0,
      marginTop: 0,
      padding: "0 48px"
    },
    "& p": {
      marginBottom: "24px",
      "& span": {
        lineHeight: "normal"
      }
    }
  },
  modal__content__title: {
    margin: "24px  0 8px",
    "& strong": {
      color: theme.palette.success.default
    }
  },
  modal__content__subtitle: {
    display: "block"
  },
  modal__content__footer: {
    width: "343px",
    margin: "0 auto",
    textAlign: "center"
  },
  modal__action: {
    width: "216px",
    margin: "24px auto 0 auto"
  },
  merchants_list: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: "16px 0 56px 0",
    "& li": {
      flexBasis: "100%",
      height: "110px",
      overflow: "hidden",
      marginBottom: "16px",
      "@media(min-width: 768px)": {
        flexBasis: "49%"
      },
      "& a": {
        textDecoration: "none"
      }
    }
  },
  next_purchases__footer__back_btn: {
    marginTop: 16
  },
  merchants_sector_list: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "16px",
    "& li": {
      marginRight: "8px",
      marginBottom: "16px",
      padding: "6px 16px",
      border: `1px solid ${theme.palette.shade.default}`,
      borderRadius: "30px",
      cursor: "pointer"
    },
    "& li.active": {
      border: `1px solid ${theme.palette.success.default}`,
      backgroundColor: theme.palette.success.medium
    }
  }
}));

const MerchantsWithoutCostModal = ({
  merchants,
  onClick,
  sectorSelected,
  onClickSeeAllShops
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  return (
    <NextModal
      open
      hideBack
      withIconClose
      modalClassname={classes.modal}
      withFullWidth
      withSpecificWidth="940px"
    >
      <div className={classes.modal__content}>
        <Like />
        <div data-testid="modal_title">
          <Typography
            variant={K.typographicVariants.heading1}
            color={theme.palette.primary.default}
            className={classes.modal__content__title}
          >
            {intl.formatMessage(locales.merchants_without_cost_modal_title)}
          </Typography>
        </div>
        <ul className={classes.merchants_sector_list}>
          {K.merchantsSector.map(sector => (
            <li
              key={sector}
              role="tab"
              onClick={() => onClick(sector)}
              onKeyPress={onClick}
              className={sectorSelected === sector ? "active" : ""}
            >
              {intl.formatMessage({
                id: `merchants_without_cost_modal_sector_${sector.replace(
                  /&/g,
                  "_"
                )}`
              })}
            </li>
          ))}
        </ul>

        <ul className={classes.merchants_list}>
          {merchants.map(merchant => (
            <li key={merchant.id}>
              <a href={merchant.link} target="_blank" rel="noreferrer">
                <MerchantCard
                  title={merchant.name}
                  content={merchant.description}
                  logo={merchant.logo}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className={classes.modal__content__footer}>
        <Telescope />
        <Typography
          variant={K.typographicVariants.heading3}
          color={theme.palette.primary.default}
          className={classes.modal__content__title}
        >
          {intl.formatMessage(
            locales.merchants_without_cost_modal_footer_title
          )}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.secondary.default}
          className={classes.modal__content__subtitle}
        >
          {intl.formatMessage(
            locales.merchants_without_cost_modal_footer_subtitle
          )}
        </Typography>
        <Button
          size="small"
          onClick={onClickSeeAllShops}
          variant="outlined"
          className={classes.modal__action}
        >
          {intl.formatMessage(
            locales.merchants_without_cost_modal_footer_action
          )}
        </Button>
      </div>
    </NextModal>
  );
};

MerchantsWithoutCostModal.propTypes = {
  merchants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      reference: PropTypes.string,
      description: PropTypes.string,
      logo: PropTypes.string,
      sector: PropTypes.string,
      link: PropTypes.string
    })
  ),
  onClick: PropTypes.func.isRequired,
  sectorSelected: PropTypes.string,
  onClickSeeAllShops: PropTypes.func.isRequired
};

MerchantsWithoutCostModal.defaultProps = {
  merchants: [],
  sectorSelected: ""
};

export default MerchantsWithoutCostModal;
