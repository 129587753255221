import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(theme => ({
  progressBar: {
    display: "flex",
    "& > span": {
      position: "relative",
      flex: "1 1 auto",
      height: "2px",
      backgroundColor: theme.palette.shade.default,
      "&::after": {
        content: "''",
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "0",
        display: "inline-block",
        width: "2px",
        backgroundColor: "white"
      },
      "&.active": {
        backgroundColor: theme.palette.secondary.default
      },
      "&.completed": {
        backgroundColor: theme.palette.secondary.default,
        "&::after": {
          backgroundColor: theme.palette.secondary.default
        }
      }
    }
  }
}));

const ProgressBar = ({ numberOfSteps, currentStep = 0 }) => {
  const classes = useStyles();
  const getClassName = index => {
    if (index === currentStep) {
      return "active";
    }
    if (index < currentStep) {
      return "completed";
    }
    return undefined;
  };

  return (
    <div className={classes.progressBar} data-testid="progress_bar">
      {Array(numberOfSteps)
        .fill("")
        .map((_, index) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={getClassName(index)}
          />
        ))}
    </div>
  );
};

ProgressBar.propTypes = {
  numberOfSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired
};

export default ProgressBar;
