import { useEffect } from "react";
import { useSetState } from "react-use";
import useMerchantsPromotions from "./queries/useMerchantsPromotions";

export const initialState = {
  merchantsPromotions: {},
  fetching: true,
  error: null
};

export default () => {
  const [state, setState] = useSetState(initialState);
  const [fetchMerchantsPromotion] = useMerchantsPromotions({
    onCompleted: data => {
      const merchantsPromotions = data?.shopper.merchantsPromotions;
      setState({
        merchantsPromotions:
          Object.entries(merchantsPromotions).length > 0
            ? merchantsPromotions
            : {},
        fetching: false
      });
    },
    onError: error => {
      setState({
        error: error.message,
        fetching: false
      });
    }
  });

  useEffect(() => {
    fetchMerchantsPromotion();
  }, []);

  return state;
};
