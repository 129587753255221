import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import classnames from "classnames";
import baseTheme from "../../theme";
import { Paper, Typography } from "../../atoms";
import { NavigationArrowRight } from "../../atoms/Icons";

import K from "../../constants";

const BACKGROUND_AND_BORDER_COLORS = {
  outstanding: {
    success: {
      backgroundColor: baseTheme.palette.success.medium,
      borderColor: baseTheme.palette.success.medium
    },
    info: {
      backgroundColor: baseTheme.palette.info.medium,
      borderColor: baseTheme.palette.info.medium
    },
    warning: {
      backgroundColor: baseTheme.palette.warning.medium,
      borderColor: baseTheme.palette.warning.medium
    },
    error: {
      backgroundColor: baseTheme.palette.error.medium,
      borderColor: baseTheme.palette.error.medium
    }
  },
  default: {
    success: {
      backgroundColor: baseTheme.palette.success.light,
      borderColor: baseTheme.palette.success.medium
    },
    info: {
      backgroundColor: baseTheme.palette.info.light,
      borderColor: baseTheme.palette.info.medium
    },
    warning: {
      backgroundColor: baseTheme.palette.warning.light,
      borderColor: baseTheme.palette.warning.medium
    },
    error: {
      backgroundColor: baseTheme.palette.error.light,
      borderColor: baseTheme.palette.error.medium
    }
  }
};

const backgroundAndBorderColor = ({ type, outstanding }) => {
  if (outstanding) {
    return BACKGROUND_AND_BORDER_COLORS.outstanding[type];
  }

  return BACKGROUND_AND_BORDER_COLORS.default[type];
};

const useStyles = createUseStyles({
  message_box: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    border: ({ borderColor }) => `1px solid ${borderColor}`,
    padding: ({ outstanding }) => (outstanding ? "16px" : "8px")
  },
  message_box__header: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginRight: "10px"
    }
  },
  message_box__title: {},
  message_box__text: {},
  message_box__link: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "8px",
    textAlign: "right",
    textDecoration: "none"
  }
});

const MessageBox = ({
  title,
  text,
  link,
  linkText,
  type,
  icon,
  testid,
  outstanding,
  className
}) => {
  const classes = useStyles({
    outstanding,
    ...backgroundAndBorderColor({ type, outstanding })
  });
  const classNames = classnames(classes.message_box, className);
  const theme = useTheme();
  return (
    <>
      <Paper className={classNames} data-testid={testid}>
        <div className={classes.message_box__header}>
          {icon && icon}
          {title && (
            <Typography
              variant={K.typographicVariants.heading4}
              className={classes.message_box__title}
            >
              {title}
            </Typography>
          )}
        </div>

        <Typography
          variant={
            outstanding
              ? K.typographicVariants.body2
              : K.typographicVariants.body3
          }
          className={classes.message_box__text}
        >
          {text}
        </Typography>

        {link && (
          <Link to={link} className={classes.message_box__link}>
            <Typography
              variant={K.typographicVariants.linkS}
              className={classes.message_box__text}
            >
              {linkText}
            </Typography>
            <NavigationArrowRight color={theme.palette.primary.default} />
          </Link>
        )}
      </Paper>
    </>
  );
};

MessageBox.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  text: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired
  ]),
  link: PropTypes.string,
  linkText: PropTypes.string,
  type: PropTypes.oneOf(["success", "info", "warning", "error"]),
  icon: PropTypes.node,
  outstanding: PropTypes.bool,
  testid: PropTypes.string
};

MessageBox.defaultProps = {
  className: undefined,
  title: undefined,
  link: undefined,
  linkText: undefined,
  icon: undefined,
  type: "error",
  testid: "",
  outstanding: false
};

export default MessageBox;
