import transformRentingPlan from "../transformRentingPlan";

const getOverdue = entry => entry.isOverdue;
const getUnpaid = entry => entry.paymentStatus === "UNPAID" && !entry.isOverdue;
const getPaid = entry => entry.paymentStatus === "PAID";

export default ({ order }) => {
  let isOverdue = false;
  let isPaid = false;
  let isPartiallyPaid = false;
  let canBeReturned = false;

  const payments = {};

  const isCancelled = order.currentOrderValue === 0;
  const isConvertible = order.conversionPlans?.length > 0 || false;
  const isAmortizable = !!order.amortization;
  const isCampaign = order.currentProductName === "pp5";

  const hasExpiredCard = order.cardReference?.isExpired || false;
  const hasExpiringSoonCard = order.cardReference?.isExpiringSoon || false;
  const hasAutoPaymentEnabled = !order.hasAutoPaymentDisabled;

  const canBeConverted = order.conversionPlans?.length > 0;

  let canChangeCard =
    order.currentProductName === "pp10"
      ? order.paymentPlan.balance > 0
      : /^[s|p]?[p|r]\d+$/.test(order.currentProductName);

  let canBePaid =
    /^i\d+$/.test(order.currentProductName) &&
    (order.invoices.some(getOverdue) || order.invoices.some(getUnpaid));

  const isPartiallyShipped =
    order.shippedCart?.items?.length > 0 &&
    order.unshippedCart?.items?.length > 0;
  const isShipped =
    order.shippedCart.items.length > 0 &&
    order.unshippedCart.items.length === 0;
  const isUnshipped = !isShipped && !isPartiallyShipped;

  const cart = [
    ...order.shippedCart.items.map(item => ({ ...item, shipped: true })),
    ...order.unshippedCart.items.map(item => ({ ...item, shipped: false }))
  ];

  let paidOn = "";
  let paidAmount = 0;
  let unpaidAmount = 0;
  let overdueAmount = 0;
  let overpaidAmount = 0;
  let unpaidPlanAmount = 0;
  let unpaidFeesAmount = 0;
  let amortizationAmount = 0;
  let unpaidAmountTotal = 0;

  if (/^i\d+$/.test(order.currentProductName)) {
    if (!isCancelled) {
      isOverdue = order.invoices.some(getOverdue);
      isPaid =
        !isOverdue &&
        !order.invoices.some(getUnpaid) &&
        !isPartiallyShipped &&
        !isUnshipped;
      canBePaid = isOverdue || canBePaid;
    }

    if (isCancelled || isPaid) {
      canChangeCard = false;
      canBePaid = false;
    }

    if (isPaid) {
      const invoicesWithoutNullPaidOn = order.invoices.filter(
        invoice => invoice.paidOn != null
      );
      paidOn =
        invoicesWithoutNullPaidOn[invoicesWithoutNullPaidOn.length - 1].paidOn;
    }

    if (isUnshipped) {
      canBePaid = false;
    }

    const overdueInvoices = order.invoices.filter(getOverdue);
    const unpaidInvoices = order.invoices.filter(getUnpaid);
    const paidInvoices = order.invoices.filter(getPaid);

    isPartiallyPaid =
      paidInvoices.length > 0 &&
      (unpaidInvoices.length > 0 ||
        overdueInvoices.length > 0 ||
        order.unshippedCart.items.length > 0);

    paidAmount = order.invoices.reduce((acc, invoice) => {
      return acc + invoice.paidAmount;
    }, 0);

    overdueAmount = overdueInvoices.reduce(
      (acc, invoice) => acc + invoice.unpaidAmount,
      0
    );

    unpaidAmount = order.invoices.reduce(
      (acc, invoice) => acc + invoice.unpaidAmount,
      0
    );

    canBeReturned = order.invoices.some(invoice => invoice.canBeReturned);

    Object.assign(payments, {
      overdueInvoices,
      unpaidInvoices,
      paidInvoices,
      count: order.invoices.length,
      overdueCount: overdueInvoices.length,
      unpaidCount: unpaidInvoices.length,
      paidCount: paidInvoices.length
    });
  } else {
    const paymentPlan =
      order.currentProductName === "r1"
        ? transformRentingPlan(order.rentingPlan)
        : order.paymentPlan;

    const instalments = [
      ...paymentPlan.pastInstalments,
      ...paymentPlan.futureInstalments
    ];

    if (!isCancelled) {
      isOverdue = paymentPlan.balance < 0;
      isPaid = !isOverdue && !instalments.some(getUnpaid);
      canBePaid = isOverdue || canBePaid;
    }

    if (isCancelled || isPaid) {
      canChangeCard = false;
      canBePaid = false;
    }

    if (isPaid) {
      paidOn =
        order.currentProductName === "pp10"
          ? instalments[instalments.length - 1].dueOn
          : instalments[instalments.length - 1].paidOn;
    }

    const overdueInstalments = instalments.filter(getOverdue);
    const unpaidInstalments = instalments.filter(getUnpaid);
    const paidInstalments = instalments.filter(getPaid);

    paidAmount = paidInstalments.reduce(
      (acc, instalment) => acc + instalment.paidAmount,
      0
    );

    overdueAmount = isOverdue ? -paymentPlan?.balance : 0;

    unpaidAmount = paymentPlan?.unchargedOrderValue;

    overpaidAmount = paymentPlan?.balance > 0 ? paymentPlan?.balance : 0;

    unpaidPlanAmount = unpaidAmount;

    unpaidFeesAmount = [...overdueInstalments, ...unpaidInstalments].reduce(
      (acc, instalment) =>
        acc +
        instalment[
          order.currentProductName === "r1"
            ? "lateInterestFeesAmount"
            : "instalmentFee"
        ],
      0
    );

    unpaidAmountTotal = paymentPlan?.futureInstalments.reduce(
      (acc, futureInstalment) => acc + futureInstalment.instalmentTotal,
      0
    );

    if (isAmortizable) {
      amortizationAmount = order.amortization.total;
    }

    Object.assign(payments, {
      overdueInstalments,
      unpaidInstalments,
      paidInstalments,
      instalments,
      paymentPlan,
      count: instalments.length,
      overdueCount: overdueInstalments.length,
      unpaidCount: unpaidInstalments.length,
      paidCount: paidInstalments.length
    });
  }

  unpaidAmount -= overpaidAmount;

  const paymentsCountInfo = {
    count: payments.count,
    overdueCount: payments.overdueCount,
    unpaidCount: payments.unpaidCount,
    paidCount: payments.paidCount
  };

  delete payments.count;
  delete payments.overdueCount;
  delete payments.unpaidCount;
  delete payments.paidCount;

  return {
    isOverdue,
    isPaid,
    isPartiallyPaid,
    isCancelled,
    isConvertible,
    isAmortizable,
    isCampaign,
    hasExpiredCard,
    hasExpiringSoonCard,
    hasAutoPaymentEnabled,
    canBeConverted,
    canChangeCard,
    canBePaid,
    isPartiallyShipped,
    isShipped,
    isUnshipped,
    cart,
    paidOn,
    paidAmount,
    unpaidAmount,
    overdueAmount,
    unpaidPlanAmount,
    unpaidAmountTotal,
    unpaidFeesAmount,
    amortizationAmount,
    canBeReturned,
    payments: {
      ...paymentsCountInfo
    },
    ...order,
    ...payments
  };
};
