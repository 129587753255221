import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import useSessionAuthorizationContext from "../../contexts/SessionAuthentication";
import { PROCESS_SHORTENED_LINK } from "../../graphql";
import { NoMatchPage } from "../../components";
import { BarLoader } from "../../atoms/Loaders";
import { shortenedLinks } from "../../utils";
import { LOGIN_PATH } from "../../routes";
import useTrackingContext from "../../contexts/Tracking";

const Token = () => {
  const { token } = useParams();
  const history = useHistory();
  const { track, eventNames } = useTrackingContext();

  const { updateSession } = useSessionAuthorizationContext();
  const [error, setError] = React.useState(false);
  const [processShortenedLink] = useMutation(PROCESS_SHORTENED_LINK, {
    onCompleted: ({
      processShortenedLink: {
        __typename,
        shortenedLinkAction,
        sessionAuthorization
      }
    }) => {
      if (shortenedLinks.isAuthorized({ __typename })) {
        const url = shortenedLinks.getRedirectionUrlFromShortenedLinkAction(
          shortenedLinkAction
        );
        updateSession(sessionAuthorization);

        const eventName = shortenedLinks.buildTrackingEventName(
          shortenedLinkAction,
          eventNames
        );
        if (eventName) track(eventName);

        history.replace(url, shortenedLinkAction);
      } else if (
        sessionAuthorization?.__typename !== "FullSession" &&
        (shortenedLinks.isInvalid({ __typename }) ||
          shortenedLinks.isExpired({ __typename }))
      ) {
        updateSession(sessionAuthorization);
        history.replace(LOGIN_PATH);
      } else {
        setError(true);
      }
    }
  });

  React.useEffect(() => {
    processShortenedLink({ variables: { code: token } });
  }, []);

  if (error) return <NoMatchPage />;

  return <BarLoader />;
};

export default Token;
