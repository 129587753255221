import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Heart = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <path
      d="M11.918 4.6c-1.76-2.06-5.02-2.91-7.66-1.1-1.4.96-2.28 2.58-2.34 4.29-.14 3.88 3.3 6.99 8.55 11.76l.1.09c.76.69 1.93.69 2.69-.01l.11-.1c5.25-4.76 8.68-7.87 8.55-11.75-.06-1.7-.94-3.32-2.34-4.28-2.64-1.8-5.9-.96-7.66 1.1Z"
      fill={color}
    />
  </svg>
);

Heart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Heart.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Heart;
