import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import classnames from "classnames";
import Label from "../Label";
import K from "../../constants";
import locales from "../../locales";

const useStyles = createUseStyles({
  product_label: {
    display: "block",
    marginLeft: "-3px",
    width: ({ width }) => width
  }
});

const ProductLabel = ({ className, product }) => {
  const intl = useIntl();
  let color;
  let title;
  let width;
  switch (product) {
    case K.products.pp3:
      color = K.colors.info;
      title = intl.formatMessage(locales.product_pp3);
      width = 125;
      break;
    case K.products.sp1:
      color = K.colors.warning;
      title = intl.formatMessage(locales.product_sp1);
      width = 86;
      break;
    case K.products.i1:
      color = K.colors.success;
      title = intl.formatMessage(locales.product_i1);
      width = 104;
      break;
    case K.products.pp5:
      color = K.colors.shade;
      title = intl.formatMessage(locales.product_pp5);
      width = 110;
      break;
    default:
      break;
  }
  const classes = useStyles({ width });
  return (
    <div className={classnames(classes.product_label, className)}>
      <Label title={title} color={color} />
    </div>
  );
};

ProductLabel.propTypes = {
  className: PropTypes.string,
  product: PropTypes.string.isRequired
};

ProductLabel.defaultProps = {
  className: ""
};

export default ProductLabel;
