import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Cursor = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M21.75 12.812a1.247 1.247 0 0 0-.792-1.135L10.57 7.693a1.834 1.834 0 0 0-2 .452 1.987 1.987 0 0 0-.438 2.067l3.844 10.72a1.212 1.212 0 0 0 1.14.818c.483 0 .917-.293 1.112-.754l1.063-2.523 2.305 2.384a1.647 1.647 0 0 0 2.38 0l.89-.919c.318-.328.493-.765.493-1.23 0-.465-.175-.901-.493-1.23l-2.276-2.353 2.44-1.126c.453-.21.735-.675.72-1.187zm-4.505 1.512a.646.646 0 0 0-.362.474.664.664 0 0 0 .173.577l2.922 3.02a.452.452 0 0 1 0 .625l-.89.919a.417.417 0 0 1-.604 0l-2.957-3.057a.616.616 0 0 0-1.02.2l-1.39 3.297L9.31 9.76a.667.667 0 0 1 .148-.698.641.641 0 0 1 .447-.197c.07 0 .146.013.228.045l10.291 3.946-3.18 1.467zm3.277-1.43.218-.609-.218.61zM7.38 6.471 5.563 4.654a.643.643 0 0 0-.909.91L6.471 7.38a.64.64 0 0 0 .909 0 .643.643 0 0 0 0-.91zM5.573 8.455H2.915a.665.665 0 1 0 0 1.33h2.658a.665.665 0 1 0 0-1.33zm3.99-6.205a.665.665 0 0 0-.665.665v2.658a.665.665 0 1 0 1.33 0V2.915a.665.665 0 0 0-.665-.665zm4.908 1.075a.643.643 0 0 0-.91 0L11.746 5.14a.643.643 0 0 0 .91.91l1.816-1.817a.643.643 0 0 0 0-.91zm-7.534 7.977a.643.643 0 0 0-.91 0l-1.816 1.816a.643.643 0 0 0 .91.91l1.816-1.817a.643.643 0 0 0 0-.91z"
    />
  </svg>
);

Cursor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Cursor.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Cursor;
