import React, { useEffect, createContext, useContext } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

const environment = process.env.NODE_ENV;
const isDevelopment = environment === "development";
const MonitoringContext = createContext();

export const MonitoringContextProvider = ({ children }) => {
  useEffect(() => {
    Sentry.init({
      debug: isDevelopment,
      environment: window.env.sentry.environment,
      dsn: window.env.sentry.dsn,
      integrations: [new Sentry.BrowserTracing()],
      sampleRate: window.env.sentry.sampleRate,
      tracesSampleRate: window.env.sentry.tracesSampleRate,
      enabled: !isDevelopment
    });
  }, []);

  const contextValue = {
    monitoringSetContext: contextData => {
      Sentry.setContext("context", contextData);
    },
    monitoringNotify: (error, info) => {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        Sentry.captureException(error);
      });
    }
  };

  return (
    <MonitoringContext.Provider value={contextValue}>
      {children}
    </MonitoringContext.Provider>
  );
};

MonitoringContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default () => useContext(MonitoringContext);
