import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Typography from "../Typography";
import K from "../../constants";

const useStyles = createUseStyles({
  calendar: {
    width: "93px",
    height: "93px",
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    "& > :last-child": {
      textTransform: "capitalize"
    }
  }
});

const Calendar = ({ day, month, color, backgroundColor }) => {
  const classes = useStyles({ color, backgroundColor });

  return (
    <div className={classes.calendar}>
      <Typography color={color} variant={K.typographicVariants.visual1}>
        {day}
      </Typography>
      <Typography color={color} variant={K.typographicVariants.heading4}>
        {month}
      </Typography>
    </div>
  );
};

Calendar.propTypes = {
  day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  month: PropTypes.string,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired
};

Calendar.defaultProps = {
  day: 0,
  month: ""
};

export default Calendar;
