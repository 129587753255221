import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { withModal } from "../../../../hocs";
import { Check } from "../../../../atoms/Illustrations";
import { Button, Typography } from "../../../../atoms";
import K from "../../../../constants";
import locales from "../../../../locales";

const useStyles = createUseStyles({
  check: {
    marginBottom: "20px"
  },
  modal__content: {
    marginTop: "1em",
    marginBottom: "100px",
    lineHeight: "1.5em"
  }
});

const SuccessfullyVerifiedCardModal = ({ lastFour }) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <div className={classes.check}>
        <Check width={100} height={100} color={theme.palette.info.default} />
      </div>
      <Typography variant={K.typographicVariants.heading1}>
        {lastFour
          ? intl.formatMessage(
              locales.verify_card_flow_card_successfully_verified_modal_with_card,
              {
                lastFour
              }
            )
          : intl.formatMessage(
              locales.verify_card_flow_card_successfully_verified_modal_without_card
            )}
      </Typography>
      <div className={classes.modal__content}>
        <Typography variant={K.typographicVariants.body1}>
          {intl.formatMessage(
            locales.verify_card_flow_card_successfully_verified_modal_message
          )}
        </Typography>
      </div>
      <div className={classes.success_plan_modal__footer}>
        <Button
          fullWidth
          variant="text"
          size="large"
          onClick={() => history.push(location.pathname)}
        >
          <Typography variant={K.typographicVariants.buttonL}>
            {intl.formatMessage(locales.general_button_close)}
          </Typography>
        </Button>
      </div>
    </>
  );
};

SuccessfullyVerifiedCardModal.propTypes = {
  lastFour: PropTypes.string
};

SuccessfullyVerifiedCardModal.defaultProps = {
  lastFour: null
};

export default withModal(SuccessfullyVerifiedCardModal, {
  withIconClose: true,
  open: true,
  hideBack: true
});
