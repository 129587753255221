import store from "store";
import { useHistory } from "react-router";
import { useSetState } from "react-use";
import useTrackingContext from "../../../contexts/Tracking";
import locales from "../../../locales";
import useCreateOtp from "./mutations/useCreateOtp";

const OTP_ENCRYPTED_SELECTORS = "EncryptedSelectors";
const OTP_INVALID_USER = "InvalidUser";
const OTP_INVALID_ARGUMENTS = "InvalidArguments";
const OTP_LOCKED_USER = "LockedUser";

export const initialState = {
  error: undefined // error is a locale object
};

export default () => {
  const [state, setState] = useSetState(initialState);
  const history = useHistory();
  const { track } = useTrackingContext();

  const [createOTPMutation, { loading: creatingOtp }] = useCreateOtp();

  const createOTP = async ({ nin, emailOrPhone, keepSessionAuthorized }) => {
    const response = await createOTPMutation({
      variables: { nin, emailOrPhone, keepSessionAuthorized }
    });
    const { createOtp: otpResponseData } = response.data;

    if (otpResponseData.__typename !== OTP_ENCRYPTED_SELECTORS) {
      let otpErrorLocale;
      switch (otpResponseData.__typename) {
        case OTP_INVALID_ARGUMENTS:
        case OTP_INVALID_USER:
          otpErrorLocale = locales.login_error_invalid_arguments;
          break;
        case OTP_LOCKED_USER:
          otpErrorLocale = locales.login_error_locked_user;
          break;
        default:
          break;
      }
      setState({ error: otpErrorLocale });
      return;
    }

    store.set("otpHash", otpResponseData.otpHash);
    track("Session Creation Start", {
      selectorType: otpResponseData.selectorType
    });
    history.push(`/validation/${otpResponseData.selectorType}`);
  };

  const handleSubmitLoginForm = (nin, emailOrPhone, keepSessionAuthorized) => {
    if (!nin && !emailOrPhone) {
      setState({ error: locales.login_form_error, fetching: false });
    } else if (!nin) {
      setState({ error: locales.login_form_error_no_dni, fetching: false });
    } else if (!emailOrPhone) {
      setState({
        error: locales.login_form_error_no_email_or_phone,
        fetching: false
      });
    } else {
      createOTP({ nin, emailOrPhone, keepSessionAuthorized });
    }
  };

  return {
    ...state,
    creatingOtp,
    handleSubmitLoginForm
  };
};
