import React from "react";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import locales from "../../locales";
import Typography from "../Typography";
import K from "../../constants";
import { Phone, WhatsApp, Faqs } from "../Icons";

const useStyles = createUseStyles({
  help_button_wrapper: {
    display: "flex"
  },
  help_button_text: {
    textDecoration: "underline",
    marginLeft: "12px",
    whiteSpace: "nowrap"
  }
});

const HelpButton = ({ medium, phoneNumber }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const iconProps = {
    width: 20,
    height: 20,
    color: theme.palette.primary.default
  };

  const renderIcon = () => {
    switch (medium) {
      case "whatsapp":
        return <WhatsApp {...iconProps} />;
      case "renting":
        return <Faqs {...iconProps} />;
      default:
        return <Phone {...iconProps} width={18} height={18} />;
    }
  };
  const labelIcon = () => {
    switch (medium) {
      case "whatsapp":
        return intl.formatMessage(locales.order_help_whatsapp);
      case "renting":
        return intl.formatMessage(locales.faqs_button_rentik);
      default:
        return phoneNumber;
    }
  };
  return (
    <div className={classes.help_button_wrapper}>
      {renderIcon()}
      <Typography
        variant={K.typographicVariants.linkL}
        color={theme.palette.primary.default}
        className={classes.help_button_text}
      >
        {labelIcon()}
      </Typography>
    </div>
  );
};

HelpButton.propTypes = {
  medium: PropTypes.string,
  phoneNumber: PropTypes.string
};

HelpButton.defaultProps = {
  medium: "",
  phoneNumber: ""
};

export default HelpButton;
