export default rentingConfiguration => {
  const instalment = charge => ({
    ...charge,
    instalmentAmount: charge.amount,
    instalmentFee: 0,
    instalmentNumber: charge.number,
    instalmentTotal: charge.amount,
    lateFeesAmount: 0
  });

  const agreedAmount = 0;
  const instalmentFee = 0;
  const instalmentAmount = rentingConfiguration.monthlyTotal;
  const futureInstalments = rentingConfiguration.futureCharges.map(instalment);
  const pastInstalments = rentingConfiguration.pastCharges.map(instalment);
  const instalments = [...futureInstalments, ...pastInstalments];

  return {
    ...rentingConfiguration,
    agreedAmount,
    futureInstalments,
    pastInstalments,
    instalments,
    instalmentFee,
    instalmentAmount
  };
};
