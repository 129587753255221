import React from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";

const Flag = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 19 24">
    <path
      d="M1.051 3.023c.44-.102.889.143 1 .547L7.43 23.058c.112.405-.155.816-.596.919A.896.896 0 016.63 24c-.367 0-.702-.228-.797-.57L.455 3.942c-.111-.405.155-.816.596-.919zM14.983 0l.413.017c.548.035.694.38.777.703l2.302 8.937c.083.322-.135.509-.476.465-1.948-.149-3.595.692-5.212 1.627l-1.038.606c-1.617.936-3.263 1.776-5.211 1.628-.34-.044-.653-.225-.778-.703-.773-2.977-1.534-5.958-2.302-8.937-.083-.322.136-.509.476-.465 4.315.329 7.147-4.19 11.462-3.86zm-3.616 8.355c-.812.5-1.622.983-2.482 1.387l.645 2.502c.86-.404 1.67-.887 2.481-1.387l-.644-2.502zM8.163 6.94l-.33.131a7.158 7.158 0 01-2.356.484l.722 2.801c.954-.029 1.8-.246 2.686-.614l-.36-1.401-.362-1.4zm8.283-.531c-.901.03-1.698.221-2.542.552l.645 2.502c.843-.331 1.64-.521 2.542-.552l-.645-2.502zm-6.1-2.099l-.318-1.233c-.818.504-1.634.99-2.5 1.398l.635 2.465c.866-.407 1.682-.894 2.5-1.397l.721 2.802c.655-.402 1.308-.79 1.99-1.13l.515-.248-.722-2.801c-.87.395-1.687.875-2.504 1.377l-.318-1.233zm4.744-3.168c-.908.031-1.709.224-2.558.558l.635 2.466c.85-.335 1.65-.527 2.558-.559l-.635-2.465z"
      fill={color}
    />
  </svg>
);

Flag.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

Flag.defaultProps = {
  width: 24,
  height: 24,
  color: theme.palette.secondary.default
};

export default Flag;
